import React, { useEffect, useState } from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCardTitle,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CFormLabel,
  CFormTextarea,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from "@coreui/react";
import { DataTableComponent, PrimaryButton } from "../../components";
import axios from "../../api/axios";
import { toast } from "react-toastify";
import { FaTrash, FaPencilAlt } from "react-icons/fa";
import { isValidDescription, isValidName } from "../../utils/validation";

const RawMaterialMainCategory = () => {
  const accessToken = localStorage.getItem("authToken");
  const [inputData, setInputData] = useState({
    name: "",
    description: "",
  });
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [rawMateialList, setRawMateialList] = useState([]);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [editedData, setEditedData] = useState({});
  const [categoryId, setCategoryId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isEditBtnLoading, setIsEditBtnLoading] = useState(false);
  const [isDeleteBtnLoading, setIsDeleteBtnLoading] = useState(false);
  const [pagination, setPagination] = useState({ page: 1, rowsPerPage: 10 });

  const columns = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      cell: (row) => (
        <div className="fs-7">{row.serialNumber ? row.serialNumber : "--------"}</div>
      ),
      width: "70px",
    },
    {
      name: "NAME",
      selector: (row) => row.name,
      cell: (row) => (
        <div className="fs-7">{row.name ? row.name : "--------"}</div>
      ),
    },
    {
      name: "DESCRIPTION",
      selector: (row) => row.description,
      cell: (row) => (
        <div className="fs-7">
          {row.description ? row.description : "--------"}
        </div>
      ),
      width: "250px",
    },
    {
      name: "ACTIONS",
      right: "true",
      cell: (row) => (
        <div className="d-flex justify-content-around align-items-center">
          <a
            href="#"
            className="text-primary me-2 d-flex align-items-center gap-1"
            style={{ cursor: "pointer", textDecoration: "none" }}
            onClick={() => handleEditModal(row)}
          >
            <FaPencilAlt /> Edit
          </a>{" "}
          |
          <a
            href="#"
            className="text-danger ms-2 d-flex align-items-center gap-1"
            style={{ cursor: "pointer", textDecoration: "none" }}
            onClick={() => handleDeleteModal(row)}
          >
            <FaTrash /> Delete
          </a>
        </div>
      ),
      minWidth: "165px",
    },
  ];

  const updatedColumns = columns.map((column) => ({
    ...column,
    name: (
      <div style={{ fontSize: "13px", fontWeight: "bold" }}>{column.name}</div>
    ),
  }));

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputData((prevState) => ({ ...prevState, [name]: value }));
  };

  const addRmMainCategory = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      const response = await axios.post(
        "masters/rm-main-category/add",
        inputData,
        { headers }
      );

      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
        getRawMaterials();

        setInputData({
          name: "",
          description: "",
        });
      }
    } catch (error) {
      if (error?.response) {
        let errorMessage = '';
        if (
          error.response?.status === 406
        ) {
          errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
        }
        else if (
          error.response?.status === 412
        ) {
          errorMessage = error?.response?.data?.data?.message;
        } else if (error.response?.status === 500) {
          errorMessage = error?.response?.data?.error
        }

        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getRawMaterials = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get("masters/rm-main-category/get", {
        headers,
      });

      if (response?.data?.status === true) {
        response?.data?.data?.categories.forEach((item, index) => {
          item.serialNumber = index + 1;
        });

        setRawMateialList(response?.data?.data?.categories);
        setFilteredData(response?.data?.data?.categories);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const handleEditModal = async (data) => {
    setEditedData({
      id: data._id,
      name: data.name,
      description: data.description,
    });
    setOpenEditModal(true);
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditedData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    setIsEditBtnLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.put(
        "masters/rm-main-category/update",
        editedData,
        { headers }
      );
      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
        getRawMaterials();
        setOpenEditModal(false);
      }
    } catch (error) {
      if (error?.response) {
        let errorMessage = '';
        if (
          error.response?.status === 406
        ) {
          errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
        }
        else if (
          error.response?.status === 412
        ) {
          errorMessage = error?.response?.data?.data?.message;
        } else if (error.response?.status === 500) {
          errorMessage = error?.response?.data?.error
        }

        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      setIsEditBtnLoading(false);
    }
  };

  const handleDeleteModal = async (data) => {
    setOpenDeleteModal(true);
    setCategoryId(data?._id);
  };

  const handleDeleteRawMaterialMain = async (e) => {
    e.preventDefault();
    setIsDeleteBtnLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.delete(
        `masters/rm-main-category/delete?id=${categoryId}`,
        { headers }
      );
      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
        getRawMaterials();
        setOpenDeleteModal(false);
      }
    } catch (error) {
      if (error.response.status === 403) {
        toast.error(error?.response?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      setIsDeleteBtnLoading(false);
    }
  };

  // const disableButton =
  //   !inputData.name.trim() ||
  //   inputData.name.trim() === "" ||
  //   !inputData.description.trim() ||
  //   inputData.description.trim() === "";

  // const editButtonDisable =
  //   !editedData ||
  //   !editedData.name ||
  //   !editedData.name.trim() ||
  //   !editedData.description ||
  //   !editedData.description.trim();

  const handlePageChange = (page) => {
    setPagination({ ...pagination, page });
  };
  
  const handleRowsPerPageChange = (rowsPerPage) => {
    setPagination({ page: 1, rowsPerPage }); // Reset to the first page when changing rows per page
  };
  
  // Function to get paginated data
  const getPaginatedData = () => {
    const startIndex = (pagination.page - 1) * pagination.rowsPerPage;
    const endIndex = startIndex + pagination.rowsPerPage;
    return filteredData.slice(startIndex, endIndex);
  };

  useEffect(() => {
    getRawMaterials();
  }, []);

  useEffect(() => {
    const result = rawMateialList.filter((item) => {
      return (
        item.name.toLowerCase().match(searchText.toLocaleLowerCase()) ||
        item.description.toLowerCase().match(searchText.toLocaleLowerCase())
      );
    });
    setFilteredData(result);
  }, [searchText]);

  return (
    <>
      <CContainer>
        <CRow>
          <CCol className="col-md-10 col-lg-4 mx-md-auto mb-md-3">
            <CCard style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}>
              <CCardHeader>
                <CCardTitle className="fs-6 fw-semibold pt-1">
                  ADD RAW MATERIAL MAIN CATEGORY
                </CCardTitle>
              </CCardHeader>
              <CForm onSubmit={addRmMainCategory}>
                <CCardBody>
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Name <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      type="text"
                      placeholder="Name"
                      className="form-control"
                      name="name"
                      value={inputData.name}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Description <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormTextarea
                      rows={4}
                      type="text"
                      placeholder="Description"
                      className="form-control"
                      name="description"
                      value={inputData.description}
                      onChange={handleChange}
                    />
                  </div>
                </CCardBody>
                <CCardFooter>
                  <PrimaryButton
                    // isDisable={disableButton}
                    title="ADD MAIN CATEGORY"
                    isLoading={isLoading}
                  />
                </CCardFooter>
              </CForm>
            </CCard>
          </CCol>

          <CCol className="col-md-12 col-lg-8">
            <div>
              <DataTableComponent
                columns={updatedColumns}
                // data={filteredData}
                data={getPaginatedData()}
                title="RAW MATERIAL MAIN CATEGORIES LIST"
                searchText={searchText}
                onChange={handleSearchChange}
                pagination
                totalRows={filteredData?.length}
                paginationTotalRows={filteredData.length} // Total number of items
                onChangeRowsPerPage={handleRowsPerPageChange}
                onChangePage={handlePageChange}
                paginationServer={false} // Set to false for client-side pagination
              />
            </div>
          </CCol>
        </CRow>
      </CContainer>

      {openEditModal ? (
        <CModal
          alignment="center"
          visible={openEditModal}
          onClose={() => setOpenEditModal(false)}
          aria-labelledby="VerticallyCenteredExample"
        >
          <CModalHeader>
            <CModalTitle id="VerticallyCenteredExample" className="fs-6">
              EDIT RAW MATERIAL MAIN CATEGORY DETAILS
            </CModalTitle>
          </CModalHeader>
          <CForm onSubmit={handleEditSubmit}>
            <CModalBody>
              <div className="mb-3">
                <CFormLabel className="fw-semibold">
                  Name <span className="text-danger">*</span>
                </CFormLabel>
                <CFormInput
                  type="text"
                  placeholder="Name"
                  className="form-control"
                  name="name"
                  value={editedData.name}
                  onChange={handleEditChange}
                />
              </div>

              <div className="mb-3">
                <CFormLabel className="fw-semibold">
                  Description <span className="text-danger">*</span>
                </CFormLabel>
                <CFormTextarea
                  rows={4}
                  type="text"
                  placeholder="Description"
                  className="form-control"
                  name="description"
                  value={editedData.description}
                  onChange={handleEditChange}
                />
              </div>
            </CModalBody>
            <CModalFooter>
              <PrimaryButton
                // isDisable={editButtonDisable}
                title="SAVE CHANGES"
                isLoading={isEditBtnLoading}
              />
            </CModalFooter>
          </CForm>
        </CModal>
      ) : null}

      {openDeleteModal ? (
        <CModal
          alignment="center"
          visible={openDeleteModal}
          onClose={() => setOpenDeleteModal(false)}
          aria-labelledby="VerticallyCenteredExample"
        >
          <CModalHeader>
            <CModalTitle id="VerticallyCenteredExample" className="fs-6">
              DELETE RAW MATERIAL MAIN CATEGORY
            </CModalTitle>
          </CModalHeader>
          <CForm onSubmit={handleDeleteRawMaterialMain}>
            <CModalBody>
              Are you sure you want to delete this raw material main category ?
            </CModalBody>
            <CModalFooter>
              <CButton
                color="warning"
                onClick={() => setOpenDeleteModal(false)}
                style={{
                  borderRadius: "18px",
                }}
              >
                CLOSE
              </CButton>
              <PrimaryButton title="CONFIRM" isLoading={isDeleteBtnLoading} />
            </CModalFooter>
          </CForm>
        </CModal>
      ) : null}
    </>
  );
};

export default RawMaterialMainCategory;
