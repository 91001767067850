import React, { useEffect, useState } from 'react'
import { CCard, CCardBody, CCardFooter, CCardHeader, CCardTitle, CCol, CForm, CFormInput, CFormLabel, CFormSelect, CFormTextarea, CRow } from '@coreui/react'
import { PrimaryButton } from '../../components'
import Select from "react-select";
import DatePicker from "react-datepicker";
import axios from '../../api/axios';
import { toast } from 'react-toastify';
import makeAnimated from "react-select/animated";
import { format } from 'date-fns';

const BOGOCoupon = ({ outlets, selectedOptions, setSelectedOptions, setSelectedCouponOption }) => {
    const accessToken = localStorage.getItem("authToken");
    const [bogoCouponInputData, setBogoCouponInputData] = useState({
        isBogoCoupon: true,
        isHidden: "",
        title: "",
        couponCode: "",
        description: "",
        usageType: "",
        validityStartDate: "",
        validityEndDate: "",
    });
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [bogoBtnLoading, setBogoBtnLoading] = useState(false);
    const animatedComponents = makeAnimated();
    const outletData = outlets.map((outlet) => ({
        value: outlet._id,
        label: `${outlet.name} (${outlet.city})`
    }));

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            width: "100%", // Adjust width based on your requirement
            borderRadius: '6px',
            minWidth: "200px", // Add a minimum width to avoid layout issues
            borderColor: state.isFocused ? "#80bdff" : "#ced4da",
            boxShadow: state.isFocused ? "0 0 0 0.2rem rgba(0,123,255,.25)" : null,
            "&:hover": {
                borderColor: state.isFocused ? "#80bdff" : "#ced4da",
            },
        }),
        placeholder: (provided) => ({
            ...provided,
            color: "#6c757d"
        }),
    };

    const handleSelectChange = (selected) => {
        setSelectedOptions(selected);
    };

    const handleFromDateChange = (date) => {
        if (date) {
            setFromDate(date);
        } else {
            console.error("Received null or undefined date");
        }
    };

    const handleToDateChange = (date) => {
        if (date) {
            setToDate(date);
        } else {
            console.error("Received null or undefined date");
        }
    };

    const handleBogoInputChange = (e) => {
        const { name, value } = e.target;
        setBogoCouponInputData((prev) => ({ ...prev, [name]: value }));
    };

    const submitBogoCouponDetails = async (e) => {
        e.preventDefault();
        setBogoBtnLoading(true);
        let selectedOutlet = selectedOptions.map((option) => option.value);
        let bogoCouponData = {
            ...bogoCouponInputData,
            excludedOutlets: selectedOutlet
        }

        if(bogoCouponData?.excludedOutlets?.length === 0) {
            delete bogoCouponData?.excludedOutlets
        }

        if (bogoCouponData?.validityStartDate === "") {
            delete bogoCouponData.validityStartDate
        }

        if (bogoCouponData?.validityEndDate === "") {
            delete bogoCouponData.validityEndDate
        }

        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };

            const response = await axios.post("coupons", bogoCouponData, {
                headers,
            });

            if (response?.data?.status === true) {
                toast.success(response?.data?.data?.message, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
                e.target.reset()
                setSelectedOptions([])
                setBogoCouponInputData({
                    title: "",
                    isHidden: "",
                    couponCode: "",
                    description: "",
                    usageType: "",
                });
                setFromDate(null);
                setToDate(null);
                setSelectedCouponOption("");
            }
        } catch (error) {
            if (error?.response) {
                let errorMessage = '';
                if (
                    error.response?.status === 406
                ) {
                    errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
                }
                else if (
                    error.response?.status === 412
                ) {
                    errorMessage = error?.response?.data?.data?.message;
                } else if (error.response?.status === 500) {
                    errorMessage = error?.response?.data?.error
                }

                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        } finally {
            setBogoBtnLoading(false);
        }
    };

    const bogoBtnDisable = () => {
        let isDisable = false;

        if (
            !bogoCouponInputData?.title?.trim() ||
            !bogoCouponInputData?.couponCode?.trim() ||
            !bogoCouponInputData?.description?.trim() ||
            !bogoCouponInputData?.usageType
            // !bogoCouponInputData?.validityEndDate ||
            // !bogoCouponInputData?.validityStartDate
        ) {
            isDisable = true;
        }

        return isDisable;
    };

    useEffect(() => {
        const formattedStartDate = fromDate ? format(fromDate, "yyyy-MM-dd") : "";
        const formattedEndDate = toDate ? format(toDate, "yyyy-MM-dd") : "";

        setBogoCouponInputData((prev) => ({
            ...prev,
            validityStartDate: formattedStartDate,
            validityEndDate: formattedEndDate,
        }));
    }, [fromDate, toDate]);

    return (
        <CCard>
            <CCardHeader>
                <CCardTitle className="fs-6">ADD BOGO COUPON</CCardTitle>
            </CCardHeader>
            <CForm onSubmit={submitBogoCouponDetails}>
                <CCardBody>
                    <CRow className="mb-3">
                        <CCol md='4'>
                            <div>
                                <CFormLabel className="fw-semibold">
                                    Title <span className="text-danger">*</span>
                                </CFormLabel>
                                <CFormInput
                                    placeholder="Title"
                                    name="title"
                                    value={bogoCouponInputData.title}
                                    onChange={handleBogoInputChange}
                                />
                            </div>
                        </CCol>
                        <CCol md='4'>
                            <div>
                                <CFormLabel className="fw-semibold">
                                    Coupon Code <span className="text-danger">*</span>
                                </CFormLabel>
                                <CFormInput
                                    placeholder="Coupon Code"
                                    name="couponCode"
                                    value={bogoCouponInputData.couponCode}
                                    onChange={handleBogoInputChange}
                                />
                            </div>
                        </CCol>
                        <CCol md='4' className='mb-3'>
                            <div>
                                <CFormLabel className="fw-semibold">
                                    Usage Type <span className="text-danger">*</span>
                                </CFormLabel>
                                <CFormSelect
                                    name="usageType"
                                    value={bogoCouponInputData.usageType}
                                    onChange={handleBogoInputChange}
                                >
                                    <option value="">Select Usage</option>
                                    <option value="MULTIPLE">MULTIPLE</option>
                                    <option value="ONE-TIME">ONE-TIME</option>
                                </CFormSelect>
                            </div>
                        </CCol>
                        <CCol md='4'>
                            <div>
                                <CFormLabel className="fw-semibold">
                                    Validity Start Date
                                </CFormLabel>
                                <DatePicker
                                    selected={fromDate ? new Date(fromDate) : null}
                                    onChange={handleFromDateChange}
                                    placeholderText="Start Date"
                                    className="form-control"
                                />
                            </div>
                        </CCol>
                        <CCol md='4'>
                            <div>
                                <CFormLabel className="fw-semibold">
                                    Validity End Date
                                </CFormLabel>
                                <DatePicker
                                    selected={toDate ? new Date(toDate) : null}
                                    onChange={handleToDateChange}
                                    placeholderText="End Date"
                                    className="form-control w-100"
                                />
                            </div>
                        </CCol>
                        <CCol md='4' className='mb-3'>
                            <div>
                                <CFormLabel className="fw-semibold">
                                    Hide Coupon
                                </CFormLabel>
                                <CFormSelect
                                    name="isHidden"
                                    value={bogoCouponInputData?.isHidden}
                                    onChange={handleBogoInputChange}
                                >
                                    <option value="">Select Option</option>
                                    <option value="true">YES</option>
                                    <option value="false">NO</option>
                                </CFormSelect>
                            </div>
                        </CCol>
                    </CRow>

                    <div className="mb-3">
                        <CFormLabel className="fw-semibold">
                            Select Excluded Outlets
                        </CFormLabel>

                        <Select
                            isMulti
                            components={animatedComponents}
                            value={selectedOptions}
                            onChange={handleSelectChange}
                            options={outletData}
                            styles={customStyles}
                            placeholder="Select Excluded Outlets"
                            closeMenuOnSelect={false}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                    ...theme.colors,
                                    primary25: "#e9ecef",
                                    primary: "#007bff",
                                },
                            })}
                        />
                    </div>
                    <div className="mb-3">
                        <CFormLabel className="fw-semibold">
                            Description <span className="text-danger">*</span>
                        </CFormLabel>
                        <CFormTextarea
                            rows={4}
                            placeholder="Description"
                            name="description"
                            value={bogoCouponInputData.description}
                            onChange={handleBogoInputChange}
                        />
                    </div>
                </CCardBody>
                <CCardFooter>
                    <PrimaryButton
                        isDisable={bogoBtnDisable()}
                        isLoading={bogoBtnLoading}
                        title="ADD BOGO COUPON"
                    />
                </CCardFooter>
            </CForm>
        </CCard>
    )
}

export default BOGOCoupon
