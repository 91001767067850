const isValidName = (name) => {
  const validNameRegex = /^[A-Za-z\s'-]+$/;

  // Check if the name contains only symbols (without any alphabets)
  if (!validNameRegex.test(name)) {
    return false;
  }

  // Check if the name contains at least one alphabet character
  if (!/[A-Za-z]/.test(name)) {
    return false;
  }

  return true;
};


const isValidDescription = (description) => {
  const validDescriptionRegex = /^[A-Za-z\s,.-]+$/;

  if (!validDescriptionRegex.test(description)) {
    return false;
  }

  return true;
};

export { isValidName, isValidDescription };
