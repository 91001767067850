import AppRoutes from "./AppRoutes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import './scss/style.scss'
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import useDynamicTitle from "./hook/useDynamicTitle";
import 'stories-react/dist/index.css';

function App() {
  useDynamicTitle()
  return (
    <div className="App">
      <AppRoutes />
      <ToastContainer position="top-right" autoClose={3000} theme="colored" pauseOnHover={false} />
    </div>
  );
}

export default App;
