import {
  Dashboard,
  Category,
  RawMaterialSubCategory,
  Outlets,
  Vendors,
  Roles,
  RawMaterialMainCategory,
  OutletUsers,
  RawMaterials,
  Products,
  ProductAddOn,
  Procurements,
  ProductsCatalog,
  ProcurementCatalog,
  StockTransfer,
  RmStockReports,
  AddCoupon,
  CouponCard,
  AddStory,
  ViewAdvertisement,
  AddAdvertisement,
  Level,
  LevelRewards,
  ViewLevelRewards,
  Add,
  CustomerReport,
  OrdersReport,
  KioskUserReport,
  ProductSalesReport,
  AddonWiseReport,
  CustomerSupportMgt,
  DaySummaryReport,
  AssetType,
  Assets,
  StorageLocation,
  AssetsStockTransaction,
  AssetStockTransactionReport,
  UserCouponReport,
  StockTransactionReport,
  ProductIngredients,
  RMReorderLevelReport
} from "./screens";

const routes = [
  { path: "/", exact: true, name: "Home" },
  { path: "/dashboard", name: "Dashboard", element: Dashboard },
  {
    path: "/raw-materials-management",
    name: "Raw Material Management",
    exact: true,
  },
  {
    path: "/masters/rm-main-category",
    name: "Raw Material Main Category",
    element: RawMaterialMainCategory,
  },
  {
    path: "/masters/rm-sub-category",
    name: "Raw Material Sub Category",
    element: RawMaterialSubCategory,
  },
  {
    path: "/masters/rawmaterials",
    name: "Raw Materials",
    element: RawMaterials,
  },

  { path: "/vendors", name: "Vendors", element: Vendors },
  { path: "/masters", name: "Masters", exact: true },
  { path: "/masters/admin-roles", name: "Admin Roles", element: Roles },
  { path: "/customer-support", name: "Customer Support Management", element: CustomerSupportMgt },
  { path: "/cancellation-request", name: "Cancellation Requests", element: Vendors },

  { path: "/outlet-management", name: "Outlet Management", exact: true },
  { path: "/outlet-management/outlets", name: "Outlets", element: Outlets },
  {
    path: "/outlet-management/outletusers",
    name: "Outlet Users",
    element: OutletUsers,
  },

  { path: "/product-management", name: "Product Management", exact: true },
  {
    path: "/masters/categories",
    name: "Category",
    element: Category,
  },
  {
    path: "/product-management/add-product",
    name: "Add Product",
    element: Products,
  },
  {
    path: "/product-management/products-catalog",
    name: "Products Catalog",
    element: ProductsCatalog,
  },
  {
    path: "/product-management/product-addon",
    name: "Product Addon",
    element: ProductAddOn,
  },
  {
    path: "/product-management/product-ingredients",
    name: "Product Ingredients",
    element: ProductIngredients,
  },
  { path: "/reports", name: "Reports", exact: true },
  {
    path: "/reports/customer-report",
    name: "Customer Reports",
    element: CustomerReport,
  },
  {
    path: "/reports/order-report",
    name: "Orders Reports",
    element: OrdersReport,
  },
  {
    path: "/reports/kiosk-user-report",
    name: "Kiosk User Reports",
    element: KioskUserReport,
  },
  {
    path: "/reports/product-report",
    name: "Product Sales Reports",
    element: ProductSalesReport,
  },
  {
    path: "/reports/addon-report",
    name: "Addon Wise Reports",
    element: AddonWiseReport,
  },
  {
    path: "/reports/day-summary-report",
    name: "Day Summary Reports",
    element: DaySummaryReport,
  },
  {
    path: "/reports/month-summary-report",
    name: "Month Summary Report",
    element: DaySummaryReport,
  },
  {
    path: "/reports/user-coupon-report",
    name: "User Coupon Reports",
    element: UserCouponReport,
  },
  {
    path: "/reports/rm-reorder-level-report",
    name: "RM Reorder Level Report",
    element: RMReorderLevelReport,
  },
  {
    path: "/reports/stock-transaction-report",
    name: "Stock Transation Reports",
    element: StockTransactionReport,
  },
  {
    path: "/masters/asset-type",
    name: "Asset Type",
    element: AssetType,
  },
  { path: "/asset-management", name: "Asset Management", exact: true },
  {
    path: "/asset-management/assets",
    name: "Add New Assets",
    element: Assets,
  },
  {
    path: "/asset-management/storage-type",
    name: "Storage Location",
    element: StorageLocation,
  },
  {
    path: "/asset-management/assets-stock-transaction",
    name: "Asset Stock Transaction",
    element: AssetsStockTransaction,
  },
  {
    path: "/asset-management/asset-stock-transaction-report",
    name: "Asset Stock Transaction Report",
    element: AssetStockTransactionReport,
  },
  { path: "/central-inventory", name: "Central Inventory", exact: true },
  {
    path: "/central-inventory/add-procurement",
    name: "Add Procurement",
    element: Procurements,
  },
  {
    path: "/central-inventory/procurements-list",
    name: "Procurements List",
    element: ProcurementCatalog,
  },
  {
    path: "/central-inventory/stock-transfer",
    name: "Stock Transfer",
    element: StockTransfer,
  },
  {
    path: "/central-inventory/rm-stock-reports",
    name: "RM Stock Reports",
    element: RmStockReports,
  },
  { path: "/coupons", name: "Coupons", exact: true },
  {
    path: "/coupons/add",
    name: "Add",
    element: AddCoupon,
  },
  {
    path: "/coupons/view",
    name: "View",
    element: CouponCard,
  },

  { path: "/content-management", name: "Content-Management", exact: true },
  {
    path: "/content-management/stories",
    name: "Stories",
    element: AddStory,
  },
  {
    path: "/content-management/view-advertisement",
    name: "View Advertisement",
    element: ViewAdvertisement,
  },
  {
    path: "/content-management/add-advertisement",
    name: "Add Advertisement",
    element: AddAdvertisement,
  },
  {
    path: "/masters/level",
    name: "LEVEL",
    element: Level,
  },
  {
    path: "/level-rewards/add",
    name: "Level Rewards / Add",
    element: LevelRewards,
  },
  {
    path: "/level-rewards/view",
    name: "Level Rewards / View",
    element: ViewLevelRewards,
  },
  {
    path: "/user-coupon-allocation",
    name: "User Coupon Allocation",
    element: Add,
  },
];

export default routes;
