import React, { useEffect, useState } from 'react'
import { CCard, CCardBody, CCardFooter, CCardHeader, CCardTitle, CCol, CForm, CFormInput, CFormLabel, CFormSelect, CFormTextarea, CRow } from '@coreui/react';
import { PrimaryButton } from '../../components';
import Select from "react-select";
import DatePicker from "react-datepicker";
import makeAnimated from "react-select/animated";
import { format } from "date-fns";
import axios from '../../api/axios';
import { toast } from 'react-toastify';

const UniversalCoupon = ({ outlets, selectedOptions, setSelectedOptions, setSelectedCouponOption }) => {
    const accessToken = localStorage.getItem("authToken");
    const animatedComponents = makeAnimated();
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const outletData = outlets.map((outlet) => ({
        value: outlet._id,
        label: `${outlet.name} (${outlet.city})`,
    }));

    const [generalCouponInputData, setGeneralCouponInputData] = useState({
        isHidden: "",
        title: "",
        couponCode: "",
        description: "",
        usageType: "",
        discountPercent: "",
        validityStartDate: "",
        validityEndDate: "",
    });
    const [generalBtnLoading, setGeneralBtnLoading] = useState(false);

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            width: "100%", // Adjust width based on your requirement
            borderRadius: "6px",
            minWidth: "200px", // Add a minimum width to avoid layout issues
            borderColor: state.isFocused ? "#80bdff" : "#ced4da",
            boxShadow: state.isFocused ? "0 0 0 0.2rem rgba(0,123,255,.25)" : null,
            "&:hover": {
                borderColor: state.isFocused ? "#80bdff" : "#ced4da",
            },
        }),
        placeholder: (provided) => ({
            ...provided,
            color: "#6c757d",
        }),
    };

    const handleSelectChange = (selected) => {
        setSelectedOptions(selected);
    };

    const handleFromDateChange = (date) => {
        if (date) {
            setFromDate(date);
        } else {
            console.error("Received null or undefined date");
        }
    };

    const handleToDateChange = (date) => {
        if (date) {
            setToDate(date);
        } else {
            console.error("Received null or undefined date");
        }
    };

    const handlegeneralInputChange = (e) => {
        const { name, value } = e.target;

        const numericFields = [
            "discountAmount",
        ];
        if (numericFields.includes(name)) {
            const numberValue = Number(value);
            if (numberValue >= 0 || value === "") {
                setGeneralCouponInputData((prev) => {
                    if (name === "discountPercent" && value === "") {
                        return {
                            ...prev,
                            [name]: value,
                            maxDiscountAmount: "",
                        };
                    }
                    return { ...prev, [name]: value };
                });
            }
        } else {
            setGeneralCouponInputData((prev) => ({ ...prev, [name]: value }));
        }
    };

    const submitGeneralCouponDetails = async (e) => {
        e.preventDefault();
        setGeneralBtnLoading(true);
        let selectedOutlet = selectedOptions.map((option) => option.value);
        let generalCouponData = {
            ...generalCouponInputData,
            excludedOutlets: selectedOutlet,
        };

        if (generalCouponData?.excludedOutlets?.length === 0) {
            delete generalCouponData?.excludedOutlets
        }

        if (generalCouponData?.validityStartDate === "") {
            delete generalCouponData.validityStartDate
        }
        if (generalCouponData?.validityEndDate === "") {
            delete generalCouponData.validityEndDate
        }
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };

            const response = await axios.post("coupons", generalCouponData, {
                headers,
            });

            if (response?.data?.status === true) {
                toast.success(response?.data?.data?.message, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
                e.target.reset();
                setSelectedOptions([]);
                setSelectedCouponOption("");
                setGeneralCouponInputData({
                    isHidden: "",
                    title: "",
                    couponCode: "",
                    description: "",
                    usageType: "",
                    discountPercent: "",
                    validityStartDate: "",
                    validityEndDate: "",
                });
                setFromDate(null);
                setToDate(null);
            }
        } catch (error) {
            if (error?.response) {
                let errorMessage = "";
                if (error.response?.status === 406) {
                    errorMessage =
                        error?.response?.data?.error?.message ||
                        error?.response?.data?.error;
                } else if (error.response?.status === 412) {
                    errorMessage =
                        error?.response?.data?.data?.message ||
                        error?.response?.data?.data?.title;
                } else if (error.response?.status === 500) {
                    errorMessage = error?.response?.data?.error;
                }

                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        } finally {
            setGeneralBtnLoading(false);
        }
    };

    const generalBtnDisable = () => {
        let isDisable = false;

        if (
            !generalCouponInputData?.title?.trim() ||
            !generalCouponInputData?.couponCode?.trim() ||
            !generalCouponInputData?.description?.trim() ||
            !generalCouponInputData?.usageType
        ) {
            isDisable = true;
        }

        return isDisable;
    };

    useEffect(() => {
        const formattedStartDate = fromDate ? format(fromDate, "yyyy-MM-dd") : "";
        const formattedEndDate = toDate ? format(toDate, "yyyy-MM-dd") : "";

        setGeneralCouponInputData((prev) => ({
            ...prev,
            validityStartDate: formattedStartDate,
            validityEndDate: formattedEndDate,
        }));
    }, [fromDate, toDate]);


    return (
        <CCard>
            <CCardHeader>
                <CCardTitle className="fs-6">ADD UNIVERSAL COUPON</CCardTitle>
            </CCardHeader>
            <CForm onSubmit={submitGeneralCouponDetails}>
                <CCardBody>
                    <CRow className="mb-3">
                        <CCol>
                            <div>
                                <CFormLabel className="fw-semibold">
                                    Title <span className="text-danger">*</span>
                                </CFormLabel>
                                <CFormInput
                                    placeholder="Title"
                                    name="title"
                                    value={generalCouponInputData.title}
                                    onChange={handlegeneralInputChange}
                                />
                            </div>
                        </CCol>
                        <CCol>
                            <div>
                                <CFormLabel className="fw-semibold">
                                    Coupon Code <span className="text-danger">*</span>
                                </CFormLabel>
                                <CFormInput
                                    placeholder="Coupon Code"
                                    name="couponCode"
                                    value={generalCouponInputData.couponCode}
                                    onChange={handlegeneralInputChange}
                                />
                            </div>
                        </CCol>
                        <CCol>
                            <div>
                                <CFormLabel className="fw-semibold">
                                    Usage Type <span className="text-danger">*</span>
                                </CFormLabel>
                                <CFormSelect
                                    name="usageType"
                                    value={generalCouponInputData.usageType}
                                    onChange={handlegeneralInputChange}
                                >
                                    <option value="">Select Usage</option>
                                    <option value="MULTIPLE">MULTIPLE</option>
                                    <option value="ONE-TIME">ONE-TIME</option>
                                </CFormSelect>
                            </div>
                        </CCol>
                    </CRow>

                    <CRow className="mb-3">
                        <CCol md="8">
                            <CFormLabel className="fw-semibold">
                                Select Excluded Outlets
                            </CFormLabel>

                            <Select
                                isMulti
                                components={animatedComponents}
                                value={selectedOptions}
                                onChange={handleSelectChange}
                                options={outletData}
                                styles={customStyles}
                                placeholder="Select Excluded Outlets"
                                closeMenuOnSelect={false}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        primary25: "#e9ecef",
                                        primary: "#007bff",
                                    },
                                })}
                            />
                        </CCol>
                        <CCol md="4">
                            <CFormLabel className="fw-semibold">
                                Hide Coupon
                            </CFormLabel>
                            <CFormSelect
                                name="isHidden"
                                value={generalCouponInputData?.isHidden}
                                onChange={handlegeneralInputChange}
                            >
                                <option value="">Select Option</option>
                                <option value="true">YES</option>
                                <option value="false">NO</option>
                            </CFormSelect>
                        </CCol>
                    </CRow>
                    

                    <div className="mb-3">
                        <CFormLabel className="fw-semibold">
                            Description <span className="text-danger">*</span>
                        </CFormLabel>
                        <CFormTextarea
                            rows={4}
                            placeholder="Description"
                            name="description"
                            value={generalCouponInputData.description}
                            onChange={handlegeneralInputChange}
                        />
                    </div>

                    <CRow className="mb-3">
                        <CCol>
                            <div>
                                <CFormLabel className="fw-semibold">
                                    Validity Start Date
                                </CFormLabel>
                                <DatePicker
                                    selected={fromDate}
                                    onChange={handleFromDateChange}
                                    placeholderText="Start Date"
                                    className="form-control"
                                />
                            </div>
                        </CCol>
                        <CCol>
                            <div>
                                <CFormLabel className="fw-semibold">
                                    Validity End Date
                                </CFormLabel>
                                <DatePicker
                                    selected={toDate}
                                    onChange={handleToDateChange}
                                    placeholderText="End Date"
                                    className="form-control"
                                />
                            </div>
                        </CCol>
                        <CCol>
                            <CFormLabel className="fw-semibold">
                                Discount Percent <span className="text-danger">*</span>
                            </CFormLabel>
                            <CFormInput
                                min={0}
                                max={100}
                                disabled={
                                    generalCouponInputData?.discountAmount
                                }
                                placeholder="Discount Percent"
                                className="no-arrows"
                                type="number"
                                name="discountPercent"
                                value={generalCouponInputData.discountPercent}
                                onChange={handlegeneralInputChange}
                            />
                        </CCol>
                    </CRow>
                </CCardBody>
                <CCardFooter>
                    <PrimaryButton
                        isDisable={generalBtnDisable()}
                        isLoading={generalBtnLoading}
                        title="ADD UNIVERSAL COUPON"
                    />
                </CCardFooter>
            </CForm>
        </CCard>
    )
}

export default UniversalCoupon
