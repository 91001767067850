import React, { Suspense } from "react";
import { CSpinner } from "@coreui/react";
import { Outlet } from "react-router-dom";

const AppContent = ({ children }) => {
  return (
    <Suspense fallback={<CSpinner color="primary" />}>
      <Outlet>{children}</Outlet>
    </Suspense>
  );
};

export default AppContent;
