import React, { useEffect, useState } from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCardTitle,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CRow,
} from "@coreui/react";
import axios from "../../api/axios";
import { toast } from "react-toastify";
import { FaMinus, FaPlus } from "react-icons/fa";
import { PrimaryButton } from "../../components";

const StockTransfer = () => {
  const accessToken = localStorage.getItem("authToken");
  const [outlets, setOutlets] = useState([]);
  const [rawMaterialList, setRawMaterialList] = useState([]);
  const [inputData, setInputData] = useState({
    outletId: "",
    outletStockTransferData: [
      {
        rawMaterialId: "",
        currentStockQuantity: "",
        transferQuantity: "",
      },
    ],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [prevArr, setPrevArr] = useState([]);
  const [validationErrors, setValidationErrors] = useState({
    outletId: "",
    outletStockTransferData: [
      {
        rawMaterialId: "",
        currentStockQuantity: "",
        transferQuantity: "",
      },
    ],
  });

  const handleAddItem = () => {
    const stockValuesErrors = inputData.outletStockTransferData.map(
      validateOutletStockTransferDataItem
    );
    if (stockValuesErrors.some((errors) => Object.keys(errors).length > 0)) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        outletStockTransferData: stockValuesErrors,
      }));
      return;
    }

    setInputData((prevData) => ({
      ...prevData,
      outletStockTransferData: [
        ...prevData.outletStockTransferData,
        {
          rawMaterialId: "",
          currentStockQuantity: "",
          transferQuantity: "",
        },
      ],
    }));
  };

  const validateOutletStockTransferDataItem = (item) => {
    const errors = {};

    if (!item.rawMaterialId.trim()) {
      errors.rawMaterialId = "Raw Material is required.";
    }

    if (item.currentStockQuantity === "") {
      errors.currentStockQuantity = "Current Stock is required.";
    }

    if (item.transferQuantity === "") {
      errors.transferQuantity = "Transferable Quantity is required.";
    }

    return errors;
  };

  const handleRemoveItem = (index) => {
    setInputData((prevData) => {
      const updatedItems = [...prevData.outletStockTransferData];
      updatedItems.splice(index, 1);
      return {
        ...prevData,
        outletStockTransferData: updatedItems,
      };
    });
  };

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    setValidationErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      outletStockTransferData: [],
    }));
    if (
      name === "rawMaterialId" ||
      name === "currentStockQuantity" ||
      name === "transferQuantity"
    ) {
      setInputData((prevData) => {
        const updatedOutletStockTransferData = [
          ...prevData.outletStockTransferData,
        ];
        updatedOutletStockTransferData[index] = {
          ...updatedOutletStockTransferData[index],
          [name]: name === "transferQuantity" ? parseFloat(value, 10) : value,
        };
        return {
          ...prevData,
          outletStockTransferData: updatedOutletStockTransferData,
        };
      });
    } else {
      setInputData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

//   const handleChange = (e, index) => {
//     const { name, value } = e.target;

//     // Clear specific validation error for this field
//     setValidationErrors((prevErrors) => ({
//         ...prevErrors,
//         outletStockTransferData: prevErrors.outletStockTransferData.map((item, i) =>
//             i === index ? { ...item, [name]: "" } : item
//         ),
//     }));

//     setInputData((prevData) => {
//         const updatedOutletStockTransferData = [...prevData.outletStockTransferData];

//         // Update the specific field in the array
//         updatedOutletStockTransferData[index] = {
//             ...updatedOutletStockTransferData[index],
//             [name]: name === "transferQuantity" ? parseFloat(value) : value,
//         };

//         return {
//             ...prevData,
//             outletStockTransferData: updatedOutletStockTransferData,
//         };
//     });
// };

  const getSingleStockTransferDetails = async (rmId, index) => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get(
        `stock-transactions/getCurrentStock?rawMaterialId=${rmId}`,
        { headers }
      );
      if (response?.data?.status === true) {
        const currentStockQuantity = response?.data?.data?.currentStock;

        setInputData((prevData) => {
          const newData = { ...prevData };
          newData.outletStockTransferData = [
            ...newData.outletStockTransferData,
          ];
          newData.outletStockTransferData[index] = {
            ...newData.outletStockTransferData[index],
            rawMaterialId: rmId,
            currentStockQuantity,
          };
          return newData;
        });
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      const response = await axios.post(
        "stock-transactions/addStockToOutlet",
        inputData,
        {
          headers,
        }
      );

      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
        setInputData({
          outletId: "",
          outletStockTransferData: [
            {
              rawMaterialId: "",
              currentStockQuantity: "",
              transferQuantity: "",
            },
          ],
        });
      }
    } catch (error) {
      if (error?.response) {
        let errorMessage = '';
        if (
          error.response?.status === 406
        ) {
          errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
        }
        else if (
          error.response?.status === 412
        ) {
          errorMessage = error?.response?.data?.data?.message;
        } else if (error.response?.status === 500) {
          errorMessage = error?.response?.data?.error
        }

        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getOutlets = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get("outlet", { headers });

      if (response?.data?.status === true) {
        setOutlets(response?.data?.data?.outlets);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const getRawMaterials = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get("raw-material", { headers });

      if (response?.data?.status === true) {
        setRawMaterialList(response?.data?.data?.rawMaterials);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const btnDisable = () => {
    let isDisable = false;

    if (!inputData.outletId) {
      isDisable = true;
    }

    for (let i = 0; i < inputData.outletStockTransferData.length; i++) {
      const item = inputData.outletStockTransferData[i];

      if (
        !item.rawMaterialId ||
        !item.currentStockQuantity ||
        !item.transferQuantity
      ) {
        isDisable = true;
        break;
      }
    }

    return isDisable;
  };

  useEffect(() => {
    getOutlets();
    getRawMaterials();
  }, []);

  return (
    <CContainer>
      <CCard style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
        <CCardHeader>
          <CCardTitle className="fw-semibold fs-6">ADD STOCK TRANSFER</CCardTitle>
        </CCardHeader>
        <CForm onSubmit={handleSubmit}>
          <CCardBody>
            <CRow>
              <CCol md="4" lg="4">
                <div>
                  <CFormLabel className="fw-semibold">
                    {" "}
                    Select Outlet <span className="text-danger">*</span>
                  </CFormLabel>
                  <CFormSelect
                    size="sm"
                    name="outletId"
                    className="mb-3"
                    onChange={handleChange}
                  >
                    <option value="">Select Outlet</option>
                    {outlets &&
                      outlets.map((rm) => (
                        <option key={rm._id} value={rm._id} >
                          {rm.name}
                        </option>
                      ))}
                  </CFormSelect>
                </div>
              </CCol>
            </CRow>
            <hr />
            {inputData.outletId !== "" &&
              inputData.outletStockTransferData.map((transferData, index) => (
                <CRow key={index}>
                  <CCol md="4" lg="4">
                    <div>
                      <CFormLabel className="fw-semibold">
                        {" "}
                        Select Raw Material{" "}
                        <span className="text-danger">*</span>
                      </CFormLabel>
                      <CFormSelect
                        value={transferData.rawMaterialId}
                        size="sm"
                        name="rawMaterialId"
                        className="mb-3"
                        onChange={(e) => {
                          getSingleStockTransferDetails(e.target.value, index);
                          setValidationErrors((prevErrors) => ({
                            ...prevErrors,
                            outletStockTransferData: [],
                          }));
                        }}
                      >
                        <option value="">Select Raw Material</option>
                        {rawMaterialList &&
                          rawMaterialList.map((rm) => {
                            const chosenRawMaterialId = inputData.outletStockTransferData.find(data => data.rawMaterialId.toString() === rm._id.toString());
                            if (chosenRawMaterialId) {
                              return (
                                <option key={rm._id} value={rm._id} disabled>
                                  {rm.name}
                                </option>
                              )
                            }
                            else {
                              return (
                                <option key={rm._id} value={rm._id}>
                                  {rm.name}
                                </option>
                              )
                            }

                          })}
                      </CFormSelect>

                      <div className="text-danger">
                        {
                          validationErrors.outletStockTransferData[index]
                            ?.rawMaterialId
                        }
                      </div>
                    </div>
                  </CCol>

                  <CCol md="3" lg="4">
                    <div className="mb-3">
                      <CFormLabel className="fw-semibold">
                        Current Quantity <span className="text-danger">*</span>
                      </CFormLabel>
                      <CFormInput
                        min={0}
                        disabled
                        type="number"
                        placeholder="Current Quantity"
                        className="form-control no-arrows"
                        name="currentStockQuantity"
                        value={transferData.currentStockQuantity || 0}
                        onChange={(e) => handleChange(e, index)}
                      />
                      <div className="text-danger">
                        {
                          validationErrors.outletStockTransferData[index]
                            ?.currentStockQuantity
                        }
                      </div>
                    </div>
                  </CCol>

                  <CCol md="4" lg="3">
                    <div className="mb-3">
                      <CFormLabel className="fw-semibold">
                        Transferable Quantity{" "}
                        <span className="text-danger">*</span>
                      </CFormLabel>
                      <CFormInput
                        min={0}
                        step= "any" // Allows decimal values
                        type="number"
                        placeholder="Transferable Quantity"
                        className="form-control no-arrows"
                        name="transferQuantity"
                        value={transferData?.transferQuantity}
                        onChange={(e) => handleChange(e, index)}
                      />
                      <div className="text-danger">
                        {
                          validationErrors.outletStockTransferData[index]
                            ?.transferQuantity
                        }
                      </div>
                    </div>
                  </CCol>

                  <CCol className="col-md-1">
                    <div className="d-flex justify-content-end mt-2">
                      {index ===
                        inputData.outletStockTransferData?.length - 1 && (
                          <div>
                            <CButton
                              size="sm"
                              onClick={handleAddItem}
                              color="primary"
                              style={{ marginRight: "3px" }}
                            >
                              <FaPlus />
                            </CButton>
                          </div>
                        )}

                      {inputData.outletStockTransferData?.length > 1 && (
                        <div>
                          <CButton
                            size="sm"
                            onClick={() => handleRemoveItem(index)}
                            color="primary"
                          >
                            <FaMinus />
                          </CButton>
                        </div>
                      )}
                    </div>
                  </CCol>
                </CRow>
              ))}
          </CCardBody>
          <CCardFooter>
            <PrimaryButton
              isDisable={btnDisable()}
              title="ADD STOCK TRANSFER"
              isLoading={isLoading}
            />
          </CCardFooter>
        </CForm>
      </CCard>
    </CContainer>
  );
};

export default StockTransfer;
