import { CCard, CCardBody, CCardFooter, CCardHeader, CCardTitle, CCol, CContainer, CForm, CFormInput, CFormLabel, CFormSelect, CInputGroup, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle, CRow, CTooltip } from '@coreui/react';
import React, { useEffect, useState } from 'react';
import { DataTableComponent, DeleteModal, PrimaryButton } from '../../components';
import axios from '../../api/axios';
import { toast } from 'react-toastify';
import { FaPencilAlt, FaTrash } from 'react-icons/fa';

const AssetsStockTransaction = () => {

    const accessToken = localStorage.getItem('authToken');
    const [stocksList, setStocksList] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [stocksInputData, setStocksInputData] = useState({
        asset: "",
        storage_location: "",
        quantity: "",
        outlet: "",
        transaction_type: "",
        particulars: ""
    });
    const [assetsList, setAssetsList] = useState([]);
    const [storageLocationList, setStorageLocationList] = useState([]);
    const [outletsList, setOutletsList] = useState([]);
    const [isAddBtnLoading, setIsAddBtnLoading] = useState(false);
    const [isEditBtnLoading, setisEditBtnLoading] = useState(false);
    const [isDeleteBtnLoading, setIsDeleteBtnLoading] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [assetStockTransactionId, setAssetStockTransactionId] = useState('');
    const [openEditModal, setOpenEditModal] = useState(false);
    const [editData, setEditData] = useState({});
    const [pagination, setPagination] = useState({ page: 1, rowsPerPage: 10 });

    const columns = [
        {
          name: "Sr.No",
          // cell: (row, index) => (pagination.page - 1) * pagination.rowsPerPage + index + 1,
          cell: (row, index) => <div className="fs-7">{row?.serialNumber ? row?.serialNumber : "--------"}</div>,
          center: true,
          width: "90px"
        },
        {
          name: "ASSET",
          selector: (row) => row.name,
          cell: (row) => (
            <div className="fs-7">{row?.asset?.name ? row.asset?.name : "--------"}</div>
          ),
        },
        {
            name: "QUANTITY",
            selector: (row) => row.name,
            cell: (row) => (
              <div className="fs-7">{row?.quantity ? row?.quantity : "--------"}</div>
            ),
            width: "100px",
            center: true
        },
        {
            name: "STORAGE LOCATION",
            selector: (row) => row.name,
            cell: (row) => (
              <div className="fs-7">{row?.storage_location?.name ? row?.storage_location?.name : "--------"}</div>
            ),
            width: "250px"

        },
        {
            name: "OUTLET",
            selector: (row) => row.name,
            cell: (row) => (
              <div className="fs-7">{row?.outlet?.name ? row?.outlet?.name : "--------"}</div>
            ),
        },
        {
            name: "TRANSACTION TYPE",
            selector: (row) => row.name,
            cell: (row) => (
              <div className="fs-7">{row?.transaction_type ? row?.transaction_type : "--------"}</div>
            ),
        },
        {
            name: "PARTICULARS",
            selector: (row) => row.name,
            cell: (row) => (
              <div className="fs-7">{row?.particulars ? row?.particulars : "--------"}</div>
            ),
            width: "180px",
        },
        {
          name: "ACTIONS",
          right: "true",
          cell: (row) => (
            <div className="d-flex justify-content-around align-items-center">
              <a
                href="#"
                onClick={() => handleEditModal(row)}
                className="text-primary me-2 d-flex align-items-center gap-1"
                style={{ textDecoration: "none" }}
              >
                <FaPencilAlt /> EDIT
              </a>{" "}
              |
              <a
                href="#"
                className="text-danger ms-2 d-flex align-items-center gap-1"
                onClick={() => handleOpenDeleteModal(row)}
                style={{ textDecoration: "none" }}
              >
                <FaTrash /> DELETE
              </a>
            </div>
          ),
          minWidth: "165px",
        },
    ]
    
    const updatedColumns = columns.map((column) => ({
            ...column,
            name : (
                <div style={{ fontSize: "13px", fontWeight: "bold" }}>{column.name}</div>
            )
        }) 
    )

    const getStocksTransactionsList = async () => {
        try {
            const headers = {
                Authorization : `Bearer ${accessToken}`
            }
            const response = await axios.get("assets-stock-transaction", { headers });
            response?.data?.data?.assetsStockTransactions.forEach((item, index) => {
                item.serialNumber = index + 1;
            })
            setStocksList(response?.data?.data?.assetsStockTransactions);
            setFilteredData(response?.data?.data?.assetsStockTransactions);
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                  position: "top-right",
                  autoClose: 3000,
                  theme: "colored",
                });
            }
        }
    }

    const getAssetsData = async() => {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`
            }
            const response = await axios.get("asset", { headers });            
            setAssetsList(response?.data?.data?.assets);
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                  position: "top-right",
                  autoClose: 3000,
                  theme: "colored",
                });
            }
        }
    }

    const getStorageLocationData = async() => {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`
            }
            const response = await axios.get("storage-location", { headers });            
            setStorageLocationList(response?.data?.data?.storageLocations);
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                  position: "top-right",
                  autoClose: 3000,
                  theme: "colored",
                });
            }
        }
    }

    const getOutletsData = async() => {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`
            }
            const response = await axios.get("outlet", { headers });
            setOutletsList(response?.data?.data?.outlets);
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                  position: "top-right",
                  autoClose: 3000,
                  theme: "colored",
                });
            }
        }
    }

    const handleSearchChange = (e) => {
        setSearchText(e.target.value)
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setStocksInputData((prevData) => ({ ...prevData, [name]: value }));
        // setStocksInputData((prevData) => ({
        //     ...prevData,
        //     [name]: value,
        //     ...(name === "storage_location" && { outlet: null }), 
        //     ...(name === "outlet" && { storage_location: null }) 
        // }));
    }

    const handleSubmit = async(e) => {
        e.preventDefault();
        setIsAddBtnLoading(true);
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`
            }
            const data = {
                ...stocksInputData
            }
            if (!data.outlet) {
                delete data.outlet;  // Remove outlet if it's not selected
            }
            if (!data.storage_location) {
                delete data.storage_location;  // Remove outlet if it's not selected
            }
            
            const response = await axios.post("assets-stock-transaction", data, { headers });
            if (response?.data?.status === true) {
                toast.success(response?.data?.data?.message, {
                position: "top-right",
                autoClose: 3000,
                theme: "colored",
                });
                getStocksTransactionsList();
                setStocksInputData({
                    asset: "",
                    storage_location: "",
                    quantity: "",
                    outlet: "",
                    transaction_type: "",
                    particulars: ""
                });
            }
        } catch (error) {
            if (error?.response) {
                let errorMessage = '';
                if (
                error.response?.status === 406
                ) {
                errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
                }
                else if (
                error.response?.status === 412
                ) {
                errorMessage = error?.response?.data?.data?.message;
                } else if (error.response?.status === 500) {
                errorMessage = error?.response?.data?.error
                }
    
                toast.error(errorMessage, {
                position: "top-right",
                autoClose: 3000,
                theme: "colored",
                });
            }
        } finally {
            setIsAddBtnLoading(false)
        }
    }

    const handleOpenDeleteModal = (data) => {
        setOpenDeleteModal(true);
        setAssetStockTransactionId(data._id);
    }

    const handleDeleteStockTransaction = async(e) => {
        e.preventDefault();
        setIsDeleteBtnLoading(true);
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`
            }
            const data = {
                _id: assetStockTransactionId
            }
            const response = await axios.delete("assets-stock-transaction", { data, headers } );
            if (response?.data?.status === true) {
                toast.success(response?.data?.data?.message, {
                  position: "top-right",
                  autoClose: 3000,
                  theme: "colored",
                });
                getStocksTransactionsList();
                setOpenDeleteModal(false);
            }
        } catch (error) {
            if (error.response.status === 403) {
                toast.error(error?.response?.data?.message, {
                  position: "top-right",
                  autoClose: 3000,
                  theme: "colored",
                });
            }
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                  position: "top-right",
                  autoClose: 3000,
                  theme: "colored",
                });
            }
        } finally {
            setIsDeleteBtnLoading(false);
        }
    }

    const handleCloseDeleteModal = () => {
        setOpenDeleteModal(false);
    }

    const handleEditModal = (data) => {        
        setOpenEditModal(true);        
        setEditData({
            _id: data?._id,
            asset: data?.asset?._id,
            storage_location: data?.storage_location?._id,
            quantity: data?.quantity,
            outlet: data?.outlet?._id,
            transaction_type: data?.transaction_type,
            particulars: data?.particulars
        })
    }

    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setEditData((prevState) => ({ ...prevState, [name] : value }));
    }

    const handleEditSubmit = async(e) => {
        e.preventDefault();
        setisEditBtnLoading(true);
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`
            }
            const payloadEditedData = {
                ...editData
            }
            if(!payloadEditedData.outlet) {
                delete payloadEditedData.outlet;
            }
            if(!payloadEditedData.storage_location) {
                delete payloadEditedData.storage_location;
            }
            const response = await axios.put("assets-stock-transaction", payloadEditedData, { headers });
            if (response?.data?.status === true) {
                toast.success(response?.data?.data?.message, {
                position: "top-right",
                autoClose: 3000,
                theme: "colored",
                });
                getStocksTransactionsList();
                setOpenEditModal(false);
            }
        } catch (error) {
            if (error?.response) {
                let errorMessage = '';
                if (
                error.response?.status === 406
                ) {
                errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
                }
                else if (
                error.response?.status === 412
                ) {
                errorMessage = error?.response?.data?.data?.message;
                } else if (error.response?.status === 500) {
                errorMessage = error?.response?.data?.error
                }
                toast.error(errorMessage, {
                position: "top-right",
                autoClose: 3000,
                theme: "colored",
                });
            }
        } finally {
            setisEditBtnLoading(false);
        }
    }

    const handlePageChange = (page) => {
        setPagination({ ...pagination, page });
      };
      
    const handleRowsPerPageChange = (rowsPerPage) => {
        setPagination({ page: 1, rowsPerPage }); // Reset to the first page when changing rows per page
    };
    
    // Function to get paginated data
    const getPaginatedData = () => {
        const startIndex = (pagination.page - 1) * pagination.rowsPerPage;
        const endIndex = startIndex + pagination.rowsPerPage;
        return filteredData.slice(startIndex, endIndex);
    };

    useEffect(() => {
        getStocksTransactionsList();
        getAssetsData();
        getStorageLocationData();
        getOutletsData();
    }, [])

    useEffect(() => {
        const result = stocksList.filter((item) => {
            return item?.asset?.name?.toLowerCase().match(searchText.toLocaleLowerCase()); 
        });
        setFilteredData(result);
    }, [searchText])

    return (
        <CContainer>
            <CRow>
                {/* <--------------- Add Form of Assets Stock Transaction -------------> */}
                <CCol className='col-md-8 col-lg-8 mb-4'>
                    <CCard style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}>
                        <CCardHeader>
                            <CCardTitle className="fs-6 fw-semibold pt-1">ADD ASSETS STOCKS TRANSACTION</CCardTitle>
                        </CCardHeader>
                        <CForm onSubmit={handleSubmit}>
                            <CCardBody>
                                <CRow className='mb-1'>
                                    <CCol md="6">
                                        <div className='mb-2'>
                                            <CInputGroup className="flex-column">
                                                <CFormLabel className="fw-semibold" htmlFor="asset_type">
                                                    Asset <span className="text-danger">*</span>
                                                </CFormLabel>
                                                <CFormSelect
                                                    className="w-100"
                                                    id="asset"
                                                    name="asset"
                                                    value={stocksInputData?.asset}
                                                    onChange={handleChange}
                                                >
                                                    <option value="" disabled>
                                                    Select Asset
                                                    </option>
                                                    {assetsList?.map((asset) => {
                                                     return (
                                                            <option key={asset?._id} value={asset?._id}>
                                                                {asset?.name}
                                                            </option>
                                                        );
                                                    })}
                                                </CFormSelect>
                                            </CInputGroup>
                                        </div>
                                    </CCol>
                                    <CCol md="6">
                                        <div className="mb-2">
                                            <CFormLabel className="fw-semibold">
                                                Quantity <span className="text-danger">*</span>
                                            </CFormLabel>
                                            <CFormInput
                                                type="number"
                                                min={1}
                                                placeholder="Quantity"
                                                required
                                                className="form-control"
                                                name="quantity"
                                                value={stocksInputData?.quantity}
                                                onChange={handleChange}
                                                onInput={(e) => {
                                                    e.target.value = e.target.value
                                                      .replace(/[^0-9]/g, '') // Allow only numbers and a decimal point
                                                      .replace(/(\..*)\./g, '$1'); // Prevent multiple decimal points
                                                }}
                                                pattern="^\d+(\.\d{1,2})?$"
                                                title="Quantity must be a whole number."
                                            />
                                            {/* {errors.title && <div className='text-danger' style={{ fontSize : "12px"}}>{errors.title}</div>} */}
                                        </div>
                                    </CCol>
                                </CRow>
                                <CRow className='mb-1'>
                                    <CCol md="6">
                                        <div className='mb-2'>
                                            <CInputGroup className="flex-column">
                                                <CFormLabel className="fw-semibold" htmlFor="asset_type">
                                                    Select Storage Location
                                                </CFormLabel>
                                                <CFormSelect
                                                    className="w-100"
                                                    id="storage_location"
                                                    name="storage_location"
                                                    value={stocksInputData?.storage_location}
                                                    onChange={handleChange}
                                                    disabled={stocksInputData?.outlet}
                                                >
                                                    <option value="">Select Storage Location</option>
                                                    { storageLocationList?.map((storage) => {
                                                        return (
                                                            <option value={storage?._id} key={storage?._id}>{storage?.name}</option>
                                                        )
                                                    })}
                                                </CFormSelect>
                                            </CInputGroup>
                                        </div>
                                    </CCol>
                                    <CCol md="6">
                                        <div className='mb-2'>
                                            <CInputGroup className="flex-column">
                                                <CFormLabel className="fw-semibold" htmlFor="asset_type">
                                                    Select Outlet
                                                </CFormLabel>
                                                <CFormSelect
                                                    className="w-100"
                                                    id="outlet"
                                                    name="outlet"
                                                    value={stocksInputData?.outlet}
                                                    onChange={handleChange}
                                                    disabled={ stocksInputData?.storage_location }
                                                >
                                                    <option value="">Select Outlet</option>
                                                    { outletsList?.map((outlets) => {
                                                        return (
                                                            <option key={outlets._id} value={outlets._id}>{outlets?.name}</option>
                                                        )
                                                    })}
                                                </CFormSelect>
                                            </CInputGroup>
                                        </div>
                                    </CCol>
                                </CRow>
                                <CRow className='mb-1'>
                                    <CCol md="6">
                                        <div className='mb-2'>
                                            <CInputGroup className="flex-column">
                                                <CFormLabel className="fw-semibold" htmlFor="asset_type">
                                                    Select Transaction Type <span className="text-danger">*</span>
                                                </CFormLabel>
                                                <CFormSelect
                                                    className="w-100"
                                                    id="transaction_type"
                                                    name="transaction_type"
                                                    value={stocksInputData?.transaction_type}
                                                    onChange={handleChange}
                                                >
                                                    <option defaultValue="">Select Transaction Type</option>
                                                    <option value="CREDIT">CREDIT</option>
                                                    <option value="DEBIT">DEBIT</option>
                                                </CFormSelect>
                                            </CInputGroup>
                                        </div>
                                    </CCol>
                                    <CCol md="6">
                                        <div className="mb-2">
                                            <CFormLabel className="fw-semibold">
                                                Particulars
                                            </CFormLabel>
                                            <CFormInput
                                                type="text"
                                                placeholder="Particulars"
                                                className="form-control"
                                                name="particulars"
                                                value={stocksInputData?.particulars}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </CCol>
                                </CRow>                            
                            </CCardBody>
                            <CCardFooter>
                                <PrimaryButton title="ADD ASSETS STOCKS TRANSACTION" isLoading={isAddBtnLoading} />
                            </CCardFooter>
                        </CForm>
                    </CCard>
                </CCol>

                {/* <---------------- List of Assets Stock Transactions --------------> */}
                <CCol className='col-md-12 col-lg-12'>
                    <DataTableComponent 
                        columns={updatedColumns}
                        // data={filteredData}
                        title="STORAGE LOCATION LIST"
                        data={getPaginatedData()} // Use the paginated data here
                        searchText={searchText}
                        onChange={handleSearchChange}
                        striped
                        itemsPerPage={10}
                        pagination
                        totalRows={filteredData?.length}
                        paginationTotalRows={filteredData?.length} // Total number of items
                        onChangeRowsPerPage={handleRowsPerPageChange}
                        onChangePage={handlePageChange}
                        paginationServer={false} // Set to false for client-side pagination
                    />
                </CCol>

                    {/* <-------------- Edit Asset Stock Transaction Modal -------------> */}
                {openEditModal ? (
                    <CModal 
                        alignment="center"
                        visible={openEditModal}
                        onClose={() => setOpenEditModal(false)}
                        aria-labelledby="VerticallyCenteredExample"
                        size='lg'
                    >
                        <CModalHeader>
                            <CModalTitle id="VerticallyCenteredExample" className="fs-6">Edit Asset Stock Transaction</CModalTitle>
                        </CModalHeader>
                        <CForm onSubmit={handleEditSubmit}>
                            <CModalBody>
                                <CRow className='mb-1'>
                                    <CCol md="6">
                                        <div className='mb-2'>
                                            <CInputGroup className="flex-column">
                                                <CFormLabel className="fw-semibold" htmlFor="asset_type">
                                                    Asset <span className="text-danger">*</span>
                                                </CFormLabel>
                                                <CFormSelect
                                                    className="w-100"
                                                    id="asset"
                                                    name="asset"
                                                    value={editData?.asset}
                                                    onChange={handleEditChange}
                                                >
                                                    <option value="" disabled>
                                                    Select Asset
                                                    </option>
                                                    {assetsList?.map((asset) => {
                                                     return (
                                                            <option key={asset?._id} value={asset?._id}>
                                                                {asset?.name}
                                                            </option>
                                                        );
                                                    })}
                                                </CFormSelect>
                                            </CInputGroup>
                                        </div>
                                    </CCol>
                                    <CCol md="6">
                                        <div className="mb-2">
                                            <CFormLabel className="fw-semibold">
                                                Quantity <span className="text-danger">*</span>
                                            </CFormLabel>
                                            <CFormInput
                                                type="number"
                                                placeholder="Quantity"
                                                required
                                                min={1}
                                                className="form-control"
                                                name="quantity"
                                                value={editData?.quantity}
                                                onChange={handleEditChange}
                                                onInput={(e) => {
                                                    e.target.value = e.target.value
                                                      .replace(/[^0-9]/g, '') // Allow only numbers and a decimal point
                                                      .replace(/(\..*)\./g, '$1'); // Prevent multiple decimal points
                                                }}
                                                pattern="^\d+(\.\d{1,2})?$"
                                                title="Quantity must be a whole number."
                                            />
                                        </div>
                                    </CCol>
                                </CRow>
                                <CRow className='mb-1'>
                                    <CCol md="6">
                                        <div className='mb-2'>
                                            <CInputGroup className="flex-column">
                                                <CFormLabel className="fw-semibold" htmlFor="asset_type">
                                                    Select Storage Location <span className="text-danger">*</span>
                                                </CFormLabel>
                                                <CFormSelect
                                                    className="w-100"
                                                    id="storage_location"
                                                    name="storage_location"
                                                    value={editData?.storage_location}
                                                    onChange={handleEditChange}
                                                    disabled={editData?.outlet}
                                                >
                                                    <option value="">Select Storage Location</option>
                                                    { storageLocationList?.map((storage) => {
                                                        return (
                                                            <option value={storage?._id} key={storage?._id}>{storage?.name}</option>
                                                        )
                                                    })}
                                                </CFormSelect>
                                            </CInputGroup>
                                        </div>
                                    </CCol>
                                    <CCol md="6">
                                        <div className='mb-2'>
                                            <CInputGroup className="flex-column">
                                                <CFormLabel className="fw-semibold" htmlFor="asset_type">
                                                    Select Outlet 
                                                </CFormLabel>
                                                <CFormSelect
                                                    className="w-100"
                                                    id="outlet"
                                                    name="outlet"
                                                    value={editData?.outlet}
                                                    disabled={editData?.storage_location}
                                                    onChange={handleEditChange}
                                                >
                                                    <option value="">Select Outlet</option>
                                                    { outletsList?.map((outlets) => {
                                                        return (
                                                            <option key={outlets._id} value={outlets._id}>{outlets?.name}</option>
                                                        )
                                                    })}
                                                </CFormSelect>
                                            </CInputGroup>
                                        </div>
                                    </CCol>
                                </CRow>
                                <CRow className='mb-1'>
                                    <CCol md="6">
                                        <div className='mb-2'>
                                            <CInputGroup className="flex-column">
                                                <CFormLabel className="fw-semibold" htmlFor="asset_type">
                                                    Select Transaction Type 
                                                </CFormLabel>
                                                <CFormSelect
                                                    className="w-100"
                                                    id="transaction_type"
                                                    name="transaction_type"
                                                    value={editData?.transaction_type}
                                                    onChange={handleEditChange}
                                                >
                                                    <option defaultValue="">Select Transaction Type</option>
                                                    <option value="CREDIT">CREDIT</option>
                                                    <option value="DEBIT">DEBIT</option>
                                                </CFormSelect>
                                            </CInputGroup>
                                        </div>
                                    </CCol>
                                    <CCol md="6">
                                        <div className="mb-2">
                                            <CFormLabel className="fw-semibold">
                                                Particulars
                                            </CFormLabel>
                                            <CFormInput
                                                type="text"
                                                placeholder="Particulars"
                                                required
                                                className="form-control"
                                                name="particulars"
                                                value={editData?.particulars}
                                                onChange={handleEditChange}
                                            />
                                        </div>
                                    </CCol>
                                </CRow>
                            </CModalBody>
                            <CModalFooter>
                                <PrimaryButton title="Save Changes" isLoading={isEditBtnLoading} />
                            </CModalFooter>
                        </CForm>
                    </CModal>
                ) : null}

                {/* <------------------- Delete Storage Location Modal ---------------------> */}
                {openDeleteModal ? (
                    <DeleteModal isVisible={openDeleteModal} onClose={handleCloseDeleteModal} onConfirm={handleDeleteStockTransaction} isLoading={isDeleteBtnLoading} title="DELETE ASSET STOCK TRANSACTION" />
                ) : null}
            </CRow>
        </CContainer>
    )
}

export default AssetsStockTransaction