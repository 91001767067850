import React, { useEffect, useState } from 'react'
import { CCard, CCardBody, CCardFooter, CCardHeader, CCardTitle, CCol, CForm, CFormInput, CFormLabel, CFormSelect, CFormTextarea, CRow } from '@coreui/react'
import { PrimaryButton } from "../../components";
import Select from "react-select";
import DatePicker from "react-datepicker";
import axios from "../../api/axios";
import { toast } from "react-toastify";
import makeAnimated from "react-select/animated";
import { format } from "date-fns";

const SizeUpgradeCoupon = ({ outlets, selectedOptions, setSelectedOptions, setSelectedCouponOption }) => {
    const accessToken = localStorage.getItem("authToken");
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const animatedComponents = makeAnimated();
    const outletData = outlets.map((outlet) => ({
        value: outlet._id,
        label: `${outlet.name} (${outlet.city})`,
    }));
    const [inputData, setInputData] = useState({
        isHidden: "",
        title: "",
        couponCode: "",
        sizeUpgradeAddon: "",
        description: "",
        usageType: "",
        validityStartDate: "",
        validityEndDate: "",
        applicableProducts: [],
    });
    const [isLoading, setIsLoading] = useState(false);
    const [addonList, setAddonList] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState('');
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            width: "100%",
            borderRadius: "6px",
            minWidth: "200px",
            borderColor: state.isFocused ? "#80bdff" : "#ced4da",
            boxShadow: state.isFocused ? "0 0 0 0.2rem rgba(0,123,255,.25)" : null,
            "&:hover": {
                borderColor: state.isFocused ? "#80bdff" : "#ced4da",
            },
        }),
        placeholder: (provided) => ({
            ...provided,
            color: "#6c757d",
        }),
    };

    const handleSelectChange = (selected) => {
        setSelectedOptions(selected);
    };

    const handleFromDateChange = (date) => {
        if (date) {
            setFromDate(date);
        } else {
            console.error("Received null or undefined date");
        }
    };

    const handleToDateChange = (date) => {
        if (date) {
            setToDate(date);
        } else {
            console.error("Received null or undefined date");
        }
    };

    const getAddonProductsList = async () => {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };
            const response = await axios.get("product-addon?limit=1000&page=1", { headers });
            if (response?.data?.status === true) {
                const addOns = response?.data?.data?.productAddons
                // const sizeAddons = addOns.filter((item) => item?.addonTitle === "Size");
                const productsWithSizeAddon = addOns.filter((item) =>
                    item.addons.some((addon) => addon.addonTitle.toLowerCase() === 'size')
                );
                setAddonList(productsWithSizeAddon)
            }
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === "applicableProducts") {
            setSelectedProduct(value)
        }
        if (name === "applicableProducts") {
            setInputData((prev) => ({ ...prev, [name]: [value] }));
        }
        else {
            setInputData((prev) => ({ ...prev, [name]: value }));
        }

    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        let selectedOutlet = selectedOptions.map((option) => option.value);
        let data = {
            ...inputData,
            excludedOutlets: selectedOutlet,
        };


        if (data?.excludedOutlets?.length === 0) {
            delete data?.excludedOutlets
        }

        if (data?.validityStartDate === "") {
            delete data.validityStartDate
        }
        if (data?.validityEndDate === "") {
            delete data.validityEndDate
        }
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };

            const response = await axios.post("coupons", data, {
                headers,
            });

            if (response?.data?.status === true) {
                toast.success(response?.data?.data?.message, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
                e.target.reset();
                setSelectedOptions([]);
                setSelectedCouponOption("");
                setInputData({
                    isHidden: "",
                    title: "",
                    couponCode: "",
                    description: "",
                    usageType: "",
                    validityStartDate: "",
                    validityEndDate: "",
                });
                setFromDate(null);
                setToDate(null);
            }
        } catch (error) {
            if (error?.response) {
                let errorMessage = "";
                if (error.response?.status === 406) {
                    errorMessage =
                        error?.response?.data?.error?.message ||
                        error?.response?.data?.error;
                } else if (error.response?.status === 412) {
                    errorMessage =
                        error?.response?.data?.data?.message ||
                        error?.response?.data?.data?.title;
                } else if (error.response?.status === 500) {
                    errorMessage = error?.response?.data?.error;
                }

                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        } finally {
            setIsLoading(false);
        }
    };

    const filteredAddons = selectedProduct
        ? addonList.filter(rm => rm.product._id === selectedProduct)
        : [];
    const updatedFilteredAddons = filteredAddons?.map((item) => item.addons?.map((mm) => mm))
    useEffect(() => {
        getAddonProductsList()
    }, []);

    useEffect(() => {
        const formattedStartDate = fromDate ? format(fromDate, "yyyy-MM-dd") : "";
        const formattedEndDate = toDate ? format(toDate, "yyyy-MM-dd") : "";

        setInputData((prev) => ({
            ...prev,
            validityStartDate: formattedStartDate,
            validityEndDate: formattedEndDate,
        }));
    }, [fromDate, toDate]);



    return (
        <CCard>
            <CCardHeader>
                <CCardTitle className="fs-6">ADD SIZE UPGRADE COUPON</CCardTitle>
            </CCardHeader>
            <CForm onSubmit={handleSubmit}>
                <CCardBody>
                    <CRow className="mb-3">
                        <CCol lg="4">
                            <div>
                                <CFormLabel className="fw-semibold">
                                    Title <span className="text-danger">*</span>
                                </CFormLabel>
                                <CFormInput
                                    placeholder="Title"
                                    name="title"
                                    value={inputData.title}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </CCol>
                        <CCol lg="4">
                            <div>
                                <CFormLabel className="fw-semibold">
                                    Coupon Code <span className="text-danger">*</span>
                                </CFormLabel>
                                <CFormInput
                                    placeholder="Coupon Code"
                                    name="couponCode"
                                    value={inputData.couponCode}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </CCol>
                        <CCol lg="4">
                            <div>
                                <CFormLabel className="fw-semibold">
                                    Usage Type <span className="text-danger">*</span>
                                </CFormLabel>
                                <CFormSelect
                                    name="usageType"
                                    value={inputData.usageType}
                                    onChange={handleInputChange}
                                >
                                    <option value="">Select Usage</option>
                                    <option value="MULTIPLE">MULTIPLE</option>
                                    <option value="ONE-TIME">ONE-TIME</option>
                                </CFormSelect>
                            </div>
                        </CCol>
                    </CRow>
                    <CRow className="mb-3">
                        <CCol lg="6">
                            <CFormLabel className="fw-semibold">
                                Select Product <span className="text-danger">*</span>
                            </CFormLabel>
                            {/* <CFormSelect
                                name="applicableProducts"
                                value={inputData.applicableProducts}
                                onChange={handleInputChange}
                            >
                                <option value="">Select Product</option>
                                {addonList.map((rm) => (
                                    <option key={rm?.product?._id} value={rm?.product?._id}>
                                        {rm?.product?.name}
                                    </option>
                                ))}
                            </CFormSelect> */}
                            <Select
                                options={addonList.map((rm) => ({
                                    value: rm?.product?._id,
                                    label: rm?.product?.name,
                                }))}
                                value={
                                    inputData?.applicableProducts
                                        ? addonList
                                            .map((rm) => ({ value: rm?.product?._id, label: rm?.product?.name }))
                                            .find((option) => option.value === inputData?.applicableProducts[0]) // Since applicableProducts is an array
                                        : null
                                }
                                onChange={(selectedOption) => {
                                    if (typeof selectedOption === 'object' && selectedOption !== null) {
                                        handleInputChange({ target: { name: 'applicableProducts', value: selectedOption.value } });
                                    } else {
                                        handleInputChange({ target: { name: 'applicableProducts', value: '' } });
                                    }
                                }}
                                classNamePrefix="react-select"
                                isClearable
                                placeholder="Select Product"
                            />
                        </CCol>
                        <CCol lg="6">
                            <CFormLabel className="fw-semibold">
                                Select Size Upgrade Addon <span className="text-danger">*</span>
                            </CFormLabel>
                            <CFormSelect
                                name="sizeUpgradeAddon"
                                value={inputData.sizeUpgradeAddon}
                                onChange={handleInputChange}
                                disabled={!inputData.applicableProducts}
                            >
                                <option value="">Select Size Upgrade Addon</option>
                                <option key={updatedFilteredAddons[0]?.[0]?.addonId} value={updatedFilteredAddons[0]?.[0]?.addonId}>
                                    {updatedFilteredAddons[0]?.[0]?.addonTitle}
                                </option>
                            </CFormSelect>
                        </CCol>
                    </CRow>

                    <div className="mb-3">
                        <CFormLabel className="fw-semibold">
                            Select Excluded Outlets
                        </CFormLabel>

                        <Select
                            isMulti
                            components={animatedComponents}
                            value={selectedOptions}
                            onChange={handleSelectChange}
                            options={outletData}
                            styles={customStyles}
                            placeholder="Select Excluded Outlets"
                            closeMenuOnSelect={false}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                    ...theme.colors,
                                    primary25: "#e9ecef",
                                    primary: "#007bff",
                                },
                            })}
                        />

                    </div>

                    <div className="mb-3">
                        <CFormLabel className="fw-semibold">
                            Description <span className="text-danger">*</span>
                        </CFormLabel>
                        <CFormTextarea
                            rows={4}
                            placeholder="Description"
                            name="description"
                            value={inputData.description}
                            onChange={handleInputChange}
                        />
                    </div>

                    <CRow>
                        <CCol>
                            <div>
                                <CFormLabel className="fw-semibold">
                                    Hide Coupon
                                </CFormLabel>
                                <CFormSelect
                                    name="isHidden"
                                    value={inputData.isHidden}
                                    onChange={handleInputChange}
                                >
                                    <option value="">Select Option</option>
                                    <option value="true">YES</option>
                                    <option value="false">NO</option>
                                </CFormSelect>
                            </div>
                        </CCol>
                        <CCol>
                            <div>
                                <CFormLabel className="fw-semibold">
                                    Validity Start Date
                                </CFormLabel>
                                <DatePicker
                                    selected={fromDate}
                                    onChange={handleFromDateChange}
                                    placeholderText="Start Date"
                                    className="form-control"
                                />
                            </div>
                        </CCol>
                        <CCol>
                            <div>
                                <CFormLabel className="fw-semibold">
                                    Validity End Date
                                </CFormLabel>
                                <DatePicker
                                    selected={toDate}
                                    onChange={handleToDateChange}
                                    placeholderText="End Date"
                                    className="form-control"
                                />
                            </div>
                        </CCol>
                    </CRow>
                </CCardBody>
                <CCardFooter>
                    <PrimaryButton
                        // isDisable={bogoBtnDisable()}
                        isLoading={isLoading}
                        title="ADD SIZE UPGRADE COUPON"
                    />
                </CCardFooter>
            </CForm>
        </CCard>
    )
}

export default SizeUpgradeCoupon
