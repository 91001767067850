import React from "react";
import {
  CCard,
  CCardBody,
  CCardTitle,
  CFormInput,
  CCol,
  CFormLabel,
} from "@coreui/react";
import DataTable from "react-data-table-component";

const DataTableComponent = ({ columns, data, title, searchText, onChange, onChangePage, onChangeRowsPerPage, page, rowsPerPage, totalRows }) => {
  const customStyles = {
    headRow: {
      style: {
        backgroundColor: '#00376E', 
        borderBottom: '2px solid #dee2e6', 
        color: '#ffffff'
      },
    },
    headCells: {
      style: {
        fontSize: '16px', 
        fontWeight: 'bold',
        textTransform: 'uppercase'
      },
    },
    cells: {
      style: {
        fontSize: '14px',
      },
    },
    pagination: {
      style: {
        borderTop: '2px solid #869fbf', 
      },
    },
  };

  return (
    <CCard style={{boxShadow: '0 4px 8px rgba(0, 0, 0, 0.009)'}}>
      <CCardBody>
      <CCardTitle className="fs-6 fw-semibold pt-1" style={{ borderBottom: '1px solid #ccc', padding: '12px' }}>{title}</CCardTitle>
        <DataTable
          columns={columns}
          data={data}
          pagination
          paginationServer // For server-side pagination
          paginationTotalRows={totalRows} // Total number of rows, should be passed from the parent
          paginationDefaultPage={page} // Current page
          paginationPerPage={rowsPerPage} // Current rows per page
          onChangePage={onChangePage} // Handle page change
          onChangeRowsPerPage={onChangeRowsPerPage} // Handle rows per page change
          highlightOnHover
          subHeader
          subHeaderComponent={
            <div className="mb-3 w-25">
              <CFormInput
                type="text"
                className="form-control w-100"
                placeholder="Search..."
                value={searchText}
                onChange={onChange}
              />
            </div>
          }
          subHeaderAlign="right"
          customStyles={customStyles}
        />
      </CCardBody>
    </CCard>
  );

};

export default DataTableComponent;
