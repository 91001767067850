import React, { useEffect, useState } from "react";
import {
  CButton,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CFormTextarea,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import axios from "../../api/axios";
import { toast } from "react-toastify";
import { isValidDescription, isValidName } from "../../utils/validation";
import { DataTableComponent, PrimaryButton } from "../../components";
import { FaPlus, FaTrash, FaPencilAlt, FaEye } from "react-icons/fa";

const RawMaterials = () => {
  const accessToken = localStorage.getItem("authToken");
  const [inputData, setInputData] = useState({
    mainCategory: "",
    subCategory: "",
    name: "",
    reOrderLevel: "",
    description: "",
    unit: "",
    hsn: "",
    taxPercent: "",
  });

  const [rawMaterialList, setRawMaterialList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [mainRawMateialList, setMainRawMateialList] = useState([]);
  const [subCategoryRawMaterials, setSubCategoryRawMaterials] = useState([]);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [editedData, setEditedData] = useState({});
  const [rawMaterialId, setRawMaterialId] = useState("");
  const [rawMaterialDetail, setRawMaterialDetail] = useState({});
  const [validationErrors, setValidationErrors] = useState({
    mainCategory: "",
    subCategory: "",
    name: "",
    description: "",
    unit: "",
    hsn: "",
    taxPercent: "",
  });
  const [editValidationErrors, setEditValidationErrors] = useState({
    mainCategory: "",
    subCategory: "",
    name: "",
    description: "",
    unit: "",
    hsn: "",
    taxPercent: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isEditBtnLoading, setIsEditBtnLoading] = useState(false);
  const [isDeleteBtnLoading, setIsDeleteBtnLoading] = useState(false);
  const [addNewOpenModal, setAddNewOpenModal] = useState(false);
  const [filteredSubCategoryRM, setFilteredSubCategoryRM] = useState([]);
  const [editedFilteredSubCatRM, setEditedFilteredSubCatRM] = useState([]);
  const [pagination, setPagination] = useState({ page: 1, rowsPerPage: 10 });
  const [rmMainCategoryId, setRmMainCategoryId] = useState('');

  const columns = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      cell: (row) => (
        <div className="fs-7">
          {row.serialNumber ? row.serialNumber : "--------"}
        </div>
      ),
      width: "50px",
    },
    {
      name: "NAME",
      selector: (row) => row.name,
      cell: (row) => (
        <div className="fs-7">{row.name ? row.name : "--------"}</div>
      ),
    },
    {
      name: "MAIN CAT. NAME",
      selector: (row) => row.mainCategory?.name,
      cell: (row) => (
        <div className="fs-7">
          {row.mainCategory?.name ? row.mainCategory?.name : "--------"}
        </div>
      ),
    },
    {
      name: "SUB CAT. NAME",
      selector: (row) => row.subCategory?.name,
      cell: (row) => (
        <div className="fs-7">
          {row.subCategory?.name ? row.subCategory?.name : "--------"}
        </div>
      ),
    },
    // {
    //   name: "DESCRIPTION",
    //   selector: (row) => row.description,
    //   cell: (row) => (
    //     <div className="fs-7">
    //       {row.description ? row.description : "--------"}
    //     </div>
    //   ),
    //   width: "150px",
    // },
    {
      name: "HSN",
      selector: (row) => row.hsn,
      cell: (row) => (
        <div className="fs-7">{row.hsn ? row.hsn : "--------"}</div>
      ),
    },
    {
      name: "TAX %",
      selector: (row) => row.taxPercent,
      cell: (row) => (
        <div className="fs-7">{row.taxPercent ? `${row.taxPercent}%` : 0}</div>
      ),
    },
    {
      name: "UNIT",
      selector: (row) => row.unit,
      cell: (row) => (
        <div className="fs-7">
          {row.unit === "LITRES"
            ? "LITRES"
            : row.unit === "GRAMS"
              ? "GRAMS"
              : row.unit === "KILOGRAMS"
                ? "KILOGRAMS"
                  : row.unit === "PACKETS"
                  ? "PACKETS"
                    : row.unit === "PCS"
                    ? "PCS"
                      : row.unit === "BOX"
                      ? "BOX"
                        : row.unit === "NOS"
                        ? "NOS"
                          : row.unit === "MM"
                          ? "MM"
                            : row.unit === "ML"
                            ? "ML"
                              : "-----"
          }
        </div>
      ),
    },
    {
      name: "ACTIONS",
      right: "true",
      cell: (row) => (
        <div className="d-flex justify-content-between align-items-center">
          <a
            href="#"
            className="text-success me-2 d-flex align-items-center gap-1"
            style={{ cursor: "pointer", textDecoration: "none" }}
            onClick={() => handleOpenViewModal(row)}
          >
            <FaEye /> View
          </a>{" "}
          |
          <a
            href="#"
            className="text-primary ms-2 me-2 d-flex align-items-center gap-1"
            style={{ cursor: "pointer", textDecoration: "none" }}
            onClick={() => handleEditModal(row)}
          >
            <FaPencilAlt /> Edit
          </a>{" "}
          |
          <a
            href="#"
            className="text-danger ms-2 d-flex align-items-center gap-1"
            style={{ cursor: "pointer", textDecoration: "none" }}
            onClick={() => handleDeleteModal(row)}
          >
            <FaTrash /> Delete
          </a>
        </div>
      ),
      width: "225px",
    },
  ];

  const filterCategoryRM = (data) => {
    const filteredData = subCategoryRawMaterials.filter(
      (item) => item.mainCategory === data?.mainCategory
    );
    setFilteredSubCategoryRM(filteredData)
  };

  const updatedColumns = columns.map((column) => ({
    ...column,
    name: (
      <div style={{ fontSize: "13px", fontWeight: "bold" }}>{column.name}</div>
    ),
  }));

  const handleAddNewOpenModal = () => {
    setAddNewOpenModal(true);
  };

  const handleOpenViewModal = (data) => {
    setOpenViewModal(true);
    getSingleRawMaterialDetail(data?._id);
  }

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputData((prevState) => ({ ...prevState, [name]: value }));
  };

  const addRawMaterial = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      const updatedData = { ...inputData };

      if(!updatedData.reOrderLevel) {
        delete updatedData.reOrderLevel;
      }

      const response = await axios.post("raw-material", updatedData, { headers });

      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });

        getRawMaterials();
        setInputData({
          mainCategory: "",
          subCategory: "",
          name: "",
          description: "",
          unit: "",
          hsn: "",
          taxPercent: "",
          reOrderLevel: "",
        });
        setAddNewOpenModal(false);
      }
    } catch (error) {
      if (error?.response) {
        let errorMessage = "";
        if (error.response?.status === 406) {
          errorMessage =
            error?.response?.data?.error?.message ||
            error?.response?.data?.error;
        } else if (error.response?.status === 412) {
          errorMessage = error?.response?.data?.data?.message;
        } else if (error.response?.status === 500) {
          errorMessage = error?.response?.data?.error;
        }

        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getRawMaterials = async (rmMainId) => {
    console.log("Raw Material Main Category Id :", rmMainId);
    
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get("raw-material", { headers });

      if (response?.data?.status === true) {
        response?.data?.data?.rawMaterials.forEach((item, index) => {
          item.serialNumber = index + 1;
        });
        console.log("Raw Materials Data :", response?.data?.data?.rawMaterials);
        
        if (rmMainId === "" || rmMainId === undefined) {
          setRawMaterialList(response?.data?.data?.rawMaterials);
          setFilteredData(response?.data?.data?.rawMaterials);
        } else {
          let filteredRawMaterial = response?.data?.data?.rawMaterials?.filter((fi) => fi?.mainCategory?._id === rmMainId);
          setFilteredData(filteredRawMaterial);
          setRawMaterialList(response?.data?.data?.rawMaterials);
        }

        
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const handleEditModal = async (data) => {
    setRawMaterialId(data._id);
    setEditedData({
      _id: data._id,
      mainCategory: data.mainCategory?._id || "",
      subCategory: data.subCategory?._id || "",
      name: data.name || "",
      description: data.description || "",
      unit: data.unit || "",
      hsn: data.hsn || "",
      reOrderLevel: data.reOrderLevel || "",
      taxPercent: data.taxPercent || 0,
    });
    setOpenEditModal(true);
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditedData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    setIsEditBtnLoading(true);

    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      const updatedData = { ...editedData };
      if(!updatedData.reOrderLevel) {
        updatedData.reOrderLevel = null
      }

      const response = await axios.put("raw-material", updatedData, { headers });
      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
        getRawMaterials();
        setOpenEditModal(false);
        getSingleRawMaterialDetail(rawMaterialId);
        setOpenViewModal(true);
        setRawMaterialId("");
      }
    } catch (error) {
      if (error?.response) {
        let errorMessage = "";
        
        if (error.response?.status === 406) {
          errorMessage =
            error?.response?.data?.error?.message ||
            error?.response?.data?.error;
        } else if (error.response?.status === 412) {
          errorMessage = error?.response?.data?.data?.message || 
          error?.response?.data?.error;
        } else if (error.response?.status === 500) {
          errorMessage = error?.response?.data?.error;
        }

        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      setIsEditBtnLoading(false);
    }
  };

  const handleDeleteModal = async (data) => {
    setOpenDeleteModal(true);
    setRawMaterialId(data?._id);
  };

  const handleDeleteRawMaterial = async (e) => {
    e.preventDefault();
    setIsDeleteBtnLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      const data = {
        _id: rawMaterialId,
      };

      const response = await axios.delete(`raw-material`, { data, headers });
      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
        getRawMaterials();
        setOpenDeleteModal(false);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      setIsDeleteBtnLoading(false);
    }
  };

  const getSingleRawMaterialDetail = async (materialId) => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get(`raw-material?_id=${materialId}`, {
        headers,
      });

      if (response?.data?.status === true) {
        setRawMaterialDetail(response?.data?.data?.rawMaterials);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const getMainRawMaterials = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get("masters/rm-main-category/get", {
        headers,
      });

      if (response?.data?.status === true) {
        setMainRawMateialList(response?.data?.data?.categories);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const getSubCategoryRawMaterials = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get("masters/rm-sub-category/get", {
        headers,
      });

      if (response?.data?.status === true) {
        setSubCategoryRawMaterials(response?.data?.data?.categories);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const handleFilteredSubCatRM = () => {
    if (editedData?.mainCategory) {
      const filteredData = subCategoryRawMaterials?.filter((item) => item?.mainCategory === editedData.mainCategory)
      setEditedFilteredSubCatRM(filteredData);
    }
  }

  // const disableButton =
  //   !inputData.mainCategory ||
  //   !inputData.subCategory ||
  //   !inputData.name.trim() ||
  //   inputData.name.trim() === "" ||
  //   !inputData.description.trim() ||
  //   inputData.description.trim() === "" ||
  //   !inputData.unit ||
  //   !inputData.taxPercent ||
  //   !inputData.hsn.trim();

  // const editButtonDisable =
  //   !editedData.mainCategory ||
  //   !editedData.subCategory ||
  //   !editedData.name ||
  //   !editedData.name.trim() ||
  //   !editedData.description ||
  //   !editedData.description.trim() ||
  //   !editedData.unit ||
  //   !editedData.taxPercent ||
  //   !editedData.hsn ||
  //   !editedData.hsn.trim();

  const handlePageChange = (page) => {
    setPagination({ ...pagination, page });
  };
  
  const handleRowsPerPageChange = (rowsPerPage) => {
    setPagination({ page: 1, rowsPerPage }); // Reset to the first page when changing rows per page
  };
  
  // Function to get paginated data
  const getPaginatedData = () => {
    const startIndex = (pagination.page - 1) * pagination.rowsPerPage;
    const endIndex = startIndex + pagination.rowsPerPage;
    return filteredData.slice(startIndex, endIndex);
  };

  useEffect(() => {
    getMainRawMaterials();
    getSubCategoryRawMaterials();
    getRawMaterials();
  }, []);

  useEffect(() => {
    const result = rawMaterialList.filter((item) => {
      return (
        item.name.toLowerCase().match(searchText.toLocaleLowerCase()) ||
        item.description.toLowerCase().match(searchText.toLocaleLowerCase()) ||
        item.mainCategory?.name
          .toLowerCase()
          .match(searchText.toLocaleLowerCase()) ||
        item.mainCategory?.description
          .toLowerCase()
          .match(searchText.toLocaleLowerCase()) ||
        item.subCategory?.name
          .toLowerCase()
          .match(searchText.toLocaleLowerCase()) ||
        item.subCategory?.description
          .toLowerCase()
          .match(searchText.toLocaleLowerCase()) ||
        item.hsn.toLowerCase().match(searchText.toLocaleLowerCase()) ||
        item.taxPercent.toString().includes(searchText) ||
        item.unit.toLowerCase().match(searchText.toLocaleLowerCase())
      );
    });
    setFilteredData(result);
  }, [searchText]);

  useEffect(() => {
    filterCategoryRM(inputData);
  }, [inputData?.mainCategory]);

  useEffect(() => {
    if (editedData?.mainCategory) {
      const data = subCategoryRawMaterials?.filter(
        (item) => item.mainCategory === editedData.mainCategory
      );
      setEditedFilteredSubCatRM(data);
    }
  }, [editedData?.mainCategory]);

  return (
    <CContainer>

      <CRow className="mb-3 justify-content-between">
        <CCol md="3" lg="3">
          <CFormLabel className="fw-semibold">Select Raw Material Main Category</CFormLabel>
          <CFormSelect
              value={rmMainCategoryId}
              onChange={(e) => {setRmMainCategoryId(e.target.value); getRawMaterials(e.target.value)}}
          >
              <option value="">Select Main Category</option>
              {mainRawMateialList.map((rm) => (
                  <option key={rm._id} value={rm._id}>
                      {rm.name}
                  </option>
              ))}
          </CFormSelect>
        </CCol>
        <CCol  style={{ display: 'flex', justifyContent: 'end' }} md={4} lg={4}>
          <div className="mb-3 text-end d-flex" style={{ flexDirection: 'column' }}>
            <CFormLabel className="text-white">.</CFormLabel>
            <button
              className="btn btn-warning"
              onClick={handleAddNewOpenModal}
              style={{ borderRadius: "18px" }}
            >
              ADD NEW RAW MATERIAL <FaPlus style={{ marginTop: "-4px" }} size={15} />
            </button>
          </div>
        </CCol>
      </CRow>

      <div>
        <DataTableComponent
          columns={updatedColumns}
          // data={filteredData}
          data={getPaginatedData()}
          title="RAW MATERIALS LIST"
          searchText={searchText}
          onChange={handleSearchChange}
          pagination
          totalRows={filteredData?.length}
          paginationTotalRows={filteredData.length} // Total number of items
          onChangeRowsPerPage={handleRowsPerPageChange}
          onChangePage={handlePageChange}
          paginationServer={false} // Set to false for client-side pagination
        />
      </div>

      {openEditModal ? (
        <CModal
          size="lg"
          alignment="center"
          visible={openEditModal}
          onClose={() => setOpenEditModal(false)}
          aria-labelledby="VerticallyCenteredExample"
        >
          <CModalHeader>
            <CModalTitle
              id="VerticallyCenteredExample"
              className="fs-6 fw-semibold"
            >
              EDIT RAW MATERIAL
            </CModalTitle>
          </CModalHeader>
          <CForm onSubmit={handleEditSubmit}>
            <CModalBody>
              <CRow>
                <CCol md="6" lg="4">
                  {" "}
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Select Main Category{" "}
                      <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormSelect
                      size="sm"
                      onChange={handleEditChange}
                      name="mainCategory"
                      className="mb-3"
                      value={editedData.mainCategory}
                    >
                      <option>Select Main Category</option>
                      {mainRawMateialList.map((rm) => (
                        <option key={rm._id} value={rm._id}>
                          {rm.name}
                        </option>
                      ))}
                    </CFormSelect>
                  </div>
                </CCol>
                <CCol md="6" lg="4">
                  {" "}
                  <div className="mb-3">
                    {" "}
                    <CFormLabel className="fw-semibold">
                      Select Sub Category <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormSelect
                      size="sm"
                      onChange={handleEditChange}
                      name="subCategory"
                      className="mb-3"
                      value={editedData.subCategory}
                    >
                      <option>Select Sub Category</option>
                      {editedFilteredSubCatRM.map((rm) => (
                        <option key={rm._id} value={rm._id}>
                          {rm.name}
                        </option>
                      ))}
                    </CFormSelect>
                  </div>
                </CCol>
                <CCol md="4" lg="4">
                  <div className="mb-3">
                    {" "}
                    <CFormLabel className="fw-semibold">
                      Name <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      type="text"
                      placeholder="Name"
                      className="form-control"
                      name="name"
                      value={editedData?.name}
                      onChange={handleEditChange}
                    />
                  </div>
                </CCol>

                <CCol md="3" lg="3">
                  {" "}
                  <div className="mb-3">
                    {" "}
                    <CFormLabel className="fw-semibold">
                      Select Units <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormSelect
                      size="sm"
                      onChange={handleEditChange}
                      name="unit"
                      className="mb-3"
                      value={editedData?.unit}
                    >
                      <option value="">Select Units</option>
                      <option value="LITRES">LITRES</option>
                      <option value="ML">ML</option>
                      <option value="GRAMS">GRAMS</option>
                      <option value="KILOGRAMS">KILOGRAMS</option>
                      <option value="PACKETS">PACKETS</option>
                      <option value="PCS">PCS</option>
                      <option value="BOX">BOX</option>
                      <option value="NOS">NOS</option>
                      <option value="MM">MM</option>
                    </CFormSelect>
                  </div>
                </CCol>
                <CCol md="3" lg="3">
                  {" "}
                  <div className="mb-3">
                    {" "}
                    <CFormLabel className="fw-semibold">
                      HSN <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      type="text"
                      placeholder="HSN"
                      className="form-control"
                      name="hsn"
                      value={editedData?.hsn}
                      onChange={handleEditChange}
                    />
                  </div>
                </CCol>
                <CCol md="3" lg="3">
                  {" "}
                  <div className="mb-3">
                    {" "}
                    <CFormLabel className="fw-semibold">
                      Tax Percent <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      min={0}
                      max={100}
                      type="number"
                      placeholder="Tax Percent"
                      className="form-control no-arrows"
                      name="taxPercent"
                      value={editedData?.taxPercent}
                      onChange={handleEditChange}
                    />
                  </div>
                </CCol>

                <CCol md="3" lg="3">
                  <CFormLabel className="fw-semibold">
                      Re-Order Level
                  </CFormLabel>
                  <CFormInput
                    min="0"
                    type="number"
                    placeholder="Re-Order Level"
                    className="form-control no-arrows"
                    name="reOrderLevel"
                    value={editedData?.reOrderLevel}
                    onChange={handleEditChange}
                  />
                </CCol>

                <CCol>
                  {" "}
                  <div className="mb-3">
                    {" "}
                    <CFormLabel className="fw-semibold">
                      Description <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormTextarea
                      rows={2}
                      type="text"
                      placeholder="Description"
                      className="form-control"
                      name="description"
                      value={editedData?.description}
                      onChange={handleEditChange}
                    />
                  </div>
                </CCol>
              </CRow>
            </CModalBody>
            <CModalFooter>
              <PrimaryButton
                // isDisable={editButtonDisable}
                title="SAVE CHANGES"
                isLoading={isEditBtnLoading}
              />
            </CModalFooter>
          </CForm>
        </CModal>
      ) : null}

      {openDeleteModal ? (
        <CModal
          alignment="center"
          visible={openDeleteModal}
          onClose={() => setOpenDeleteModal(false)}
          aria-labelledby="VerticallyCenteredExample"
        >
          <CModalHeader>
            <CModalTitle
              id="VerticallyCenteredExample"
              className="fs-6 fw-semibold"
            >
              DELETE RAW MATERIAL
            </CModalTitle>
          </CModalHeader>
          <CForm onSubmit={handleDeleteRawMaterial}>
            <CModalBody>
              Are you sure you want to delete this raw material ?
            </CModalBody>
            <CModalFooter>
              <CButton
                color="warning"
                onClick={() => setOpenDeleteModal(false)}
                style={{
                  borderRadius: "18px",
                }}
              >
                CLOSE
              </CButton>
              <PrimaryButton title="CONFIRM" isLoading={isDeleteBtnLoading} />
            </CModalFooter>
          </CForm>
        </CModal>
      ) : null}

      {openViewModal ? (
        <CModal
          size="lg"
          alignment="center"
          visible={openViewModal}
          onClose={() => setOpenViewModal(false)}
          aria-labelledby="VerticallyCenteredExample"
        >
          <CModalBody>
            <CTable striped bordered>
              <CTableHead>
                <CTableRow>
                  <CTableHeaderCell
                    colSpan="2"
                    className="text-center fw-semibold"
                  >
                    VIEW RAW MATERIAL DETAILS
                  </CTableHeaderCell>
                </CTableRow>
              </CTableHead>
              <CTableBody>
                <CTableRow>
                  <CTableDataCell className="fw-semibold">Raw Material Name</CTableDataCell>
                  <CTableDataCell>{rawMaterialDetail?.name}</CTableDataCell>
                </CTableRow>
                <CTableRow>
                  <CTableDataCell className="fw-semibold">
                    Description
                  </CTableDataCell>
                  <CTableDataCell>
                    {rawMaterialDetail?.description}
                  </CTableDataCell>
                </CTableRow>
                <CTableRow>
                  <CTableDataCell className="fw-semibold">
                    RM Main Cat. Name
                  </CTableDataCell>
                  <CTableDataCell>
                    {rawMaterialDetail?.mainCategory?.name}
                  </CTableDataCell>
                </CTableRow>
                <CTableRow>
                  <CTableDataCell className="fw-semibold">
                    RM Main Cat. Description
                  </CTableDataCell>
                  <CTableDataCell>
                    {rawMaterialDetail?.mainCategory?.description}
                  </CTableDataCell>
                </CTableRow>
                <CTableRow>
                  <CTableDataCell className="fw-semibold">
                    RM Sub Cat. Name
                  </CTableDataCell>
                  <CTableDataCell>
                    {rawMaterialDetail?.subCategory?.name}
                  </CTableDataCell>
                </CTableRow>
                <CTableRow>
                  <CTableDataCell className="fw-semibold">
                    RM Sub Cat. Description
                  </CTableDataCell>
                  <CTableDataCell>
                    {rawMaterialDetail?.subCategory?.description}
                  </CTableDataCell>
                </CTableRow>
                <CTableRow>
                  <CTableDataCell className="fw-semibold">HSN</CTableDataCell>
                  <CTableDataCell>{rawMaterialDetail?.hsn}</CTableDataCell>
                </CTableRow>
                <CTableRow>
                  <CTableDataCell className="fw-semibold">Re-Order Level</CTableDataCell>
                  <CTableDataCell>{rawMaterialDetail?.reOrderLevel}</CTableDataCell>
                </CTableRow>
                <CTableRow>
                  <CTableDataCell className="fw-semibold">
                    Tax Percent
                  </CTableDataCell>
                  <CTableDataCell>
                    {rawMaterialDetail?.taxPercent}%
                  </CTableDataCell>
                </CTableRow>
                <CTableRow>
                  <CTableDataCell className="fw-semibold">Unit</CTableDataCell>
                  <CTableDataCell>
                    {rawMaterialDetail.unit === "LITRES"
                      ? "LITRES"
                      : rawMaterialDetail.unit === "GRAMS"
                        ? "GRAMS"
                        : rawMaterialDetail.unit === "KILOGRAMS"
                          ? "KILOGRAMS"
                          : rawMaterialDetail.unit === "PACKETS"
                            ? "PACKETS"
                              : rawMaterialDetail.unit === "PCS"
                              ? "PCS"
                                : rawMaterialDetail.unit === "BOX"
                                ? "BOX"
                                  : rawMaterialDetail.unit === "NOS"
                                  ? "NOS"
                                    : rawMaterialDetail.unit === "MM"
                                    ? "MM"
                                      : rawMaterialDetail.unit === "ML"
                                      ? "ML"
                                    : "---"
                    }
                  </CTableDataCell>
                </CTableRow>
              </CTableBody>
            </CTable>
          </CModalBody>
          <CModalFooter>
            <CButton
              className="btn btn-warning"
              onClick={() => setOpenViewModal(false)}
            >
              CLOSE
            </CButton>
          </CModalFooter>
        </CModal>
      ) : null}

      {addNewOpenModal ? (
        <CModal
          size="lg"
          alignment="center"
          visible={addNewOpenModal}
          onClose={() => setAddNewOpenModal(false)}
          aria-labelledby="VerticallyCenteredExample"
        >
          <CModalHeader>
            <CModalTitle>ADD RAW MATERIAL</CModalTitle>
          </CModalHeader>
          <CForm onSubmit={addRawMaterial}>
            <CModalBody>
              <CRow>
                <CCol md="6" lg="4">
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Select Main Category{" "}
                      <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormSelect
                      size="sm"
                      onChange={handleChange}
                      name="mainCategory"
                      className="mb-3"
                      aria-label="Small select example"
                      value={inputData?.mainCategory}
                    >
                      <option value="">Select Main Category</option>
                      {mainRawMateialList.map((rm) => (
                        <option key={rm._id} value={rm._id}>
                          {rm.name}
                        </option>
                      ))}
                    </CFormSelect>
                  </div>
                </CCol>
                <CCol md="6" lg="4">
                  {" "}
                  <div className="mb-3">
                    {" "}
                    <CFormLabel className="fw-semibold">
                      Select Sub Category <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormSelect
                      size="sm"
                      onChange={handleChange}
                      name="subCategory"
                      className="mb-3"
                      aria-label="Small select example"
                      value={inputData?.subCategory}
                      disabled={!inputData?.mainCategory}
                    >
                      <option value="">Select Sub Category</option>
                      {filteredSubCategoryRM.map((rm) => (
                        <option key={rm._id} value={rm._id}>
                          {rm.name}
                        </option>
                      ))}
                    </CFormSelect>
                  </div>
                </CCol>

                <CCol md="4" lg="4">
                  {" "}
                  <div className="mb-3">
                    {" "}
                    <CFormLabel className="fw-semibold">
                      Name <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      type="text"
                      placeholder="Name"
                      className="form-control"
                      name="name"
                      value={inputData.name}
                      onChange={handleChange}
                    />
                  </div>
                </CCol>

                <CCol md="3" lg="3">
                  {" "}
                  <div className="mb-3">
                    {" "}
                    <CFormLabel className="fw-semibold">
                      Select Units <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormSelect
                      size="sm"
                      onChange={handleChange}
                      name="unit"
                      className="mb-3"
                      aria-label="Small select example"
                      value={inputData?.unit}
                    >
                      <option value="">Select Units</option>
                      <option value="LITRES">LITRES</option>
                      <option value="ML">ML</option>
                      <option value="GRAMS">GRAMS</option>
                      <option value="KILOGRAMS">KILOGRAMS</option>
                      <option value="PACKETS">PACKETS</option>
                      <option value="PCS">PCS</option>
                      <option value="BOX">BOX</option>
                      <option value="NOS">NOS</option>
                      <option value="MM">MM</option>
                    </CFormSelect>
                  </div>
                </CCol>

                <CCol md="3" lg="3">
                  <div className="mb-3">
                    {" "}
                    <CFormLabel className="fw-semibold">
                      HSN <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      type="text"
                      placeholder="HSN"
                      className="form-control"
                      name="hsn"
                      value={inputData.hsn}
                      onChange={handleChange}
                    />
                  </div>
                </CCol>

                <CCol md="3" lg="3">
                  {" "}
                  <div className="mb-3">
                    {" "}
                    <CFormLabel className="fw-semibold">
                      Tax Percent <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      min={0}
                      max={100}
                      type="number"
                      placeholder="Tax Percent"
                      className="form-control no-arrows"
                      name="taxPercent"
                      value={inputData.taxPercent}
                      onChange={handleChange}
                    />
                  </div>
                </CCol>

                <CCol md="3" lg="3">
                  <CFormLabel className="fw-semibold">
                      Re-Order Level
                  </CFormLabel>
                  <CFormInput
                    min="0"
                    type="number"
                    placeholder="Re-Order Level"
                    className="form-control no-arrows"
                    name="reOrderLevel"
                    value={inputData.reOrderLevel}
                    onChange={handleChange}
                  />
                </CCol>
                
                <CCol>
                  <div className="mb-3">
                    {" "}
                    <CFormLabel className="fw-semibold">
                      Description <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormTextarea
                      rows={2}
                      type="text"
                      placeholder="Description"
                      className="form-control"
                      name="description"
                      value={inputData.description}
                      onChange={handleChange}
                    />
                  </div>
                </CCol>
              </CRow>
            </CModalBody>
            <CModalFooter>
              <PrimaryButton
                style={{ borderRadius: "18px" }}
                // isDisable={disableButton}
                title="ADD RAW MATERIAL"
                isLoading={isLoading}
              />
            </CModalFooter>
          </CForm>
        </CModal>
      ) : null}
    </CContainer>
  );
};

export default RawMaterials;
