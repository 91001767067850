import { CBadge, CCol, CFormLabel, CFormSelect, CRow, CSpinner, CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow } from '@coreui/react'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import axios from '../../api/axios';

const RMReorderLevelReport = () => {

    const accessToken = localStorage.getItem("authToken");
    const [mainCategoryList, setmainCategoryList] = useState([]);
    const [mainCategoryId, setMainCategoryId] = useState('');
    const [subCategoryList, setSubCategoryList] = useState([]);
    const [subCategoryId, setSubCategoryId] = useState('');
    const [rMReportList, setRMReportList] = useState([]);
    const [rmOriginalList, setRMOriginalList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filterdData, setFilterdData] = useState([]);



    const getMainRawMaterials = async () => {
        try {
          const headers = {
            Authorization: `Bearer ${accessToken}`,
          };
          const response = await axios.get("masters/rm-main-category/get", {
            headers,
          });
    
          if (response?.data?.status === true) {
            setmainCategoryList(response?.data?.data?.categories);
          }
        } catch (error) {
          if (error.response && error.response?.data?.status === false) {
            let errorMessage = error.response.data.error;
            toast.error(errorMessage, {
              position: "top-right",
              autoClose: 3000,
              theme: "colored",
            });
          }
        }
    };
    
    const getSubCategoryRawMaterials = async () => {
        try {
          const headers = {
            Authorization: `Bearer ${accessToken}`,
          };
          const response = await axios.get("masters/rm-sub-category/get", {
            headers,
          });
    
          if (response?.data?.status === true) {
            setSubCategoryList(response?.data?.data?.categories);
          }
        } catch (error) {
          if (error.response && error.response?.data?.status === false) {
            let errorMessage = error.response.data.error;
            toast.error(errorMessage, {
              position: "top-right",
              autoClose: 3000,
              theme: "colored",
            });
          }
        }
    };

    const getReports = async() => {
        setLoading(false);
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`
            }
            const response = await axios.get("raw-material/re-order-level-report", { headers });
            console.log("Response of the reports", response?.data?.data?.rawMaterials);
            setRMReportList(response?.data?.data?.rawMaterials);
            setRMOriginalList(response?.data?.data?.rawMaterials);

            //fetch the main categories from the above response
            const mainCategoryList = response?.data?.data?.rawMaterials.map(material=>material.mainCategory);
            const mainCategoryUniqueList = mainCategoryList.filter((material, index, self) =>
                index === self.findIndex((obj) => obj._id === material._id)
              );
            setmainCategoryList(mainCategoryUniqueList??[]);

            //fetch the sub categories from the above response
            const subCategoryList = response?.data?.data?.rawMaterials.map(material=>material.subCategory);
            const subCategoryUniqueList = subCategoryList.filter((material, index, self) =>
                index === self.findIndex((obj) => obj._id === material._id)
              );
            console.log("set sub category in use effect");  
            setSubCategoryList(subCategoryUniqueList??[]);

        } catch (error) {
            let errorMessage = '';
            if ( error.response?.status === 406 ) {
                errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
            }
            else if ( error.response?.status === 412 ) {
                errorMessage = error?.response?.data?.data?.message;
            } else if (error.response?.status === 500) {
                errorMessage = error?.response?.data?.error
            }

            toast.error(errorMessage, {
                position: "top-right",
                autoClose: 3000,
                theme: "colored",
            });            
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        // getMainRawMaterials();
        // getSubCategoryRawMaterials();
        getReports();
    }, [])

    console.log("subc ategry list: ",subCategoryList)

    const getFilteredMainCategoryRawMaterials=(e)=>{
        setMainCategoryId(e.target.value);
        if(e.target.value==='all'){
            const originalRMList = [...rmOriginalList];
            setRMReportList(originalRMList);

            //update the sub category list
            const subCategoryList = rmOriginalList.map(material=>material.subCategory);
            
            const updatedSubCategoryUniqueList = Array.from(
                new Map(subCategoryList.map(item => [item._id, item])).values()
              );
            setSubCategoryList(updatedSubCategoryUniqueList??[]);
        }
        else{
            const filteredRawMaterialList = rmOriginalList.filter(material=>material.mainCategory._id.toString()===e.target.value.toString());
            setRMReportList(filteredRawMaterialList??[]);

            //update the sub category list
            const subCategoryList = rmOriginalList.map(material=>material.subCategory);
            const updatedSubCategoryList = subCategoryList.filter(material=>material.mainCategory.toString()===e.target.value.toString());
            console.log("updated subcategory list: ",updatedSubCategoryList);
            
            const updatedSubCategoryUniqueList = Array.from(
                new Map(updatedSubCategoryList.map(item => [item._id, item])).values()
              );
            console.log("unnique sub category list: ",updatedSubCategoryUniqueList);
            setSubCategoryList(prev=>[...updatedSubCategoryList]);
        }  
    }

    const getFilteredSubCategoryRawMaterials=(e)=>{
        setSubCategoryId(e.target.value);
        if(e.target.value==='all'){
            setRMReportList(prev=>[...prev]);
        }
        else{
            const filteredRawMaterialList = rmOriginalList.filter(material=>material.subCategory._id.toString()===e.target.value.toString());
            setRMReportList(filteredRawMaterialList??[]);
        }  
    }

    return (
        <div className='container'>

            <h5>RM RE-ORDER LEVEL REPORT</h5>

            <CRow className='mt-3'>
                <CCol lg="3" md="4">
                    <CFormLabel className="fw-semibold">Select RM Main Category</CFormLabel>
                    <CFormSelect name="select-field" value={mainCategoryId} onChange={(e) => getFilteredMainCategoryRawMaterials(e)}>
                        <option value="all">RM Main Category</option>
                        {mainCategoryList.map((rm,index) => (
                            <option key={index} value={rm._id}>
                                {rm.name}
                            </option>
                        ))}
                    </CFormSelect>
                </CCol>
                <CCol lg="3" md="4">
                    <CFormLabel className="fw-semibold">Select RM Sub Category</CFormLabel>
                    <CFormSelect name="select-field" value={subCategoryId} onChange={(e) => getFilteredSubCategoryRawMaterials(e)}>
                        <option value="all">RM Sub Category</option>
                        {subCategoryList.map((rm,index) => (
                            <option key={index} value={rm._id}>
                                {rm.name}
                            </option>
                        ))}
                    </CFormSelect>
                </CCol>
            </CRow>

            <CRow className='mt-4'>
                <CCol>
                    <CTable striped bordered hover responsive>
                        <CTableHead>
                            <CTableRow style={{ fontSize: '15px' }}>
                            <CTableHeaderCell>#</CTableHeaderCell>
                            <CTableHeaderCell>Raw Material</CTableHeaderCell>
                            <CTableHeaderCell>RM Main Category</CTableHeaderCell>
                            <CTableHeaderCell>Rm Sub Category</CTableHeaderCell>
                            <CTableHeaderCell className='text-center'>Re-Order Level</CTableHeaderCell>
                            <CTableHeaderCell className='text-center'>Current Stock</CTableHeaderCell>
                            <CTableHeaderCell>Status</CTableHeaderCell>
                            </CTableRow>
                        </CTableHead>
                        <CTableBody>
                            {loading ? (
                            <CTableRow>
                                <CTableDataCell colSpan="10" className="text-center">
                                <CSpinner color="primary" size="lg" />
                                </CTableDataCell>
                            </CTableRow>
                            ) :
                            rMReportList?.length > 0 ? (
                                
                                <>
                                    {rMReportList?.map((customerData, index) => (
                                        <CTableRow key={index} style={{ fontSize: '15px', backgroundColor: (customerData?.inStock === true &&  customerData?.isReOrderLevelReached === true) ? "#9df5b4":(customerData?.inStock === true &&  customerData?.isReOrderLevelReached === false) ? "#fff2a8" : "#ffafa8" } }>
                                            <CTableDataCell>{index + 1}</CTableDataCell>
                                            <CTableDataCell className='fw-semibold text-secondary'>{customerData?.name}</CTableDataCell>
                                            <CTableDataCell>{customerData?.mainCategory?.name}</CTableDataCell>
                                            <CTableDataCell>{customerData?.subCategory?.name}</CTableDataCell>
                                            <CTableDataCell className='text-center'><span>{customerData?.reOrderLevel}</span> <span>{customerData?.unit}</span></CTableDataCell>
                                            <CTableDataCell className='text-center'>{customerData?.currentCentralStock} {customerData?.unit}</CTableDataCell>
                                            <CTableDataCell className='text-center'>
                                                {(customerData?.inStock === true && customerData?.isReOrderLevelReached === true) ? (
                                                    <CBadge className='w-75' color="success">AT RE-ORDER LEVEL</CBadge>
                                                ) : (customerData?.inStock === true && customerData?.isReOrderLevelReached === false) ? (
                                                    <CBadge className='w-75' color="warning">LOW STOCK</CBadge>
                                                ) : (
                                                    <CBadge className='w-75' color="danger">OUT OF STOCK</CBadge>
                                                )}
                                            </CTableDataCell>
                                            {/* <CTableDataCell>{(customerData?.inStock === true &&  customerData?.isReOrderLevelReached === true) ? "AT RE-ORDER LEVEL ":(customerData?.inStock === true &&  customerData?.isReOrderLevelReached === false) ? "LOW STOCK" : "OUT OF STOCK" }</CTableDataCell> */}
                                        </CTableRow>
                                    ))}
                                </>
                            ) : (
                            <CTableRow>
                                <CTableDataCell colSpan="7" className="text-center text-danger fw-5">
                                    No Reports Found
                                </CTableDataCell>
                            </CTableRow>
                            )}
                        </CTableBody>
                    </CTable>
                </CCol>
            </CRow>
        </div>
    )
}

export default RMReorderLevelReport