import { CCard, CCardBody, CCardHeader, CCardTitle, CCol, CContainer, CFormLabel, CFormSelect, CRow } from "@coreui/react";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from "../../api/axios";
import BirthdayCoupon from "./BirthdayCoupon";
import BOGOCoupon from "./BOGOCoupon";
import BoosterPoint from "./BoosterPoint";
import SizeUpgrade from "./SizeUpgrade";

const LevelRewards = () => {
    const accessToken = localStorage.getItem("authToken");
    const [selectedCouponOption, setSelectedCouponOption] = useState("");
    const [levels, setLevels] = useState([]);
    const [couponsData, setCouponsData] = useState([]);

    const fetchLevelsData = async () => {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };
            const response = await axios.get("level", { headers });
            if (response?.data?.status === true) {
                const levels = response?.data?.data?.levels;
                setLevels(levels)
            }
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
    };

    const fetchCouponData = async () => {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };
            const response = await axios.get("coupons", { headers });

            if (response?.data?.status === true) {
                setCouponsData(response?.data?.data?.coupons);
            }
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
    };

    useEffect(() => {
        fetchLevelsData()
        fetchCouponData()
    }, [])

    return (
        <CContainer>
            <CRow>
                <CCol className={`${selectedCouponOption === "" ? "col-lg-6 col-md-12 mx-md-auto" : "col-lg-4 col-md-12 mx-auto mb-3"}`}>
                    <CCard>
                        <CCardHeader>
                            <CCardTitle className="fs-6 pt-1">SELECT LEVEL REWARD</CCardTitle>
                        </CCardHeader>
                        <CCardBody>
                            <div className="mb-5">
                                <CFormLabel className="fw-semibold">
                                    Select a Level Reward <span className="text-danger">*</span>
                                </CFormLabel>
                                <CFormSelect
                                    onChange={(e) => setSelectedCouponOption(e.target.value)}
                                    value={selectedCouponOption}
                                >
                                    <option value="">Select a Level Reward</option>
                                    <option value="BIRTHDAY">BIRTHDAY</option>
                                    <option value="BOGO">BOGO</option>
                                    <option value="SIZE UPGRADE">SIZE UPGRADE</option>
                                    <option value="BOOSTER POINT">BOOSTER POINT</option>
                                    <option value="FREE COMPLIMENTARY">FREE COMPLIMENTARY</option>
                                </CFormSelect>
                            </div>
                        </CCardBody>
                    </CCard>
                </CCol>

                <CCol>
                    {selectedCouponOption === "BIRTHDAY" && (
                        <BirthdayCoupon levels={levels} />
                    )}
                    {selectedCouponOption === "BOGO" && (
                        <BOGOCoupon levels={levels} couponsData={couponsData} />
                    )}
                    {selectedCouponOption === "SIZE UPGRADE" && (
                        <SizeUpgrade levels={levels} couponsData={couponsData} />
                    )}
                    {selectedCouponOption === "BOOSTER POINT" && (
                        <BoosterPoint levels={levels} couponsData={couponsData} />
                    )}
                    {/* {} */}
                </CCol>
            </CRow>
        </CContainer>
    )
}

export default LevelRewards;