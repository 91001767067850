import React, { useEffect, useState } from 'react'
import { CButton, CCol, CContainer, CForm, CFormInput, CFormLabel, CFormSelect, CFormTextarea, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle, CRow, CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow } from '@coreui/react'
import { DataTableComponent, DeleteModal, PrimaryButton } from '../../components'
import Select from "react-select";
import DatePicker from "react-datepicker";
import makeAnimated from "react-select/animated";
import { toast } from 'react-toastify';
import axios from '../../api/axios';
import { format } from 'date-fns';
import { FaEye, FaPencilAlt, FaPlus, FaTrash } from 'react-icons/fa';
import debounce from 'lodash/debounce';

const Add = () => {
    const accessToken = localStorage.getItem("authToken");
    const animatedComponents = makeAnimated();
    const [usersList, setUsersList] = useState([]);
    const [productsList, setProductsList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [couponData, setCouponData] = useState({
        title: "",
        description: "",
        usageType: "",
        couponCode: "",
        discountPercent: "",
        discountAmount: "",
        validityStartDate: "",
        validityEndDate: "",
        discountPrice: "",
        applicableQuantityType: ""
    });
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [addNewOpenModal, setAddNewOpenModal] = useState(false);
    const [userCouponAllocationData, setUserCouponAllocationData] = useState([]);
    const [openViewModal, setOpenViewModal] = useState(false);
    const [couponUsers, setCouponUsers] = useState([]);
    const [couponDetails, setCouponDetails] = useState({});
    const [openEditModal, setOpenEditModal] = useState(false);
    const [editCouponData, setEditCouponData] = useState({
        title: "",
        description: "",
        usageType: "",
        couponCode: "",
        discountPercent: "",
        discountAmount: "",
        validityStartDate: "",
        validityEndDate: "",
        discountPrice: "",
        applicableQuantityType: ""
    });
    const [userCouponId, setUserCouponId] = useState("");
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [userCouponAllocationId, setUserCouponAllocationId] = useState("");
    const [isDeleteBtnLoading, setIsDeleteBtnLoading] = useState(false);
    const [isEditBtnLoading, setIsEditBtnLoading] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [pagination, setPagination] = useState({ page: 1, rowsPerPage: 10 });
    const [filteredData, setFilteredData] = useState([]);

    const formattedDate = (string) => {
        return string?.split("T")[0];
    }

    const columns = [
        {
            name: "Sr.No",
            // cell: (row, index) => (pagination.page - 1) * pagination.rowsPerPage + index + 1,
            cell: (row, index) => <div className="fs-7">{row.serialNumber ? row.serialNumber : "--------"}</div>,
            // center: true,
        },
        {
            name: "COUPON TITLE",
            selector: (row) => row.coupon_id?.title,
            cell: (row) => (
                <div className="fs-7">{row.coupon_id?.title ? row.coupon_id?.title : "--------"}</div>
            ),
        },
        {
            name: "CODE",
            selector: (row) => row.coupon_id?.couponCode,
            cell: (row) => (
                <div className="fs-7">{row.coupon_id?.couponCode ? row.coupon_id?.couponCode : "--------"}</div>
            ),
        },
        {
            name: "USAGE-TYPE",
            selector: (row) => row.coupon_id?.usageType
            ,
            cell: (row) => (
                <div className="fs-7">{row.coupon_id?.usageType
                    ? row.coupon_id?.usageType
                    : "--------"}</div>
            ),
        },
        {
            name: "VALID FROM ",
            selector: (row) => row.coupon_id?.validityStartDate
            ,
            cell: (row) => (
                <div className="fs-7">{row.coupon_id?.validityStartDate
                    ? formattedDate(row.coupon_id?.validityStartDate)
                    : "--------"}</div>
            ),
        },
        {
            name: "VALID TO ",
            selector: (row) => row.coupon_id?.validityEndDate
            ,
            cell: (row) => (
                <div className="fs-7">{row.coupon_id?.validityEndDate
                    ? formattedDate(row.coupon_id?.validityEndDate)
                    : "--------"}</div>
            ),
        },
        {
            name: "ACTIONS",
            right: "true",
            width: "25%",
            cell: (row) => (
                <div className="d-flex justify-content-around align-items-center">
                    <a
                        href="#"
                        className="text-info ms-2 me-2 "
                        style={{ cursor: "pointer", textDecoration: "none" }}
                        onClick={() => handleOpenViewModal(row)}
                    >
                        <FaEye /> View
                    </a>{" "}
                    | {" "}
                    <a
                        href="#"
                        onClick={() => handleOpenEditModal(row)}
                        className="text-primary ms-2 me-2 d-flex align-items-center gap-1"
                        style={{ textDecoration: "none" }}
                    >
                        <FaPencilAlt /> EDIT
                    </a>{" "}
                    |
                    <a
                        href="#"
                        className="text-danger ms-2 d-flex align-items-center gap-1"
                        onClick={() => handleDeleteModal(row)}
                        style={{ textDecoration: "none" }}
                    >
                        <FaTrash /> DELETE
                    </a>
                </div>
            ),
        },
    ];

    const updatedColumns = columns.map((column) => ({
        ...column,
        name: (
            <div style={{ fontSize: "13px", fontWeight: "bold" }}>{column.name}</div>
        ),
    }));

    const usersInfo = usersList.map((user) => ({
        value: user?._id,
        label: `${user?.name}, ${user?.mobile}`
    }));

    const productsInfo = productsList.map((product) => ({
        value: product?._id,
        label: `${product?.name}`
    }));

    const categoriesData = categoryList.map((category) => ({
        value: category._id,
        label: `${category.name}`
    }));

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            width: "100%",
            borderRadius: '6px',
            minWidth: "200px",
            borderColor: state.isFocused ? "#80bdff" : "#ced4da",
            boxShadow: state.isFocused ? "0 0 0 0.2rem rgba(0,123,255,.25)" : null,
            "&:hover": {
                borderColor: state.isFocused ? "#80bdff" : "#ced4da",
            },
        }),
        placeholder: (provided) => ({
            ...provided,
            color: "#6c757d"
        }),
    };

    const handleSearchChange = (e) => {
        setSearchText(e.target.value);
    };

    const handleRowsPerPageChange = (rowsPerPage) => {
        setPagination({ ...pagination, rowsPerPage });
    };

    const handlePageChange = (page) => {
        setPagination({ ...pagination, page });
    };

    const handleDeleteModal = async (data) => {
        setOpenDeleteModal(true);
        setUserCouponAllocationId(data?._id);
    };

    const handleDeleteUserCouponAllocation = async (e) => {
        e.preventDefault();
        setIsDeleteBtnLoading(true);
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };

            const data = {
                _id: userCouponAllocationId,
            };
            const response = await axios.delete(`user-coupon-allocation`, { data, headers });
            console.log("response", response);

            if (response?.data?.status === true) {
                toast.success(response?.data?.data?.message, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
                // fetchData();
                setOpenDeleteModal(false);
            }
            fetchData();
        } catch (error) {
            if (error.response.status === 403) {
                toast.error(error?.response?.data?.message, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }

            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        } finally {
            setIsDeleteBtnLoading(false);
        }
    };

    const handleCloseDeleteModal = () => {
        setOpenDeleteModal(false)
    }

    const handleAddNewOpenModal = () => {
        setAddNewOpenModal(true);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCouponData((prev) => ({ ...prev, [name]: value }))
    }

    const handleSelectUsersChange = (users) => {
        setSelectedUsers(users);
    }

    const handleSelectCategoryChange = (cats) => {
        setSelectedCategories(cats);
    }

    const handleSelectProductChange = (products) => {
        setSelectedProducts(products);
    }

    const handleFromDateChange = (date) => {
        if (date) {
            setFromDate(date);
        } else {
            console.error("Received null or undefined date");
        }
    };

    const handleToDateChange = (date) => {
        if (date) {
            setToDate(date);
        } else {
            console.error("Received null or undefined date");
        }
    };

    // ----- Previous get users list ------
    // const getUsersList = async () => {
    //     try {
    //         const headers = {
    //             Authorization: Bearer ${accessToken},
    //         };
    //         const response = await axios.get("user", { headers });

    //         if (response?.data?.status === true) {
    //             const users = response?.data?.data?.users
    //             setUsersList(users);
    //         }
    //     } catch (error) {
    //         if (error.response && error.response?.data?.status === false) {
    //             let errorMessage = error.response.data.error;
    //             toast.error(errorMessage, {
    //                 position: "top-right",
    //                 autoClose: 3000,
    //                 theme: "colored",
    //             });
    //         }
    //     }
    // };

    // Modified getUsersList to accept a search query
    const getUsersList = async (query) => {
        if (!query) {
            setUsersList([]); // Clear the user list if there's no query
            return;
        }
        
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };
            const response = await axios.get(`user?search=${query}`, { headers });

            if (response?.data?.status === true) {
                const users = response?.data?.data?.users;
                setUsersList(users);
            }
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
    };

    // Debounce the search function to avoid excessive calls
    const debouncedGetUsersList = debounce(getUsersList, 500);

    const getProductsList = async () => {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };
            const response = await axios.get("product", { headers });
            if (response?.data?.status === true) {
                setProductsList(response?.data?.data?.products);
            }
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
    };

    const getCategoryList = async () => {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };
            const response = await axios.get("masters/category/get", { headers });

            if (response?.data?.status === true) {
                setCategoryList(response?.data?.data?.categories);
            }
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
    };

    const handleSubmitCoupon = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };

            let updatedCouponData = { ...couponData };

            const selectedCategoriesData = selectedCategories.map((c) => c.value);
            const selectedProductsData = selectedProducts.map((c) => c.value);

            // Apply logic to update coupon data
            // if (selectedCategoriesData?.length > 0 || selectedProductsData?.length > 0) {
            //     delete updatedCouponData.discountAmount;
            //     delete updatedCouponData.discountPercent;
            // }

            // Add condition to only send discountPercent and remove other fields
            if (selectedCategoriesData?.length > 0 && updatedCouponData?.discountPercent) {
                delete updatedCouponData.discountAmount;
                delete updatedCouponData.discountPrice;
                delete updatedCouponData.applicableQuantityType;
            } else if (selectedCategoriesData?.length > 0 && updatedCouponData?.discountAmount) {
                delete updatedCouponData.discountPercent;
                delete updatedCouponData.discountPrice;
                delete updatedCouponData.applicableQuantityType;
            } else if (selectedCategoriesData?.length > 0 && updatedCouponData?.discountPrice) {
                delete updatedCouponData.discountPercent;
                delete updatedCouponData.discountAmount;
            }

            // Add condition to only send discountPercent and remove other fields
            if (selectedProductsData?.length > 0 && updatedCouponData?.discountPercent) {
                delete updatedCouponData.discountAmount;
                delete updatedCouponData.discountPrice;
                delete updatedCouponData.applicableQuantityType;
            } else if (selectedProductsData?.length > 0 && updatedCouponData?.discountAmount) {
                delete updatedCouponData.discountPercent;
                delete updatedCouponData.discountPrice;
                delete updatedCouponData.applicableQuantityType;
            } else if (selectedProductsData?.length > 0 && updatedCouponData?.discountPrice) {
                delete updatedCouponData.discountPercent;
                delete updatedCouponData.discountAmount;
            }

            if (selectedCategoriesData?.length === 0 && selectedProductsData?.length === 0) {
                delete updatedCouponData?.discountAmount
                delete updatedCouponData.discountPrice;
                delete updatedCouponData.applicableQuantityType;
            }

            if (updatedCouponData?.discountAmount) {
                delete updatedCouponData.discountPercent;
            }

            if (updatedCouponData?.discountPercent) {
                delete updatedCouponData.discountAmount;
            }

            let newCouponData;

            if (selectedCategoriesData?.length > 0) {
                newCouponData = {
                    ...updatedCouponData,
                    applicableCategories: selectedCategoriesData
                }
            }

            if (selectedProductsData?.length > 0) {
                newCouponData = {
                    ...updatedCouponData,
                    applicableProducts: selectedProductsData
                }
            }

            if (selectedCategoriesData?.length === 0 && selectedProductsData?.length === 0) {
                newCouponData = updatedCouponData
            }

            const response = await axios.post("coupons", newCouponData, {
                headers,
            });

            if (response?.data?.status === true) {
                const couponId = response?.data?.data?.dbResponse?._id;
                await handleSubmit(couponId);
            }
        } catch (error) {

            if (error?.response) {
                let errorMessage = '';
                if (
                    error.response?.status === 406
                ) {
                    errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
                }
                else if (
                    error.response?.status === 412 || error?.response?.status === 404
                ) {
                    errorMessage = error?.response?.data?.data?.title;
                } else if (error.response?.status === 500) {
                    errorMessage = error?.response?.data?.error
                }

                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        } finally {
            setIsLoading(false);
        }
    }

    const handleSubmit = async (couponId) => {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };

            const selectedUsersData = selectedUsers.map((c) => c.value);
            const data = {
                users: selectedUsersData,
                coupon_id: couponId
            }

            const response = await axios.post("user-coupon-allocation", data, {
                headers,
            });

            if (response?.data?.status === true) {
                toast.success(response?.data?.data?.message, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
                fetchData()
                setCouponData({
                    title: "",
                    description: "",
                    usageType: "",
                    couponCode: "",
                    discountPercent: "",
                    discountAmount: "",
                    validityStartDate: "",
                    validityEndDate: "",
                    discountPrice: "",
                    applicableQuantityType: ""
                });
                setSelectedCategories([])
                setSelectedProducts([])
                setSelectedUsers([]);
                setFromDate(null);
                setToDate(null);
                setAddNewOpenModal(false)
            }
        } catch (error) {
            if (error?.response) {
                let errorMessage = '';
                if (
                    error.response?.status === 406
                ) {
                    errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
                }
                else if (
                    error.response?.status === 412 || error?.response?.status === 404
                ) {
                    errorMessage = error?.response?.data?.data?.title;
                } else if (error.response?.status === 500) {
                    errorMessage = error?.response?.data?.error
                }

                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
    }

    const fetchData = async () => {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };
            const response = await axios.get("user-coupon-allocation", { headers });
            if (response?.data?.status === true) {
                response?.data?.data?.userCouponAllocations.forEach((item, index) => {
                    item.serialNumber = index + 1;
                });
                const responseData = response?.data?.data?.userCouponAllocations
                setUserCouponAllocationData(responseData);
                setFilteredData(responseData)
            }
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
    }

    const handleOpenViewModal = (data) => {
        setOpenViewModal(true);
        setCouponUsers(data?.users);
        setCouponDetails(data?.coupon_id);
    }

    const handleOpenEditModal = (data) => {
        console.log("Edited Datata :", data);
        
        setUserCouponId(data?._id)
        const formattedStartDate = data?.coupon_id?.validityStartDate ? format(data?.coupon_id?.validityStartDate, "yyyy-MM-dd") : null;
        const formattedEndDate = data?.coupon_id?.validityEndDate ? format(data?.coupon_id?.validityEndDate, "yyyy-MM-dd") : null;

        let selectedEditedApplicableCategories = data?.coupon_id?.applicableCategories ? data?.coupon_id?.applicableCategories : [];
        let mappedSelectedEditedApplicableCategories = selectedEditedApplicableCategories.map((m) => ({
            value: m?._id,
            label: m?.name
        }));

        let selectedEditedApplicableProducts = data?.coupon_id?.applicableProducts ? data?.coupon_id?.applicableProducts : [];
        let mappedSelectedEditedApplicableProducts = selectedEditedApplicableProducts.map((m) => ({
            value: m?._id,
            label: m?.name
        }));

        setEditCouponData({
            _id: data?.coupon_id?._id,
            title: data?.coupon_id?.title,
            couponCode: data?.coupon_id?.couponCode,
            description: data?.coupon_id?.description,
            validityStartDate: formattedStartDate,
            validityEndDate: formattedEndDate,
            usageType: data?.coupon_id?.usageType,
            ...(data?.coupon_id?.discountAmount !== null && { discountAmount: data.coupon_id.discountAmount }),
            ...(data?.coupon_id?.discountPercent !== null && { discountPercent: data.coupon_id.discountPercent }),
            ...(data?.coupon_id?.discountPrice !== null && { discountPrice: data.coupon_id.discountPrice }),
            ...(data?.coupon_id?.applicableQuantityType !== null && { applicableQuantityType: data.coupon_id.applicableQuantityType }),
        });

        let selectedEditedUsers = data?.users;
        let mappedSelectedEditedUsers = selectedEditedUsers.map((m) => ({
            value: m?._id,
            label: m?.name
        }));

        setSelectedUsers(mappedSelectedEditedUsers);
        setSelectedCategories(mappedSelectedEditedApplicableCategories);
        setSelectedProducts(mappedSelectedEditedApplicableProducts);
        setOpenEditModal(true);
    }

    const handleEditValidStartDateChange = (date) => {
        if (date) {
            const formattedDate = format(new Date(date), "yyyy-MM-dd");
            setEditCouponData((prev) => ({
                ...prev,
                validityStartDate: formattedDate,
            }));
        } else {
            setEditCouponData((prev) => ({
                ...prev,
                validityStartDate: null,
            }));
        }
    };

    const handleEditValidEndDateChange = (date) => {
        if (date) {
            const formattedDate = format(new Date(date), "yyyy-MM-dd");
            setEditCouponData((prev) => ({
                ...prev,
                validityEndDate: formattedDate,
            }));
        } else {
            setEditCouponData((prev) => ({
                ...prev,
                validityEndDate: null,
            }));
        }
    };

    const handleEditInputChange = (e) => {
        const { name, value } = e.target;
        setEditCouponData((prev) => ({ ...prev, [name]: value }))
    }

    const submitEditForm = async (e) => {
        e.preventDefault();
        setIsEditBtnLoading(true);
        const selectedCategoriesData = selectedCategories.map((c) => c.value);
        const selectedProductsData = selectedProducts.map((c) => c.value);

        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };

            let updatedCouponData;

            // Prepare data separately from state
            updatedCouponData = {
                ...editCouponData,
                ...(selectedCategoriesData.length > 0 && { applicableCategories: selectedCategoriesData }),
                ...(selectedProductsData.length > 0 && { applicableProducts: selectedProductsData }),
            };

            if (updatedCouponData?.discountAmount) delete updatedCouponData?.discountPercent
            if (updatedCouponData?.discountPercent) delete updatedCouponData?.discountAmount

            const response = await axios.put("coupons", updatedCouponData, {
                headers,
            });

            if (response?.status === 200) {
                const couponId = response?.data?.data?.updatedCoupon?._id;
                await handleEditSubmit(couponId)
            }
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = '';
                if (error.response?.status === 406) {
                    errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
                } else if (error.response?.status === 404) {
                    errorMessage = error?.response?.data?.data?.title;
                } else if (error.response?.status === 412) {
                    errorMessage = error?.response?.data?.data?.title;
                } else if (error.response?.status === 500) {
                    errorMessage = error?.response?.data?.error
                }
                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        } finally {
            setIsEditBtnLoading(false);
        }
    };

    const handleEditSubmit = async (couponId) => {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };

            const selectedUsersData = selectedUsers.map((c) => c.value);
            const data = {
                _id: userCouponId,
                users: selectedUsersData,
                coupon_id: couponId
            }

            const response = await axios.put("user-coupon-allocation", data, {
                headers,
            });

            if (response?.data?.status === true) {
                toast.success(response?.data?.data?.message, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
                setOpenEditModal(false)
                fetchData()
            }
        } catch (error) {
            if (error?.response) {
                let errorMessage = '';
                if (
                    error.response?.status === 406
                ) {
                    errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
                }
                else if (
                    error.response?.status === 412 || error?.response?.status === 404
                ) {
                    errorMessage = error?.response?.data?.data?.title;
                } else if (error.response?.status === 500 || error?.response?.status === 400) {
                    errorMessage = error?.response?.data?.error
                }

                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
    }

    useEffect(() => {
        getUsersList();
        getProductsList();
        getCategoryList();
        fetchData()
    }, []);

    useEffect(() => {
        const formattedStartDate = fromDate ? format(fromDate, "yyyy-MM-dd") : "";
        const formattedEndDate = toDate ? format(toDate, "yyyy-MM-dd") : "";

        setCouponData((prev) => ({
            ...prev,
            validityStartDate: formattedStartDate,
            validityEndDate: formattedEndDate,
        }));
    }, [fromDate, toDate]);

    useEffect(() => {
        const result = userCouponAllocationData.filter((item) => {
            return (
                item.coupon_id.couponCode.toLowerCase().match(searchText.toLocaleLowerCase()) ||
                item.coupon_id.title.toLowerCase().match(searchText.toLocaleLowerCase()) ||
                item.coupon_id.usageType.toLowerCase().match(searchText.toLocaleLowerCase())
            );
        });
        setFilteredData(result);
    }, [searchText]);

    return (
        <CContainer>
            <div className="mb-3 text-end">
                <button
                    className="btn btn-warning"
                    onClick={handleAddNewOpenModal}
                    style={{ borderRadius: "18px" }}
                >
                    ADD NEW USER COUPON <FaPlus style={{ marginTop: "-4px" }} size={15} />
                </button>
            </div>

            <div>
                <DataTableComponent
                    columns={updatedColumns}
                    data={filteredData}
                    title="USER COUPON ALLOCATION LIST"
                    searchText={searchText}
                    onChange={handleSearchChange}
                    pagination
                    paginationServer
                    paginationTotalRows={filteredData.length}
                    onChangeRowsPerPage={handleRowsPerPageChange}
                    onChangePage={handlePageChange}
                />
            </div>

            {addNewOpenModal ? (
                <CModal
                    alignment="center"
                    visible={addNewOpenModal}
                    onClose={() => setAddNewOpenModal(false)}
                    aria-labelledby="VerticallyCenteredExample"
                    size='xl'
                >
                    <CModalHeader>
                        <CModalTitle>ADD USER COUPON ALLOCATION</CModalTitle>
                    </CModalHeader>
                    <CForm onSubmit={handleSubmitCoupon}>
                        <CModalBody>
                            <CRow className="mb-3">
                                <CCol md={12} className='mb-3'>
                                    <CFormLabel className="fw-semibold">
                                        Select Users <span className="text-danger">*</span>
                                    </CFormLabel>
                                    {/* <Select
                                        isMulti
                                        components={animatedComponents}
                                        options={usersInfo}
                                        value={selectedUsers || []}
                                        onChange={handleSelectUsersChange}
                                        placeholder="Select Users"
                                        closeMenuOnSelect={false}
                                        styles={{
                                            ...customStyles,
                                            control: (base) => ({
                                                ...base,
                                                // minHeight: '100px', // Set the height
                                            }),
                                            menu: (base) => ({
                                                ...base,
                                            }),
                                        }}
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                                ...theme.colors,
                                                primary25: "#e9ecef",
                                                primary: "#007bff",
                                            },
                                        })}
                                    /> */}
                                    <Select
                                        isMulti
                                        components={animatedComponents}
                                        options={usersInfo}
                                        value={selectedUsers || []}
                                        onChange={handleSelectUsersChange}
                                        onInputChange={(query) => {
                                            debouncedGetUsersList(query); // Fetch user list based on input query
                                        }}
                                        placeholder="Select Users"
                                        closeMenuOnSelect={false}
                                        styles={{
                                            ...customStyles,
                                            control: (base) => ({
                                                ...base,
                                                // minHeight: '100px', // Set the height
                                            }),
                                            menu: (base) => ({
                                                ...base,
                                            }),
                                        }}
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                                ...theme.colors,
                                                primary25: "#e9ecef",
                                                primary: "#007bff",
                                            },
                                        })}
                                    />
                                </CCol>
                                <CCol md={3}>
                                    <div>
                                        <CFormLabel className="fw-semibold">
                                            Title <span className="text-danger">*</span>
                                        </CFormLabel>
                                        <CFormInput
                                            placeholder="Title"
                                            name="title"
                                            value={couponData.title || ""}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </CCol>
                                <CCol md={3}>
                                    <div>
                                        <CFormLabel className="fw-semibold">
                                            Coupon Code <span className="text-danger">*</span>
                                        </CFormLabel>
                                        <CFormInput
                                            placeholder="Coupon Code"
                                            name="couponCode"
                                            value={couponData.couponCode || ""}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </CCol>

                                <CCol md={3} className='mb-3'>
                                    <CFormLabel className="fw-semibold">
                                        Select Categories <span className="text-danger">*</span>
                                    </CFormLabel>
                                    <Select
                                        isMulti
                                        isDisabled={
                                            selectedProducts?.length > 0
                                        }
                                        components={animatedComponents}
                                        options={categoriesData}
                                        value={selectedCategories || []}
                                        onChange={handleSelectCategoryChange}
                                        placeholder="Select Categories"
                                        closeMenuOnSelect={false}
                                        styles={customStyles}
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                                ...theme.colors,
                                                primary25: "#e9ecef",
                                                primary: "#007bff",
                                            },
                                        })}
                                    />
                                </CCol>
                                <CCol md={3}>
                                    <CFormLabel className="fw-semibold">
                                        Select Products <span className="text-danger">*</span>
                                    </CFormLabel>
                                    <Select
                                        isMulti
                                        isDisabled={
                                            selectedCategories?.length > 0
                                        }
                                        components={animatedComponents}
                                        options={productsInfo}
                                        value={selectedProducts || []}
                                        onChange={handleSelectProductChange}
                                        placeholder="Select Products"
                                        styles={customStyles}
                                        closeMenuOnSelect={false}
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                                ...theme.colors,
                                                primary25: "#e9ecef",
                                                primary: "#007bff",
                                            },
                                        })}
                                    />
                                </CCol>

                                <CCol className='mb-3' md={3}>
                                    <div>
                                        <CFormLabel className="fw-semibold">
                                            Validity Start Date <span className="text-danger">*</span>
                                        </CFormLabel>
                                        <DatePicker
                                            selected={fromDate}
                                            onChange={handleFromDateChange}
                                            placeholderText="Start Date"
                                            className="form-control"
                                        />
                                    </div>
                                </CCol>
                                <CCol md={3}>
                                    <div>
                                        <CFormLabel className="fw-semibold">
                                            Validity End Date <span className="text-danger">*</span>
                                        </CFormLabel>
                                        <DatePicker
                                            selected={toDate}
                                            onChange={handleToDateChange}
                                            placeholderText="End Date"
                                            className="form-control"
                                        />
                                    </div>
                                </CCol>
                                <CCol md={3}>
                                    <div>
                                        <CFormLabel className="fw-semibold">
                                            Usage Type <span className="text-danger">*</span>
                                        </CFormLabel>
                                        <CFormSelect
                                            name="usageType"
                                            value={couponData.usageType}
                                            onChange={handleInputChange}
                                        >
                                            <option value="">Select Usage</option>
                                            <option value="ONE-TIME">ONE-TIME</option>
                                            <option value="MULTIPLE">MULTIPLE</option>
                                        </CFormSelect>
                                    </div>
                                </CCol>
                                <CCol md={3}>
                                    <CFormLabel className="fw-semibold">
                                        Discount Percent <span className="text-danger">*</span>
                                    </CFormLabel>
                                    <CFormInput
                                        min={0}
                                        max={100}
                                        disabled={
                                            couponData?.discountAmount || couponData?.discountPrice
                                        }
                                        placeholder="Discount Percent"
                                        className="no-arrows"
                                        type="number"
                                        name="discountPercent"
                                        value={couponData.discountPercent || ""}
                                        onChange={handleInputChange}
                                    />
                                </CCol>
                                <CCol md={4}>
                                    <CFormLabel className="fw-semibold">
                                        Discount Amount <span className="text-danger">*</span>
                                    </CFormLabel>
                                    <CFormInput
                                        min={0}
                                        step={0.01}
                                        disabled={
                                            couponData?.discountPercent || couponData?.discountPrice || (selectedCategories?.length === 0 && selectedProducts?.length === 0)
                                        }
                                        placeholder="Discount Amount"
                                        className="no-arrows"
                                        type="number"
                                        name="discountAmount"
                                        value={couponData.discountAmount || ""}
                                        onChange={handleInputChange}
                                    />
                                </CCol>
                                <CCol md={4}>
                                    <CFormLabel className="fw-semibold">
                                        Discount Price <span className="text-danger">*</span>
                                    </CFormLabel>
                                    <CFormInput
                                        min={0}
                                        step={0.01}
                                        disabled={
                                            couponData?.discountAmount || couponData?.discountPercent
                                        }
                                        placeholder="Discount Price"
                                        className="no-arrows"
                                        type="number"
                                        name="discountPrice"
                                        value={couponData.discountPrice || ""}
                                        onChange={handleInputChange}
                                    />
                                </CCol>
                                <CCol md={4} className='mb-2'>
                                    <div>
                                        <CFormLabel className="fw-semibold">
                                            Applicable Quantity Type <span className="text-danger">*</span>
                                        </CFormLabel>
                                        <CFormSelect
                                            name="applicableQuantityType"
                                            value={couponData.applicableQuantityType || ""}
                                            onChange={handleInputChange}
                                            disabled={
                                                couponData?.discountPercent || couponData?.discountAmount
                                            }
                                        >
                                            <option value="">Select Applicable Quantity Type</option>
                                            <option value="SINGLE">SINGLE</option>
                                            <option value="MULTIPLE">MULTIPLE</option>
                                        </CFormSelect>
                                    </div>
                                </CCol>
                                <CCol md={12}>
                                    <CFormLabel className="fw-semibold">
                                        Description <span className="text-danger">*</span>
                                    </CFormLabel>
                                    <CFormTextarea
                                        rows={2}
                                        placeholder="Description"
                                        name="description"
                                        value={couponData.description || ""}
                                        onChange={handleInputChange}
                                    />
                                </CCol>
                            </CRow>
                        </CModalBody>
                        <CModalFooter>
                            <PrimaryButton
                                // isDisable={generalBtnDisable()}
                                isLoading={isLoading}
                                title="ADD USER COUPON ALLOCATION"
                            />
                        </CModalFooter>
                    </CForm>
                </CModal>
            ) : null}

            {openViewModal && (
                <CModal
                    alignment="center"
                    visible={openViewModal}
                    onClose={() => setOpenViewModal(false)}
                    aria-labelledby="VerticallyCenteredExample"
                    size='xl'
                >
                    <CModalHeader>
                        <CModalTitle
                            id="VerticallyCenteredExample"
                            className="fs-7 fw-semibold"
                        >
                            VIEW USER COUPON ALLOCATION DETAILS
                        </CModalTitle>
                    </CModalHeader>
                    <CModalBody style={{ backgroundColor: "#f8f9fa" }}>
                        <CTable className="table table-bordered">
                            <CTableHead>
                                <CTableRow>
                                    <CTableHeaderCell className="text-center" colSpan={7}>
                                        COUPON DETAILS
                                    </CTableHeaderCell>
                                </CTableRow>
                            </CTableHead>
                            <CTableBody>
                                <CTableRow>
                                    <CTableDataCell className='w-50 fw-semibold'>
                                        Title
                                    </CTableDataCell>
                                    <CTableDataCell>
                                        {couponDetails?.title}
                                    </CTableDataCell>
                                </CTableRow>
                                <CTableRow>
                                    <CTableDataCell className='fw-semibold'>
                                        Description
                                    </CTableDataCell>
                                    <CTableDataCell>
                                        {couponDetails?.description}
                                    </CTableDataCell>
                                </CTableRow>
                                <CTableRow>
                                    <CTableDataCell className='fw-semibold'>
                                        Coupon Code
                                    </CTableDataCell>
                                    <CTableDataCell>
                                        <i><strong>{couponDetails?.couponCode}</strong></i>
                                    </CTableDataCell>
                                </CTableRow>
                                <CTableRow>
                                    <CTableDataCell>
                                        Usage-Type
                                    </CTableDataCell>
                                    <CTableDataCell>
                                        {couponDetails?.usageType}
                                    </CTableDataCell>
                                </CTableRow>
                                {
                                    couponDetails?.applicableCategories?.length > 0 &&
                                    <CTableRow>
                                        <CTableDataCell>
                                            Applicable Categories
                                        </CTableDataCell>
                                        {
                                            couponDetails?.applicableCategories?.length > 0 && <CTableDataCell>
                                                {couponDetails?.applicableCategories.map((item) => item?.name).join(', ')}
                                            </CTableDataCell>
                                        }
                                    </CTableRow>
                                }
                                {
                                    couponDetails?.applicableProducts?.length > 0 &&
                                    <CTableRow>
                                        <CTableDataCell>
                                            Applicable Products
                                        </CTableDataCell>
                                        {
                                            couponDetails?.applicableProducts?.length > 0 && <CTableDataCell>
                                                {couponDetails?.applicableProducts.map((item) => item?.name).join(', ')}
                                            </CTableDataCell>
                                        }
                                    </CTableRow>
                                }

                                {
                                    couponDetails?.applicableQuantityType &&
                                    <CTableRow>
                                        <CTableDataCell>
                                            Applicable Quantity Type
                                        </CTableDataCell>
                                        <CTableDataCell>
                                            {couponDetails?.applicableQuantityType}
                                        </CTableDataCell>
                                    </CTableRow>
                                }
                                {
                                    couponDetails?.discountPrice &&
                                    <CTableRow>
                                        <CTableDataCell>
                                            Discount Price
                                        </CTableDataCell>
                                        <CTableDataCell className='text-success fw-semibold'>
                                            ₹. {couponDetails?.discountPrice}
                                        </CTableDataCell>
                                    </CTableRow>
                                }
                                {
                                    couponDetails?.discountPercent &&
                                    <CTableRow>
                                        <CTableDataCell>
                                            Discount Percent
                                        </CTableDataCell>
                                        <CTableDataCell className='text-success fw-semibold'>
                                            {couponDetails?.discountPercent} % OFF
                                        </CTableDataCell>
                                    </CTableRow>
                                }
                                {
                                    couponDetails?.discountAmount &&
                                    <CTableRow>
                                        <CTableDataCell>
                                            Discount Amount
                                        </CTableDataCell>
                                        <CTableDataCell className='text-success fw-semibold'>
                                            ₹. {couponDetails?.discountAmount}
                                        </CTableDataCell>
                                    </CTableRow>
                                }
                                <CTableRow>
                                    <CTableDataCell>
                                        Valid From
                                    </CTableDataCell>
                                    <CTableDataCell>
                                        {formattedDate(couponDetails?.validityStartDate)}
                                    </CTableDataCell>
                                </CTableRow>
                                <CTableRow>
                                    <CTableDataCell>
                                        Valid Till
                                    </CTableDataCell>
                                    <CTableDataCell>
                                        {formattedDate(couponDetails?.validityEndDate)}
                                    </CTableDataCell>
                                </CTableRow>
                            </CTableBody>
                        </CTable>


                        <CTable className="table table-bordered">
                            <CTableHead>
                                <CTableRow>
                                    <CTableHeaderCell className="text-center" colSpan={7}>
                                        USERS DETAILS
                                    </CTableHeaderCell>
                                </CTableRow>
                            </CTableHead>
                            <CTableBody>
                                <CTableRow>
                                    <CTableHeaderCell className="text-center">
                                        SR. No
                                    </CTableHeaderCell>
                                    <CTableHeaderCell className="text-center">
                                        NAME
                                    </CTableHeaderCell>
                                    <CTableHeaderCell className="text-center">
                                        EMAIL ID
                                    </CTableHeaderCell>
                                    <CTableHeaderCell className="text-center">
                                        D.O.B
                                    </CTableHeaderCell>
                                    <CTableHeaderCell className="text-center">
                                        GENDER
                                    </CTableHeaderCell>
                                </CTableRow>

                                {
                                    couponUsers?.map((user, index) =>
                                        <CTableRow>
                                            <CTableDataCell className="text-center">
                                                {index + 1}
                                            </CTableDataCell>
                                            <CTableDataCell className="text-center">
                                                {user?.name}
                                            </CTableDataCell>
                                            <CTableDataCell className="text-center">
                                                {user?.email}
                                            </CTableDataCell>
                                            <CTableDataCell className="text-center">
                                                {user?.dob ? formattedDate(user?.dob) : "---"}
                                            </CTableDataCell>
                                            <CTableDataCell className="text-center">
                                                {user?.gender ? user.gender : "---"}
                                            </CTableDataCell>
                                        </CTableRow>
                                    )
                                }
                            </CTableBody>
                        </CTable>

                    </CModalBody>
                    <CModalFooter style={{ backgroundColor: "#f8f9fa" }}>
                        <CButton
                            style={{ borderRadius: "18px" }}
                            className="btn btn-primary"
                            onClick={() => setOpenViewModal(false)}
                        >
                            CLOSE
                        </CButton>
                    </CModalFooter>
                </CModal>
            )}

            {openEditModal && (
                <CModal
                    size="xl"
                    alignment="center"
                    visible={openEditModal}
                    onClose={() => setOpenEditModal(false)}
                    aria-labelledby="VerticallyCenteredExample"
                >
                    <CModalHeader>
                        <CModalTitle>EDIT USER COUPON ALLOCATION DETAILS</CModalTitle>
                    </CModalHeader>
                    <CForm onSubmit={submitEditForm}>
                        <CModalBody>
                            <CRow className="mb-3">
                                <CCol md={12} className='mb-3'>
                                    <CFormLabel className="fw-semibold">
                                        Select Users <span className="text-danger">*</span>
                                    </CFormLabel>
                                    {/* <Select
                                        isMulti
                                        components={animatedComponents}
                                        options={usersInfo}
                                        value={selectedUsers || []}
                                        onChange={handleSelectUsersChange}
                                        placeholder="Select Users"
                                        closeMenuOnSelect={false}
                                        styles={customStyles}
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                                ...theme.colors,
                                                primary25: "#e9ecef",
                                                primary: "#007bff",
                                            },
                                        })}
                                    /> */}
                                    <Select
                                        isMulti
                                        components={animatedComponents}
                                        options={usersInfo}
                                        value={selectedUsers || []}
                                        onChange={handleSelectUsersChange}
                                        onInputChange={(query) => {
                                            debouncedGetUsersList(query); // Fetch user list based on input query
                                        }}
                                        placeholder="Select Users"
                                        closeMenuOnSelect={false}
                                        styles={{
                                            ...customStyles,
                                            control: (base) => ({
                                                ...base,
                                                // minHeight: '100px', // Set the height
                                            }),
                                            menu: (base) => ({
                                                ...base,
                                            }),
                                        }}
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                                ...theme.colors,
                                                primary25: "#e9ecef",
                                                primary: "#007bff",
                                            },
                                        })}
                                    />
                                </CCol>
                                <CCol md={3}>
                                    <div>
                                        <CFormLabel className="fw-semibold">
                                            Title <span className="text-danger">*</span>
                                        </CFormLabel>
                                        <CFormInput
                                            placeholder="Title"
                                            name="title"
                                            value={editCouponData.title || ""}
                                            onChange={handleEditInputChange}
                                        />
                                    </div>
                                </CCol>
                                <CCol md={3}>
                                    <div>
                                        <CFormLabel className="fw-semibold">
                                            Coupon Code <span className="text-danger">*</span>
                                        </CFormLabel>
                                        <CFormInput
                                            placeholder="Coupon Code"
                                            name="couponCode"
                                            value={editCouponData.couponCode || ""}
                                            onChange={handleEditInputChange}
                                        />
                                    </div>
                                </CCol>
                                <CCol md={3} className='mb-3'>
                                    <CFormLabel className="fw-semibold">
                                        Select Categories <span className="text-danger">*</span>
                                    </CFormLabel>
                                    <Select
                                        isMulti
                                        isDisabled={selectedCategories.length === 0}
                                        components={animatedComponents}
                                        options={categoriesData}
                                        value={selectedCategories || []}
                                        onChange={handleSelectCategoryChange}
                                        placeholder="Select Categories"
                                        closeMenuOnSelect={false}
                                        styles={customStyles}
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                                ...theme.colors,
                                                primary25: "#e9ecef",
                                                primary: "#007bff",
                                            },
                                        })}
                                    />
                                </CCol>
                                <CCol md={3} className='mb-3'>
                                    <CFormLabel className="fw-semibold">
                                        Select Products <span className="text-danger">*</span>
                                    </CFormLabel>
                                    <Select
                                        isMulti
                                        components={animatedComponents}
                                        isDisabled={selectedProducts.length === 0}
                                        options={productsInfo}
                                        value={selectedProducts || []}
                                        onChange={handleSelectProductChange}
                                        placeholder="Select Products"
                                        styles={customStyles}
                                        closeMenuOnSelect={false}
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                                ...theme.colors,
                                                primary25: "#e9ecef",
                                                primary: "#007bff",
                                            },
                                        })}
                                    />
                                </CCol>


                                <CCol className='mb-3' md={3}>
                                    <div>
                                        <CFormLabel className="fw-semibold">
                                            Validity Start Date <span className="text-danger">*</span>
                                        </CFormLabel>
                                        <DatePicker
                                            onChange={handleEditValidStartDateChange}
                                            placeholderText="Start Date"
                                            className="form-control"
                                            selected={editCouponData?.validityStartDate ? new Date(editCouponData?.validityStartDate) : null}
                                        />
                                    </div>
                                </CCol>
                                <CCol md={3}>
                                    <div>
                                        <CFormLabel className="fw-semibold">
                                            Validity End Date <span className="text-danger">*</span>
                                        </CFormLabel>
                                        <DatePicker
                                            selected={editCouponData?.validityEndDate ? new Date(editCouponData?.validityEndDate) : null}
                                            onChange={handleEditValidEndDateChange}
                                            placeholderText="End Date"
                                            className="form-control"
                                        />
                                    </div>
                                </CCol>
                                <CCol md={3}>
                                    <div>
                                        <CFormLabel className="fw-semibold">
                                            Usage Type <span className="text-danger">*</span>
                                        </CFormLabel>
                                        <CFormSelect
                                            name="usageType"
                                            value={editCouponData.usageType || "ONE-TIME"}
                                            onChange={handleEditInputChange}
                                        >
                                            <option value="">Select Usage</option>
                                            <option value="MULTIPLE">MULTIPLE</option>
                                            <option value="ONE-TIME">ONE-TIME</option>
                                        </CFormSelect>
                                    </div>
                                </CCol>
                                <CCol md={3}>
                                    <CFormLabel className="fw-semibold">
                                        Discount Percent <span className="text-danger">*</span>
                                    </CFormLabel>
                                    <CFormInput
                                        min={0}
                                        max={100}
                                        disabled={
                                            editCouponData?.discountAmount
                                        }
                                        placeholder="Discount Percent"
                                        className="no-arrows"
                                        type="number"
                                        name="discountPercent"
                                        value={editCouponData.discountPercent || ""}
                                        onChange={handleEditInputChange}
                                    />
                                </CCol>

                                <CCol md={4} className='mb-3'>
                                    <CFormLabel className="fw-semibold">
                                        Discount Amount <span className="text-danger">*</span>
                                    </CFormLabel>
                                    <CFormInput
                                        min={0}
                                        step={0.01}
                                        disabled={
                                            editCouponData?.discountPercent
                                        }
                                        placeholder="Discount Amount"
                                        className="no-arrows"
                                        type="number"
                                        name="discountAmount"
                                        value={editCouponData.discountAmount || ""}
                                        onChange={handleEditInputChange}
                                    />
                                </CCol>

                                <CCol md={4}>
                                    <CFormLabel className="fw-semibold">
                                        Discount Price <span className="text-danger">*</span>
                                    </CFormLabel>
                                    <CFormInput
                                        min={0}
                                        step={0.01}
                                        disabled={
                                            editCouponData?.discountAmount || editCouponData?.discountPercent
                                        }
                                        placeholder="Discount Price"
                                        className="no-arrows"
                                        type="number"
                                        name="discountPrice"
                                        value={editCouponData.discountPrice || ""}
                                        onChange={handleEditInputChange}
                                    />
                                </CCol>

                                <CCol md={4}>
                                    <div>
                                        <CFormLabel className="fw-semibold">
                                            Applicable Quantity Type <span className="text-danger">*</span>
                                        </CFormLabel>
                                        <CFormSelect
                                            name="applicableQuantityType"
                                            value={editCouponData.applicableQuantityType || ""}
                                            onChange={handleEditInputChange}
                                            disabled={
                                                editCouponData?.discountPercent || editCouponData?.discountAmount
                                            }
                                        >
                                            <option value="">Select Applicable Quantity Type</option>
                                            <option value="SINGLE">SINGLE</option>
                                            <option value="MULTIPLE">MULTIPLE</option>
                                        </CFormSelect>
                                    </div>
                                </CCol>


                                <CCol md={12}>
                                    <CFormLabel className="fw-semibold">
                                        Description <span className="text-danger">*</span>
                                    </CFormLabel>
                                    <CFormTextarea
                                        rows={2}
                                        placeholder="Description"
                                        name="description"
                                        value={editCouponData.description || ""}
                                        onChange={handleEditInputChange}
                                    />
                                </CCol>
                            </CRow>
                        </CModalBody>
                        <CModalFooter>
                            <PrimaryButton
                                title="SAVE CHANGES"
                                isLoading={isEditBtnLoading}
                                style={{ borderRadius: "18px" }}
                            />
                        </CModalFooter>
                    </CForm>
                </CModal>
            )}

            {openDeleteModal ? (
                <DeleteModal isVisible={openDeleteModal} onClose={handleCloseDeleteModal} onConfirm={handleDeleteUserCouponAllocation} isLoading={isDeleteBtnLoading} title="DELETE USER COUPON ALLOCATION" />
            ) : null}
        </CContainer>
    )
}

export default Add
