import React from 'react'
import PropTypes from 'prop-types';
import { CButton, CForm, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle } from '@coreui/react'
import PrimaryButton from './PrimaryButton';

const DeleteModal = ({ isVisible, onClose, onConfirm, isLoading, title }) => {
    return (
        <CModal alignment="center" visible={isVisible} onClose={onClose} aria-labelledby="VerticallyCenteredExample">
            <CModalHeader>
                <CModalTitle id="VerticallyCenteredExample" className="fs-6">
                    {title}
                </CModalTitle>
            </CModalHeader>
            <CForm onSubmit={onConfirm}>
                <CModalBody>Are you sure you want to delete this item?</CModalBody>
                <CModalFooter>
                    <CButton
                        color="warning"
                        onClick={onClose}
                        style={{
                            borderRadius: '18px',
                        }}
                    >
                        CLOSE
                    </CButton>
                    <PrimaryButton title="CONFIRM" isLoading={isLoading} />
                </CModalFooter>
            </CForm>
        </CModal>
    )
}

DeleteModal.propTypes = {
    isVisible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    title: PropTypes.string,
};

DeleteModal.defaultProps = {
    isLoading: false,
    title: 'DELETE ITEM',
};


export default DeleteModal
