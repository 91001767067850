import { CCard, CCardBody, CCardFooter, CCardHeader, CCardTitle, CCol, CContainer, CForm, CFormInput, CFormLabel, CFormSelect, CInputGroup, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle, CRow } from '@coreui/react'
import React, { useEffect, useState } from 'react'
import { DataTableComponent, DeleteModal, PrimaryButton } from '../../components'
import axios from '../../api/axios';
import { toast } from 'react-toastify';
import { FaPencilAlt, FaPlus, FaTrash } from 'react-icons/fa';

const KioskOutletUser = () => {

    const accessToken = localStorage.getItem('authToken');
    const [outletsList, setOutletsList] = useState([]);
    const [outletUserList, setOutletUserList] = useState([]);
    const [addNewOpenModal, setAddNewOpenModal] = useState(false);
    const [userInputData, setUserInputData] = useState({
        name: "",
        signupType: "",
        mobile: "",
        email: "",
        outlet: "",
        password: ""
    });
    const [isAddBtnLoading, setIsAddBtnLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [editErrors, setEditErrors] = useState({})
    const [searchText, setSearchText] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [isEditBtnLoading, setIsEditBtnLoading] = useState(false);
    const [isDeleteBtnLoading, setIsDeleteBtnLoading] = useState(false);
    const [editedUserData, setEditedUserData] = useState({});
    const [kioskUserId, setKioskUserId] = useState('');
    const [pagination, setPagination] = useState({ page: 1, rowsPerPage: 10 });

    const columns = [
        {
          name: "Sr.No",
          // cell: (row, index) => (pagination.page - 1) * pagination.rowsPerPage + index + 1,
          cell: (row, index) => <div className="fs-7">{row.serialNumber ? row.serialNumber : "--------"}</div>,
          center: true,
          width: "90px"
        },
        {
          name: "NAME",
          selector: (row) => row.name,
          cell: (row) => (
            <div className="fs-7">{row.name ? row.name : "--------"}</div>
          ),
        },
        {
            name: "MOBILE",
            selector: (row) => row.name,
            cell: (row) => (
              <div className="fs-7">{row.mobile ? row.mobile : "--------"}</div>
            ),
            width: "160px",
            center: true
        },
        {
            name: "EMAIL ADDRESS",
            selector: (row) => row.name,
            cell: (row) => (
              <div className="fs-7">{row.email ? row.email : "--------"}</div>
            ),
            width: "250px"

        },
        {
            name: "OUTLET",
            selector: (row) => row.name,
            cell: (row) => (
              <div className="fs-7">{row.outlet.name ? row.outlet.name : "--------"}</div>
            ),
        },
        {
            name: "SIGNUP TYPE",
            selector: (row) => row.name,
            cell: (row) => (
              <div className="fs-7">{row.signupType ? row.signupType : "--------"}</div>
            ),
        },
        {
          name: "ACTIONS",
          right: "true",
          cell: (row) => (
            <div className="d-flex justify-content-around align-items-center">
              <a
                href="#"
                onClick={() => handleEditModal(row)}
                className="text-primary me-2 d-flex align-items-center gap-1"
                style={{ textDecoration: "none" }}
              >
                <FaPencilAlt /> EDIT
              </a>{" "}
              |
              <a
                href="#"
                className="text-danger ms-2 d-flex align-items-center gap-1"
                onClick={() => handleOpenDeleteModal(row)}
                style={{ textDecoration: "none" }}
              >
                <FaTrash /> DELETE
              </a>
            </div>
          ),
          minWidth: "165px",
        },
    ]
    
    const updatedColumns = columns.map((column) => ({
            ...column,
            name : (
                <div style={{ fontSize: "13px", fontWeight: "bold" }}>{column.name}</div>
            )
        }) 
    )

    const getKioskOultetUsers = async() => {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`
            }
            const response = await axios.get('user/kiosk-outlet-user', { headers });
            response?.data?.data?.kioskOutletUsers?.forEach((item, index) => {
                item.serialNumber = index + 1;
            })
            setOutletUserList(response?.data?.data?.kioskOutletUsers);
            setFilteredData(response?.data?.data?.kioskOutletUsers);
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                  position: "top-right",
                  autoClose: 3000,
                  theme: "colored",
                });
            }
        }
    }

    const getOutletsData = async() => {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`
            }
            const response = await axios.get("outlet", { headers });
            setOutletsList(response?.data?.data?.outlets);
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                  position: "top-right",
                  autoClose: 3000,
                  theme: "colored",
                });
            }
        }
    }

    const handleAddNewOpenModal = () => {
        setAddNewOpenModal(true);
    }

    const handleChange = (e) => {   
        const { name, value } = e.target;
        setUserInputData((prevState) => ( { ...prevState, [name] : value }));
    }

    const handleSubmit = async(e) => {
        e.preventDefault();
        if(validateForm()) {
            setIsAddBtnLoading(true);
            try {
                const headers = {
                    Authorization: `Bearer ${accessToken}`
                }
                const response = await axios.post("auth/user/create-outlet-user-account", userInputData, { headers });
                if (response?.data?.status === true) {
                        toast.success(response?.data?.data?.message, {
                        position: "top-right",
                        autoClose: 3000,
                        theme: "colored",
                    });
                    getKioskOultetUsers();
                    setUserInputData({
                        name: "",
                        signupType: "",
                        mobile: "",
                        email: "",
                        outlet: "",
                        password: ""
                    });
                    setAddNewOpenModal(false);
                }
            } catch (error) {
                if (error?.response) {
                    let errorMessage = '';
                    if ( error.response?.status === 406 ) {
                        errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
                    }
                    else if ( error.response?.status === 412 ) {
                        errorMessage = error?.response?.data?.data?.message;
                    } else if (error.response?.status === 500) {
                        errorMessage = error?.response?.data?.error
                    }
                    toast.error(errorMessage, {
                        position: "top-right",
                        autoClose: 3000,
                        theme: "colored",
                    });
                }
            } finally {
                setIsAddBtnLoading(false);
            }
        }
    }

    const validateForm = () => {
        const newErrors = {};
        const trimmedTitle = userInputData?.name.trim();
        const wordCount = trimmedTitle.split(/\s+/).length;
        const specialCharacterPattern = /[!@#$%^&*(),.?":{}|<>]/;
    
        // Validate name
        if (!userInputData?.name) {
            newErrors.name = "Name is required";
        } else if (!isNaN(userInputData?.name) || /^-\d+$/.test(userInputData?.name)) {
            newErrors.name = "Name should not contain numeric or a negative integer";
        } else if (trimmedTitle.length < 2) {
            newErrors.name = "Name must contain at least 2 letters";
        } else if (specialCharacterPattern.test(userInputData?.name)) {
            newErrors.name = "Name should not contain special characters";
        }
    
        // Validate email
        if (!userInputData?.email) {
            newErrors.email = "Email Address is required";
        // } 
        // else if (!isNaN(userInputData?.email) || /^-\d+$/.test(userInputData?.email)) {
        //     newErrors.email = "Email Address should not contain numeric or a negative integer";
        } else if (userInputData?.email.length < 2) {
            newErrors.email = "Email Address must contain at least 2 letters";
        }
    
        // Validate mobile
        const trimmedMobile = userInputData?.mobile?.trim();
        if (!trimmedMobile) {
            newErrors.mobile = "Mobile number is required";
        } else if (trimmedMobile.length !== 10) {
            newErrors.mobile = "Mobile number must be exactly 10 digits";
        } else if (specialCharacterPattern.test(trimmedMobile)) {
            newErrors.mobile = "Mobile number should not contain special characters";
        }
    
        // Validate password
        if (!userInputData?.password) {
            newErrors.password = "Password is required";
        } else if (userInputData?.password.length < 5) {
            newErrors.password = "Password must be at least 5 letters";
        }
    
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const validateEditForm = () => {
        const newErrors = {};
        const trimmedTitle = editedUserData?.name.trim();
        const wordCount = trimmedTitle.split(/\s+/).length;
        const specialCharacterPattern = /[!@#$%^&*(),.?":{}|<>]/;
    
        // Validate name
        if (!editedUserData?.name) {
            newErrors.name = "Name is required";
        } else if (!isNaN(editedUserData?.name) || /^-\d+$/.test(editedUserData?.name)) {
            newErrors.name = "Name should not contain numeric or a negative integer";
        } else if (trimmedTitle.length < 2) {
            newErrors.name = "Name must contain at least 2 letters";
        } else if (specialCharacterPattern.test(editedUserData?.name)) {
            newErrors.name = "Name should not contain special characters";
        }
    
        // Validate email
        if (!editedUserData?.email) {
            newErrors.email = "Email Address is required";
        // } 
        // else if (!isNaN(editedUserData?.email) || /^-\d+$/.test(editedUserData?.email)) {
        //     newErrors.email = "Email Address should not contain numeric or a negative integer";
        } else if (editedUserData?.email.length < 2) {
            newErrors.email = "Email Address must contain at least 2 letters";
        }
    
        // Validate mobile
        const trimmedMobile = editedUserData?.mobile?.trim();
        if (!trimmedMobile) {
            newErrors.mobile = "Mobile number is required";
        } else if (trimmedMobile.length !== 10) {
            newErrors.mobile = "Mobile number must be exactly 10 digits";
        } else if (specialCharacterPattern.test(trimmedMobile)) {
            newErrors.mobile = "Mobile number should not contain special characters";
        }
    
        setEditErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSearchChange = (e) => {
        setSearchText(e.target.value)
    };

    const handleEditModal = (data) => {
        setOpenEditModal(true);
        setEditedUserData({
            _id: data?._id,
            name: data?.name,
            mobile: data?.mobile,
            email:  data?.email
        })
    }

    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setEditedUserData((prevState) => ({
            ...prevState,
            [name] : value
        }))
    }

    const handleEditSubmit = async (e) => {
        e.preventDefault();
        if (validateEditForm()) {
            setIsEditBtnLoading(true);
            try {
                const headers = {
                    Authorization: `Bearer ${accessToken}`
                }
                const response = await axios.put("user/kiosk-outlet-user/profile", editedUserData, { headers });
                if (response?.data?.status === true) {
                    toast.success(response?.data?.data?.message, {
                        position: "top-right",
                        autoClose: 3000,
                        theme: "colored",
                    });
                    getKioskOultetUsers();
                    setOpenEditModal(false);
                }
            } catch (error) {
                if (error?.response) {
                    let errorMessage = '';
                    if ( error.response?.status === 406 ) {
                        errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
                    }
                    else if ( error.response?.status === 412 ) {
                        errorMessage = error?.response?.data?.data?.message;
                    } else if (error.response?.status === 500) {
                        errorMessage = error?.response?.data?.error
                    }
                    toast.error(errorMessage, {
                        position: "top-right",
                        autoClose: 3000,
                        theme: "colored",
                    });
                }
            } finally {
                setIsEditBtnLoading(false);
            }
        }
    }

    const handleOpenDeleteModal = (data) => {
        setOpenDeleteModal(true);
        setKioskUserId(data?._id);
    }

    const handleDeleteKioskUser = async (e) => {
        e.preventDefault();
        setIsDeleteBtnLoading(true);
        try {
            const headers = {
                Authorization : `Bearer ${accessToken}`
            }
            const data = {
                _id: kioskUserId
            }
            
            const response = await axios.delete("user/kiosk-outlet-user", { data, headers });
            if (response?.data?.status === true) {
                toast.success(response?.data?.data?.message, {
                  position: "top-right",
                  autoClose: 3000,
                  theme: "colored",
                });
                getKioskOultetUsers();
                setOpenDeleteModal(false);
            }
        } catch (error) {
            if (error.response.status === 403) {
                toast.error(error?.response?.data?.message, {
                  position: "top-right",
                  autoClose: 3000,
                  theme: "colored",
                });
            }
        
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                  position: "top-right",
                  autoClose: 3000,
                  theme: "colored",
                });
            }
        } finally {
            setIsDeleteBtnLoading(false);
        }
    }

    const handleCloseDeleteModal = () => {
        setOpenDeleteModal(false);
    }

    const handlePageChange = (page) => {
        setPagination({ ...pagination, page });
    };
      
    const handleRowsPerPageChange = (rowsPerPage) => {
        setPagination({ page: 1, rowsPerPage }); // Reset to the first page when changing rows per page
    };
      
      // Function to get paginated data
    const getPaginatedData = () => {
        const startIndex = (pagination.page - 1) * pagination.rowsPerPage;
        const endIndex = startIndex + pagination.rowsPerPage;
        return filteredData?.slice(startIndex, endIndex);
    };

    useEffect(() => {
        getOutletsData();
        getKioskOultetUsers();
    }, []);

    useEffect(() => {
        const result = outletUserList.filter((item) => {
            return item?.name.toLowerCase().match(searchText.toLocaleLowerCase());
        });
        setFilteredData(result);
    }, [searchText]);

    return (
        <CContainer>
            <div className="mb-3 text-end">
                <button
                className="btn btn-warning"
                onClick={handleAddNewOpenModal}
                style={{ borderRadius: "18px" }}
                >
                    ADD NEW KIOSK OUTLET USER <FaPlus style={{ marginTop: "-4px" }} size={15} />
                </button>
            </div>

            {/* <-------- List of Kiosk Outlet Users -------> */}
            <div>
                <DataTableComponent
                    columns={updatedColumns}
                    title="KIOSK OUTLET USERS"
                    // data={filteredData}
                    data={getPaginatedData()} // Use the paginated data here
                    searchText={searchText}
                    onChange={handleSearchChange}
                    striped
                    itemsPerPage={10}
                    pagination
                    totalRows={filteredData?.length}
                    paginationTotalRows={filteredData?.length} // Total number of items
                    onChangeRowsPerPage={handleRowsPerPageChange}
                    onChangePage={handlePageChange}
                    paginationServer={false} // Set to false for client-side pagination
                />
            </div>

            {/* <------------------- Add New Kioskk User Modal ---------------------> */}
            {addNewOpenModal ? (
                <CModal
                    alignment="center"
                    visible={addNewOpenModal}
                    onClose={() => {setAddNewOpenModal(false); setErrors({})}}
                    aria-labelledby="VerticallyCenteredExample"
                >
                    <CModalHeader>
                        <CModalTitle>ADD KISOK OUTLET USER</CModalTitle>
                    </CModalHeader>
                        <CForm onSubmit={handleSubmit}>
                            <CModalBody>
                                <CRow>
                                    <CCol>
                                        <div className="mb-3">
                                            <CFormLabel className="fw-semibold">
                                                Name <span className="text-danger">*</span>
                                            </CFormLabel>
                                            <CFormInput
                                                type="text"
                                                placeholder="Name"
                                                className="form-control"
                                                name="name"
                                                value={userInputData?.name}
                                                onChange={handleChange}
                                            />
                                            {errors.name && <div className='text-danger' style={{ fontSize : "12px"}}>{errors.name}</div>}
                                        </div>
                                    </CCol>
                                    <CCol>
                                        <div className="mb-3">
                                            <CFormLabel className="fw-semibold">
                                                Email <span className="text-danger">*</span>
                                            </CFormLabel>
                                            <CFormInput
                                                type="text"
                                                placeholder="Email"
                                                className="form-control"
                                                name="email"
                                                value={userInputData?.email}
                                                onChange={handleChange}
                                            />
                                            {errors.email && <div className='text-danger' style={{ fontSize : "12px"}}>{errors.email}</div>}
                                        </div>
                                    </CCol>
                                </CRow>
                                
                                <CRow>
                                    <CCol>
                                        {" "}
                                        <div className="mb-3">
                                            <CFormLabel className="fw-semibold">
                                                Mobile <span className="text-danger">*</span>
                                            </CFormLabel>
                                            <CFormInput
                                                min={1}
                                                minLength={10}
                                                type="number"
                                                placeholder="Mobile"
                                                className="form-control no-arrows"
                                                name="mobile"
                                                value={userInputData?.mobile}
                                                onChange={handleChange}
                                                onInput={(e) => {
                                                    e.target.value = e.target.value
                                                      .replace(/[^0-9]/g, '') // Allow only numbers and a decimal point
                                                      .replace(/(\..*)\./g, '$1'); // Prevent multiple decimal points
                                                }}
                                                pattern="^\d+(\.\d{1,2})?$"
                                                title="Mobile Number must be a whole number."
                                            />
                                            {errors.mobile && <div className='text-danger' style={{ fontSize : "12px"}}>{errors.mobile}</div>}
                                        </div>
                                    </CCol>
                                    <CCol>
                                        <div className='mb-2'>
                                            <CInputGroup className="flex-column">
                                                <CFormLabel className="fw-semibold" htmlFor="asset_type">
                                                    Select Outlet <span className="text-danger">*</span>
                                                </CFormLabel>
                                                <CFormSelect
                                                    className="w-100"
                                                    id="outlet"
                                                    name="outlet"
                                                    value={userInputData?.outlet}
                                                    onChange={handleChange}
                                                >
                                                    <option value="" disabled>Select Outlet</option>
                                                    { outletsList?.map((outlets) => {
                                                        return (
                                                            <option key={outlets._id} value={outlets._id}>{outlets?.name}</option>
                                                        )
                                                    })}
                                                </CFormSelect>
                                            </CInputGroup>
                                        </div>
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol>
                                        <div className='mb-2'>
                                            <CInputGroup className="flex-column">
                                                <CFormLabel className="fw-semibold" htmlFor="asset_type">
                                                    Select SignUp Type <span className="text-danger">*</span>
                                                </CFormLabel>
                                                <CFormSelect
                                                    className="w-100"
                                                    id="signupType"
                                                    name="signupType"
                                                    value={userInputData?.signupType}
                                                    onChange={handleChange}
                                                >
                                                    <option defaultValue="">Select Signup Type</option>
                                                    <option value="REGULAR">REGULAR</option>
                                                    {/* <option value="DEBIT">DEBIT</option> */}
                                                </CFormSelect>
                                            </CInputGroup>
                                        </div>
                                    </CCol>
                                    <CCol>
                                        <div className="mb-3">
                                            <CFormLabel className="fw-semibold">
                                                Password <span className="text-danger">*</span>
                                            </CFormLabel>
                                            <CFormInput
                                                type="password"
                                                placeholder="Password"
                                                className="form-control"
                                                name="password"
                                                value={userInputData?.password}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </CCol>
                                </CRow>
                                
                            </CModalBody>
                            <CModalFooter>
                                <PrimaryButton title="ADD OUTLET USER" isLoading={isAddBtnLoading} />
                            </CModalFooter>
                        </CForm>
                </CModal>
            ) : null}

            {/* <------------------- Edit Kioskk User Modal ---------------------> */}
            {openEditModal ? (
                <CModal
                alignment="center"
                visible={openEditModal}
                onClose={() => {setOpenEditModal(false); setEditErrors({})}}
                aria-labelledby="VerticallyCenteredExample"
            >
                <CModalHeader>
                    <CModalTitle>EDIT KISOK OUTLET USER</CModalTitle>
                </CModalHeader>
                    <CForm onSubmit={handleEditSubmit}>
                        <CModalBody>
                                    <div className="mb-3">
                                        <CFormLabel className="fw-semibold">
                                            Name <span className="text-danger">*</span>
                                        </CFormLabel>
                                        <CFormInput
                                            type="text"
                                            placeholder="Name"
                                            className="form-control"
                                            name="name"
                                            value={editedUserData?.name}
                                            onChange={handleEditChange}
                                        />
                                        {editErrors.name && <div className='text-danger' style={{ fontSize : "12px"}}>{editErrors.name}</div>}
                                    </div>
                                    <div className="mb-3">
                                        <CFormLabel className="fw-semibold">
                                            Mobile <span className="text-danger">*</span>
                                        </CFormLabel>
                                        <CFormInput
                                            min={1}
                                            minLength={10}
                                            type="number"
                                            placeholder="Mobile"
                                            className="form-control no-arrows"
                                            name="mobile"
                                            value={editedUserData?.mobile}
                                            onChange={handleEditChange}
                                            onInput={(e) => {
                                                e.target.value = e.target.value
                                                  .replace(/[^0-9]/g, '') // Allow only numbers and a decimal point
                                                  .replace(/(\..*)\./g, '$1'); // Prevent multiple decimal points
                                            }}
                                            pattern="^\d+(\.\d{1,2})?$"
                                            title="Mobile Number must be a whole number."
                                        />
                                        {editErrors.mobile && <div className='text-danger' style={{ fontSize : "12px"}}>{editErrors.mobile}</div>}
                                    </div>
                                    <div className="mb-3">
                                        <CFormLabel className="fw-semibold">
                                            Email <span className="text-danger">*</span>
                                        </CFormLabel>
                                        <CFormInput
                                            type="text"
                                            placeholder="Email"
                                            className="form-control"
                                            name="email"
                                            value={editedUserData?.email}
                                            onChange={handleEditChange}
                                        />
                                        {editErrors.email && <div className='text-danger' style={{ fontSize : "12px"}}>{editErrors.email}</div>}
                                    </div>
                            
                        </CModalBody>
                        <CModalFooter>
                            <PrimaryButton title="UPDATE OUTLET USER" isLoading={isEditBtnLoading}  />
                        </CModalFooter>
                    </CForm>
            </CModal>
            ) : null }

            {/* <------------------- Delete Kioskk User Modal ---------------------> */}
            {openDeleteModal ? (
                <DeleteModal isVisible={openDeleteModal} onClose={handleCloseDeleteModal} onConfirm={handleDeleteKioskUser} isLoading={isDeleteBtnLoading} title="DELETE KISOK USER" />
            ) : null}

        </CContainer>
    )
}

export default KioskOutletUser