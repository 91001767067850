import React from 'react'
import { AppContent, AppHeader, AppSideBar } from './components';

const AppLayout = () => {
    return (
        <div>
            <AppSideBar />
            <div className="wrapper d-flex flex-column min-vh-100 bg-light">
                <AppHeader />
                <div className="body flex-grow-1 px-1">
                    <AppContent />
                </div>
            </div>
        </div>
    )
}
export default AppLayout
