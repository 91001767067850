import React, { useEffect, useState } from "react";
import axios from "../../api/axios";
import { toast } from "react-toastify";
import {
    CButton,
    CCard,
    CCardBody,
    CCardFooter,
    CCardHeader,
    CCardTitle,
    CCol,
    CContainer,
    CForm,
    CFormInput,
    CFormLabel,
    CFormSelect,
    CImage,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CRow,
    CTable,
    CTableBody,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow,
} from "@coreui/react";
import { DeleteModal, PrimaryButton } from "../../components";
import { FaRegEye, FaEdit } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import Select from "react-select";

const ViewAdvertisement = () => {
    const accessToken = localStorage.getItem("authToken");
    const [productsList, setProductsList] = useState([]);
    const [clickableAdvertisementData, setClickableAdvertisementData] = useState(
        []
    );
    const [nonClickableAdvertisementData, setNonClickableAdvertisementData] =
        useState([]);
    const [topImageAdd, setTopImageAdd] = useState([]);
    const [bottomImageAdd, setBottomImageAdd] = useState({});
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [adId, setAdId] = useState("");
    const [isDeleteBtnLoading, setIsDeleteBtnLoading] = useState(false);
    const [openViewModal, setOpenViewModal] = useState(false);
    const [singleAdData, setSingleAdData] = useState("");
    const [activeTab, setActiveTab] = useState("CLICKABLE");
    const [openEditModal, setOpenEditModal] = useState(false);
    const [editedData, setEditedData] = useState({});
    const [image, setImage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isOpenUpdateImageModal, setIsOpenUpdateImageModal] = useState(false);
    const [isImageUpdating, setIsImageUpdating] = useState(false)

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    const getAdvertisementList = async () => {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };
            const response = await axios.get("advertisement", { headers });

            if (response?.data?.status === true) {
                const totalNonClickableAdvertisements=response?.data?.data?.advertisements.nonClickableAdvertisements;
                console.log("Total Non Clickable Add :", totalNonClickableAdvertisements);
                
                const nonClickableAdvertisements=totalNonClickableAdvertisements.filter(ad=>ad.isTopImage===false && ad.isBottomImage===false);
                const topImageAdvertisement=totalNonClickableAdvertisements.filter(ad=>ad.isTopImage===true);
                const bottomImageAdvertisement=totalNonClickableAdvertisements.find(ad=>ad.isBottomImage===true);
                setClickableAdvertisementData(
                    response?.data?.data?.advertisements.clickableAdvertisements
                );
                console.log("Top Images :", topImageAdvertisement);
                
                setNonClickableAdvertisementData(nonClickableAdvertisements);
                setTopImageAdd(topImageAdvertisement);
                setBottomImageAdd(bottomImageAdvertisement);
                
            }
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
    };

    const handleOpenViewModal = (id) => {
        setOpenViewModal(true);
        getSingleAdvertisement(id);
    };

    const getSingleAdvertisement = async (id) => {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };
            const response = await axios.get(`advertisement?_id=${id}`, { headers });

            if (response?.data?.status === true) {
                setSingleAdData(response?.data?.data?.advertisements);
            }
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
    };

    const handleOpenDeleteModal = (id) => {
        setOpenDeleteModal(true);
        setAdId(id);
    };

    const deleteAdvertisement = async (e) => {
        e.preventDefault();
        setIsDeleteBtnLoading(true);
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };

            const data = {
                _id: adId,
            };
            const response = await axios.delete(`advertisement`, { data, headers });
            if (response?.data?.status === true) {
                toast.success(response?.data?.data?.message, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
                getAdvertisementList();
                setOpenDeleteModal(false);
            }
        } catch (error) {
            if (error?.response) {
                let errorMessage = '';
                if (
                    error.response?.status === 406
                ) {
                    errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
                }
                else if (
                    error.response?.status === 412
                ) {
                    errorMessage = error?.response?.data?.data?.message;
                } else if (error.response?.status === 500) {
                    errorMessage = error?.response?.data?.error
                }

                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        } finally {
            setIsDeleteBtnLoading(false);
        }
    };

    const getProductsList = async () => {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };
            const response = await axios.get("product", { headers });
            if (response?.data?.status === true) {
                setProductsList(response?.data?.data?.products);
            }
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
    };

    const handleOpenEditModal = (data) => {
        setOpenEditModal(true);
        setEditedData({
            _id: data?._id,
            title: data?.title,
            type: data?.type,
            sequenceNo: data?.sequenceNo,
            product: data?.product?._id,
            isTopImage: data?.isTopImage,
            isBottomImage: data?.isBottomImage,
            link: data?.link
        })
    };

    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setEditedData((prev) => ({ ...prev, [name]: value }))
    }

    const handleEditImageChange = (e) => {
        const input = document.getElementById("imageInput");
        const file = input.files[0];

        if (file) {
            const reader = new FileReader();

            // <--------------- This is Validation For Updating Image --------------> 
            // reader.onloadend = () => {
            //     const image = new Image();
            //     image.src = reader.result;

            //     image.onload = () => {
            //         const width = image.width;
            //         const height = image.height;

            //         const aspectRatio = width / height;
            //         const targetAspectRatio = 16 / 9;

            //         if (Math.abs(aspectRatio - targetAspectRatio) < 0.01) {
            //             alert("Image dimensions are valid!");
            //             setImage(file);
            //         } else {
            //             alert(
            //                 "Image dimensions are not valid. Please choose an image with a 16:9 aspect ratio."
            //             );
            //             input.value = "";
            //         }
            //     };
            // };

            setImage(file);

            reader.readAsDataURL(file);
        }
    }

    const handleEditSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true)
        try {
            const formData = new FormData();
            formData.append("_id", editedData?._id)
            formData.append("title", editedData.title);
            formData.append("type", editedData.type);
            formData.append("sequenceNo", editedData.sequenceNo);
            if (editedData?.product !== "" && editedData?.product !== undefined && editedData?.product !== null) {                
                formData.append("product", editedData?.product);
            }
            if (editedData?.isTopImage !== ""){
                formData.append("isTopImage", editedData?.isTopImage);
            }
            if (editedData?.link !== ""){
                formData.append("link", editedData?.link);
            }
            if (editedData?.isBottomImage !== ""){
                formData.append("isBottomImage", editedData?.isBottomImage);
            }

            const headers = {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${accessToken}`,
            };

            const response = await axios.put("advertisement", formData, {
                headers,
            });

            if (response?.data?.status === true) {
                toast.success(response?.data?.data?.message, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
                setOpenEditModal(false);
                getAdvertisementList();
            }
        } catch (error) {
            if (error?.response) {
                let errorMessage = '';
                if ( error.response?.status === 406 ) {
                    errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
                }
                else if ( error.response?.status === 404 ) {
                    errorMessage = error?.response?.data?.data?.message;
                }
                else if ( error.response?.status === 412 ) {
                    errorMessage = error?.response?.data?.data?.message;
                } else if (error.response?.status === 500) {
                    errorMessage = error?.response?.data?.error
                }

                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        } finally {
            setIsLoading(false);
        }
    }

    const openUpdateImageModal = (data) => {
        setEditedData((prev) => ({
            ...prev,
            _id: data?._id
        }))
        setIsOpenUpdateImageModal(true)
    }

    const handleEditImage = async (e) => {
        e.preventDefault()
        setIsImageUpdating(true)

        try {
            const formData = new FormData();
            formData.append("_id", editedData?._id)
            formData.append("adImage", image);

            const headers = {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${accessToken}`,
            };

            const response = await axios.put("advertisement", formData, {
                headers,
            });

            if (response?.data?.status === true) {
                toast.success(response?.data?.data?.message, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
                e.target.reset()
                setIsOpenUpdateImageModal(false)
                getAdvertisementList()
            }
        } catch (error) {
            if (error?.response) {
                let errorMessage = '';
                if (
                    error.response?.status === 406
                ) {
                    errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
                }
                else if (
                    error.response?.status === 412
                ) {
                    errorMessage = error?.response?.data?.data?.message;
                } else if (error.response?.status === 500) {
                    errorMessage = error?.response?.data?.error
                }

                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        } finally {
            setIsImageUpdating(false)
        }
    }

    const handleCloseDeleteModal = () => {
        setOpenDeleteModal(false)
    }

    useEffect(() => {
        getAdvertisementList();
        getProductsList()
    }, []);

    return (
        <>
            <CContainer>
                <div
                    className="d-flex justify-content-center gap-2 mb-2"
                    style={{ cursor: "pointer" }}
                >
                    <div
                        color="none"
                        className={` ${activeTab === "CLICKABLE" ? "text-secondary" : "text-dark"
                            } text-center p-2 fw-semibold`}
                        onClick={() => handleTabChange("CLICKABLE")}
                        style={{
                            flex: 1,
                            borderBottom:
                                activeTab === "CLICKABLE"
                                    ? "2px solid #bcbcbd"
                                    : "1px solid #bcbcbd",
                            transition: "border-bottom 0.3s ease-in-out",
                            background: activeTab === "CLICKABLE" ? "#e7e9eb" : "none",
                            border: "1px solid #bcbcbd",
                            borderRadius: "5px",
                        }}
                    >
                        CLICKABLE
                    </div>
                    <div
                        color="none"
                        className={` ${activeTab === "NON CLICKABLE" ? "text-secondary" : "text-dark"
                            } text-center p-2 fw-semibold`}
                        onClick={() => handleTabChange("NON CLICKABLE")}
                        style={{
                            flex: 1,
                            borderBottom:
                                activeTab === "NON CLICKABLE"
                                    ? "2px solid #bcbcbd"
                                    : "1px solid #bcbcbd",
                            transition: "border-bottom 0.3s ease-in-out",
                            background: activeTab === "NON CLICKABLE" ? "#e7e9eb" : "none",
                            border: "1px solid #bcbcbd",
                            borderRadius: "5px",
                        }}
                    >
                        NON CLICKABLE
                    </div>
                    <div
                        color="none"
                        className={` ${activeTab === "TOP & BOTTOM IMAGES" ? "text-secondary" : "text-dark"
                            } text-center p-2 fw-semibold`}
                        onClick={() => handleTabChange("TOP & BOTTOM IMAGES")}
                        style={{
                            flex: 1,
                            borderBottom:
                                activeTab === "TOP & BOTTOM IMAGES"
                                    ? "2px solid #bcbcbd"
                                    : "1px solid #bcbcbd",
                            transition: "border-bottom 0.3s ease-in-out",
                            background: activeTab === "TOP & BOTTOM IMAGES" ? "#e7e9eb" : "none",
                            border: "1px solid #bcbcbd",
                            borderRadius: "5px",
                        }}
                    >
                        TOP & BOTTOM IMAGES
                    </div>
                </div>

                {activeTab === "CLICKABLE" && (
                    <CRow>
                        {clickableAdvertisementData?.map((ad) => (
                            <CCol key={ad.id} lg="4" md="4" className="mt-3">
                                <CCard className="mb-3 ">
                                    <CCardHeader>
                                        <CCardTitle className="d-flex justify-content-between">
                                            <div>
                                                {ad?.sequenceNo}
                                            </div>
                                            <div>
                                                {ad?.title && ad.title.toUpperCase()}
                                            </div>
                                        </CCardTitle>
                                    </CCardHeader>
                                    <CCardBody>
                                        <CImage
                                            src={ad.adImagePath}
                                            alt="ad-pic"
                                            style={{ width: "100%", cursor: 'pointer', height: '40vh' }}
                                            onClick={() => openUpdateImageModal(ad)}
                                        />
                                    </CCardBody>
                                    <CCardFooter className="d-flex justify-content-around gap-2">
                                        <CButton
                                            onClick={() => handleOpenViewModal(ad._id)}
                                            color="info"
                                            className="px-4"
                                        >
                                            VIEW
                                        </CButton>
                                        <CButton
                                            color="warning"
                                            className="px-4"
                                            onClick={() => handleOpenEditModal(ad)}
                                        >
                                            EDIT
                                        </CButton>
                                        <CButton
                                            onClick={() => handleOpenDeleteModal(ad._id)}
                                            color="danger"
                                            className="px-4"
                                        >
                                            DELETE
                                        </CButton>
                                    </CCardFooter>
                                </CCard>
                            </CCol>
                        ))}
                    </CRow>
                )}

                {activeTab === "NON CLICKABLE" && (
                    <CRow>
                        {nonClickableAdvertisementData?.map((ad) => (
                            <CCol key={ad.id} md="4" lg="4" className="mt-3">
                                <CCard className="mb-3 ">
                                    <CCardHeader>
                                        <CCardTitle>{ad?.title && ad.title.toUpperCase()}</CCardTitle>
                                    </CCardHeader>
                                    <CCardBody>
                                        <CImage
                                            src={ad.adImagePath}
                                            alt="ad-pic"
                                            style={{ width: "100%", height: '40vh' }}
                                            onClick={() => openUpdateImageModal(ad)}
                                        />
                                    </CCardBody>
                                    <CCardFooter className="d-flex justify-content-around gap-2">
                                        <CButton
                                            color="warning"
                                            onClick={() => handleOpenEditModal(ad)}
                                            className="px-4"
                                        >
                                            EDIT
                                        </CButton>
                                        <CButton
                                            onClick={() => handleOpenDeleteModal(ad._id)}
                                            color="danger"
                                            className="px-4"
                                        >
                                            DELETE
                                        </CButton>
                                    </CCardFooter>
                                </CCard>
                            </CCol>
                        ))}
                    </CRow>
                )}

                {activeTab === "TOP & BOTTOM IMAGES" && (
                    <CRow>
                        {topImageAdd?.map((ad) => (
                            <CCol key={ad?.id} md="4" lg="4" className="mt-3">
                                <CCard className="mb-3">
                                    <CCardHeader>
                                        <CCardTitle>{ad?.title && ad?.title.toUpperCase()}</CCardTitle>
                                    </CCardHeader>
                                    <CCardBody>
                                        <CImage
                                            src={ad?.adImagePath}
                                            alt="ad-pic"
                                            style={{ width: "100%", height: '40vh' }}
                                            onClick={() => openUpdateImageModal(ad)}
                                        />
                                    </CCardBody>
                                    <CCardFooter className="d-flex justify-content-around gap-2">
                                        <CButton
                                            color="warning"
                                            onClick={() => handleOpenEditModal(ad)}
                                        >
                                            EDIT
                                        </CButton>
                                        <CButton
                                            onClick={() => handleOpenDeleteModal(ad?._id)}
                                            color="danger"
                                        >
                                            DELETE
                                        </CButton>
                                    </CCardFooter>
                                </CCard>
                            </CCol>
                        ))}
                            <CCol key={bottomImageAdd?.id} md="4" lg="4" className="mt-3">
                                <CCard className="mb-3 ">
                                    <CCardHeader>
                                        <CCardTitle>{bottomImageAdd?.title && bottomImageAdd?.title.toUpperCase()}</CCardTitle>
                                    </CCardHeader>
                                    <CCardBody>
                                        <CImage
                                            src={bottomImageAdd?.adImagePath}
                                            alt="ad-pic"
                                            style={{ width: "100%", height: '40vh' }}
                                            onClick={() => openUpdateImageModal(bottomImageAdd)}
                                        />
                                    </CCardBody>
                                    <CCardFooter className="d-flex justify-content-around gap-2">
                                        <CButton
                                            color="warning"
                                            onClick={() => handleOpenEditModal(bottomImageAdd)}
                                            className="px-4"
                                        >
                                            EDIT    
                                        </CButton>
                                        <CButton
                                            onClick={() => handleOpenDeleteModal(bottomImageAdd._id)}
                                            color="danger"
                                            className="px-4"
                                        >
                                            DELETE
                                        </CButton>
                                    </CCardFooter>
                                </CCard>
                            </CCol>
                    </CRow>
                )}
            </CContainer>

            {openDeleteModal ? (
                <DeleteModal isVisible={openDeleteModal} onClose={handleCloseDeleteModal} onConfirm={deleteAdvertisement} isLoading={isDeleteBtnLoading} title="DELETE ADVERTISEMENT" />
            ) : null}

            {openViewModal ? (
                <CModal
                    alignment="center"
                    size="lg"
                    visible={openViewModal}
                    onClose={() => setOpenViewModal(false)}
                    aria-labelledby="VerticallyCenteredExample"
                >
                    <CModalHeader>
                        <CModalTitle id="VerticallyCenteredExample" className="fs-6">
                            VIEW ADVERTISEMENT DETAILS
                        </CModalTitle>
                    </CModalHeader>
                    <CModalBody>
                        <div>
                            <h5 className="text-center">
                                {singleAdData?.title && singleAdData.title.toUpperCase()}
                            </h5>
                        </div>
                        <hr />
                        <div className="d-flex justify-content-center">
                            <img
                                src={singleAdData?.adImagePath}
                                alt="ad-photo"
                                className="w-100"
                            />
                        </div>
                        <div className="mt-3">
                            <CTable className="table table-bordered">
                                <CTableHead>
                                    <CTableRow>
                                        <CTableHeaderCell className="text-center" colSpan={6}>
                                            PRODUCT INFORMATION
                                        </CTableHeaderCell>
                                    </CTableRow>
                                </CTableHead>
                                <CTableBody>
                                    <CTableRow>
                                        <CTableHeaderCell className="text-center">
                                            IMAGE
                                        </CTableHeaderCell>
                                        <CTableHeaderCell className="text-center">
                                            NAME
                                        </CTableHeaderCell>
                                        <CTableHeaderCell className="text-center">
                                            DESCRIPTION
                                        </CTableHeaderCell>
                                        <CTableHeaderCell className="text-center">
                                            HSN
                                        </CTableHeaderCell>
                                        <CTableHeaderCell className="text-center">
                                            MRP
                                        </CTableHeaderCell>
                                        <CTableHeaderCell className="text-center">
                                            OFFER PRICE
                                        </CTableHeaderCell>
                                    </CTableRow>
                                    <CTableRow>
                                        <CTableDataCell className="text-center">
                                            <img
                                                src={singleAdData?.product?.displayImage}
                                                alt="product-image"
                                                style={{ width: "100px", height: "100px" }}
                                            />
                                        </CTableDataCell>
                                        <CTableDataCell className="text-center">
                                            {singleAdData?.product?.name}
                                        </CTableDataCell>
                                        <CTableDataCell className="text-center">
                                            {singleAdData?.product?.description}
                                        </CTableDataCell>
                                        <CTableDataCell className="text-center">
                                            {singleAdData?.product?.hsn}
                                        </CTableDataCell>
                                        <CTableDataCell className="text-center">
                                            {singleAdData?.product?.mrp}
                                        </CTableDataCell>
                                        <CTableDataCell className="text-center">
                                            {singleAdData?.product?.offerPrice} Rs
                                        </CTableDataCell>
                                    </CTableRow>
                                </CTableBody>
                            </CTable>
                        </div>
                    </CModalBody>
                    <CModalFooter>
                        <CButton
                            color="warning"
                            onClick={() => setOpenViewModal(false)}
                            style={{
                                borderRadius: "18px",
                            }}
                        >
                            CLOSE
                        </CButton>
                    </CModalFooter>
                </CModal>
            ) : null}

            {openEditModal ? (
                <CModal
                    alignment="center"
                    visible={openEditModal}
                    onClose={() => setOpenEditModal(false)}
                    aria-labelledby="VerticallyCenteredExample"
                    size="lg"
                >
                    <CModalHeader>
                        <CModalTitle id="VerticallyCenteredExample" className="fs-6">
                            EDIT ADVERTISEMENT
                        </CModalTitle>
                    </CModalHeader>
                    <CForm onSubmit={handleEditSubmit}>
                        <CModalBody>
                            <CForm>
                                <CRow>
                                    <CCol>
                                        <div className="mb-3">
                                            <CFormLabel className="fw-semibold">
                                                Title <span className="text-danger">*</span>
                                            </CFormLabel>
                                            <CFormInput
                                                type="text"
                                                placeholder="Title"
                                                className="form-control"
                                                name="title"
                                                value={editedData?.title}
                                                onChange={handleEditChange}
                                            />
                                        </div>
                                    </CCol>
                                    <CCol>
                                        <div>
                                            <CFormLabel className="fw-semibold">
                                                Select Type <span className="text-danger">*</span>
                                            </CFormLabel>
                                            <CFormSelect
                                                size="sm"
                                                onChange={handleEditChange}
                                                value={editedData?.type}
                                                name="type"
                                                className="mb-3"
                                                aria-label="Small select example"
                                            >
                                                <option value="">Select Type</option>
                                                <option value="CLICKABLE">CLICKABLE</option>
                                                <option value="NON-CLICKABLE">NON CLICKABLE</option>
                                            </CFormSelect>
                                        </div>
                                    </CCol>
                                    <CCol>
                                        <div className="mb-3">
                                            <CFormLabel className="fw-semibold">
                                                Sequence No. <span className="text-danger">*</span>
                                            </CFormLabel>
                                            <CFormInput
                                                type="text"
                                                placeholder="Sequence No."
                                                className="form-control"
                                                name="sequenceNo"
                                                value={editedData?.sequenceNo}
                                                onChange={handleEditChange}
                                            />
                                        </div>
                                    </CCol>
                                </CRow>

                                <CRow>
                                    { editedData?.isTopImage || editedData?.isBottomImage ? (
                                        <>
                                        <CCol>
                                            <CFormLabel className='fw-semibold'>Add Link</CFormLabel>
                                            <CFormInput 
                                                type="text"
                                                placeholder="Add Link"
                                                className="form-control"
                                                name="link"
                                                value={editedData.link}
                                                disabled={editedData.type === "CLICKABLE"}
                                                onChange={handleEditChange} 
                                            />
                                        </CCol>
                                            <CCol>
                                                <div className="mb-3">
                                                    <CFormLabel className="fw-semibold">
                                                        Top Image{" "}
                                                        <span className="text-danger">*</span>
                                                    </CFormLabel>
                                                    <CFormSelect
                                                        size="sm"
                                                        onChange={handleEditChange}
                                                        name="isTopImage"
                                                        value={editedData?.isTopImage}
                                                        className="mb-3"
                                                        aria-label="Small select example"
                                                    >
                                                        <option value="">Select</option>
                                                        <option key="isTopImage" value="true">YES</option>
                                                        <option key="isTopImage" value="false">NO</option>
                                                    </CFormSelect>
                                                </div>
                                            </CCol>
                                            <CCol>
                                                <div className="mb-3">
                                                    <CFormLabel className="fw-semibold">
                                                        Bottom Image{" "}
                                                        <span className="text-danger">*</span>
                                                    </CFormLabel>
                                                    <CFormSelect
                                                        size="sm"
                                                        onChange={handleEditChange}
                                                        name="isBottomImage"
                                                        value={editedData?.isBottomImage}
                                                        className="mb-3"
                                                        aria-label="Small select example"
                                                    >
                                                        <option value="">Select</option>
                                                        <option key="isBottomImage" value="true">YES</option>
                                                        <option key="isBottomImage" value="false">NO</option>
                                                    </CFormSelect>
                                                </div>
                                            </CCol>
                                        </>
                                            ):(
                                        // <CCol>
                                        //     <div className="mb-3">
                                        //         <CFormLabel className="fw-semibold">
                                        //             Select Product{" "}
                                        //             <span className="text-danger">*</span>
                                        //         </CFormLabel>
                                        //         <CFormSelect
                                        //             disabled={editedData?.type === "NON-CLICKABLE"}
                                        //             size="sm"
                                        //             onChange={handleEditChange}
                                        //             name="product"
                                        //             value={editedData?.product}
                                        //             className="mb-3"
                                        //             aria-label="Small select example"
                                        //         >
                                        //             <option value="">Select Product</option>
                                        //             {productsList.map((rm) => (
                                        //                 <option key={rm._id} value={rm._id}>
                                        //                     {rm.name}
                                        //                 </option>
                                        //             ))}
                                        //         </CFormSelect>
                                        //     </div>
                                        // </CCol>
                                        <CCol>
                                            <div className="mb-3">
                                            <CFormLabel className="fw-semibold">
                                                Select Product <span className="text-danger">*</span>
                                            </CFormLabel>
                                            <Select
                                                options={productsList.map((rm) => ({
                                                    value: rm._id,
                                                    label: rm.name,
                                                }))}
                                                value={
                                                editedData?.product
                                                    ? productsList
                                                        .map((rm) => ({ value: rm._id, label: rm.name }))
                                                        .find((option) => option.value === editedData?.product)
                                                    : null
                                                }
                                                onChange={(selectedOption) => {
                                                if (typeof selectedOption === 'object' && selectedOption !== null) {
                                                    handleEditChange({ target: { name: 'product', value: selectedOption.value } });
                                                } else {
                                                    handleEditChange({ target: { name: 'product', value: '' } });
                                                } 
                                                }}
                                                classNamePrefix="react-select"
                                                isClearable
                                                placeholder="Select Product"
                                            />
                                            </div>
                                      </CCol>
                                    )}
                                        
                                        
                                </CRow>

                                {/* <CRow>
                                    <CCol className='col-md-6'>
                                        <div className="mb-3">
                                            <CFormLabel className="fw-semibold">
                                                Choose File <span className="text-danger">*</span>
                                            </CFormLabel>
                                            <CFormInput
                                                type="file"
                                                onChange={handleEditImageChange}
                                                className="form-control"
                                                id="imageInput"
                                            />
                                        </div>
                                    </CCol>
                                </CRow> */}
                            </CForm>
                        </CModalBody>
                        <CModalFooter>
                            <PrimaryButton title="SAVE CHANGES" isLoading={isLoading} />
                        </CModalFooter>
                    </CForm>
                </CModal>
            ) : null}

            {isOpenUpdateImageModal && (
                <CModal
                    alignment="center"
                    visible={isOpenUpdateImageModal}
                    onClose={() => setIsOpenUpdateImageModal(false)}
                    aria-labelledby="VerticallyCenteredExample"
                >
                    <CModalHeader>
                        <CModalTitle id="VerticallyCenteredExample" className="fs-6">
                            UPDATE ADVERTISEMENT IMAGE
                        </CModalTitle>
                    </CModalHeader>
                    <CForm onSubmit={handleEditImage}>
                        <CModalBody>
                            <CForm>
                                <CRow>
                                    <CCol>
                                        <div className="mb-3">
                                            <CFormLabel className="fw-semibold">
                                                Choose File <span className="text-danger">*</span>
                                            </CFormLabel>
                                            <CFormInput
                                                type="file"
                                                onChange={handleEditImageChange}
                                                className="form-control"
                                                id="imageInput"
                                            />
                                        </div>
                                    </CCol>
                                </CRow>
                            </CForm>
                        </CModalBody>
                        <CModalFooter>
                            <PrimaryButton title="SAVE CHANGES" isLoading={isImageUpdating} />
                        </CModalFooter>
                    </CForm>
                </CModal>
            )
            }
        </>
    );
};

export default ViewAdvertisement;
