import React, { useEffect, useState, useRef } from "react";
import axios from "../../api/axios";
import {
  CRow,
  CCol,
  CButton,
  CFormSelect,
  CTable,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
  CTableDataCell,
  CSpinner,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CBadge,
  CTableFoot,
  CFormInput,
  CFormLabel,
  CPagination,
  CPaginationItem,
} from "@coreui/react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";

const OrdersReport = () => {
  const accessToken = localStorage.getItem("authToken");
  const [ordersData, setOrdersData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
//   const [outlet, setOutlet] = useState("66ea74e12f6e11c3f2527eb7");
  const [selectedOutlet, setSelectedOutlet] = useState("");
  const [outlets, setOutlets] = useState([]); // Fetch outlets dynamically if required
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [viewDetailsModal, setViewDetailsModal] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [tableLoading, setTableLoading] = useState(false);
  const [subBtnLoading, setSubBtnLoading] = useState(false);
  const [printBtnLoading, setPrintBtnLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState('');
  const [totalRecords, setTotalRecords] = useState('');
  const [ordersGrandTotal, setOrdersGrandTotal] = useState('');
  const [recordsPerPage, setRecordsPerPage] = useState('25');
  const [errorMsg, setErrorMsg] = useState('');

  const lastPage = Math.ceil(totalRecords / recordsPerPage);

  const handlePageChange = (page) => {
    console.log("page :", page);
    
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
    getOrderData(startDate, endDate, selectedOutlet, page);
  };  

  const getOutlets = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get("outlet", { headers });

      if (response?.data?.status === true) {
        console.log("outlets :", response.data.data);
        const activeOutlets = response?.data?.data?.outlets.filter((active) => active?.status === "ACTIVE");
        setOutlets(activeOutlets);
        // setOutlets(response?.data?.data?.outlets);
      }
    } catch (error) {
      if (error?.response) {
        let errorMessage = "";
        if (error.response?.status === 406) {
          errorMessage =
            error?.response?.data?.error?.message ||
            error?.response?.data?.error;
        } else if (error.response?.status === 412) {
          errorMessage = error?.response?.data?.data?.message;
        } else if (error.response?.status === 500) {
          errorMessage = error?.response?.data?.error;
        }

        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const handleSearchOrders = () => {
    const trimmedSearchText = searchText.trim().toLowerCase(); 
    if (trimmedSearchText) {
      const filtered = ordersData.filter(order => {
        const orderNo = order.order?.order_no; // Order number (number)
        const orderSequence = order.order?.order_sequence; // Order sequence (string)
        const userName = order.order?.user?.name; // User name (string)
  
        // Convert orderNo to string for comparison
        const orderNoString = String(orderNo);
  
        // Concatenate order_sequence and order_no for combined searching
        const combinedSearchString = `${orderSequence}${orderNoString}`.toLowerCase();
  
        // Check if the combined search string includes the trimmed search text
        return combinedSearchString.includes(trimmedSearchText) || // Check combined string
               (typeof userName === 'string' && userName.toLowerCase().includes(trimmedSearchText)); // Check user name
      });
      
      setFilteredData(filtered);
    } else {
      setFilteredData(ordersData); // Reset to all data if search text is empty
    }
  };

  const handleSubmit = async() => {
    setSubBtnLoading(true);
    const adjustedStartDate = new Date(startDate.setHours(23, 59, 59, 999));
    const adjustedEndDate = new Date(endDate.setHours(23, 59, 59, 999)); 
    const formatDate = (date) => {
      return date.toISOString().split('T')[0]; // Format: '2024-07-31'
    };

    await getOrderData(adjustedStartDate, adjustedEndDate, selectedOutlet, currentPage, recordsPerPage, searchText);
    console.log("Filtered Data:" , filteredData);
    
    setSubBtnLoading(false);

  };

  const getOrderData = async (startDate, endDate, outlet, page, limit, search) => {
    try {
      setLoading(true);
      const headers = { Authorization: `Bearer ${accessToken}` };
  
      // let url = `order/getOrdersForAdmin?startDate=${startDate.toISOString().split("T")[0]}&endDate=${endDate.toISOString().split("T")[0]}&page=${page}&limit=${recordsPerPage}`;
  
      // if (outlet) {
      //   url += `&outlet=${outlet}`;
      // }
      // if (search !== '' && search !== undefined) {
      //   url += `&search=${search}`;
      // }

      let url = `order/getOrdersForAdmin?startDate=${startDate.toISOString().split("T")[0]}&endDate=${endDate.toISOString().split("T")[0]}`;

      if (search && search !== '') {
        url += `&search=${search}`;
      } else {
        // Only add pagination parameters if there's no search query
        url += `&page=${page}&limit=${recordsPerPage}`;
      }

      if (outlet) {
        url += `&outlet=${outlet}`;
      }
      const response = await axios.get(url, { headers });
      
      if (response?.data?.status === true) {
        const ordersData = response?.data?.data?.totalOrdersData || [];
        if (ordersData.length > 0) {

          setOrdersData(ordersData);
          setFilteredData(ordersData);
          setTotalPages(response?.data?.data?.paginationData?.totalPages);
          setTotalRecords(response?.data?.data?.totalOrdersCount);
          setOrdersGrandTotal(response?.data?.data?.ordersGrandTotal);
      
          toast.success("Data Fetched Successfully", {
            position: "top-right",
            autoClose: 3000,
            theme: "colored",
          });
        } else {
          toast.info("No records found.", {
            position: "top-right",
            autoClose: 3000,
            theme: "colored",
          });
        }
      } else if (response?.data?.status === false) {
        toast.info("No records available. Please try again later.", {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
        console.log("resp[onmse if false :", response?.data?.data?.message);
        setErrorMsg(response?.data?.data?.message);
        // Optionally, you can clear the data here if needed
        setOrdersData([]);
        setFilteredData([]);
        setTotalPages(0);
        setTotalRecords(0);
        setOrdersGrandTotal(0);
      }
    } catch (error) {
      console.error("Error fetching orders data:", error);
  
      if (error?.response) {
        let errorMessage = '';
        if (error.response?.status === 406) {
          errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error;
        } else if (error.response?.status === 412) {
          errorMessage = error?.response?.data?.data?.message;
        } else if (error.response?.status === 500) {
          errorMessage = error?.response?.data?.error;
        }
  
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const viewOrderDetails = (order) => {
    setSelectedOrder(order);
    setViewDetailsModal(true);
  };

  const closeModal = () => {
    setViewDetailsModal(false);
    setSelectedOrder(null);
  };

  const getAllOrderDataForReport = async (startDate, endDate, outlet) => {
    try {
      setLoading(true);
      const headers = { Authorization: `Bearer ${accessToken}` };
  
      let url = `order/getOrdersForAdmin?startDate=${startDate.toISOString().split("T")[0]}&endDate=${endDate.toISOString().split("T")[0]}&page=1&limit=10000`; // Set a high limit for all data
  
      if (outlet) {
        url += `&outlet=${outlet}`;
      }
  
      const response = await axios.get(url, { headers });
      return response?.data?.data?.totalOrdersData || []; // Return all orders data
    } catch (error) {
      console.error("Error fetching orders data for report:", error);
      return [];
    } finally {
      setLoading(false);
    }
  };
  
  const handlePrintReport = async () => {
    setPrintBtnLoading(true);
    // Fetch all order data for the report
    const allOrdersData = await getAllOrderDataForReport(startDate, endDate, selectedOutlet);
    setPrintBtnLoading(false);
  
    const grandTotal = allOrdersData
      ?.reduce((total, orderInfo) => {
        // Check if the order is not cancelled, then add its grandTotal
        return orderInfo?.order?.orderStatus !== "CANCELLED"
          ? total + (orderInfo?.order?.grandTotal || 0)
          : total;
      }, 0)
      .toFixed(2);

  
    const formatDate = (date) => {
      return new Date(date).toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      });
    };
  
    const formattedStartDate = formatDate(startDate);
    const formattedEndDate = formatDate(endDate);
  
    const tableContent = `
      <table style="border-collapse: collapse; width: 100%;">
        <thead>
          <tr>
            <th style="border: 1px solid black; padding: 8px; text-align: left;">Sr.No</th>
            <th style="border: 1px solid black; padding: 8px; text-align: left;">Order No</th>
            <th style="border: 1px solid black; padding: 8px; text-align: left; width: 120px">User Details</th>
            <th style="border: 1px solid black; padding: 8px; text-align: left;">Order Status</th>
            <th style="border: 1px solid black; padding: 8px; text-align: left;">Date & Time</th>
            <th style="border: 1px solid black; padding: 8px; text-align: left;">Coupon Status</th>
            <th style="border: 1px solid black; padding: 8px; text-align: left;">Coupon Code</th>
            <th style="border: 1px solid black; padding: 8px; text-align: left;">Total</th>
            <th style="border: 1px solid black; padding: 8px; text-align: left;">Tax Amount</th>
            <th style="border: 1px solid black; padding: 8px; text-align: left; width: 70px">Overall Total</th>
          </tr>
        </thead>
        <tbody>
          ${allOrdersData.length > 0
            ? allOrdersData.map((orderInfo, index) => {
                const userMobile = orderInfo?.order?.user?.mobile;
                const formattedMobile = userMobile.includes('_deleted_')
                  ? `${userMobile.split('_deleted_')[0]} [Deleted]`
                  : userMobile;
          
                return `
                  <tr>
                    <td style="border: 1px solid black; padding: 8px; font-size: 11px">${index + 1}</td>
                    <td style="border: 1px solid black; padding: 8px; font-size: 11px">${orderInfo?.order?.order_sequence}${orderInfo?.order?.order_no}</td>
                    <td style="border: 1px solid black; padding: 8px; font-size: 11px; word-break: break-word; width: 200px">
                      <span>${orderInfo?.order?.user?.name}</span> <br />
                      <span>${formattedMobile}</span>
                    </td>
                    <td style="border: 1px solid black; padding: 8px; font-size: 11px">${orderInfo?.order?.orderStatus}</td>
                    <td style="border: 1px solid black; padding: 8px; font-size: 11px;">
                      <span>${new Date(orderInfo?.order?.createdAt).toLocaleDateString('en-US', {
                        day: 'numeric',
                        month: 'numeric',
                        year: 'numeric',
                      })}</span> <br />
                      <span>${new Date(orderInfo?.order?.createdAt).toLocaleTimeString('en-US', {
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: true,
                      })}</span>
                    </td>
                    <td style="border: 1px solid black; padding: 8px; font-size: 11px">${orderInfo?.order?.coupon ? "Applied" : "Not Applied"}</td>
                    <td style="border: 1px solid black; padding: 8px; font-size: 11px">${orderInfo?.order?.coupon ? orderInfo?.order?.coupon?.couponCode : "-----"}</td>
                    <td style="border: 1px solid black; padding: 8px; font-size: 11px">${orderInfo?.order?.totalTaxable?.toFixed(2)}</td>
                    <td style="border: 1px solid black; padding: 8px; font-size: 11px">${orderInfo?.order?.totalTaxAmount?.toFixed(2)}</td>
                    <td style="border: 1px solid black; padding: 8px; text-align: end; font-size: 13px; width: 150px">₹. ${orderInfo?.order?.grandTotal?.toFixed(2)}</td>
                  </tr>
                `;
              }).join("") + `
              <tr style="font-weight: semibold;">
                <td colspan="9" style="border: 1px solid black; padding: 8px; text-align: right; font-size: 12px">
                  Total (Excluding Tax)
                </td>
                <td style="border: 1px solid black; padding: 8px; text-align: right; font-size: 12px">
                  ₹ ${allOrdersData
                      .filter(orderData => orderData?.order?.orderStatus !== "CANCELLED") // Exclude cancelled orders
                      .reduce((total, orderData) => total + (orderData?.order?.totalTaxable || 0), 0)
                      .toFixed(2)}
                </td>
              </tr>
              <tr style="font-weight: semibold;">
                <td colspan="9" style="border: 1px solid black; padding: 8px; text-align: right; font-size: 12px">
                  Total Tax
                </td>
                <td style="border: 1px solid black; padding: 8px; text-align: right; font-size: 12px">
                  ₹ ${allOrdersData
                      .filter(orderData => orderData?.order?.orderStatus !== "CANCELLED") // Exclude cancelled orders
                      .reduce((total, orderData) => total + (orderData?.order?.totalTaxAmount || 0), 0)
                      .toFixed(2)}
                </td>
              </tr>
              <tr style="font-weight: bold;">
                  <td colspan="9" style="border: 1px solid black; padding: 8px; text-align: right; font-size: 12px">Grand Total</td>
                  <td style="border: 1px solid black; padding: 8px; text-align: right; font-size: 12px">₹ ${grandTotal}</td>
              </tr>`
            : `<tr>
                <td colspan="10" style="border: 1px solid black; padding: 8px; text-align: center;">No Orders Found</td>
              </tr>`
          }
        </tbody>
      </table>
    `;
  
    const win = window.open("", "", "width=900,height=600");
    win.document.write(`
      <html>
        <head>
          <title>Orders Report</title>
          <style>
            table { border-collapse: collapse; width: 100%; }
            th, td { border: 1px solid black; padding: 8px; text-align: left; }
            th { background-color: #f2f2f2; }
          </style>
        </head>
        <body onload="window.print();window.close()">
          <div style="display: flex; justify-content: space-between; align-items: center">
            <h2>Orders Report</h2>
            <span>From [${formattedStartDate} to ${formattedEndDate}]</span>
          </div>
          <div>${tableContent}</div>
        </body>
      </html>
    `);
    win.document.close();
  };

   // useEffect for fetching order data
   useEffect(() => {
    const fetchOrderData = () => {
      if (searchText === "") {
        const now = new Date();
        const firstOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
        
        // Get the current date and set start and end of the day
        const currentDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());

        // Start of the current day (00:00:00.000)
        const start = new Date(currentDate.setHours(23, 59, 59, 999));

        // End of the current day (23:59:59.999)
        const end = new Date(now.setHours(23, 59, 59, 999));

        // Set the start date and end date
        setStartDate(start);
        setEndDate(end);

      }
    };

    fetchOrderData();
  }, []);

  useEffect(() => {
    getOrderData(startDate, endDate, selectedOutlet, currentPage, recordsPerPage, searchText)
  }, [recordsPerPage])
  
  useEffect(() => {
    getOutlets();
  }, []);

  return (
    <div className="container">
        <h5>ORDERS REPORT</h5>
        <CRow className="mt-3">
            <CCol md={6}>
              <CFormLabel className="fw-semibold">Outlet</CFormLabel>
              <CFormSelect
                  id="selectField"
                  name="selectField"
                  value={selectedOutlet}
                  onChange={(e) => setSelectedOutlet(e.target.value)}
              >
                  <option value="">All Outlets</option>

                  {outlets.map((outName, index) => {
                  return (
                      <option key={index} value={outName?._id}>
                      {outName?.name}
                      </option>
                  );
                  })}
              </CFormSelect>
            </CCol>

            <CCol className="col-md-2" md={2}>
              <CFormLabel className="fw-semibold">Start Date</CFormLabel>
              <ReactDatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  dateFormat="dd-MM-yyyy"  // Set the date format here
                  placeholderText="Select Start Date"
                  className="form-control"
              />
            </CCol>

            <CCol className="col-md-2" md={2}>
              <CFormLabel className="fw-semibold">End Date</CFormLabel>
              <ReactDatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  dateFormat="dd-MM-yyyy"  // Set the date format here
                  placeholderText="Select End Date"
                  className="form-control"
              />
              {error && (
                  <div className="text-danger" style={{ fontSize: "14px" }}>
                    {error}
                  </div>
              )}
            </CCol>

            <CCol xs={12} md={2}>
              <CFormLabel className="text-white">.</CFormLabel>
              <CButton
                  color="primary"
                  className="w-100"
                  onClick={handleSubmit}
                  disabled={subBtnLoading}
              >
                  {subBtnLoading ? <CSpinner size="sm" /> : "Submit"}
              </CButton>
            </CCol>
        </CRow>

        <CRow className="mt-3">
          <CCol md="5" lg="6">
            <CFormInput
              type="text"
              placeholder="Search By Name or Order Number"
              onChange={(e) => setSearchText(e.target.value)}
            />
          </CCol>
          <CCol md="3" lg="3" className="text-center">
            <CButton className="btn rounded-pill w-100" onClick={handleSubmit}>
              {subBtnLoading ? <CSpinner size="sm" /> : "Search"}
            </CButton>
          </CCol>
          <CCol md="3" lg="3" className="text-center">
            <CButton className="btn rounded-pill w-100 bg-success border-success" disabled={printBtnLoading} onClick={handlePrintReport}>
              {printBtnLoading ? <CSpinner size="sm" /> : "Print Report"}
            </CButton>
          </CCol>
        </CRow>

        <CRow className="mt-3">
          <CCol>
            {/* Pagination */}              
            <CPagination className="justify-content-end" style={{ cursor: 'pointer' }}>
                <div className='d-flex me-3' style={{ flexDirection: 'row', alignItems: 'center' }}>
                      <CFormLabel className='fw-semibold me-2'>Records Per Page :- </CFormLabel>
                      <CFormSelect
                        className="ml-3"
                        style={{ width: 'auto' }}
                        value={recordsPerPage}
                        onChange={(e) => setRecordsPerPage(e.target.value)}
                      >
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                        <option value={200}>200</option>
                      </CFormSelect>
                </div>
                <CPaginationItem
                  disabled={currentPage === 1}
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  Previous
                </CPaginationItem>
                {totalPages > 15 ? (
                  <>
                    {currentPage > 3 && <CPaginationItem onClick={() => handlePageChange(1)}>1</CPaginationItem>}
                    {currentPage > 4 && <CPaginationItem disabled>...</CPaginationItem>}

                    {Array.from({ length: 5 }, (_, i) => {
                      const pageNumber = currentPage > 3 ? currentPage - 2 + i : i + 1;
                      if (pageNumber <= totalPages) {
                        return (
                          <CPaginationItem
                            key={pageNumber}
                            active={pageNumber === currentPage}
                            onClick={() => handlePageChange(pageNumber)}
                          >
                            {pageNumber}
                          </CPaginationItem>
                        );
                      }
                      return null;
                    })}

                    {currentPage < totalPages - 3 && <CPaginationItem disabled>...</CPaginationItem>}
                    {currentPage < totalPages - 2 && <CPaginationItem onClick={() => handlePageChange(totalPages)}>{totalPages}</CPaginationItem>}
                  </>
                ) : (
                  Array.from({ length: totalPages }, (_, i) => (
                    <CPaginationItem
                      key={i + 1}
                      active={i + 1 === currentPage}
                      onClick={() => handlePageChange(i + 1)}
                    >
                      {i + 1}
                    </CPaginationItem>
                  ))
                )}
                <CPaginationItem
                  disabled={currentPage === totalPages}
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  Next
                </CPaginationItem>
            </CPagination>
            <CTable striped bordered hover responsive>
              <CTableHead>
                <CTableRow style={{ fontSize: '15px' }}>
                  <CTableHeaderCell>#</CTableHeaderCell>
                  <CTableHeaderCell>Order No</CTableHeaderCell>
                  <CTableHeaderCell>User Details</CTableHeaderCell>
                  <CTableHeaderCell style={{ width: '140px' }}>Order Status</CTableHeaderCell>
                  <CTableHeaderCell>Date & Time</CTableHeaderCell>
                  <CTableHeaderCell>Coupon Code</CTableHeaderCell>
                  <CTableHeaderCell>Total</CTableHeaderCell>
                  <CTableHeaderCell>Tax Amount</CTableHeaderCell>
                  <CTableHeaderCell>Overall Total</CTableHeaderCell>
                  <CTableHeaderCell>Actions</CTableHeaderCell>
                </CTableRow>
              </CTableHead>
                <CTableBody>
                  {loading ? (
                    <CTableRow>
                      <CTableDataCell colSpan="10" className="text-center">
                        <CSpinner color="primary" size="lg" /> {/* Display spinner */}
                      </CTableDataCell>
                    </CTableRow>
                  ) : filteredData?.length > 0 ? (
                    <>
                      {filteredData?.map((orderData, index) => (
                        <CTableRow key={index} style={{ fontSize: '15px' }}>
                          <CTableDataCell>{(currentPage - 1) * recordsPerPage + index + 1}</CTableDataCell>
                          <CTableDataCell>
                            {orderData?.order?.order_sequence}
                            {orderData?.order?.order_no}
                          </CTableDataCell>
                          <CTableDataCell style={{ width: '17%', wordBreak: "break-word" }}>
                            <span className="text-secondary fw-semibold">{orderData?.order?.user?.name}</span> <br /> <span className="text-primary">{orderData?.order?.user?.mobile}</span>
                          </CTableDataCell>
                          <CTableDataCell className="text-center">
                            <CBadge
                              className="w-75"
                              size="sm"
                              color={
                                orderData?.order?.orderStatus === "COMPLETED"
                                  ? "success"
                                  : orderData?.order?.orderStatus === "DELIVERED"
                                  ? "primary"
                                  : orderData?.order?.orderStatus === "CONFIRMED"
                                  ? "secondary"
                                  : orderData?.order?.orderStatus === "READY-TO-PICK"
                                  ? "warning"
                                  : "danger"
                              }
                            >
                              {orderData?.order?.orderStatus}
                            </CBadge>
                          </CTableDataCell>
                          <CTableDataCell>
                            <span>
                              {new Date(orderData?.order?.createdAt).toLocaleDateString('en-US', {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric',
                              })}
                            </span> <br />
                            <span>
                              {new Date(orderData?.order?.createdAt).toLocaleTimeString('en-US', {
                                hour: 'numeric',
                                minute: 'numeric',
                                hour12: true,
                              })}
                            </span>
                          </CTableDataCell>
                          <CTableDataCell className="text-center">
                            {orderData?.order?.coupon ? orderData?.order?.coupon?.couponCode : "--Not Applied--"}
                          </CTableDataCell>
                          <CTableDataCell className="text-end">
                            ₹{orderData?.order?.totalTaxable?.toFixed(2)}
                          </CTableDataCell>
                          <CTableDataCell className="text-end">
                            ₹{orderData?.order?.totalTaxAmount?.toFixed(2)}
                          </CTableDataCell>
                          <CTableDataCell className="text-end">
                            ₹{orderData?.order?.grandTotal?.toFixed(2)}
                          </CTableDataCell>
                          <CTableDataCell className="text-center">
                            <CButton
                              color="info"
                              size="sm"
                              onClick={() => viewOrderDetails(orderData)}
                            >
                              View Details
                            </CButton>
                          </CTableDataCell>
                        </CTableRow>
                      ))}

                      {/* Grand Total Row */}
                      { currentPage === lastPage && (
                        <>
                          {/* <CTableRow>
                            <CTableDataCell colSpan="8" className="text-end fw-semibold text-secondary">
                              Total (Excluding Tax):
                            </CTableDataCell>
                            <CTableDataCell className="text-end fw-semibold text-secondary">
                              ₹ { filteredData
                                  .filter(orderData => orderData?.order?.orderStatus !== "CANCELLED") 
                                  .reduce((total, orderData) => total + orderData?.order?.totalTaxable, 0)
                                  .toFixed(2)
                              }
                            </CTableDataCell>
                            <CTableDataCell></CTableDataCell>
                          </CTableRow>
                          <CTableRow>
                            <CTableDataCell colSpan="8" className="text-end fw-semibold text-secondary">
                              Total Tax:
                            </CTableDataCell>
                            <CTableDataCell className="text-end fw-semibold text-secondary">
                              ₹ { filteredData
                                  .filter(orderData => orderData?.order?.orderStatus !== "CANCELLED") 
                                  .reduce((total, orderData) => total + orderData?.order?.totalTaxAmount, 0)
                                  .toFixed(2)
                              }
                            </CTableDataCell>
                            <CTableDataCell></CTableDataCell>
                          </CTableRow> */}
                          <CTableRow>
                            <CTableDataCell colSpan="8" className="text-end fw-bold text-success">
                              Grand Total:
                            </CTableDataCell>
                            <CTableDataCell className="text-end fw-bold text-success">
                              {/* ₹{filteredData
                                .filter(orderData => orderData?.order?.orderStatus !== "CANCELLED")  // Exclude cancelled orders
                                .reduce((total, orderData) => total + orderData?.order?.grandTotal, 0)
                                .toFixed(2)
                              } */}
                              ₹ {ordersGrandTotal?.toFixed(2)}
                            </CTableDataCell>
                            <CTableDataCell></CTableDataCell>
                          </CTableRow>
                        </>
                      ) }
                    </>
                  ) : (
                    <CTableRow>
                      <CTableDataCell colSpan="10" className="text-center text-danger fw-bold">
                        { errorMsg ? errorMsg : 'Select Outlet To View Orders Details'}
                      </CTableDataCell>
                    </CTableRow>
                  )}
                </CTableBody>
            </CTable>
            {/* Pagination */}              
              <CPagination className="justify-content-end" style={{ cursor: 'pointer' }}>
                <div className='d-flex me-3' style={{ flexDirection: 'row', alignItems: 'center' }}>
                      <CFormLabel className='fw-semibold me-2'>Records Per Page :- </CFormLabel>
                      <CFormSelect
                        className="ml-3"
                        style={{ width: 'auto' }}
                        value={recordsPerPage}
                        onChange={(e) => setRecordsPerPage(e.target.value)}
                      >
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                        <option value={200}>200</option>
                      </CFormSelect>
                </div>
                <CPaginationItem
                  disabled={currentPage === 1}
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  Previous
                </CPaginationItem>
                {totalPages > 15 ? (
                  <>
                    {currentPage > 3 && <CPaginationItem onClick={() => handlePageChange(1)}>1</CPaginationItem>}
                    {currentPage > 4 && <CPaginationItem disabled>...</CPaginationItem>}

                    {Array.from({ length: 5 }, (_, i) => {
                      const pageNumber = currentPage > 3 ? currentPage - 2 + i : i + 1;
                      if (pageNumber <= totalPages) {
                        return (
                          <CPaginationItem
                            key={pageNumber}
                            active={pageNumber === currentPage}
                            onClick={() => handlePageChange(pageNumber)}
                          >
                            {pageNumber}
                          </CPaginationItem>
                        );
                      }
                      return null;
                    })}

                    {currentPage < totalPages - 3 && <CPaginationItem disabled>...</CPaginationItem>}
                    {currentPage < totalPages - 2 && <CPaginationItem onClick={() => handlePageChange(totalPages)}>{totalPages}</CPaginationItem>}
                  </>
                ) : (
                  Array.from({ length: totalPages }, (_, i) => (
                    <CPaginationItem
                      key={i + 1}
                      active={i + 1 === currentPage}
                      onClick={() => handlePageChange(i + 1)}
                    >
                      {i + 1}
                    </CPaginationItem>
                  ))
                )}
                <CPaginationItem
                  disabled={currentPage === totalPages}
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  Next
                </CPaginationItem>
              </CPagination>
          </CCol>
        </CRow>

        {/* Modal for viewing order details */}
        <CModal
          visible={viewDetailsModal}
          onClose={closeModal}
          className="modal-lg"
        >
          <CModalHeader onClose={closeModal}>
            <CModalTitle className="text-center w-100">Order Details</CModalTitle>
          </CModalHeader>
          <CModalBody>
            {selectedOrder ? (
              <div className="p-3">
                <CRow className="mb-2">
                  <CCol xs={4}>
                    <h6>Order No:</h6>
                    <p>{selectedOrder?.order?.order_sequence}{selectedOrder?.order?.order_no}</p>
                  </CCol>
                  <CCol xs={4}>
                    <h6>Order Type :</h6>
                    <p>{selectedOrder?.order?.ordersType}</p>
                  </CCol>
                  <CCol xs={4}>
                    <h6>Date & Time:</h6>
                    <p>
                      {new Date(selectedOrder?.order?.createdAt).toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                      })}{" / "}
                      {new Date(selectedOrder?.order?.createdAt).toLocaleTimeString('en-US', {
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: true,
                      })}
                    </p>
                  </CCol>
                </CRow>
                <CRow className="mb-2">
                  <CCol xs={4}>
                    <h6>User Name:</h6>
                    <p>{selectedOrder?.order?.user?.name}</p>
                  </CCol>
                  <CCol xs={4}>
                    <h6>Mobile Number:</h6>
                    <p>{selectedOrder?.order?.user?.mobile}</p>
                  </CCol>
                  <CCol xs={4}>
                    <h6>Source :</h6>
                    <p>{selectedOrder?.order?.source}</p>
                  </CCol>
                </CRow>
                <CRow className="mb-2">
                  <CCol xs={4}>
                    <h6>Status:</h6>
                    <CBadge 
                      color={
                        selectedOrder?.order?.orderStatus === "COMPLETED"
                          ? "success"
                          : selectedOrder?.order?.orderStatus === "DELIVERED"
                          ? "primary"
                          : selectedOrder?.order?.orderStatus === "CONFIRMED"
                          ? "secondary"
                          : selectedOrder?.order?.orderStatus === "READY-TO-PICK"
                          ? "warning"
                          : "danger"
                      }
                    >
                      {selectedOrder?.order?.orderStatus}
                    </CBadge>
                  </CCol>
                  <CCol xs={4}>
                      <h6>Payment Status:</h6>
                      <CBadge
                          className="w-25"
                          color={
                          selectedOrder.order?.paymentStatus === "PAID"
                              ? "success"
                              : "danger"
                          }
                      >
                      {selectedOrder?.order?.paymentStatus}
                    </CBadge>
                  </CCol>
                  <CCol xs={4}>
                    <h6>Coupon Applied :</h6>
                    <p>{selectedOrder?.order?.coupon ? "YES" : "NO"}</p>
                  </CCol>
                </CRow>
                { selectedOrder?.order?.coupon ? (
                  <CRow className="mb-2">
                    <CCol xs={4} md={4}>
                      <h6>Coupon Name :</h6>
                      <p>{selectedOrder?.order?.coupon?.title}</p>
                    </CCol>
                    <CCol xs={4} md={4}>
                      <h6>Coupon Code :</h6>
                      <p className="text-danger">{selectedOrder?.order?.coupon?.couponCode}</p>
                    </CCol>
                    <CCol xs={4} md={4}>
                      <h6>Usage Type :</h6>
                      <p>{selectedOrder?.order?.coupon?.usageType}</p>
                    </CCol>
                  </CRow>
                ) : null }
                

                <CRow className="mb-1">
                  <CCol xs={12}>
                    <h6>Ordered Items:</h6>
                    <CTable striped bordered hover responsive>
                      <CTableHead>
                        <CTableRow>
                          <CTableHeaderCell className="fw-semibold">Product</CTableHeaderCell>
                          <CTableHeaderCell className="text-center fw-semibold">Quantity</CTableHeaderCell>
                          <CTableHeaderCell className="text-center fw-semibold">Price</CTableHeaderCell>
                          {/* <CTableHeaderCell>Add-ons</CTableHeaderCell> */}
                        </CTableRow>
                      </CTableHead>
                      <CTableBody>
                          {selectedOrder?.orderItems?.map((item) => (
                              <CTableRow key={item._id}>
                              <CTableDataCell>{item.product.name}
                                {item.addons.length > 0 ? (
                                  <ul style={{ fontSize: '14px' }}>
                                      {item.addons.map((addon) => (
                                      <li key={addon._id}>
                                          {addon.selectedValue} (₹{addon.offerPrice})
                                      </li>
                                      ))}
                                  </ul>
                                  ) : (
                                  ""
                                )}
                              </CTableDataCell>
                              <CTableDataCell className="text-center">{item.quantity}</CTableDataCell>
                              <CTableDataCell className="text-end">
                                  ₹{item.rate.toFixed(2)}
                                  <ul  style={{ fontSize: '14px', listStyleType: 'none' }}>
                                      {item.addons.map((addon) => (
                                      <li key={addon._id}>
                                        + (₹{addon.offerPrice})
                                      </li>
                                      ))}
                                  </ul>
                              </CTableDataCell>
                              {/* <CTableDataCell>
                                  {item.addons.length > 0 ? (
                                  <ul>
                                      {item.addons.map((addon) => (
                                      <li key={addon._id}>
                                          {addon.selectedValue} (₹{addon.offerPrice})
                                      </li>
                                      ))}
                                  </ul>
                                  ) : (
                                  "No Add-ons"
                                  )}
                              </CTableDataCell> */}
                              </CTableRow>
                          ))}
                          {/* Other Charges */}
                          { selectedOrder?.order?.platformFees !== 0 ? (
                            <CTableRow>
                              <CTableDataCell colSpan={2} className="text-end" style={{ fontSize: '14px' }}>
                                Platform Fees:
                              </CTableDataCell>
                              <CTableDataCell style={{ fontSize: '14px' }} className="text-end">
                                ₹ {Number(selectedOrder?.order?.platformFees).toFixed(2)}
                              </CTableDataCell>
                            </CTableRow>
                          ) : null }
                          
                          { selectedOrder?.order?.packingCharges !== 0 && null ? (
                            <CTableRow>
                              <CTableDataCell colSpan={2} className="text-end" style={{ fontSize: '14px' }}>Packing Fees:</CTableDataCell>
                              <CTableDataCell style={{ fontSize: '14px' }} className="text-end">
                                ₹ {selectedOrder?.order?.packingCharges?.toFixed(2)}
                              </CTableDataCell>
                            </CTableRow>
                          ) : null }

                          { selectedOrder?.order?.deliveryCharges !== 0 && null ? (
                            <CTableRow>
                              <CTableDataCell colSpan={2} className="text-end" style={{ fontSize: '14px' }}>Delivery Charges:</CTableDataCell>
                              <CTableDataCell style={{ fontSize: '14px' }} className="text-end">
                                ₹ {selectedOrder?.order?.deliveryCharges?.toFixed(2)}
                              </CTableDataCell>
                            </CTableRow>
                          ) : null }

                          <CTableRow>
                            <CTableDataCell colSpan={2} className="text-end" style={{ fontSize: '14px' }}>Total Tax Amount:</CTableDataCell>
                            <CTableDataCell style={{ fontSize: '14px' }} className="text-end">
                              ₹ {selectedOrder?.order?.totalTaxAmount?.toFixed(2)}
                            </CTableDataCell>
                          </CTableRow>

                          <CTableRow>
                            <CTableDataCell colSpan={2} className="text-end" style={{ fontSize: '14px' }}>Total Discount:</CTableDataCell>
                            <CTableDataCell style={{ fontSize: '14px' }} className="text-end">
                              ₹ {selectedOrder?.order?.totalDiscount?.toFixed(2)}
                            </CTableDataCell>
                          </CTableRow>

                          {/* Calculate grand total */}
                          <CTableRow>
                              <CTableDataCell colSpan={2} className="text-end"><strong>Grand Total:</strong></CTableDataCell>
                              <CTableDataCell className="text-end">
                                ₹ {selectedOrder?.orderItems?.reduce((total, item) => total + item.grandTotal, 0).toFixed(2)}
                              </CTableDataCell>
                          </CTableRow>
                      </CTableBody>
                    </CTable>
                  </CCol>
                </CRow>

                <CRow className="mb-1">
                  <CCol xs={12}>
                    <h6>Order Status Log :</h6>
                    <CTable striped bordered hover responsive>
                      <CTableHead>
                        <CTableRow>
                          <CTableHeaderCell className="fw-semibold">Order Status</CTableHeaderCell>
                          <CTableHeaderCell className="fw-semibold">Time</CTableHeaderCell>
                          <CTableHeaderCell className="fw-semibold">Date</CTableHeaderCell>
                        </CTableRow>
                      </CTableHead>
                      <CTableBody>
                        {selectedOrder?.orderStatusLogs?.length > 0 ? (
                          selectedOrder.orderStatusLogs.map((log) => (
                            <CTableRow key={log._id}>
                              <CTableDataCell>
                                <CBadge
                                  color={
                                    log.orderStatus === "COMPLETED"
                                      ? "success"
                                      : log.orderStatus === "DELIVERED"
                                      ? "primary"
                                      : log.orderStatus === "CONFIRMED"
                                      ? "secondary"
                                      : log.orderStatus === "READY-TO-PICK"
                                      ? "warning"
                                      : log.orderStatus === "CANCELLED"
                                      ? "danger"
                                      : "dark"
                                  }
                                >
                                  {log.orderStatus}
                                </CBadge>
                              </CTableDataCell>
                              <CTableDataCell>
                                {new Date(log.createdAt).toLocaleTimeString('en-US', {
                                  hour: 'numeric',
                                  minute: 'numeric',
                                  hour12: true,
                                })}
                              </CTableDataCell>
                              <CTableDataCell>
                                {new Date(log.createdAt).toLocaleDateString('en-US', {
                                  year: 'numeric',
                                  month: 'long',
                                  day: 'numeric',
                                })}                                
                              </CTableDataCell>
                            </CTableRow>
                          ))
                        ) : (
                          <CTableRow>
                            <CTableDataCell colSpan={3} className="text-center">
                              No order status logs available.
                            </CTableDataCell>
                          </CTableRow>
                        )}
                      </CTableBody>
                    </CTable>
                  </CCol>
                </CRow>
              </div>
            ) : (
              <p>No order details available.</p>
            )}
          </CModalBody>
          <CModalFooter>
            <CButton color="secondary" onClick={closeModal}>
              Close
            </CButton>
          </CModalFooter>
        </CModal>
    </div>
  );
};

export default OrdersReport;
