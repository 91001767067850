import CIcon from "@coreui/icons-react";
import { CSidebar, CSidebarBrand, CSidebarNav } from "@coreui/react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import SimpleBar from "simplebar-react";

import navigation from "../_nav.js";
import AppSidebarNav from "./AppSidebarNav.jsx";

const AppSideBar = () => {
  const dispatch = useDispatch();
  const unfoldable = useSelector((state) => state.sidebarUnfoldable);
  const sidebarShow = useSelector((state) => state.sidebarShow);

  return (
    <CSidebar
      position="fixed"
      unfoldable={unfoldable}
      visible={sidebarShow}
      onVisibleChange={(visible) => {
        dispatch({ type: "set", sidebarShow: visible });
      }}
    >
      <CSidebarBrand className="d-none d-md-flex" to="/">
        <CIcon
          className="sidebar-brand-full"
          //  icon={logoNegative}
          height={35}
        />
        <CIcon
          className="sidebar-brand-narrow"
          // icon={sygnet}
          height={35}
        />
      </CSidebarBrand>
      <CSidebarNav>
        {/* <SimpleBar> */}
          <AppSidebarNav items={navigation} />
        {/* </SimpleBar> */}
      </CSidebarNav>
    </CSidebar>
  );
};

export default AppSideBar;
