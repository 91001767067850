import React, { useEffect, useState } from 'react'
import { CCard, CCardBody, CCardFooter, CCardHeader, CCardTitle, CCol, CForm, CFormInput, CFormLabel, CFormSelect, CFormTextarea, CRow } from '@coreui/react'
import Select from "react-select";
import DatePicker from "react-datepicker";
import { PrimaryButton } from '../../components';
import axios from '../../api/axios';
import { toast } from 'react-toastify';
import makeAnimated from "react-select/animated";
import { format } from 'date-fns';

const CategoryCoupon = ({ selectedOptions, setSelectedOptions, setSelectedCouponOption, outlets, selectedCategories, setSelectedCategories }) => {
    const accessToken = localStorage.getItem("authToken");
    const [categoryCouponInputData, setCategoryCouponInputData] = useState({
        isHidden: "",
        title: "",
        couponCode: "",
        description: "",
        usageType: "",
        discountPercent: "",
        discountAmount: "",
        maxDiscountAmount: "",
        validityStartDate: "",
        validityEndDate: "",
    });
    const [categoryBtnLoading, setCategoryBtnLoading] = useState(false);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [categoryList, setCategoryList] = useState([]);
    const [productList, setProductList] = useState([]);
    const [selectedExProducts, setSelectedExProducts] = useState([]);
    const animatedComponents = makeAnimated();
    const outletData = outlets.map((outlet) => ({
        value: outlet._id,
        label: `${outlet.name} (${outlet.city})`
    }));
    const productData = productList.map((prod) => ({
        value: prod._id,
        label: `${prod.name}`,
        catId: prod?.category?._id
    }))
    const filteredProductsData = productData.filter(product =>
        selectedCategories.some(category => product.catId === category.value)
    )

    const categoriesData = categoryList.map((category) => ({
        value: category._id,
        label: `${category.name}`
    }))

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            width: "100%",
            borderRadius: '6px',
            minWidth: "200px",
            borderColor: state.isFocused ? "#80bdff" : "#ced4da",
            boxShadow: state.isFocused ? "0 0 0 0.2rem rgba(0,123,255,.25)" : null,
            "&:hover": {
                borderColor: state.isFocused ? "#80bdff" : "#ced4da",
            },
        }),
        placeholder: (provided) => ({
            ...provided,
            color: "#6c757d"
        }),
    };

    const handleCategoryCouponChange = (e) => {
        const { name, value } = e.target;

        const numericFields = ["discountPercent", "discountAmount", "maxDiscountAmount"];
        if (numericFields.includes(name)) {
            const numberValue = Number(value);
            if (numberValue >= 0 || value === "") {
                setCategoryCouponInputData((prev) => ({ ...prev, [name]: value }));
            }
        } else {
            setCategoryCouponInputData((prev) => ({ ...prev, [name]: value }));
        }
    };

    const handleSelectChange = (selected) => {
        setSelectedOptions(selected);
    };

    const handleSelectCategoryChange = (cats) => {
        setSelectedCategories(cats);
    }

    const handleSelectProductChange = (ex) => {
        setSelectedExProducts(ex);
    }

    const handleFromDateChange = (date) => {
        if (date) {
            setFromDate(date);
        } else {
            console.error("Received null or undefined date");
        }
    };

    const handleToDateChange = (date) => {
        if (date) {
            setToDate(date);
        } else {
            console.error("Received null or undefined date");
        }
    };

    const getCategoryList = async () => {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };
            const response = await axios.get("masters/category/get", { headers });

            if (response?.data?.status === true) {
                setCategoryList(response?.data?.data?.categories);
            }
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
    };

    const getProductsList = async () => {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };
            const response = await axios.get("product", { headers });
            if (response?.data?.status === true) {
                setProductList(response?.data?.data?.products);
            }
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
    };

    const submitCategoryCouponDetails = async (e) => {
        e.preventDefault();
        setCategoryBtnLoading(true);
        if (categoryCouponInputData?.discountAmount) {
            delete categoryCouponInputData?.discountPercent;
            delete categoryCouponInputData?.maxDiscountAmount;
        } else if (categoryCouponInputData?.discountPercent) {
            delete categoryCouponInputData?.discountAmount;
        }
        let selectedOutlet = selectedOptions.map((option) => option.value);
        let selectedCategoryInfo = selectedCategories.map((c) => c.value);
        let selectedExcludedProducts = selectedExProducts.map((ex) => ex.value);
        let newcategory = {
            ...categoryCouponInputData,
            excludedOutlets: selectedOutlet,
            applicableCategories: selectedCategoryInfo,
            excludedProductList: selectedExcludedProducts
        }

        if (newcategory?.excludedOutlets?.length === 0) {
            delete newcategory?.excludedOutlets
        }

        if (newcategory?.validityStartDate === "") {
            delete newcategory.validityStartDate
        }
        if (newcategory?.validityEndDate === "") {
            delete newcategory.validityEndDate
        }

        if (newcategory?.maxDiscountAmount === "") {
            delete newcategory?.maxDiscountAmount
        }

        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };

            const response = await axios.post("coupons", newcategory, {
                headers,
            });

            if (response?.data?.status === true) {
                toast.success(response?.data?.data?.message, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
                e.target.reset()
                setSelectedOptions([])
                setSelectedCouponOption("");
                setCategoryCouponInputData({
                    isHidden: "",
                    title: "",
                    couponCode: "",
                    description: "",
                    usageType: "",
                    discountPercent: "",
                    discountAmount: "",
                    applicableCategory: "",
                    validityStartDate: "",
                    validityEndDate: "",
                });
                setFromDate(null);
                setToDate(null);
            }
        } catch (error) {
            if (error?.response) {
                let errorMessage = '';
                if (
                    error.response?.status === 406
                ) {
                    errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
                }
                else if (
                    error.response?.status === 412
                ) {
                    errorMessage = error?.response?.data?.data?.title;
                } else if (error.response?.status === 500) {
                    errorMessage = error?.response?.data?.error
                }

                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        } finally {
            setCategoryBtnLoading(false);
        }
    };

    const categoryBtnDisable = () => {
        let isDisable = false;

        if (
            !categoryCouponInputData?.title?.trim() ||
            !categoryCouponInputData?.couponCode?.trim() ||
            !categoryCouponInputData?.description?.trim() ||
            !categoryCouponInputData?.usageType ||
            !selectedCategories
        ) {
            isDisable = true;
        }

        return isDisable;
    };

    useEffect(() => {
        getCategoryList();
        getProductsList();
    }, [])

    useEffect(() => {
        const formattedStartDate = fromDate ? format(fromDate, "yyyy-MM-dd") : "";
        const formattedEndDate = toDate ? format(toDate, "yyyy-MM-dd") : "";

        setCategoryCouponInputData((prev) => ({
            ...prev,
            validityStartDate: formattedStartDate,
            validityEndDate: formattedEndDate,
        }));
    }, [fromDate, toDate]);

    return (
        <CCard>
            <CCardHeader>
                <CCardTitle className="fs-6">
                    ADD CATEGORY COUPON
                </CCardTitle>
            </CCardHeader>
            <CForm onSubmit={submitCategoryCouponDetails}>
                <CCardBody>
                    <CRow className="mb-3">
                        <CCol className="col-md-4">
                            <div>
                                <CFormLabel className="fw-semibold">
                                    Title <span className="text-danger">*</span>
                                </CFormLabel>
                                <CFormInput
                                    placeholder="Title"
                                    name="title"
                                    value={categoryCouponInputData.title}
                                    onChange={handleCategoryCouponChange}
                                />
                            </div>
                        </CCol>
                        <CCol className="col-md-4">
                            <div>
                                <CFormLabel className="fw-semibold">
                                    Coupon Code <span className="text-danger">*</span>
                                </CFormLabel>
                                <CFormInput
                                    placeholder="Coupon Code"
                                    name="couponCode"
                                    value={categoryCouponInputData.couponCode}
                                    onChange={handleCategoryCouponChange}
                                />
                            </div>
                        </CCol>
                        <CCol className="col-md-4">
                            <CFormLabel className="fw-semibold">
                                Usage Type <span className="text-danger">*</span>
                            </CFormLabel>
                            <CFormSelect
                                name="usageType"
                                value={categoryCouponInputData.usageType}
                                onChange={handleCategoryCouponChange}
                            >
                                <option value="">Select Usage</option>
                                <option value="MULTIPLE">MULTIPLE</option>
                                <option value="ONE-TIME">ONE-TIME</option>
                            </CFormSelect>
                        </CCol>

                    </CRow>

                    <CRow className="mb-3">
                        <CCol className="col-md-6">
                            <CFormLabel className="fw-semibold">
                                Select Categories <span className="text-danger">*</span>
                            </CFormLabel>
                            {/* <CFormSelect
                                name="applicableCategory"
                                value={categoryCouponInputData.applicableCategory}
                                onChange={handleCategoryCouponChange}
                            >
                                <option value="">Select Category</option>
                                {categoryList.map((rm) => (
                                    <option key={rm._id} value={rm._id}>
                                        {rm.name}
                                    </option>
                                ))}
                            </CFormSelect> */}
                            <Select
                                isMulti
                                components={animatedComponents}
                                options={categoriesData}
                                value={selectedCategories}
                                onChange={handleSelectCategoryChange}
                                placeholder="Select Categories"
                                closeMenuOnSelect={false}
                                styles={customStyles}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        primary25: "#e9ecef",
                                        primary: "#007bff",
                                    },
                                })}
                            />
                        </CCol>
                        <CCol className="col-md-6">
                            <CFormLabel className="fw-semibold">
                                Select Excluded Products
                            </CFormLabel>
                            <Select
                                isMulti
                                components={animatedComponents}
                                options={filteredProductsData}
                                value={selectedExProducts}
                                onChange={handleSelectProductChange}
                                placeholder="Selected Excluded Products"
                                closeMenuOnSelect={false}
                                styles={customStyles}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        primary25: "#e9ecef",
                                        primary: "#007bff",
                                    },
                                })}
                            />
                        </CCol>
                    </CRow>

                    <div className='mb-3'>
                        <CFormLabel className="fw-semibold">
                            Select Excluded Outlets
                        </CFormLabel>
                        <Select
                            isMulti
                            components={animatedComponents}
                            value={selectedOptions}
                            onChange={handleSelectChange}
                            options={outletData}
                            styles={customStyles}
                            placeholder="Select Excluded Outlets"
                            closeMenuOnSelect={false}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                    ...theme.colors,
                                    primary25: "#e9ecef",
                                    primary: "#007bff",
                                },
                            })}
                        />
                    </div>

                    <div className="mb-3">
                        <CFormLabel className="fw-semibold">
                            Description <span className="text-danger">*</span>
                        </CFormLabel>
                        <CFormTextarea
                            rows={4}
                            placeholder="Description"
                            name="description"
                            value={categoryCouponInputData.description}
                            onChange={handleCategoryCouponChange}
                        />
                    </div>

                    <CRow className="mb-3">
                        <CCol>
                            <div>
                                <CFormLabel className="fw-semibold">
                                    Hide Coupon
                                </CFormLabel>
                                <CFormSelect
                                    name="isHidden"
                                    value={categoryCouponInputData.isHidden}
                                    onChange={handleCategoryCouponChange}
                                >
                                    <option value="">Select Option</option>
                                    <option value="true">YES</option>
                                    <option value="false">NO</option>
                                </CFormSelect>
                            </div>
                        </CCol>
                        <CCol>
                            <div>
                                <CFormLabel className="fw-semibold">
                                    Validity Start Date
                                </CFormLabel>
                                <DatePicker
                                    selected={fromDate}
                                    onChange={handleFromDateChange}
                                    placeholderText="Start Date"
                                    className="form-control"
                                />
                            </div>
                        </CCol>
                        <CCol>
                            <div>
                                <CFormLabel className="fw-semibold">
                                    Validity End Date
                                </CFormLabel>
                                <DatePicker
                                    selected={toDate}
                                    onChange={handleToDateChange}
                                    placeholderText="End Date"
                                    className="form-control"
                                />
                            </div>
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol>
                            <CFormLabel className="fw-semibold">
                                Discount Percent <span className="text-danger">*</span>
                            </CFormLabel>
                            <CFormInput
                                min={0}
                                max={100}
                                disabled={
                                    categoryCouponInputData?.discountAmount
                                }
                                placeholder="Discount Percent"
                                type="number"
                                className="no-arrows"
                                name="discountPercent"
                                value={categoryCouponInputData.discountPercent}
                                onChange={handleCategoryCouponChange}
                            />
                        </CCol>
                        <CCol>
                            <CFormLabel className="fw-semibold">
                                Discount Amount <span className="text-danger">*</span>
                            </CFormLabel>
                            <CFormInput
                                disabled={
                                    categoryCouponInputData?.discountPercent
                                }
                                placeholder="Discount Amount"
                                type="number"
                                className="no-arrows"
                                name="discountAmount"
                                value={categoryCouponInputData.discountAmount}
                                onChange={handleCategoryCouponChange}
                            />
                        </CCol>
                        <CCol>
                            <CFormLabel className="fw-semibold">
                                Max. Dis Amount
                            </CFormLabel>
                            <CFormInput
                                disabled={
                                    categoryCouponInputData?.discountAmount
                                }
                                placeholder="Max Discount Amount"
                                type="number"
                                className="no-arrows"
                                name="maxDiscountAmount"
                                value={categoryCouponInputData.maxDiscountAmount}
                                onChange={handleCategoryCouponChange}
                            />
                        </CCol>
                    </CRow>
                </CCardBody>
                <CCardFooter>
                    <PrimaryButton
                        isDisable={categoryBtnDisable()}
                        isLoading={categoryBtnLoading}
                        title="ADD CATEGORY COUPON"
                    />
                </CCardFooter>
            </CForm>
        </CCard>
    )
}

export default CategoryCoupon
