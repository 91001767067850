import React, { useEffect, useState } from 'react'
import axios from '../../api/axios';
import { toast } from 'react-toastify';
import { CBadge, CButton, CCol, CContainer, CForm, CFormLabel, CFormSelect, CImage, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle, CRow, CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow } from '@coreui/react';
import { DeleteModal, PrimaryButton } from '../../components';
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { Button, Image } from 'react-bootstrap';

const ViewLevelRewards = () => {
    const accessToken = localStorage.getItem("authToken");
    const [rewardsData, setRewardsData] = useState([]);
    const [secondRewardsData, setSecondRewardsData] = useState([]);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [isDeleteBtnLoading, setIsDeleteBtnLoading] = useState(false);
    const [levelRewardId, setLevelRewardId] = useState("");
    const [openEditModal, setOpenEditModal] = useState(false);
    const [isEditBtnLoading, setIsEditBtnLoading] = useState(false);
    const [singleLevelRewardData, setSingleLevelRewardData] = useState({});
    const [editBirthDayCouponInfo, setEditBirthDayCouponInfo] = useState({});
    const [levels, setLevels] = useState([]);
    const [couponsData, setCouponsData] = useState([]);
    const [filteredLevels, setFilteredLevels] = useState([]);
    const [editBOGODetails, setEditBOGODetails] = useState({});
    const [editSizeUpgradeDetails, setEditSizeUpgradeDetails] = useState({});
    const [editPointBoosterDetails, setEditPointBoosterDetails] = useState({});
    const [selectedOptions, setSelectedOptions] = useState([]);
    const animatedComponents = makeAnimated();
    const [openViewModal, setOpenViewModal] = useState(false);
    const [birthDayCouponData, setBirthDayCouponData] = useState({});
    const [bogoCouponData, setBogoCouponData] = useState([]);

    const fetchBirthdayCouponData = async () => {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };
            const response = await axios.get("settings", { headers });
            if (response?.data?.status === true) {
                const coupon = response?.data?.data?.settings?.couponInfo?.coupon;
                setBirthDayCouponData(coupon)
            }
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
    };

    const fetchBOGOCouponData = async () => {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };
            const response = await axios.get("coupons", { headers });
            if (response?.data?.status === true) {
                const coupon = response?.data?.data?.coupons;
                const bogoCoupons = coupon.filter((coupon) => coupon.isBogoCoupon === true)
                setBogoCouponData(bogoCoupons)
            }
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
    };

    const fetchLevelsData = async () => {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };
            const response = await axios.get("level", { headers });
            if (response?.data?.status === true) {
                const levels = response?.data?.data?.levels;
                setLevels(levels)
                filterLevels(levels)
            }
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
    };

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            width: "100%", // Adjust width based on your requirement
            borderRadius: '6px',
            minWidth: "200px", // Add a minimum width to avoid layout issues
            borderColor: state.isFocused ? "#80bdff" : "#ced4da",
            boxShadow: state.isFocused ? "0 0 0 0.2rem rgba(0,123,255,.25)" : null,
            "&:hover": {
                borderColor: state.isFocused ? "#80bdff" : "#ced4da",
            },
        }),
        placeholder: (provided) => ({
            ...provided,
            color: "#6c757d"
        }),
    };

    const handleFilterText = (e) => {
        const filterValue = e?.target?.value || "";

        // If "ALL" (empty value) is selected, show all rewardsData
        if (filterValue === "") {
            setSecondRewardsData(rewardsData);
            return;
        }

        // Otherwise, filter based on the selected sequenceNo
        const filteredData = rewardsData?.filter((item) => item?.level?.sequenceNo === parseInt(filterValue));

        if (filteredData?.length > 0) {
            setSecondRewardsData(filteredData);
        } else {
            setSecondRewardsData(rewardsData);
        }
    };


    const couponsList = couponsData
        .filter(item => item.sizeUpgradeAddon !== null && item.sizeUpgradeAddon !== undefined)
        .map(item => ({
            value: item._id,
            label: `${item.title} (${item.couponCode})`
        }));

    const handleSelectChange = (selected) => {
        setSelectedOptions(selected);
    };

    const fetchCouponData = async () => {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };
            const response = await axios.get("coupons", { headers });

            if (response?.data?.status === true) {
                setCouponsData(response?.data?.data?.coupons);
            }
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
    };

    const filterLevels = (levels) => {
        const level = levels?.filter((item) => item.title !== "Level 1");
        setFilteredLevels(level)
    }

    const fetchRewardsData = async () => {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };
            const response = await axios.get("level-reward", { headers });

            if (response?.data?.status === true) {
                const levelRewards = response?.data?.data?.levelRewards
                setRewardsData(levelRewards)
                setSecondRewardsData(levelRewards)
            }
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
    }

    const handleDeleteModal = (data) => {
        setOpenDeleteModal(true)
        setLevelRewardId(data?._id)
    }

    const deleteLevelReward = async (e) => {
        e.preventDefault();
        setIsDeleteBtnLoading(true);
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };

            const data = {
                _id: levelRewardId
            }

            const response = await axios.delete(`level-reward`, { data, headers });
            if (response?.data?.status === true) {
                toast.success(response?.data?.data?.message, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
                fetchRewardsData();
                setOpenDeleteModal(false);
            }
        } catch (error) {
            if (error.response.status === 403) {
                toast.error(error?.response?.data?.message, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }

            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        } finally {
            setIsDeleteBtnLoading(false);
        }
    }

    const handleOpenEditModal = (rewardId) => {
        setOpenEditModal(true);
        fetchSingleLevelRewardDetails(rewardId)
    }

    const fetchSingleLevelRewardDetails = async (id) => {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };
            const response = await axios.get(`level-reward?_id=${id}`, { headers });

            if (response?.data?.status === true) {
                const levelRewards = response?.data?.data?.levelRewards;
                setSingleLevelRewardData(levelRewards)
                if (levelRewards?.applyingConditions === null) {
                    setEditBirthDayCouponInfo({
                        _id: levelRewards?._id,
                        level: levelRewards?.level?._id,
                        type: levelRewards?.type,
                        couponData: levelRewards?.couponInfo?.couponData?._id
                    })
                }

                if (levelRewards?.couponInfo?.couponData?.isBogoCoupon === true) {
                    setEditBOGODetails({
                        _id: levelRewards?._id,
                        level: levelRewards?.level?._id,
                        type: levelRewards?.type,
                        couponData: levelRewards?.couponInfo?.couponData?._id,
                        interval: levelRewards?.applyingConditions?.interval,
                        value: levelRewards?.applyingConditions?.value,
                        numberOfTimes: levelRewards?.applyingConditions?.numberOfTimes
                    })
                }

                if (levelRewards?.rewardType !== undefined || levelRewards?.rewardType !== "") {
                    setEditPointBoosterDetails({
                        _id: levelRewards?._id,
                        level: levelRewards?.level?._id,
                        type: levelRewards?.type,
                        rewardType: levelRewards?.rewardType,
                        interval: levelRewards?.applyingConditions?.interval,
                        value: levelRewards?.applyingConditions?.value,
                        numberOfTimes: levelRewards?.applyingConditions?.numberOfTimes
                    })
                }

                if (levelRewards?.rewardType !== undefined || levelRewards?.rewardType === "") {
                    setEditSizeUpgradeDetails({
                        _id: levelRewards?._id,
                        level: levelRewards?.level?._id,
                        type: levelRewards?.type,
                        interval: levelRewards?.applyingConditions?.interval,
                        value: levelRewards?.applyingConditions?.value,
                        numberOfTimes: levelRewards?.applyingConditions?.numberOfTimes
                    })
                    const couponsData = levelRewards?.couponInfo?.couponData?.map((item) => ({
                        value: item._id,
                        label: `${item.title} (${item.couponCode})`
                    }))

                    setSelectedOptions(couponsData)
                }
            }
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
    }

    const handleEditBirthDayRewardChange = (e) => {
        const { name, value } = e.target;
        setEditBirthDayCouponInfo((prev) => ({ ...prev, [name]: value }))
    }

    const editBirthDaySubmit = async (e) => {
        e.preventDefault();
        setIsEditBtnLoading(true);
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };

            if (editBirthDayCouponInfo?.type === "REWARD") {
                delete editBirthDayCouponInfo?.couponData
            }

            const response = await axios.put("level-reward", editBirthDayCouponInfo, { headers });
            if (response?.data?.status === true) {
                toast.success(response?.data?.data?.message, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
                setOpenEditModal(false);
            }
        } catch (error) {
            if (error?.response) {
                let errorMessage = '';
                if (
                    error.response?.status === 406
                ) {
                    errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
                }
                else if (
                    error.response?.status === 412
                ) {
                    errorMessage = error?.response?.data?.data?.message;
                } else if (error.response?.status === 500) {
                    errorMessage = error?.response?.data?.error
                }

                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        } finally {
            setIsEditBtnLoading(false);
        }
    };

    const handleEditBOGORewardChange = (e) => {
        const { name, value } = e.target;
        if (name === "type" && value === "REWARD") {
            setEditBOGODetails((prev) => ({ ...prev, couponsData: "" }))
        }
        setEditBOGODetails((prev) => ({ ...prev, [name]: value }));
    }

    const editBOGOSubmit = async (e) => {
        e.preventDefault();
        setIsEditBtnLoading(true);
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };

            let data = {
                _id: editBOGODetails?._id,
                level: editBOGODetails?.level,
                type: editBOGODetails?.type,
                couponData: editBOGODetails?.couponData,
                applyingConditions: {
                    interval: editBOGODetails?.interval,
                    value: Number(editBOGODetails?.value),
                    numberOfTimes: Number(editBOGODetails?.numberOfTimes)
                }
            }

            if (data?.type === "REWARD") {
                delete data?.couponData
            }

            if (data?.applyingConditions?.value === "null") {
                delete data?.applyingConditions?.value
            }

            const response = await axios.put("level-reward", data, { headers });
            if (response?.data?.status === true) {
                toast.success(response?.data?.data?.message, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
                setOpenEditModal(false);
            }
        } catch (error) {
            if (error?.response) {
                let errorMessage = '';
                if (
                    error.response?.status === 406
                ) {
                    errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
                }
                else if (
                    error.response?.status === 412
                ) {
                    errorMessage = error?.response?.data?.data?.message;
                } else if (error.response?.status === 500) {
                    errorMessage = error?.response?.data?.error
                }

                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        } finally {
            setIsEditBtnLoading(false);
        }
    };

    const handleEditPointBoosterRewardChange = (e) => {
        const { name, value } = e.target;
        setEditPointBoosterDetails((prev) => ({ ...prev, [name]: value }))
    }

    const editPointBoosterSubmit = async (e) => {
        e.preventDefault();
        setIsEditBtnLoading(true);
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };

            let data = {
                _id: editPointBoosterDetails?._id,
                level: editPointBoosterDetails?.level,
                type: editPointBoosterDetails?.type,
                rewardType: editPointBoosterDetails?.rewardType,
                applyingConditions: {
                    interval: editPointBoosterDetails?.interval,
                    value: Number(editPointBoosterDetails?.value)
                }
            }

            const response = await axios.put("level-reward", data, { headers });
            if (response?.data?.status === true) {
                toast.success(response?.data?.data?.message, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
                setOpenEditModal(false);
            }
        } catch (error) {
            if (error?.response) {
                let errorMessage = '';
                if (
                    error.response?.status === 406
                ) {
                    errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
                }
                else if (
                    error.response?.status === 412
                ) {
                    errorMessage = error?.response?.data?.data?.message;
                } else if (error.response?.status === 500) {
                    errorMessage = error?.response?.data?.error
                }

                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        } finally {
            setIsEditBtnLoading(false);
        }
    };

    const handleEditSizeUpgradeChange = (e) => {
        const { name, value } = e.target
        setEditSizeUpgradeDetails((prev) => ({ ...prev, [name]: value }))
    }

    const editSizeUpgradeSubmit = async (e) => {
        e.preventDefault();
        setIsEditBtnLoading(true);
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };

            const selectedCoupons = selectedOptions.map((option) => option.value);

            let data = {
                _id: editSizeUpgradeDetails?._id,
                level: editSizeUpgradeDetails?.level,
                type: editSizeUpgradeDetails?.type,
                couponData: selectedCoupons,
                applyingConditions: {
                    interval: editSizeUpgradeDetails?.interval,
                    value: Number(editSizeUpgradeDetails?.value),
                    numberOfTimes: Number(editSizeUpgradeDetails?.numberOfTimes)
                }
            }

            const response = await axios.put("level-reward", data, { headers });
            if (response?.data?.status === true) {
                toast.success(response?.data?.data?.message, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
                setOpenEditModal(false);
            }
        } catch (error) {
            if (error?.response) {
                let errorMessage = '';
                if (
                    error.response?.status === 406
                ) {
                    errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
                }
                else if (
                    error.response?.status === 412
                ) {
                    errorMessage = error?.response?.data?.data?.message;
                } else if (error.response?.status === 500) {
                    errorMessage = error?.response?.data?.error
                }

                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        } finally {
            setIsEditBtnLoading(false);
        }
    };

    const handleOpenViewModal = (rewardId) => {
        setOpenViewModal(true)
        fetchSingleLevelRewardDetails(rewardId)
    }

    const findDay = (num) => {
        let day = num === 0 ? "SUNDAY" : num === 1 ? "MONDAY" : num === 2 ? "TUESDAY" : num === 3 ? "WEDNESDAY" : num === 4 ? "THURSDAY" : num === 5 ? "FRIDAY" : "SATURDAY"

        return day;
    }

    const handleCloseDeleteModal = () => {
        setOpenDeleteModal(false)
    }

    useEffect(() => {
        fetchRewardsData();
        fetchCouponData()
        fetchLevelsData();
        fetchBirthdayCouponData();
        fetchBOGOCouponData()
    }, [])

    useEffect(() => {
        handleFilterText()
    }, [rewardsData])

    return (
        <CContainer>
            <CRow>
                <div className='d-flex justify-content-end'>
                    <div className='w-25'>
                        <CFormLabel className='fw-semibold'>Filter By Level:</CFormLabel>
                        <CFormSelect onChange={handleFilterText}>
                            <option value="">ALL</option>
                            {levels.map((level) => <option value={level?.sequenceNo}>{level.title}</option>)}
                        </CFormSelect>
                    </div>
                </div>
                {
                    secondRewardsData && secondRewardsData?.map((reward) => (
                        // <CCol sm="4" md="12" lg="6" className="mt-4 ml-md-auto">
                        //     <div className="coupon-card">
                        //         <div className="coupon-header-reward">
                        //             <h5>{reward?.level?.title}</h5>
                        //         </div>
                        //         <div className="coupon-body">
                        //             <div>
                        //                 <Image src={reward?.level?.bannerImage} alt='banner-image' fluid className='w-100' />
                        //             </div>
                        //             <h5>{reward?.applyingConditions === null ? "BIRTHDAY REWARD" : reward?.couponInfo?.couponData?.isBogoCoupon === true ? "BOGO REWARD" : reward?.couponInfo?.isSizeUpgradeCoupon === true ? "SIZE UPGRADE REWARD" : "BOOSTER POINT REWARD"}</h5>
                        //         </div>
                        //         <div className="coupon-footer">
                        //             <button className="redeem-button details-button" onClick={() => { handleOpenViewModal(reward?._id) }}>Details</button>
                        //             <button className="redeem-button modify-button" onClick={() => handleOpenEditModal(reward?._id)}>Update</button>
                        //             <button className="redeem-button remove-button" onClick={() => handleDeleteModal(reward)}>Remove</button>
                        //         </div>
                        //     </div>
                        // </CCol>
                        <CCol sm="12" md="6" lg="4" className="mt-4 ml-md-auto mb-2">
                            <div style={{
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                borderRadius: '12px',
                                overflow: 'hidden',
                                transition: 'transform 0.3s ease-in-out'
                            }}
                                onMouseEnter={e => e.currentTarget.style.transform = 'translateY(-5px)'}
                                onMouseLeave={e => e.currentTarget.style.transform = 'translateY(0)'}
                            >
                                <div style={{
                                    backgroundColor: '#007bff',
                                    color: 'white',
                                    padding: '10px 15px',
                                    borderBottom: '1px solid #0056b3'
                                }}
                                >
                                    <h5 style={{ margin: 0 }}>{reward?.level?.title}</h5>
                                </div>
                                <div style={{ padding: '15px' }}>
                                    <div style={{ marginBottom: '15px' }}>
                                        <Image src={reward?.level?.bannerImage} alt='banner-image' fluid style={{ width: '100%', borderRadius: '8px' }} />
                                    </div>
                                    <h5 style={{ textAlign: 'center', fontWeight: 'bold' }}>
                                        {reward?.applyingConditions === null ? "BIRTHDAY REWARD"
                                            : reward?.couponInfo?.couponData?.isBogoCoupon ? "BOGO REWARD"
                                                : reward?.couponInfo?.isSizeUpgradeCoupon ? "SIZE UPGRADE REWARD"
                                                    : "BOOSTER POINT REWARD"}
                                    </h5>
                                </div>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    padding: '15px'
                                }}
                                >
                                    <button style={{
                                        // backgroundColor: '#28a745',
                                        backgroundColor: 'yellowgreen',
                                        color: 'white',
                                        padding: '8px 16px',
                                        border: 'none',
                                        borderRadius: '14px',
                                        cursor: 'pointer',
                                        transition: 'background-color 0.3s'
                                    }}
                                        onMouseEnter={e => e.currentTarget.style.backgroundColor = '#218838'}
                                        onMouseLeave={e => e.currentTarget.style.backgroundColor = '#28a745'}
                                        onClick={() => handleOpenViewModal(reward?._id)}
                                    >
                                        Details
                                    </button>
                                    <button style={{
                                        backgroundColor: '#007bff',
                                        color: 'white',
                                        padding: '8px 16px',
                                        border: 'none',
                                        borderRadius: '14px',
                                        cursor: 'pointer',
                                        transition: 'background-color 0.3s'
                                    }}
                                        onMouseEnter={e => e.currentTarget.style.backgroundColor = '#0056b3'}
                                        onMouseLeave={e => e.currentTarget.style.backgroundColor = '#007bff'}
                                        onClick={() => handleOpenEditModal(reward?._id)}
                                    >
                                        Update
                                    </button>
                                    <button style={{
                                        backgroundColor: '#dc3545',
                                        color: 'white',
                                        padding: '8px 16px',
                                        border: 'none',
                                        borderRadius: '14px',
                                        cursor: 'pointer',
                                        transition: 'background-color 0.3s'
                                    }}
                                        onMouseEnter={e => e.currentTarget.style.backgroundColor = '#c82333'}
                                        onMouseLeave={e => e.currentTarget.style.backgroundColor = '#dc3545'}
                                        onClick={() => handleDeleteModal(reward)}
                                    >
                                        Remove
                                    </button>
                                </div>
                            </div>
                        </CCol>
                    ))
                }
            </CRow>

            {openDeleteModal ? (
                <DeleteModal isVisible={openDeleteModal} onClose={handleCloseDeleteModal} onConfirm={deleteLevelReward} isLoading={isDeleteBtnLoading} title="DELETE LEVEL REWARD" />
            ) : null}

            {openEditModal ? (
                <CModal
                    alignment="center"
                    visible={openEditModal}
                    onClose={() => setOpenEditModal(false)}
                    aria-labelledby="VerticallyCenteredExample"
                    size='lg'
                >
                    <CModalHeader>
                        <CModalTitle id="VerticallyCenteredExample" className="fs-6">
                            EDIT LEVEL REWARD
                        </CModalTitle>
                    </CModalHeader>
                    {
                        singleLevelRewardData && (singleLevelRewardData?.applyingConditions === null || singleLevelRewardData?.applyingConditions === undefined) && (
                            <CForm onSubmit={editBirthDaySubmit}>
                                <CModalBody>
                                    <CRow>
                                        <CCol>
                                            <div className="mb-3">
                                                <CFormLabel className="fw-semibold">
                                                    Select Level
                                                    <span className="text-danger">*</span>
                                                </CFormLabel>
                                                <CFormSelect
                                                    size="sm"
                                                    onChange={handleEditBirthDayRewardChange}
                                                    name="level"
                                                    className="mb-3"
                                                    aria-label="Small select example"
                                                    value={editBirthDayCouponInfo?.level}
                                                >
                                                    <option value="">Select Level</option>
                                                    {levels.map((level) => (
                                                        <option key={level._id} value={level._id}>
                                                            {level.title}
                                                        </option>
                                                    ))}
                                                </CFormSelect>
                                            </div>
                                        </CCol>
                                        <CCol>
                                            <div className="mb-3">
                                                <CFormLabel className="fw-semibold">
                                                    Select Type
                                                    <span className="text-danger">*</span>
                                                </CFormLabel>
                                                <CFormSelect
                                                    size="sm"
                                                    onChange={handleEditBirthDayRewardChange}
                                                    name="type"
                                                    className="mb-3"
                                                    aria-label="Small select example"
                                                    value={editBirthDayCouponInfo?.type}
                                                >
                                                    <option value="">Select Type</option>
                                                    <option value="COUPON">COUPON</option>
                                                    <option value="REWARD">REWARD</option>
                                                </CFormSelect>
                                            </div>
                                        </CCol>

                                        <CCol>
                                            <div className="mb-3">
                                                <CFormLabel className="fw-semibold">
                                                    Select Coupon
                                                    <span className="text-danger">*</span>
                                                </CFormLabel>
                                                <CFormSelect
                                                    size="sm"
                                                    onChange={handleEditBirthDayRewardChange}
                                                    name="couponData"
                                                    className="mb-3"
                                                    aria-label="Small select example"
                                                    value={editBirthDayCouponInfo?.couponData}
                                                    disabled={editBirthDayCouponInfo?.type === "REWARD"}
                                                >
                                                    <option value="">Select Coupon</option>
                                                    <option value={birthDayCouponData?._id}>{birthDayCouponData?.title}</option>
                                                </CFormSelect>
                                            </div>
                                        </CCol>
                                    </CRow>
                                </CModalBody>
                                <CModalFooter>
                                    <PrimaryButton
                                        title="SAVE CHANGES"
                                        isLoading={isEditBtnLoading}
                                    />
                                </CModalFooter>
                            </CForm>
                        )
                    }

                    {
                        singleLevelRewardData && singleLevelRewardData?.couponInfo?.couponData?.isBogoCoupon === true && (
                            <CForm onSubmit={editBOGOSubmit}>
                                <CModalBody>
                                    <CRow>
                                        <CCol>
                                            <div className="mb-3">
                                                <CFormLabel className="fw-semibold">
                                                    Select Level
                                                    <span className="text-danger">*</span>
                                                </CFormLabel>
                                                <CFormSelect
                                                    size="sm"
                                                    onChange={handleEditBOGORewardChange}
                                                    name="level"
                                                    className="mb-3"
                                                    aria-label="Small select example"
                                                    value={editBOGODetails?.level}
                                                >
                                                    <option value="">Select Level</option>
                                                    {filteredLevels?.map((level) => (
                                                        <option key={level._id} value={level._id}>
                                                            {level.title}
                                                        </option>
                                                    ))}
                                                </CFormSelect>
                                            </div>
                                        </CCol>
                                        <CCol>
                                            <div className="mb-3">
                                                <CFormLabel className="fw-semibold">
                                                    Select Type
                                                    <span className="text-danger">*</span>
                                                </CFormLabel>
                                                <CFormSelect
                                                    size="sm"
                                                    onChange={handleEditBOGORewardChange}
                                                    name="type"
                                                    className="mb-3"
                                                    aria-label="Small select example"
                                                    value={editBOGODetails?.type}
                                                >
                                                    <option value="">Select Type</option>
                                                    <option value="COUPON">COUPON</option>
                                                    <option value="REWARD">REWARD</option>
                                                </CFormSelect>
                                            </div>
                                        </CCol>

                                        <CCol>
                                            <div className="mb-3">
                                                <CFormLabel className="fw-semibold">
                                                    Select Coupon
                                                    <span className="text-danger">*</span>
                                                </CFormLabel>
                                                <CFormSelect
                                                    disabled={editBOGODetails?.type === "REWARD"}
                                                    size="sm"
                                                    onChange={handleEditBOGORewardChange}
                                                    name="couponData"
                                                    className="mb-3"
                                                    aria-label="Small select example"
                                                    value={editBOGODetails?.couponData}
                                                >
                                                    <option value="">Select Coupon</option>
                                                    {bogoCouponData.map((level) => (
                                                        <option key={level._id} value={level._id}>
                                                            {level.title}
                                                        </option>
                                                    ))}
                                                </CFormSelect>
                                            </div>
                                        </CCol>

                                        <hr />
                                        <h6>Applying Conditions</h6>
                                        <hr />

                                        <CCol>
                                            <div className="mb-3">
                                                <CFormLabel className="fw-semibold">
                                                    Select Interval
                                                    <span className="text-danger">*</span>
                                                </CFormLabel>
                                                <CFormSelect
                                                    size="sm"
                                                    onChange={handleEditBOGORewardChange}
                                                    name="interval"
                                                    className="mb-3"
                                                    aria-label="Small select example"
                                                    value={editBOGODetails?.interval}
                                                >
                                                    <option value="">Select Interval</option>
                                                    <option value="WEEKLY">WEEKLY</option>
                                                </CFormSelect>
                                            </div>
                                        </CCol>

                                        <CCol>
                                            <div className="mb-3">
                                                <CFormLabel className="fw-semibold">
                                                    Select Value
                                                    <span className="text-danger">*</span>
                                                </CFormLabel>
                                                <CFormSelect
                                                    size="sm"
                                                    onChange={handleEditBOGORewardChange}
                                                    name="value"
                                                    className="mb-3"
                                                    aria-label="Small select example"
                                                    value={editBOGODetails?.value}
                                                >
                                                    <option value="">Select value</option>
                                                    <option value="0">SUNDAY</option>
                                                    <option value="1">MONDAY</option>
                                                    <option value="2">TUESDAY</option>
                                                    <option value="3">WEDNESDAY</option>
                                                    <option value="4">THURSDAY</option>
                                                    <option value="5">FRIDAY</option>
                                                    <option value="6">SATURDAY</option>
                                                    <option value="null">ANY DAY OF THE WEEK</option>
                                                </CFormSelect>
                                            </div>
                                        </CCol>

                                        <CCol>
                                            <div className="mb-3">
                                                <CFormLabel className="fw-semibold">
                                                    Number Of Times
                                                    <span className="text-danger">*</span>
                                                </CFormLabel>
                                                <CFormSelect
                                                    size="sm"
                                                    onChange={handleEditBOGORewardChange}
                                                    name="numberOfTimes"
                                                    className="mb-3"
                                                    aria-label="Small select example"
                                                    value={editBOGODetails?.numberOfTimes}
                                                >
                                                    <option value="">Number Of Times</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                </CFormSelect>
                                            </div>
                                        </CCol>
                                    </CRow>
                                </CModalBody>
                                <CModalFooter>
                                    <PrimaryButton
                                        title="SAVE CHANGES"
                                        isLoading={isEditBtnLoading}
                                    />
                                </CModalFooter>
                            </CForm>
                        )
                    }

                    {
                        singleLevelRewardData && singleLevelRewardData?.rewardType !== "" && singleLevelRewardData?.rewardType !== null && singleLevelRewardData?.rewardType !== undefined && (
                            <CForm onSubmit={editPointBoosterSubmit}>
                                <CModalBody>
                                    <CRow>
                                        <CCol>
                                            <div className="mb-3">
                                                <CFormLabel className="fw-semibold">
                                                    Select Level
                                                    <span className="text-danger">*</span>
                                                </CFormLabel>
                                                <CFormSelect
                                                    size="sm"
                                                    onChange={handleEditPointBoosterRewardChange}
                                                    name="level"
                                                    className="mb-3"
                                                    aria-label="Small select example"
                                                    value={editPointBoosterDetails?.level}
                                                >
                                                    <option value="">Select Level</option>
                                                    {filteredLevels?.map((level) => (
                                                        <option key={level._id} value={level._id}>
                                                            {level.title}
                                                        </option>
                                                    ))}
                                                </CFormSelect>
                                            </div>
                                        </CCol>
                                        <CCol>
                                            <div className="mb-3">
                                                <CFormLabel className="fw-semibold">
                                                    Select Type
                                                    <span className="text-danger">*</span>
                                                </CFormLabel>
                                                <CFormSelect
                                                    size="sm"
                                                    onChange={handleEditPointBoosterRewardChange}
                                                    name="type"
                                                    className="mb-3"
                                                    aria-label="Small select example"
                                                    value={editPointBoosterDetails?.type}
                                                >
                                                    <option value="">Select Type</option>
                                                    <option value="COUPON">COUPON</option>
                                                    <option value="REWARD">REWARD</option>
                                                </CFormSelect>
                                            </div>
                                        </CCol>

                                        <CCol>
                                            <div className="mb-3">
                                                <CFormLabel className="fw-semibold">
                                                    Select Reward Type
                                                    <span className="text-danger">*</span>
                                                </CFormLabel>
                                                <CFormSelect
                                                    size="sm"
                                                    onChange={handleEditPointBoosterRewardChange}
                                                    name="rewardType"
                                                    className="mb-3"
                                                    aria-label="Small select example"
                                                    value={editPointBoosterDetails?.rewardType}
                                                >
                                                    <option value="">Select Reward Type</option>
                                                    <option value="POINT_BOOSTER">POINT BOOSTER</option>
                                                    <option value="GIFT">GIFT</option>
                                                </CFormSelect>
                                            </div>
                                        </CCol>

                                        <hr />
                                        <h6>Applying Conditions</h6>
                                        <hr />

                                        <CCol>
                                            <div className="mb-3">
                                                <CFormLabel className="fw-semibold">
                                                    Select Interval
                                                    <span className="text-danger">*</span>
                                                </CFormLabel>
                                                <CFormSelect
                                                    size="sm"
                                                    onChange={handleEditPointBoosterRewardChange}
                                                    name="interval"
                                                    className="mb-3"
                                                    aria-label="Small select example"
                                                    value={editPointBoosterDetails?.interval}
                                                >
                                                    <option value="">Select Interval</option>
                                                    <option value="WEEKLY">WEEKLY</option>
                                                </CFormSelect>
                                            </div>
                                        </CCol>

                                        <CCol>
                                            <div className="mb-3">
                                                <CFormLabel className="fw-semibold">
                                                    Select Value
                                                    <span className="text-danger">*</span>
                                                </CFormLabel>
                                                <CFormSelect
                                                    size="sm"
                                                    onChange={handleEditPointBoosterRewardChange}
                                                    name="value"
                                                    className="mb-3"
                                                    aria-label="Small select example"
                                                    value={editPointBoosterDetails?.value}
                                                >
                                                    <option value="">Select value</option>
                                                    <option value="0">SUNDAY</option>
                                                    <option value="1">MONDAY</option>
                                                    <option value="2">TUESDAY</option>
                                                    <option value="3">WEDNESDAY</option>
                                                    <option value="4">THURSDAY</option>
                                                    <option value="5">FRIDAY</option>
                                                    <option value="6">SATURDAY</option>
                                                </CFormSelect>
                                            </div>
                                        </CCol>
                                    </CRow>
                                </CModalBody>
                                <CModalFooter>
                                    <PrimaryButton title="SAVE CHANGES" />
                                </CModalFooter>
                            </CForm>
                        )
                    }

                    {
                        singleLevelRewardData && singleLevelRewardData?.couponInfo?.isSizeUpgradeCoupon === true && (
                            <CForm onSubmit={editSizeUpgradeSubmit}>
                                <CModalBody>
                                    <CRow>
                                        <CCol>
                                            <div className="mb-3">
                                                <CFormLabel className="fw-semibold">
                                                    Select Level
                                                    <span className="text-danger">*</span>
                                                </CFormLabel>
                                                <CFormSelect
                                                    size="sm"
                                                    onChange={handleEditSizeUpgradeChange}
                                                    name="level"
                                                    className="mb-3"
                                                    aria-label="Small select example"
                                                    value={editSizeUpgradeDetails?.level}
                                                >
                                                    <option value="">Select Level</option>
                                                    {filteredLevels.map((level) => (
                                                        <option key={level._id} value={level._id}>
                                                            {level.title}
                                                        </option>
                                                    ))}
                                                </CFormSelect>
                                            </div>
                                        </CCol>
                                        <CCol>
                                            <div className="mb-3">
                                                <CFormLabel className="fw-semibold">
                                                    Select Type
                                                    <span className="text-danger">*</span>
                                                </CFormLabel>
                                                <CFormSelect
                                                    size="sm"
                                                    onChange={handleEditSizeUpgradeChange}
                                                    name="type"
                                                    className="mb-3"
                                                    aria-label="Small select example"
                                                    value={editSizeUpgradeDetails?.type}
                                                >
                                                    <option value="">Select Type</option>
                                                    <option value="COUPON">COUPON</option>
                                                    <option value="REWARD">REWARD</option>
                                                </CFormSelect>
                                            </div>
                                        </CCol>

                                        <div className="mb-3">
                                            <CFormLabel className="fw-semibold">
                                                Select Coupons <span className="text-danger">*</span>
                                            </CFormLabel>

                                            <Select
                                                isMulti
                                                components={animatedComponents}
                                                value={selectedOptions}
                                                onChange={handleSelectChange}
                                                options={couponsList}
                                                styles={customStyles}
                                                placeholder="Select Coupons"
                                                closeMenuOnSelect={false}
                                                theme={(theme) => ({
                                                    ...theme,
                                                    borderRadius: 0,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary25: "#e9ecef",
                                                        primary: "#007bff",
                                                    },
                                                })}
                                            />

                                        </div>

                                        <hr />
                                        <h6>Applying Conditions</h6>
                                        <hr />

                                        <CCol>
                                            <div className="mb-3">
                                                <CFormLabel className="fw-semibold">
                                                    Select Interval
                                                    <span className="text-danger">*</span>
                                                </CFormLabel>
                                                <CFormSelect
                                                    size="sm"
                                                    onChange={handleEditSizeUpgradeChange}
                                                    name="interval"
                                                    className="mb-3"
                                                    aria-label="Small select example"
                                                    value={editSizeUpgradeDetails?.interval}
                                                >
                                                    <option value="">Select Interval</option>
                                                    <option value="WEEKLY">WEEKLY</option>
                                                </CFormSelect>
                                            </div>
                                        </CCol>

                                        <CCol>
                                            <div className="mb-3">
                                                <CFormLabel className="fw-semibold">
                                                    Select Value
                                                    <span className="text-danger">*</span>
                                                </CFormLabel>
                                                <CFormSelect
                                                    size="sm"
                                                    onChange={handleEditSizeUpgradeChange}
                                                    name="value"
                                                    className="mb-3"
                                                    aria-label="Small select example"
                                                    // Fallback to "null" if value is null, so it selects "ANY DAY OF THE WEEK"
                                                    value={editSizeUpgradeDetails?.value ?? "null"}
                                                >
                                                    <option value="">Select value</option>
                                                    <option value="0">SUNDAY</option>
                                                    <option value="1">MONDAY</option>
                                                    <option value="2">TUESDAY</option>
                                                    <option value="3">WEDNESDAY</option>
                                                    <option value="4">THURSDAY</option>
                                                    <option value="5">FRIDAY</option>
                                                    <option value="6">SATURDAY</option>
                                                    <option value="null">ANY DAY OF THE WEEK</option>
                                                </CFormSelect>
                                            </div>
                                        </CCol>

                                        <CCol>
                                            <div className="mb-3">
                                                <CFormLabel className="fw-semibold">
                                                    Number Of Times
                                                    <span className="text-danger">*</span>
                                                </CFormLabel>
                                                <CFormSelect
                                                    size="sm"
                                                    onChange={handleEditSizeUpgradeChange}
                                                    name="numberOfTimes"
                                                    className="mb-3"
                                                    aria-label="Small select example"
                                                    value={editSizeUpgradeDetails?.numberOfTimes}
                                                >
                                                    <option value="">Number Of Times</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                </CFormSelect>
                                            </div>
                                        </CCol>
                                    </CRow>
                                </CModalBody>
                                <CModalFooter>
                                    <PrimaryButton title={"SAVE CHANGES"} />
                                </CModalFooter>
                            </CForm>
                        )
                    }
                </CModal>
            ) : null
            }

            {
                openViewModal && (
                    <CModal
                        alignment="center"
                        visible={openViewModal}
                        onClose={() => setOpenViewModal(false)}
                        aria-labelledby="VerticallyCenteredExample"
                        size='lg'
                    >
                        <CModalHeader>
                            <CModalTitle id="VerticallyCenteredExample" className="fs-6">
                                VIEW LEVEL REWARD DETAILS
                            </CModalTitle>
                        </CModalHeader>
                        <CModalBody>
                            <div>
                                <h5 className='text-center mb-4'>{singleLevelRewardData?.applyingConditions === null ? "BIRTHDAY REWARD" : singleLevelRewardData?.couponInfo?.couponData?.isBogoCoupon === true ? "BOGO REWARD" : singleLevelRewardData?.couponInfo?.isSizeUpgradeCoupon === true ? "SIZE UPGRADE REWARD" : "BOOSTER POINT REWARD"}</h5>
                            </div>
                            <CRow>
                                <CCol className="position-relative">
                                    <CImage src={singleLevelRewardData?.level?.bannerImage} alt="banner-image" fluid className="w-100" />
                                    {
                                        singleLevelRewardData?.applyingConditions === null ? null : <div className="position-absolute top-0 left-0 p-2 d-flex justify-content-between" style={{ background: 'none', color: 'white' }}>
                                            <CBadge>Interval: {singleLevelRewardData?.applyingConditions?.interval || 'N/A'}</CBadge>
                                            {/* <CBadge >Day: {findDay(singleLevelRewardData?.applyingConditions?.value) || 'N/A'}</CBadge> */}
                                            <CBadge>
                                                Day: {singleLevelRewardData?.applyingConditions?.value === null 
                                                    ? 'ANY DAY OF THE WEEK' 
                                                    : findDay(singleLevelRewardData?.applyingConditions?.value) || 'N/A'}
                                            </CBadge>
                                        </div>
                                    }

                                </CCol>
                                <CCol>
                                    <CTable bordered>
                                        <CTableHead>
                                            <CTableRow>
                                                <CTableHeaderCell colSpan={2} className='text-center'>LEVEL INFORMATION</CTableHeaderCell>
                                            </CTableRow>
                                        </CTableHead>
                                        <CTableBody>
                                            <CTableRow>
                                                <CTableDataCell className='fw-semibold'>Title</CTableDataCell>
                                                <CTableDataCell className='fw-semibold'>{singleLevelRewardData?.level?.title}</CTableDataCell>
                                            </CTableRow>
                                            <CTableRow>
                                                <CTableDataCell>Sequence No. </CTableDataCell>
                                                <CTableDataCell>{singleLevelRewardData?.level?.sequenceNo}</CTableDataCell>
                                            </CTableRow>

                                            <CTableRow>
                                                <CTableDataCell>Points Earning Percent</CTableDataCell>
                                                <CTableDataCell>{singleLevelRewardData?.level?.pointsEarningPercent} %</CTableDataCell>
                                            </CTableRow>
                                            <CTableRow>
                                                <CTableDataCell>Required Points</CTableDataCell>
                                                <CTableDataCell>{singleLevelRewardData?.level?.requiredPoints} </CTableDataCell>
                                            </CTableRow>
                                        </CTableBody>
                                    </CTable>
                                </CCol>
                            </CRow>

                            {
                                singleLevelRewardData?.couponInfo?.couponData && (
                                    <CTable bordered>
                                        <CTableHead>
                                            <CTableRow>
                                                <CTableHeaderCell colSpan={2} className="text-center">
                                                    COUPON INFORMATION
                                                </CTableHeaderCell>
                                            </CTableRow>
                                        </CTableHead>
                                        <CTableBody>
                                            {Array.isArray(singleLevelRewardData.couponInfo.couponData)
                                                ? singleLevelRewardData.couponInfo.couponData.map((coupon, index) => (
                                                    <React.Fragment key={index}>
                                                        <CTableRow>
                                                            <CTableDataCell className='fw-semibold'>Title</CTableDataCell>
                                                            <CTableDataCell className='fw-semibold'>{coupon.title}</CTableDataCell>
                                                        </CTableRow>
                                                        <CTableRow>
                                                            <CTableDataCell>Description</CTableDataCell>
                                                            <CTableDataCell>{coupon.description}</CTableDataCell>
                                                        </CTableRow>
                                                        <CTableRow>
                                                            <CTableDataCell>Coupon Code</CTableDataCell>
                                                            <CTableDataCell className='text-warning fw-semibold'>{coupon.couponCode}</CTableDataCell>
                                                        </CTableRow>
                                                        <CTableRow>
                                                            <CTableDataCell>Usage Type</CTableDataCell>
                                                            <CTableDataCell>{coupon.usageType}</CTableDataCell>
                                                        </CTableRow>
                                                        {index < singleLevelRewardData.couponInfo.couponData.length - 1 && (
                                                            <CTableRow>
                                                                <CTableDataCell colSpan={2} className="text-center">
                                                                    <hr />
                                                                </CTableDataCell>
                                                            </CTableRow>
                                                        )}
                                                        <hr />
                                                    </React.Fragment>
                                                ))
                                                : (
                                                    <React.Fragment>
                                                        <CTableRow>
                                                            <CTableDataCell className='fw-semibold'>Title</CTableDataCell>
                                                            <CTableDataCell className='fw-semibold'>{singleLevelRewardData.couponInfo.couponData.title}</CTableDataCell>
                                                        </CTableRow>
                                                        <CTableRow>
                                                            <CTableDataCell>Description</CTableDataCell>
                                                            <CTableDataCell>{singleLevelRewardData.couponInfo.couponData.description}</CTableDataCell>
                                                        </CTableRow>
                                                        <CTableRow>
                                                            <CTableDataCell>Coupon Code</CTableDataCell>
                                                            <CTableDataCell className='text-warning fw-semibold'>{singleLevelRewardData.couponInfo.couponData.couponCode}</CTableDataCell>
                                                        </CTableRow>
                                                        <CTableRow>
                                                            <CTableDataCell>Usage Type</CTableDataCell>
                                                            <CTableDataCell>{singleLevelRewardData.couponInfo.couponData.usageType}</CTableDataCell>
                                                        </CTableRow>
                                                    </React.Fragment>
                                                )}
                                        </CTableBody>
                                    </CTable>
                                )
                            }
                        </CModalBody>
                        <CModalFooter>
                            <Button onClick={() => setOpenViewModal(false)}>CLOSE</Button>
                        </CModalFooter>
                    </CModal>
                )
            }

        </CContainer >
    )
}

export default ViewLevelRewards
