import { CBadge, CButton, CCol, CContainer, CFormLabel, CFormSelect, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle, CRow, CSpinner, CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow } from '@coreui/react'
import React, { useEffect, useState } from 'react'
import { DataTableComponent } from '../../components'
import axios from '../../api/axios';
import { toast } from 'react-toastify';
import ReactDatePicker from 'react-datepicker';

const CustomerSupportMgt = () => {
  const accessToken = localStorage.getItem("authToken");
  const [ticketList, setTicketList] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [selectedStatus, setSelectedStatus] = useState("OPEN");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [loadingRowId, setLoadingRowId] = useState(null);
  const [pagination, setPagination] = useState({ page: 1, rowsPerPage: 10 });
  const [viewModal, setViewModal] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState({});

  const columns = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      cell: (row, index) => (
        <div>
          <div className="fs-7">{index + 1}</div>
        </div>
      ),
      width: "50px",
    },
    {
      name: "TICKET NO",
      selector: (row) => row.ticket_no,
      cell: (row) => (
        <div>
          <div className="fs-7">{row.ticket_no ? row.ticket_no : "--------"}</div>
        </div>
      ),
      width: "140px",
    },
    {
      name: "RAISED ON",
      selector: (row) => row.createdAt,
      cell: (row) => (
        <div>
          <div className="fs-7">
            {row.createdAt ? (
              <>
                {new Date(row.createdAt).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric"
                })}
                <br />
                {new Date(row.createdAt).toLocaleTimeString("en-US", {
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: true
                })}
              </>
            ) : (
              "--------"
            )}
          </div>
        </div>
      ),
      width: "140px",
    },
    {
      name: "NAME",
      selector: (row) => row.name,
      cell: (row) => (
        <div>
          <div className="fs-7 fw-semibold text-success">{row?.user?.name ? row?.user?.name : "--------"}</div>
          {/* <div>
            <a href="#" className="fs-7" style={{ textDecoration: "none" }}>
              {row?.user_id?.email?.includes("_deleted_") ? 
                `${row?.user_id?.email.split("_deleted_")[0]} [Deleted]` :
                row?.user_id?.email
              }
            </a>
          </div> */}
        </div>

      ),
      width: "190px",
    },
    {
      name: "SUPPORT TYPE",
      selector: (row) => row.support_type,
      cell: (row) => (
        <div>
          <div className="fs-7">{row.support_type ? row.support_type : "--------"}</div>
        </div>
      ),
      width: "170px",
    },
    {
      name: "SUBJECT",
      selector: (row) => row.subject,
      cell: (row) => (
        <div className="fs-7">
          {row.subject ? row.subject.toUpperCase() : "--------"}
        </div>
      ),
      width: "180px",
    },
    {
      name: "STATUS",
      selector: (row) => row.status,
      cell: (row) => (
        <p className={`${row.status === 'OPEN' ? 'text-success' : 'text-danger'} fs-7 fw-bold mt-3`}> {row?.status}</p>
      ),
    },
    {
      name: "ACTIONS",
      center: "true",
      cell: (row) => (
        <div className='w-100' style={{ display: 'flex', alignItems: "center", gap: '10px' }}>
          <CButton
            size="sm"
            className="w-100 fw-semibold"
            style={{
              borderRadius: '20px',
              fontSize: '11px',
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
              transition: 'background-color 0.3s, transform 0.3s',
              transform: loadingRowId === row._id ? 'scale(1)' : 'scale(1.05)',
            }}
            onClick={() => handleUpdateStatus(row)}
            disabled={loadingRowId === row._id}
            color={loadingRowId === row._id ? "secondary" : row?.status === "OPEN" ? "danger" : "success"}
          >
            {loadingRowId === row._id ? (
              <> Loading........ </>
            ) : (
              row?.status === "OPEN" ? "Close Ticket" : "Open Ticket"
            )}
          </CButton>

          <CButton
            size="sm"
            className="w-100 fw-semibold"
            style={{
              borderRadius: '20px',
              fontSize: '11px',
              backgroundColor: '#1E9CED',
              color: 'white',
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
              transition: 'background-color 0.3s, transform 0.3s',
              transform: 'scale(1.05)',
            }}
            onClick={() => handleViewDetails(row)}
          >
            View Details
          </CButton>
        </div>
      ),
      minWidth: "220px",
    },
  ];

  const updatedColumns = columns.map((column) => ({
    ...column,
    name: (
      <div style={{ fontSize: "13px", fontWeight: "bold" }}>{column.name}</div>
    ),
  }));

  const handleSubmit = async (e) => {
    const adjustedStartDate = new Date(startDate?.setHours(23, 59, 59, 999)); // start of day
    const adjustedEndDate = new Date(endDate?.setHours(23, 59, 59, 999)); // end of day

    setLoading(true);
    await getTickets(selectedStatus, adjustedStartDate, adjustedEndDate); // Corrected parameter order
    toast.success("Data Fetched Successfully", {
      position: "top-right",
      autoClose: 3000,
      theme: "colored",
    });
    setLoading(false);
  }

  const getTickets = async (status, startDate, endDate) => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      // Format the dates here
      const updatedStartDate = startDate ? startDate.toISOString().split("T")[0] : null;
      const updatedEndDate = endDate ? endDate.toISOString().split("T")[0] : null;

      let url = `customer-support/get-tickets?`

      if (status) {
        url += `status=${status}&`
      }

      if (updatedStartDate) {
        url += `startDate=${updatedStartDate}&`
      }

      if (updatedEndDate) {
        url += `endDate=${updatedEndDate}&`
      }

      // Remove the trailing '&' if it exists
      url = url.endsWith('&') ? url.slice(0, -1) : url;

      // Use formatted params in the URL
      const response = await axios.get(url, { headers });

      setTicketList(response?.data?.data?.tickets);
      setFilteredData(response?.data?.data?.tickets);
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      } else if (error.response && error.response?.data?.status === 404) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  }

  const handleEndDateChange = (date) => {
    if (date && startDate && date < startDate) {
      setError("End date cannot be earlier than start date")
    } else {
      setError("");
      setEndDate(date);
    }
  }

  const handleUpdateStatus = async (data) => {
    const newStatus = data?.status === "OPEN" ? "CLOSE" : "OPEN";
    setLoadingRowId(data?._id);
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.put(`customer-support/user-tickets`, {
        _id: data?._id,
        status: newStatus
      }, { headers });

      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        })
        getTickets(selectedStatus, startDate, endDate)
      }
    } catch (error) {
      if (error?.response) {
        let errorMessage = '';
        if (
          error.response?.status === 406
        ) {
          errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
        }
        else if (
          error.response?.status === 412
        ) {
          errorMessage = error?.response?.data?.data?.message;
        } else if (error.response?.status === 500) {
          errorMessage = error?.response?.data?.error
        }

        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      setLoadingRowId(null);
    }
  };

  const handleViewDetails = (data) => {
    console.log("View Modal :", data);
    
    setViewModal(true);
    setSelectedTicket(data)
  }

  const handleCloseViewModal = () => {
    setViewModal(false);
  }

  const handlePageChange = (page) => {
    setPagination({ ...pagination, page });
  };

  const handleRowsPerPageChange = (rowsPerPage) => {
    setPagination({ page: 1, rowsPerPage }); // Reset to the first page when changing rows per page
  };

  // Function to get paginated data
  const getPaginatedData = () => {
    const startIndex = (pagination.page - 1) * pagination.rowsPerPage;
    const endIndex = startIndex + pagination.rowsPerPage;
    return filteredData.slice(startIndex, endIndex);
  };

  useEffect(() => {
    const now = new Date();
    const firstOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    const currentDate = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate()
    );
    // Set the start date and end date

    const start = new Date(firstOfMonth.setHours(23, 59, 59, 999)); // start of day
    const end = new Date(currentDate.setHours(23, 59, 59, 999)); // end of day

    setStartDate(start);
    setEndDate(end);
    getTickets(selectedStatus, start, end);
  }, []);

  useEffect(() => {
    const result = ticketList.filter((item) => {
      return (
        item?.user_id?.name?.toLowerCase().match(searchText.toLocaleLowerCase()) ||
        item?.support_type?.toLowerCase().match(searchText.toLocaleLowerCase()) ||
        item?.ticket_no?.toLowerCase().match(searchText.toLocaleLowerCase())
      )
    });
    setFilteredData(result)
  }, [searchText]);

  return (
    <CContainer>
      <h5>CUSTOMER SUPPORT MANAGEMENT</h5>
      {/* <-------- Filters Status, Start-date & End Date -------> */}
      <CRow className="mt-3">
        <CCol md={4}>
          <CFormLabel className='fw-semibold'>Select Status</CFormLabel>
          <CFormSelect
            id="selectField"
            name="selectField"
            value={selectedStatus}
            onChange={(e) => setSelectedStatus(e.target.value)}
          >
            <option value="">ALL</option>
            <option value="OPEN">OPEN</option>
            <option value="CLOSE">CLOSED</option>
          </CFormSelect>
        </CCol>
        <CCol className="col-md-3" md={3}>
          <CFormLabel className='fw-semibold'>Select Start Date</CFormLabel>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <ReactDatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              dateFormat="dd-MM-yyyy"  // Set the date format here
              placeholderText="Select Start Date"
              className="form-control"
            />
          </div>
        </CCol>
        <CCol className="col-md-3" md={3}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <CFormLabel className='fw-semibold'>Select End Date</CFormLabel>
            <ReactDatePicker
              selected={endDate}
              // onChange={(date) => setEndDate(date)}
              dateFormat="dd-MM-yyyy"  // Set the date format here
              onChange={handleEndDateChange}
              placeholderText="Select End Date"
              className="form-control"
            />
            {error && <div className="text-danger" style={{ fontSize: '14px' }}>{error}</div>}
          </div>
        </CCol>

        <CCol xs={12} md={2}>
          <CFormLabel className='text-white'>.</CFormLabel>
          <CButton color="primary" className="w-100"
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? <CSpinner size="sm" /> : 'Submit'}
          </CButton>
        </CCol>
      </CRow>

      {/* <------ List Of Tickets -----> */}
      <CRow>
        <CCol className="col-md-12 col-lg-12 mt-4">
          <DataTableComponent
            title="TICKETS LIST"
            columns={updatedColumns}
            // data={filteredData}
            data={getPaginatedData()} // Use the paginated data here
            searchText={searchText}
            onChange={handleSearchChange}
            striped
            itemsPerPage={10}
            pagination
            totalRows={filteredData?.length}
            paginationTotalRows={filteredData?.length} // Total number of items
            onChangeRowsPerPage={handleRowsPerPageChange}
            onChangePage={handlePageChange}
            paginationServer={false} // Set to false for client-side pagination
          />
        </CCol>
      </CRow>

      {/* Modal for viewing order details */}
      <CModal
        visible={viewModal}
        onClose={handleCloseViewModal}
        className="modal-xl"
      >
        <CModalHeader onClose={handleCloseViewModal}>
          <CModalTitle className="text-center w-100">SUPPORT REQUEST DETAILS</CModalTitle>
        </CModalHeader>
        <CModalBody>
          {selectedTicket ? (
            <div className="p-3">
              <CRow className="mb-2">
                <h6>USER DETAILS -</h6>
                <CCol xs={4}>
                  <h6 className='text-secondary'>User Name :</h6>
                  <p>{selectedTicket?.user?.name}</p>
                </CCol>
                <CCol xs={4}>
                  <h6 className='text-secondary'>Mobile Number :</h6>
                  <p>
                    {selectedTicket?.user?.mobile?.includes("_deleted_")
                      ? `${selectedTicket?.user?.mobile.split("_deleted_")[0]} [Deleted]`
                      : selectedTicket?.user?.mobile}
                  </p>
                </CCol>
                <CCol xs={4}>
                  <h6 className='text-secondary'>Email :</h6>
                  <p>
                    {selectedTicket?.user?.email?.includes("_deleted_")
                      ? `${selectedTicket?.user?.email.split("_deleted_")[0]} [Deleted]`
                      : selectedTicket?.user?.email
                    }
                  </p>
                </CCol>
              </CRow>
              
              <hr />

              <CRow className="mb-2">
                <h6>TICKET DETAILS -</h6>
                <CCol xs={4}>
                  <h6 className='text-secondary'>Ticket No :</h6>
                  <p>{selectedTicket?.ticket_no}</p>
                </CCol>
                <CCol xs={4}>
                  <h6 className='text-secondary'>Status :</h6>
                  <p
                    style={{ color: selectedTicket?.status === "OPEN" ? "green" : "red" }}
                  >
                    {selectedTicket?.status}
                  </p>
                </CCol>
                <CCol xs={4}>
                  <h6 className='text-secondary'>Created On :</h6>
                  <p>
                    {new Date(selectedTicket?.createdAt).toLocaleDateString('en-US', {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                    })}{" / "}
                    {new Date(selectedTicket?.createdAt).toLocaleTimeString('en-US', {
                      hour: 'numeric',
                      minute: 'numeric',
                      hour12: true,
                    })}
                  </p>
                </CCol>
              </CRow>
              
              <CRow>
                <CCol xs={4}>
                  <h6 className='text-secondary'>Status Updated On :</h6>
                  <p>
                    {
                      selectedTicket?.updatedAt !== selectedTicket?.createdAt ? (
                        <>
                          {new Date(selectedTicket?.updatedAt).toLocaleDateString('en-US', {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                          })}
                          {" / "}
                          {new Date(selectedTicket?.updatedAt).toLocaleTimeString('en-US', {
                            hour: 'numeric',
                            minute: 'numeric',
                            hour12: true,
                          })}
                        </>
                      ) : (
                        "Yet To Be Updated"
                      )
                    }
                  </p>
                </CCol>
                <CCol xs={4}>
                  <h6 className='text-secondary'>Support Type :</h6>
                  <p>{selectedTicket?.support_type}</p>
                </CCol>
                <CCol xs={4} md={4}>
                  <h6 className='text-secondary'>Subject :</h6>
                  <p>{selectedTicket?.subject}</p>
                </CCol>
              </CRow>

              <CRow>
                <CCol xs={12} md={12}>
                  <h6 className='text-secondary'>Message (Description) :</h6>
                  <p>{selectedTicket?.message}</p>
                </CCol>
              </CRow>

              { selectedTicket?.order !== null ? (
              <hr /> ) : null }

              <CRow className="mb-2">
                {
                  selectedTicket?.order !== null && 
                  <>
                    <h6>ORDER DETAILS -</h6>
                    <CCol xs={4}>
                      <h6 className='text-secondary'>Order No :</h6>
                      <p>{`${selectedTicket?.order?.order_sequence}${selectedTicket?.order?.order_no}`}</p>
                    </CCol>
                  </>
                }

                {
                  selectedTicket?.order !== null && <CCol xs={4}>
                    <h6 className='text-secondary'>Serial No :</h6>
                    <p>{selectedTicket?.order?.serialNo}</p>
                  </CCol>
                }

                {
                  selectedTicket?.order !== null && <CCol xs={4}>
                    <h6 className='text-secondary'>Order Type :</h6>
                    <p >{selectedTicket?.order?.ordersType}</p>
                  </CCol>
                }

                {
                  selectedTicket?.order !== null && <CCol xs={4}>
                    <h6 className='text-secondary'>Payment Status :</h6>
                    <p className='text-success fw-semibold'>{selectedTicket?.order?.paymentStatus}</p>
                  </CCol>
                }
                {
                  selectedTicket?.order !== null && <CCol xs={4}>
                    <h6 className='text-secondary'>Order Status :</h6>
                    <p className='text-warning'>{selectedTicket?.order?.orderStatus}</p>
                  </CCol>
                }
                {
                  selectedTicket?.order !== null && <CCol xs={4}>
                    <h6 className='text-secondary'>Grand Total :</h6>
                    <p >₹. {selectedTicket?.order?.grandTotal.toFixed(2)}</p>
                  </CCol>
                }
              </CRow>

              { selectedTicket?.support_type === "PAYMENT-REFUND" ? (
              <hr /> ) : null }

              { selectedTicket?.support_type === "PAYMENT-REFUND" ? (
                <>
                  <CRow className="mb-2">
                    <h6>ORDERD ITEMS -</h6>
                    <CTable striped bordered hover responsive>
                        <CTableHead>
                            <CTableRow>
                            <CTableHeaderCell className="fw-semibold">Product</CTableHeaderCell>
                            <CTableHeaderCell className="text-center fw-semibold">Quantity</CTableHeaderCell>
                            <CTableHeaderCell className="text-center fw-semibold">Price</CTableHeaderCell>
                            {/* <CTableHeaderCell>Add-ons</CTableHeaderCell> */}
                            </CTableRow>
                        </CTableHead>
                        <CTableBody>
                            {selectedTicket?.order?.order_items?.map((item) => (
                                <CTableRow key={item?._id}>
                                    <CTableDataCell>{item?.product?.name}
                                        {item?.addons?.length > 0 ? (
                                        <ul style={{ fontSize: '14px' }}>
                                            {item?.addons?.map((addon) => (
                                            <li key={addon?._id}>
                                                {addon?.selectedValue} (₹{addon?.offerPrice})
                                            </li>
                                            ))}
                                        </ul>
                                        ) : (
                                        ""
                                        )}
                                    </CTableDataCell>
                                    <CTableDataCell className="text-center">{item?.quantity}</CTableDataCell>
                                    <CTableDataCell className="text-end">
                                        ₹{item?.rate?.toFixed(2)}
                                        <ul  style={{ fontSize: '14px', listStyleType: 'none' }}>
                                            {item?.addons?.map((addon) => (
                                            <li key={addon?._id}>
                                                + (₹{addon?.offerPrice})
                                            </li>
                                            ))}
                                        </ul>
                                    </CTableDataCell>
                                </CTableRow>
                            ))}
                            {/* Other Charges */}
                            { selectedTicket?.order?.platformFees !== 0 ? (
                                <CTableRow>
                                <CTableDataCell colSpan={2} className="text-end" style={{ fontSize: '14px' }}>
                                    Platform Fees:
                                </CTableDataCell>
                                <CTableDataCell style={{ fontSize: '14px' }} className="text-end">
                                    ₹ {Number(selectedTicket?.order?.platformFees).toFixed(2)}
                                </CTableDataCell>
                                </CTableRow>
                            ) : null }
                            
                            { selectedTicket?.order?.packingCharges !== 0 && null ? (
                                <CTableRow>
                                <CTableDataCell colSpan={2} className="text-end" style={{ fontSize: '14px' }}>Packing Fees:</CTableDataCell>
                                <CTableDataCell style={{ fontSize: '14px' }} className="text-end">
                                    ₹ {selectedTicket?.order?.packingCharges?.toFixed(2)}
                                </CTableDataCell>
                                </CTableRow>
                            ) : null }

                            { selectedTicket?.order?.deliveryCharges !== 0 && null ? (
                                <CTableRow>
                                <CTableDataCell colSpan={2} className="text-end" style={{ fontSize: '14px' }}>Delivery Charges:</CTableDataCell>
                                <CTableDataCell style={{ fontSize: '14px' }} className="text-end">
                                    ₹ {selectedTicket?.order?.deliveryCharges?.toFixed(2)}
                                </CTableDataCell>
                                </CTableRow>
                            ) : null }

                            <CTableRow>
                                <CTableDataCell colSpan={2} className="text-end" style={{ fontSize: '14px' }}>Total Tax Amount:</CTableDataCell>
                                <CTableDataCell style={{ fontSize: '14px' }} className="text-end">
                                ₹ {selectedTicket?.order?.totalTaxAmount?.toFixed(2)}
                                </CTableDataCell>
                            </CTableRow>

                            <CTableRow>
                                <CTableDataCell colSpan={2} className="text-end" style={{ fontSize: '14px' }}>Total Discount:</CTableDataCell>
                                <CTableDataCell style={{ fontSize: '14px' }} className="text-end">
                                ₹ {selectedTicket?.order?.totalDiscount?.toFixed(2)}
                                </CTableDataCell>
                            </CTableRow>

                            {/* Calculate grand total */}
                            <CTableRow>
                                <CTableDataCell colSpan={2} className="text-end"><strong>Grand Total:</strong></CTableDataCell>
                                <CTableDataCell className="text-end text-success fw-semibold">
                                    ₹. {selectedTicket?.order?.grandTotal.toFixed(2)}
                                </CTableDataCell>
                            </CTableRow>
                        </CTableBody>
                    </CTable>
                  </CRow>

                  <CRow className="mb-1">
                    <CCol xs={12}>
                    <h6>Order Status Log :</h6>
                    <CTable striped bordered hover responsive>
                      <CTableHead>
                        <CTableRow>
                          <CTableHeaderCell className="fw-semibold">Order Status</CTableHeaderCell>
                          <CTableHeaderCell className="fw-semibold">Time</CTableHeaderCell>
                          <CTableHeaderCell className="fw-semibold">Date</CTableHeaderCell>
                        </CTableRow>
                      </CTableHead>
                      <CTableBody>
                        {selectedTicket?.order?.order_status_logs?.length > 0 ? (
                          selectedTicket?.order?.order_status_logs?.map((log) => (
                            <CTableRow key={log._id}>
                              <CTableDataCell>
                                <CBadge
                                  color={
                                    log?.orderStatus === "COMPLETED"
                                      ? "success"
                                      : log?.orderStatus === "DELIVERED"
                                      ? "primary"
                                      : log?.orderStatus === "CONFIRMED"
                                      ? "secondary"
                                      : log?.orderStatus === "READY-TO-PICK"
                                      ? "warning"
                                      : log?.orderStatus === "CANCELLED"
                                      ? "danger"
                                      : "dark"
                                  }
                                >
                                  {log?.orderStatus}
                                </CBadge>
                              </CTableDataCell>
                              <CTableDataCell>
                                {new Date(log?.createdAt).toLocaleTimeString('en-US', {
                                  hour: 'numeric',
                                  minute: 'numeric',
                                  hour12: true,
                                })}
                              </CTableDataCell>
                              <CTableDataCell>
                                {new Date(log?.createdAt).toLocaleDateString('en-US', {
                                  year: 'numeric',
                                  month: 'long',
                                  day: 'numeric',
                                })}                                
                              </CTableDataCell>
                            </CTableRow>
                          ))
                        ) : (
                          <CTableRow>
                            <CTableDataCell colSpan={3} className="text-center">
                              No order status logs available.
                            </CTableDataCell>
                          </CTableRow>
                        )}
                      </CTableBody>
                    </CTable>
                  </CCol>
                  </CRow>
                </>
              ) : null }
            </div>
          ) : (
            <p>No details available.</p>
          )}
        </CModalBody>
        <CModalFooter>
          <CButton color="secondary" onClick={handleCloseViewModal}>
            Close
          </CButton>
        </CModalFooter>
      </CModal>
    </CContainer>
  )
}

export default CustomerSupportMgt