import { Route, Routes } from "react-router-dom";
import { Login } from "./auth";
import AppLayout from "./AppLayout";
import {
  AddAdvertisement,
  AddCoupon,
  AddStory,
  Category,
  CouponCard,
  Dashboard,
  Level,
  LevelRewards,
  OutletUsers,
  Outlets,
  ProcurementCatalog,
  Procurements,
  ProductAddOn,
  Products,
  ProductsCatalog,
  RawMaterialMainCategory,
  RawMaterialSubCategory,
  RawMaterials,
  RmStockReports,
  Roles,
  StockTransfer,
  Vendors,
  ViewAdvertisement,
  ViewLevelRewards,
  AssetType,
  Assets,
  AssetsStockTransaction,
  AssetStockTransactionReport,
  StorageLocation,
  VersionControl,
  Settings,
  KioskOutletUser,
  CustomerSupportMgt,
  Add,
  CustomerReport,
  ProductSalesReport,
  AddonWiseReport,
  OrdersReport,
  CancellationRequests,
  KioskUserReport,
  DaySummaryReport,
  UserCouponReport,
  StockTransactionReport,
  ProductIngredients,
  MonthSummaryReport,
  RMReorderLevelReport
} from "./screens/index";

import ProtectedRoutes from "./ProtectedRoutes";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/" element={<AppLayout />}>
        <Route element={<ProtectedRoutes />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/masters/admin-roles" element={<Roles />} />
          <Route
            path="/asset-management/assets"
            element={<Assets />}
          />
          <Route
            path="/masters/asset-type"
            element={<AssetType />}
          />
          <Route
            path="/asset-management/assets-stock-transaction"
            element={<AssetsStockTransaction />}
          />
          <Route
            path="/asset-management/storage-type"
            element={<StorageLocation />}
          />
          <Route
            path="/asset-management/asset-stock-transaction-report"
            element={<AssetStockTransactionReport />}
          />
          <Route
            path="/masters/rm-main-category"
            element={<RawMaterialMainCategory />}
          />
          <Route
            path="/masters/rm-sub-category"
            element={<RawMaterialSubCategory />}
          />
          <Route
            path="/masters/rawmaterials"
            element={<RawMaterials />}
          />
          <Route path="/outlet-management/outlets" element={<Outlets />} />
          <Route
            path="/outlet-management/outletusers"
            element={<OutletUsers />}
          />
          <Route path="/customer-support" element={<CustomerSupportMgt />} />
          <Route path="/vendors" element={<Vendors />} />
          <Route path="/kisok-outlet-user" element={<KioskOutletUser />} />
          <Route path="/masters/categories" element={<Category />} />
          <Route
            path="/product-management/add-product"
            element={<Products />}
          />
          <Route
            path="/product-management/products-catalog"
            element={<ProductsCatalog />}
          />
          <Route
            path="/product-management/product-addon"
            element={<ProductAddOn />}
          />
          <Route
            path="/product-management/product-ingredients"
            element={<ProductIngredients />}
          />

          <Route
            path="/central-inventory/add-procurement"
            element={<Procurements />}
          />
          <Route
            path="/central-inventory/procurements-list"
            element={<ProcurementCatalog />}
          />
          <Route
            path="/central-inventory/stock-transfer"
            element={<StockTransfer />}
          />
          <Route
            path="/central-inventory/rm-stock-reports"
            element={<RmStockReports />}
          />
          <Route path="/coupons/view" element={<CouponCard />} />
          <Route path="/coupons/add" element={<AddCoupon />} />
          <Route path="/content-management/stories" element={<AddStory />} />
          <Route path="/content-management/view-advertisement" element={<ViewAdvertisement />} />
          <Route path="/content-management/add-advertisement" element={<AddAdvertisement />} />
          <Route path="/masters/level" element={<Level />} />
          <Route path="/level-rewards/add" element={<LevelRewards />} />
          <Route path="/level-rewards/view" element={<ViewLevelRewards />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/version-control" element={<VersionControl />} />
          <Route path="/reports/customer-report" element={<CustomerReport />} />
          <Route path="/reports/order-report" element={<OrdersReport />} />
          <Route path="/reports/product-report" element={<ProductSalesReport />} />
          <Route path="/reports/addon-report" element={<AddonWiseReport />} />
          <Route path="/reports/kiosk-user-report" element={<KioskUserReport />} />
          <Route path="/reports/day-summary-report" element={<DaySummaryReport />} />
          <Route path="/reports/month-summary-report" element={<MonthSummaryReport />} />
          <Route path="/reports/user-coupon-report" element={<UserCouponReport />} />
          <Route path="/reports/rm-reorder-level-report" element={<RMReorderLevelReport />} />
          <Route path="/reports/stock-transaction-report" element={<StockTransactionReport />} />
          <Route path="/user-coupon-allocation" element={< Add />} />
          <Route path="/cancellation-request" element={<CancellationRequests />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default AppRoutes;
