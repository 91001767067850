import { CCard, CCardBody, CCardFooter, CCardHeader, CCardTitle, CCol, CForm, CFormLabel, CFormSelect, CRow } from '@coreui/react'
import React, { useEffect, useState } from 'react'
import axios from '../../api/axios';
import { toast } from 'react-toastify';
import { PrimaryButton } from '../../components';

const BOGOCoupon = ({ levels }) => {
    const accessToken = localStorage.getItem("authToken");
    const [inputData, setInputData] = useState({
        level: "",
        type: "",
        couponData: "",
        interval: "",
        value: "",
        numberOfTimes: ""
    });
    const [isLoading, setIsLoading] = useState(false);
    const [filteredLevels, setFilteredLevels] = useState([]);
    const [couponData, setCouponData] = useState({})

    const filterLevels = () => {
        const level = levels?.filter((item) => item.sequenceNo !== 1);
        setFilteredLevels(level)
    }

    const fetchCouponData = async () => {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };
            const response = await axios.get("coupons", { headers });
            if (response?.data?.status === true) {
                const coupon = response?.data?.data?.coupons;
                const bogoCoupons = coupon.filter((coupon) => coupon.isBogoCoupon === true)
                setCouponData(bogoCoupons)
            }
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target
        setInputData((prev) => ({ ...prev, [name]: value }))
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };

            const data = {
                level: inputData?.level,
                type: inputData?.type,
                couponData: inputData?.couponData,
                applyingConditions: {
                    interval: inputData?.interval,
                    value: inputData?.value,
                    numberOfTimes: inputData?.numberOfTimes
                }
            }

            if (data?.type === "REWARD") {
                delete data?.couponData
            }

            if (data?.applyingConditions?.value === "null") {
                delete data?.applyingConditions?.value
            }

            const response = await axios.post("level-reward", data, { headers });

            if (response?.data?.status === true) {
                toast.success(response?.data?.data?.message, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
                setInputData({
                    level: "",
                    type: "",
                    couponData: "",
                    interval: "",
                    value: "",
                    numberOfTimes: ""
                })
            }
        } catch (error) {
            if (error?.response) {
                let errorMessage = '';
                if (
                    error.response?.status === 406
                ) {
                    errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
                }
                else if (
                    error.response?.status === 412
                ) {
                    errorMessage = error?.response?.data?.data?.message;
                } else if (error.response?.status === 500) {
                    errorMessage = error?.response?.data?.error
                }

                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        filterLevels();
        fetchCouponData()
    }, [])
    return (
        <CCard>
            <CCardHeader>
                <CCardTitle>
                    ADD BOGO REWARD
                </CCardTitle>
            </CCardHeader>
            <CForm onSubmit={handleSubmit}>
                <CCardBody>
                    <CRow>
                        <CCol>
                            <div className="mb-3">
                                <CFormLabel className="fw-semibold">
                                    Select Level
                                    <span className="text-danger">*</span>
                                </CFormLabel>
                                <CFormSelect
                                    size="sm"
                                    onChange={handleChange}
                                    name="level"
                                    className="mb-3"
                                    aria-label="Small select example"
                                    value={inputData?.level}
                                >
                                    <option value="">Select Level</option>
                                    {filteredLevels.map((level) => (
                                        <option key={level._id} value={level._id}>
                                            {level.title}
                                        </option>
                                    ))}
                                </CFormSelect>
                            </div>
                        </CCol>
                        <CCol>
                            <div className="mb-3">
                                <CFormLabel className="fw-semibold">
                                    Select Type
                                    <span className="text-danger">*</span>
                                </CFormLabel>
                                <CFormSelect
                                    size="sm"
                                    onChange={handleChange}
                                    name="type"
                                    className="mb-3"
                                    aria-label="Small select example"
                                    value={inputData?.type}
                                >
                                    <option value="">Select Type</option>
                                    <option value="COUPON">COUPON</option>
                                    <option value="REWARD">REWARD</option>
                                </CFormSelect>
                            </div>
                        </CCol>

                        <CCol>
                            <div className="mb-3">
                                <CFormLabel className="fw-semibold">
                                    Select Coupon
                                    <span className="text-danger">*</span>
                                </CFormLabel>
                                <CFormSelect
                                    size="sm"
                                    onChange={handleChange}
                                    name="couponData"
                                    className="mb-3"
                                    aria-label="Small select example"
                                    value={inputData?.couponData}
                                    disabled={inputData?.type === "REWARD"}
                                >
                                    <option value="">Select Coupon</option>
                                    {Array.isArray(couponData) && couponData.map((level) => (
                                        <option key={level._id} value={level._id}>
                                            {level.title}
                                        </option>
                                    ))}
                                </CFormSelect>
                            </div>
                        </CCol>

                        <hr />
                        <h6>Applying Conditions</h6>
                        <hr />

                        <CCol>
                            <div className="mb-3">
                                <CFormLabel className="fw-semibold">
                                    Select Interval
                                    <span className="text-danger">*</span>
                                </CFormLabel>
                                <CFormSelect
                                    size="sm"
                                    onChange={handleChange}
                                    name="interval"
                                    className="mb-3"
                                    aria-label="Small select example"
                                    value={inputData?.interval}
                                >
                                    <option value="">Select Interval</option>
                                    <option value="WEEKLY">WEEKLY</option>
                                </CFormSelect>
                            </div>
                        </CCol>

                        <CCol>
                            <div className="mb-3">
                                <CFormLabel className="fw-semibold">
                                    Select Value
                                    <span className="text-danger">*</span>
                                </CFormLabel>
                                <CFormSelect
                                    size="sm"
                                    onChange={handleChange}
                                    name="value"
                                    className="mb-3"
                                    aria-label="Small select example"
                                    value={inputData?.value}
                                >
                                    <option value="">Select value</option>
                                    <option value="0">SUNDAY</option>
                                    <option value="1">MONDAY</option>
                                    <option value="2">TUESDAY</option>
                                    <option value="3">WEDNESDAY</option>
                                    <option value="4">THURSDAY</option>
                                    <option value="5">FRIDAY</option>
                                    <option value="6">SATURDAY</option>
                                    <option value="null">ANY DAY OF THE WEEK</option>
                                </CFormSelect>
                            </div>
                        </CCol>
                        <CCol>
                            <div className="mb-3">
                                <CFormLabel className="fw-semibold">
                                    Number of Times
                                    <span className="text-danger">*</span>
                                </CFormLabel>
                                <CFormSelect
                                    size="sm"
                                    onChange={handleChange}
                                    name="numberOfTimes"
                                    className="mb-3"
                                    aria-label="Small select example"
                                    value={inputData?.numberOfTimes}
                                >
                                    <option value="">Number of Times</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                </CFormSelect>
                            </div>
                        </CCol>
                    </CRow>
                </CCardBody>
                <CCardFooter>
                    <PrimaryButton title={"ADD"} isLoading={isLoading} />
                </CCardFooter>
            </CForm>
        </CCard>
    )
}

export default BOGOCoupon
