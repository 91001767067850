import React, { useEffect, useState } from "react";
import {
  CButton,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CFormTextarea,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableRow,
} from "@coreui/react";
import { toast } from "react-toastify";
import { FaPlus } from "react-icons/fa";
import axios from "../../api/axios";
import { DataTableComponent, DeleteModal, PrimaryButton } from "../../components";
import { FaTrash, FaPencilAlt } from "react-icons/fa";

const Vendors = () => {
  const accessToken = localStorage.getItem("authToken");
  const [inputData, setInputData] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    country: "",
    pincode: "",
    city: "",
    state: "",
    gstin: "",
  });
  const [countryList, setCountryList] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [editedData, setEditedData] = useState({});
  const [vendorId, setVendorId] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [vendorDetails, setVendorDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isEditBtnLoading, setIsEditBtnLoading] = useState(false);
  const [isDeleteBtnLoading, setIsDeleteBtnLoading] = useState(false);
  const [addNewOpenModal, setAddNewOpenModal] = useState(false);
  const [pagination, setPagination] = useState({ page: 1, rowsPerPage: 10 });

  const columns = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      cell: (row) => (
        <div>
          <div className="fs-7">{row.serialNumber ? row.serialNumber : "--------"}</div>
        </div>
      ),
      width: "50px",
    },
    {
      name: "NAME",
      selector: (row) => row.name,
      cell: (row) => (
        <div>
          <div className="fs-7">{row.name ? row.name : "--------"}</div>
        </div>
      ),
      width: "170px",
    },
    {
      name: "GST NO.",
      selector: (row) => row.gstin,
      cell: (row) => (
        <div>
          <a href="#" className="fs-7" style={{ textDecoration: "none" }}>
            {row.gstin ? row.gstin : "--------"}
          </a>
        </div>
      ),
      width: "170px",
    },
    {
      name: "CONTACT",
      selector: (row) => row.email,
      cell: (row) => (
        <div>
          <p className="fs-7 mt-2" >
            <span className="text-primary"> {row.email ? row.email : "--------"}</span>
            <br />
            <span className="text-success">{row.phone ? row.phone : "--------"}</span>
          </p>{" "}
        </div>
      ),
      width: "210px",
    },
    {
      name: "ADDRESS",
      selector: (row) => row.address,
      cell: (row) => (
        <div>
          <div className="fs-7">{row.address ? row.address : "--------"}</div>
        </div>
      ),
      width: "200px",
    },
    {
      name: "CITY & PINCODE",
      selector: (row) => row.city,
      cell: (row) => (

        <div>
          <p className="fs-7 mt-2" >
            <span className="text-primary"> {row.city ? row.city.toUpperCase() : "--------"}</span>
            <br />
            <span className="text-success">{row.pincode ? row.pincode : "--------"}</span>
          </p>{" "}
        </div>
      ),
      width: "140px",
    },
    {
      name: "STATE",
      selector: (row) => row.state,
      cell: (row) => (
        <div className="fs-7">
          {row.state ? row.state.toUpperCase() : "--------"}
        </div>
      ),
      width: "120px",
    },
    {
      name: "ACTIONS",
      right: "true",
      cell: (row) => (
        <div className="d-flex justify-content-around align-items-center">
          <a
            href="#"
            className="text-primary me-2 d-flex align-items-center gap-1"
            style={{ cursor: "pointer", textDecoration: "none" }}
            onClick={() => handleEditModal(row)}
          >
            <FaPencilAlt /> Edit
          </a>{" "}
          |
          <a
            href="#"
            className="text-danger ms-2 d-flex align-items-center gap-1"
            style={{ cursor: "pointer", textDecoration: "none" }}
            onClick={() => handleDeleteModal(row)}
          >
            <FaTrash /> Delete
          </a>
        </div>
      ),
      minWidth: "165px",
    },
  ];

  const updatedColumns = columns.map((column) => ({
    ...column,
    name: (
      <div style={{ fontSize: "13px", fontWeight: "bold" }}>{column.name}</div>
    ),
  }));

  const handleAddNewOpenModal = () => {
    setAddNewOpenModal(true);
  };

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setInputData((prevState) => ({
      ...prevState,
      [name]: value,
      ...(name === "pincode" && value === "" ? { city: "", state: "" } : {}),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const filteredData = { ...inputData };
      if (!filteredData.email) delete filteredData.email;
      if (!filteredData.gstin) delete filteredData.gstin;
      const response = await axios.post("vendor", filteredData, { headers });

      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });

        getVendors();

        setInputData({
          name: "",
          email: "",
          phone: "",
          address: "",
          pincode: "",
          city: "",
          state: "",
          gstin: "",
        });
        setAddNewOpenModal(false);
      }
    } catch (error) {
      if (error?.response) {
        let errorMessage = '';
        if (
          error.response?.status === 406
        ) {
          errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
        }
        else if (
          error.response?.status === 412
        ) {
          errorMessage = error?.response?.data?.data?.message;
        } else if (error.response?.status === 500) {
          errorMessage = error?.response?.data?.error
        }

        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getVendors = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get("vendor", { headers });

      if (response?.data?.status === true) {
        response?.data?.data?.vendors.forEach((item, index) => {
          item.serialNumber = index + 1;
        });

        setVendors(response?.data?.data?.vendors);
        setFilteredData(response?.data?.data?.vendors);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;        
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      } else if(error.response && error.response?.data?.status === 404) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const handleEditModal = async (data) => {
    setEditedData({
      _id: data?._id,
      name: data.name,
      email: data.email,
      address: data.address,
      phone: data.phone,
      country: data.country,
      pincode: data.pincode,
      city: data.city,
      state: data.state,
      gstin: data.gstin,
    });
    setVendorId(data._id);
    setOpenEditModal(true);
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditedData((prevState) => ({
      ...prevState,
      [name]: value,
      ...(name === "pincode" && value === "" ? { city: "", state: "" } : {}),
    }));
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    setIsEditBtnLoading(true);

    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const filteredData = { ...editedData };
      if (!filteredData.email) delete filteredData.email;
      if (!filteredData.gstin) delete filteredData.gstin;
      const response = await axios.put("vendor", filteredData, { headers });
      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
        getVendors();
        setOpenEditModal(false);
        getSingleVendorDetails(vendorId);
        setOpenViewModal(true);
        setVendorId("");
      }
    } catch (error) {
      if (error?.response) {
        let errorMessage = '';
        if (
          error.response?.status === 406
        ) {
          errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
        }
        else if (
          error.response?.status === 412
        ) {
          errorMessage = error?.response?.data?.data?.message;
        } else if (error.response?.status === 500) {
          errorMessage = error?.response?.data?.error
        }

        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      setIsEditBtnLoading(false);
    }
  };

  const handleDeleteModal = async (data) => {
    setOpenDeleteModal(true);
    setCategoryId(data?._id);
  };

  const handleDeleteVendor = async (e) => {
    e.preventDefault();
    setIsDeleteBtnLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      const data = {
        _id: categoryId,
      };
      const response = await axios.delete(`vendor`, { data, headers });
      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
        getVendors();
        setOpenDeleteModal(false);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      setIsDeleteBtnLoading(false);
    }
  };

  const handleOpenViewModal = (data) => {
    getSingleVendorDetails(data?._id);
    setOpenViewModal(true);
  };

  const getSingleVendorDetails = async (vendorId) => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get(`vendor?_id=${vendorId}`, { headers });

      if (response?.data?.status === true) {
        setVendorDetails(response?.data?.data?.vendors);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const getPostalCodeDetails = async () => {
    try {
      // const response = await axios.get(
      //   `https://api.postalpincode.in/pincode/${inputData.pincode}`
      // );
      const response = await axios.get(
        `https://api.zipcodestack.com/v1/search?codes=${inputData?.pincode}&country=${inputData?.country}&apikey=01J93RQKC9NH4A8K5G34RRT6GE`
      );
      const postalCode = inputData?.pincode;
      if (response?.data?.results?.[postalCode]?.length > 0) {
        const postOffice = response?.data?.results?.[postalCode]?.[0];
        setInputData((prevData) => ({
          ...prevData,
          city: postOffice?.city,
          state: postOffice?.state
        }))
      } else {
        toast.error("Country or pincode might be incorrect", {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
      
      // if (response?.data[0]?.Status === "Success") {
      //   const postOffice = response?.data[0]?.PostOffice[0];
      //   setInputData((prevData) => ({
      //     ...prevData,
      //     city: postOffice?.Division || "",
      //     state: postOffice?.State || "",
      //   }));
      // } else if (response?.data[0]?.Status === "Error") {
      //   toast.error(`${response?.data[0]?.Message} or Invalid Pincode.`, {
      //     position: "top-right",
      //     autoClose: 3000,
      //     theme: "colored",
      //   });
      //   setInputData((prevData) => ({
      //     ...prevData,
      //     city: "",
      //     state: "",
      //   }));
      // }
    } catch (error) {
      toast.error(error.message, {
        position: "top-right",
        autoClose: 3000,
        theme: "colored",
      });
    }
  };

  const getEditPostalCodeDetails = async () => {
    try {
      // const response = await axios.get(
      //   `https://api.postalpincode.in/pincode/${editedData.pincode}`
      // );
      const response = await axios.get(
        `https://api.zipcodestack.com/v1/search?codes=${editedData?.pincode}&country=${editedData?.country}&apikey=01J93RQKC9NH4A8K5G34RRT6GE`
      );
      const postalCode = editedData?.pincode;
      if (response?.data?.results?.[postalCode]?.length > 0) {
        const postOffice = response?.data?.results?.[postalCode]?.[0];
        setEditedData((prevData) => ({
          ...prevData,
          city: postOffice?.city,
          state: postOffice?.state
        }))
      } else {
        toast.error("Country or pincode might be incorrect", {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } catch (error) {
      toast.error(error.message, {
        position: "top-right",
        autoClose: 3000,
        theme: "colored",
      });
    }
  };

  const getCountryData = async () => {
    try {
      const response = await axios.get("country");
      // if (response?.data[0]?.Status === "Success") {
        setCountryList(response?.data?.data?.countries);
      // }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  }

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false)
  }

  const disableButton =
    !inputData?.name?.trim() ||
    // !inputData?.email?.trim() ||
    !inputData?.country ||
    // inputData?.phone?.length < 10 ||
    !inputData?.pincode ||
    inputData?.pincode?.length < 4 ||
    !inputData?.address?.trim() ||
    !inputData?.city ||
    !inputData?.state ;
    // !inputData?.gstin?.trim() ||
    // inputData?.gstin?.length < 15;

  const editButtonDisable =
    !editedData ||
    !editedData?.name?.trim() ||
    // !editedData?.email?.trim() ||
    !editedData?.country ||
    // editedData?.phone?.length < 10 ||
    !editedData?.pincode ||
    editedData?.pincode?.length < 4 ||
    !editedData?.address?.trim() ||
    !editedData?.city ||
    !editedData?.state ;
    // !editedData?.gstin?.trim() ||
    // editedData?.gstin?.length < 15;

  const handlePageChange = (page) => {
    setPagination({ ...pagination, page });
  };
    
  const handleRowsPerPageChange = (rowsPerPage) => {
      setPagination({ page: 1, rowsPerPage }); // Reset to the first page when changing rows per page
  };
  
  // Function to get paginated data
  const getPaginatedData = () => {
      const startIndex = (pagination.page - 1) * pagination.rowsPerPage;
      const endIndex = startIndex + pagination.rowsPerPage;
      return filteredData.slice(startIndex, endIndex);
  };

  useEffect(() => {
    getVendors();
    getCountryData();
  }, []);

  useEffect(() => {
    const result = vendors.filter((item) => {
      return (
        item?.name?.toLowerCase().match(searchText.toLocaleLowerCase()) ||
        item?.email?.toLowerCase().match(searchText.toLocaleLowerCase()) ||
        item?.phone?.toLowerCase().match(searchText.toLocaleLowerCase()) ||
        item?.address?.toLowerCase().match(searchText.toLocaleLowerCase()) ||
        item?.pincode?.toLowerCase().match(searchText.toLocaleLowerCase()) ||
        item?.city?.toLowerCase().match(searchText.toLocaleLowerCase()) ||
        item?.state?.toLowerCase().match(searchText.toLocaleLowerCase()) ||
        item?.gstin?.toLowerCase().match(searchText.toLocaleLowerCase())
      );
    });
    setFilteredData(result);
  }, [searchText]);

  return (
    <CContainer>
      <div className="mb-3 text-end">
        <button
          className="btn btn-warning"
          onClick={handleAddNewOpenModal}
          style={{ borderRadius: "18px" }}
        >
          ADD NEW VENDOR <FaPlus style={{ marginTop: "-4px" }} size={15} />
        </button>
      </div>

      <div>
        <DataTableComponent
          columns={updatedColumns}
          title="VENDORS LIST"
          // data={filteredData}
          data={getPaginatedData()} // Use the paginated data here
          searchText={searchText}
          onChange={handleSearchChange}
          striped
          itemsPerPage={10}
          pagination
          totalRows={filteredData?.length}
          paginationTotalRows={filteredData?.length} // Total number of items
          onChangeRowsPerPage={handleRowsPerPageChange}
          onChangePage={handlePageChange}
          paginationServer={false} // Set to false for client-side pagination
        />
      </div>

      {openEditModal ? (
        <CModal
          size="lg"
          alignment="center"
          visible={openEditModal}
          onClose={() => setOpenEditModal(false)}
          aria-labelledby="VerticallyCenteredExample"
        >
          <CModalHeader>
            <CModalTitle id="VerticallyCenteredExample" className="fs-6">
              EDIT VENDOR
            </CModalTitle>
          </CModalHeader>
          <CForm onSubmit={handleEditSubmit}>
            <CModalBody>
              <CRow>
                <CCol>
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Name <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      type="text"
                      placeholder="Name"
                      className="form-control"
                      name="name"
                      value={editedData.name}
                      onChange={handleEditChange}
                    />
                  </div>
                </CCol>
                <CCol>
                  <div className="mb-3">
                    {" "}
                    <CFormLabel className="fw-semibold">
                      Email 
                    </CFormLabel>
                    <CFormInput
                      type="text"
                      placeholder="Email"
                      className="form-control"
                      name="email"
                      value={editedData.email}
                      onChange={handleEditChange}
                    />
                  </div>
                </CCol>
                <CCol>
                  <div className="mb-3">
                    {" "}
                    <CFormLabel className="fw-semibold">
                      Phone <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      minLength={10}
                      type="number"
                      placeholder="Phone"
                      className="form-control no-arrows"
                      name="phone"
                      value={editedData.phone}
                      onChange={handleEditChange}
                    />
                  </div>
                </CCol>
              </CRow>
              <CRow>
                <CCol>
                  <div className="mb-3">
                    {" "}
                    <CFormLabel className="fw-semibold">
                      Address <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormTextarea
                      rows={2}
                      type="text"
                      placeholder="Address"
                      className="form-control"
                      name="address"
                      value={editedData.address}
                      onChange={handleEditChange}
                    />
                  </div>
                </CCol>
                <CCol md={4}>
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Country <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormSelect
                      id="selectField"
                      name="country"
                      value={editedData?.country}
                      onChange={handleEditChange}
                    >
                      <option value="">Select Country</option>
                      { countryList?.map((country) => {                        
                        return (
                          <option key={country?._id} value={country?.code}>
                            {country?.name}
                          </option>
                        );
                      })}
                    </CFormSelect>
                  </div>
                </CCol>
              </CRow>

              <CRow>
                <CCol>
                  <div className="mb-3">
                    {" "}
                    <CFormLabel className="fw-semibold">
                      Pincode <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      max={999999}
                      type="number"
                      placeholder="Pincode"
                      className="form-control no-arrows"
                      name="pincode"
                      value={editedData.pincode}
                      onChange={handleEditChange}
                      onBlur={getEditPostalCodeDetails}
                    />
                  </div>
                </CCol>
                <CCol>
                  {" "}
                  <div className="mb-3">
                    {" "}
                    <CFormLabel className="fw-semibold">
                      City <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      type="text"
                      placeholder="City"
                      className="form-control"
                      name="city"
                      value={editedData.city}
                      onChange={handleEditChange}
                    />
                  </div>
                </CCol>
                <CCol>
                  {" "}
                  <div className="mb-3">
                    {" "}
                    <CFormLabel className="fw-semibold">
                      State <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      type="text"
                      placeholder="State"
                      className="form-control"
                      name="state"
                      value={editedData.state}
                      onChange={handleEditChange}
                    />
                  </div>
                </CCol>
              </CRow>
              <CRow>
                <CCol>
                  {" "}
                  <div className="mb-3">
                    {" "}
                    <CFormLabel className="fw-semibold">
                      GSTIN 
                    </CFormLabel>
                    <CFormInput
                      type="text"
                      placeholder="GSTIN"
                      className="form-control"
                      name="gstin"
                      value={editedData.gstin}
                      onChange={handleEditChange}
                    />
                  </div>
                </CCol>
              </CRow>
            </CModalBody>
            <CModalFooter>
              <PrimaryButton
                style={{ borderRadius: "18px" }}
                isDisable={editButtonDisable}
                title="SAVE CHANGES"
                isLoading={isEditBtnLoading}
              />
            </CModalFooter>
          </CForm>
        </CModal>
      ) : null}

      {openDeleteModal ? (
        <DeleteModal isVisible={openDeleteModal} onClose={handleCloseDeleteModal} onConfirm={handleDeleteVendor} isLoading={isDeleteBtnLoading} title="DELETE VENDOR" />
      ) : null}

      {openViewModal ? (
        <CModal
          alignment="center"
          visible={openViewModal}
          onClose={() => setOpenViewModal(false)}
          aria-labelledby="VerticallyCenteredExample"
        >
          <CModalHeader>
            <CModalTitle id="VerticallyCenteredExample" className="fs-6">
              VIEW VENDOR DETAILS
            </CModalTitle>
          </CModalHeader>
          <CModalBody>
            <CTable striped bordered>
              <CTableBody>
                <CTableRow>
                  <CTableDataCell className="fw-semibold">Name</CTableDataCell>
                  <CTableDataCell>{vendorDetails?.name}</CTableDataCell>
                </CTableRow>
                <CTableRow>
                  <CTableDataCell className="fw-semibold">Email</CTableDataCell>
                  <CTableDataCell>{vendorDetails?.email}</CTableDataCell>
                </CTableRow>
                <CTableRow>
                  <CTableDataCell className="fw-semibold">Phone</CTableDataCell>
                  <CTableDataCell>{vendorDetails?.phone}</CTableDataCell>
                </CTableRow>
                <CTableRow>
                  <CTableDataCell className="fw-semibold">GSTIN</CTableDataCell>
                  <CTableDataCell>{vendorDetails?.gstin}</CTableDataCell>
                </CTableRow>
                <CTableRow>
                  <CTableDataCell className="fw-semibold">
                    Address
                  </CTableDataCell>
                  <CTableDataCell>{vendorDetails?.address}</CTableDataCell>
                </CTableRow>
                <CTableRow>
                  <CTableDataCell className="fw-semibold">City</CTableDataCell>
                  <CTableDataCell>{vendorDetails?.city}</CTableDataCell>
                </CTableRow>
                <CTableRow>
                  <CTableDataCell className="fw-semibold">State</CTableDataCell>
                  <CTableDataCell>{vendorDetails?.state}</CTableDataCell>
                </CTableRow>
                <CTableRow>
                  <CTableDataCell className="fw-semibold">
                    Pincode
                  </CTableDataCell>
                  <CTableDataCell>{vendorDetails?.pincode} </CTableDataCell>
                </CTableRow>
              </CTableBody>
            </CTable>
          </CModalBody>
          <CModalFooter>
            <CButton
              style={{ borderRadius: "18px" }}
              className="btn btn-warning"
              onClick={() => setOpenViewModal(false)}
            >
              CLOSE
            </CButton>
          </CModalFooter>
        </CModal>
      ) : null}

      {addNewOpenModal ? (
        <CModal
          size="lg"
          alignment="center"
          visible={addNewOpenModal}
          onClose={() => {setAddNewOpenModal(false); setInputData({
            name: '',
            address: '',
            city: '',
            country: '',
            email: '',
            gstin: '',
            phone: '',
            pincode: '',
            state: ''
          })}}
          aria-labelledby="VerticallyCenteredExample"
        >
          <CModalHeader>
            <CModalTitle>ADD VENDOR</CModalTitle>
          </CModalHeader>
          <CForm onSubmit={handleSubmit}>
            <CModalBody>
              <CRow>
                <CCol>
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Name <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      type="text"
                      placeholder="Name"
                      className="form-control"
                      name="name"
                      value={inputData.name}
                      onChange={handleChange}
                    />
                  </div>
                </CCol>
                <CCol>
                  {" "}
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Email
                    </CFormLabel>
                    <CFormInput
                      type="email"
                      placeholder="Email"
                      className="form-control"
                      name="email"
                      value={inputData.email}
                      onChange={handleChange}
                    />
                  </div>
                </CCol>
                <CCol>
                  {" "}
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Phone <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      type="number"
                      placeholder="Phone"
                      className="form-control no-arrows"
                      name="phone"
                      value={inputData.phone}
                      onChange={handleChange}
                    />
                  </div>
                </CCol>
              </CRow>

              <CRow>
                <CCol>
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Address <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormTextarea
                      rows={2}
                      type="text"
                      placeholder="Address"
                      className="form-control"
                      name="address"
                      value={inputData.address}
                      onChange={handleChange}
                    />
                  </div>
                </CCol>
                <CCol md={4}>
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Country <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormSelect
                      id="selectField"
                      name="country"
                      value={inputData?.country}
                      onChange={handleChange}
                    >
                      <option value="">Select Country</option>
                      { countryList.map((country, index) => {
                        return (
                          <option key={index} value={country?.code}>
                            {country?.name}
                          </option>
                        );
                      })}
                    </CFormSelect>
                  </div>
                </CCol>
              </CRow>

              <CRow>
                <CCol>
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Pincode <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      maxLength={6}
                      type="number"
                      placeholder="Pincode"
                      className="form-control no-arrows"
                      name="pincode"
                      value={inputData.pincode}
                      onChange={handleChange}
                      onBlur={getPostalCodeDetails}
                    />
                    <div style={{ fontSize: '12px', color: 'tomato' }}>Select The Country First</div>
                  </div>
                </CCol>
                <CCol>
                  {" "}
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      City <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      type="text"
                      placeholder="City"
                      className="form-control"
                      name="city"
                      value={inputData.city}
                      onChange={handleChange}
                    />
                  </div>
                </CCol>

                <CCol>
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      State <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      type="text"
                      placeholder="State"
                      className="form-control"
                      name="state"
                      value={inputData.state}
                      onChange={handleChange}
                    />
                  </div>
                </CCol>
              </CRow>
              <CRow>
                <CCol>
                  {" "}
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      GSTIN 
                    </CFormLabel>
                    <CFormInput
                      type="text"
                      placeholder="GstIn"
                      className="form-control"
                      name="gstin"
                      value={inputData.gstin}
                      onChange={handleChange}
                    />
                  </div>
                </CCol>
              </CRow>
            </CModalBody>
            <CModalFooter>
              <PrimaryButton
                isDisable={disableButton}
                title="ADD VENDOR"
                isLoading={isLoading}
              />
            </CModalFooter>
          </CForm>
        </CModal>
      ) : null}
    </CContainer>
  );
};

export default Vendors;
