import React from "react";
import { CButton, CSpinner } from "@coreui/react";

const PrimaryButton = ({ title, isLoading, style, isDisable }) => {
  const mergedStyle = {
    borderRadius: "18px",
    ...style,
  };
  return (
    <CButton
      type="submit"
      style={mergedStyle}
      disabled={isDisable}
      color={
        title === "CONFIRM"
          ? "danger"
          : title === "SAVE CHANGES"
          ? "success"
          : "primary"
      }
    >
      {isLoading ? (
        <span className="d-flex align-items-center justify-content-center gap-2">
          <CSpinner size="sm" /> {" Loading..."}
        </span>
      ) : (
        <span>{title}</span>
      )}
    </CButton>
  );
};

export default PrimaryButton;
