import { CCard, CCardBody, CCardFooter, CCardHeader, CCardTitle, CCol, CContainer, CForm, CFormInput, CFormLabel, CFormTextarea, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle, CRow } from '@coreui/react'
import React, { useState, useEffect } from 'react'
import { DataTableComponent, DeleteModal, PrimaryButton } from '../../components'
import axios from '../../api/axios';
import { FaPencilAlt, FaTrash } from 'react-icons/fa';
import { toast } from 'react-toastify';

const StorageLocation = () => {

    const accessToken = localStorage.getItem('authToken');
    const [storageLocationList, setStorageLocationList] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [storageLocatioId, setStorageLocatioId] = useState('');
    const [addBtnLoading, setAddBtnLoading] = useState(false);
    const [isEditBtnLoading, setIsEditBtnLoading] = useState(false);
    const [isDeleteBtnLoading, setIsDeleteBtnLoading] = useState(false);
    const [storageLocationInputData, setStorageLocationInputData] = useState({
        name: '',
        address: ''
    })
    const [editedData, setEditedData] = useState({});
    const [openEditModal, setopenEditModal] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [errors, setErrors] = useState({});
    const [editErrors, setEditErrors] = useState({});
    const [pagination, setPagination] = useState({ page: 1, rowsPerPage: 10 });

    const columns = [
        {
          name: "Sr.No",
          // cell: (row, index) => (pagination.page - 1) * pagination.rowsPerPage + index + 1,
          cell: (row, index) => <div className="fs-7">{row.serialNumber ? row.serialNumber : "--------"}</div>,
          center: true,
          width: "90px"
        },
        {
          name: "NAME",
          selector: (row) => row.name,
          cell: (row) => (
            <div className="fs-7">{row.name ? row.name : "--------"}</div>
          ),
          width: "220px"
        },
        {
            name: "ADDRESS",
            selector: (row) => row.address,
            cell: (row) => (
              <div className="fs-7">{row.address ? row.address : "--------"}</div>
            ),
            width: "290px"
          },
        {
          name: "ACTIONS",
          right: "true",
          cell: (row) => (
            <div className="d-flex justify-content-around align-items-center">
              <a
                href="#"
                onClick={() => handleEditModal(row)}
                className="text-primary me-2 d-flex align-items-center gap-1"
                style={{ textDecoration: "none" }}
              >
                <FaPencilAlt /> EDIT
              </a>{" "}
              |
              <a
                href="#"
                className="text-danger ms-2 d-flex align-items-center gap-1"
                onClick={() => handleDeleteModal(row)}
                style={{ textDecoration: "none" }}
              >
                <FaTrash /> DELETE
              </a>
            </div>
          ),
          minWidth: "165px",
        },
    ]

    const updatedColumns = columns.map((column) => ({
            ...column,
            name : (
            <div style={{ fontSize: "13px", fontWeight: "bold" }}>{column.name}</div>
            )
        }) 
    )

    const getStorageLocation = async() => {
        try {
            const headers = {
                Authorization : `Bearer ${accessToken}`
            }
            const response = await axios.get('storage-location', { headers })
            response.data?.data?.storageLocations.forEach((item, index) => {
                item.serialNumber = index + 1;
            });
            setStorageLocationList(response?.data?.data?.storageLocations);
            setFilteredData(response?.data?.data?.storageLocations);
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                  position: "top-right",
                  autoClose: 3000,
                  theme: "colored",
                });
            }
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setStorageLocationInputData((prevState) => ({ ...prevState, [name]: value }));
    }

    const handleSubmit = async(e) => {
        e.preventDefault();
        if(validateForm()) {
            setAddBtnLoading(true);
            try {
                const headers = {
                    Authorization: `Bearer ${accessToken}`
                }
                const response = await axios.post('storage-location', storageLocationInputData, { headers });
                if (response?.data?.status === true) {
                    toast.success(response?.data?.data?.message, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                    });
                    getStorageLocation();
                    setStorageLocationInputData({
                        name: "",
                        address: ""
                    });
                }
            } catch (error) {
                if (error?.response) {
                    let errorMessage = '';
                    if (
                    error.response?.status === 406
                    ) {
                    errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
                    }
                    else if (
                    error.response?.status === 412
                    ) {
                    errorMessage = error?.response?.data?.data?.message;
                    } else if (error.response?.status === 500) {
                    errorMessage = error?.response?.data?.error
                    }
        
                    toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                    });
                }
            } finally {
                setAddBtnLoading(false);
            }
        }
    }

    const handleEditModal = (data) => {        
        setopenEditModal(true);
        setEditedData({
            _id: data?._id,
            name: data?.name,
            address: data?.address
        })
    }

    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setEditedData((prevState) => ({ ...prevState, [name]: value} ))
    }

    const handleEditSubmit = async(e) => {
        e.preventDefault();
        if( validateEditForm() ) {
            setIsEditBtnLoading(true);
            try {
                const headers = {
                    Authorization: `Bearer ${accessToken}`
                }
                const response = await axios.put('storage-location', editedData, { headers });
                if (response?.data?.status === true) {
                    toast.success(response?.data?.data?.message, {
                        position: "top-right",
                        autoClose: 3000,
                        theme: "colored",
                    });
                    getStorageLocation();
                    setopenEditModal(false);
                }
            } catch (error) {
                if (error?.response) {
                    let errorMessage = '';
                    if ( error.response?.status === 406 ) {
                        errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
                    }
                    else if ( error.response?.status === 412 ) {
                        errorMessage = error?.response?.data?.data?.message;
                    } else if (error.response?.status === 500) {
                        errorMessage = error?.response?.data?.error
                    }
                    toast.error(errorMessage, {
                        position: "top-right",
                        autoClose: 3000,
                        theme: "colored",
                    });
                }
            } finally {
                setIsEditBtnLoading(false);
            }
        }
    }

    const handleSearchChange = (e) => {
        setSearchText(e.target.value)
    }

    const handleDeleteModal = (data) => {
        setOpenDeleteModal(true);
        setStorageLocatioId(data._id)
    }

    const handleDeleteStorageLocation = async(e) => {
        e.preventDefault();
        setIsDeleteBtnLoading(true);
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`
            }
            const data = {
                _id: storageLocatioId
            }
            const response = await axios.delete('storage-location', { data, headers });
            if (response?.data?.status === true) {
                toast.success(response?.data?.data?.message, {
                  position: "top-right",
                  autoClose: 3000,
                  theme: "colored",
                });
                getStorageLocation();
                setOpenDeleteModal(false);
            }
        } catch (error) {
            if (error.response.status === 403) {
                toast.error(error?.response?.data?.message, {
                  position: "top-right",
                  autoClose: 3000,
                  theme: "colored",
                });
            }
        
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                  position: "top-right",
                  autoClose: 3000,
                  theme: "colored",
                });
            }
        } finally {
            setIsDeleteBtnLoading(false);
        }
    }

    const handleCloseDeleteModal = () => {
        setOpenDeleteModal(false);
    }

    const validateForm = () => {
        const newErrors = {};
        const trimmedTitle = storageLocationInputData?.name.trim();
        const wordCount = trimmedTitle.split(/\s+/).length;
        const specialCharacterPattern = /[!@#$%^&*(),.?":{}|<>]/;

        if(!storageLocationInputData?.name) {
            newErrors.name = "Name is required";
        } else if (!isNaN(storageLocationInputData?.name) || /^-\d+$/.test(storageLocationInputData?.name)) {
            newErrors.name = "Name should not contain numeric or a negative integer";
        } else if (trimmedTitle.length < 2) { // Check if title has at least 2 letters
            newErrors.name = "Name must contain at least 2 letters";
        } else if (specialCharacterPattern.test(storageLocationInputData?.name)) {
            newErrors.name = "Name should not contain special characters";
        }
        // if(!versionForm.is_current_version) {
        if (storageLocationInputData.address === "" || storageLocationInputData.address === null || storageLocationInputData.address === undefined) {
            newErrors.address = "Address is required"
        } else if (!isNaN(storageLocationInputData?.address) || /^-\d+$/.test(storageLocationInputData?.address)) {
            newErrors.address = "Address should not contain numeric or a negative integer";
        } else if (trimmedTitle.length < 2) { // Check if title has at least 2 letters
            newErrors.address = "Address must contain at least 2 letters";
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    }

    const validateEditForm = () => {
        const newErrors = {};
        const trimmedTitle = editedData?.name.trim();
        const wordCount = trimmedTitle.split(/\s+/).length;
        const specialCharacterPattern = /[!@#$%^&*(),.?":{}|<>]/;

        if(!editedData?.name) {
            newErrors.name = "Name is required";
        } else if (!isNaN(editedData?.name) || /^-\d+$/.test(editedData?.name)) {
            newErrors.name = "Name should not contain numeric or a negative integer";
        } else if (trimmedTitle.length < 2) { // Check if title has at least 2 letters
            newErrors.name = "Name must contain at least 2 letters";
        } else if (specialCharacterPattern.test(editedData?.name)) {
            newErrors.name = "Name should not contain special characters";
        }
        // if(!versionForm.is_current_version) {
        if (editedData.address === "" || editedData.address === null || editedData.address === undefined) {
            newErrors.address = "Address is required"
        } 
        // else if (!isNaN(editedData?.address) || /^-\d+$/.test(editedData?.address)) {
        //     newErrors.address = "Address should not contain numeric or a negative integer";
         else if (trimmedTitle.length < 2) { // Check if title has at least 2 letters
            newErrors.address = "Address must contain at least 2 letters";
        }
        setEditErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    }

    const handlePageChange = (page) => {
        setPagination({ ...pagination, page });
      };
      
    const handleRowsPerPageChange = (rowsPerPage) => {
        setPagination({ page: 1, rowsPerPage }); // Reset to the first page when changing rows per page
    };
    
    // Function to get paginated data
    const getPaginatedData = () => {
        const startIndex = (pagination.page - 1) * pagination.rowsPerPage;
        const endIndex = startIndex + pagination.rowsPerPage;
        return filteredData.slice(startIndex, endIndex);
    };

    useEffect(() => {
        getStorageLocation();
    }, [])

    useEffect(() => {
        const result = storageLocationList.filter((item) => {
            return item?.name.toLowerCase().match(searchText.toLocaleLowerCase());
        });
        setFilteredData(result);
    }, [searchText])

    return (
        <CContainer>
            <CRow>
                {/* <------------- Add Form of Storage Location ------------> */}
                <CCol className='col-md-4 col-lg-4'>
                    <CCard style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}>
                        <CCardHeader>
                            <CCardTitle className="fs-6 fw-semibold pt-1">ADD STORAGE LOCATION</CCardTitle>
                        </CCardHeader>
                        <CForm onSubmit={handleSubmit}>
                            <CCardBody>
                                <div className="mb-3">
                                    <CFormLabel className="fw-semibold">
                                        Name <span className="text-danger">*</span>
                                    </CFormLabel>
                                    <CFormInput
                                        type="text"
                                        placeholder="Name"
                                        className="form-control"
                                        name="name"
                                        value={storageLocationInputData?.name}
                                        onChange={handleChange}
                                    />
                                    {errors.name && <div className='text-danger' style={{ fontSize : "12px"}}>{errors.name}</div>}
                                </div>
                                <div className="mb-3">
                                    <CFormLabel className="fw-semibold">
                                    Address <span className="text-danger">*</span>
                                    </CFormLabel>
                                    <CFormTextarea
                                    rows={4}
                                    type="text"
                                    placeholder="Address"
                                    className="form-control"
                                    name="address"
                                    value={storageLocationInputData?.address}
                                    onChange={handleChange}
                                    />
                                    {errors.address && <div className='text-danger' style={{ fontSize : "12px"}}>{errors.address}</div>}
                                </div>
                            </CCardBody>
                            <CCardFooter>
                                <PrimaryButton title="ADD STORAGE LOCATION"/>
                            </CCardFooter>
                        </CForm>
                    </CCard>
                </CCol>

                {/* <------------- List Of Storage Locations ---------------> */}
                <CCol className='col-md-8 col-lg-8'>
                    <DataTableComponent 
                        columns={updatedColumns}
                        // data={filteredData}
                        title="STORAGE LOCATION LIST"
                        data={getPaginatedData()} // Use the paginated data here
                        searchText={searchText}
                        onChange={handleSearchChange}
                        striped
                        itemsPerPage={10}
                        pagination
                        totalRows={filteredData?.length}
                        paginationTotalRows={filteredData?.length} // Total number of items
                        onChangeRowsPerPage={handleRowsPerPageChange}
                        onChangePage={handlePageChange}
                        paginationServer={false} // Set to false for client-side pagination
                    />
                </CCol>
            </CRow>

            {/* <----------------- Edit Storage Location Modal ---------------------> */}
            {openEditModal ? (
                <CModal
                    alignment="center"
                    visible={openEditModal}
                    onClose={() => setopenEditModal(false)}
                    aria-labelledby="VerticallyCenteredExample"
                >
                    <CModalHeader>
                        <CModalTitle id="VerticallyCenteredExample" className="fs-6">
                            EDIT STORAGE LOCATION
                        </CModalTitle>
                    </CModalHeader>
                    <CForm onSubmit={handleEditSubmit}>
                        <CModalBody>
                            <div className="mb-3">
                                <CFormLabel className="fw-semibold">
                                    Name <span className="text-danger">*</span>
                                </CFormLabel>
                                <CFormInput
                                    type="text"
                                    placeholder="Name"
                                    // required
                                    className="form-control"
                                    name="name"
                                    value={editedData?.name}
                                    onChange={handleEditChange}
                                />
                                {editErrors.name && <div className='text-danger' style={{ fontSize : "12px"}}>{editErrors.name}</div>}
                            </div>
                            <div className="mb-3">
                                <CFormLabel className="fw-semibold">
                                Address <span className="text-danger">*</span>
                                </CFormLabel>
                                <CFormTextarea
                                    rows={4}
                                    type="text"
                                    placeholder="Address"
                                    className="form-control"
                                    name="address"
                                    value={editedData?.address}
                                    onChange={handleEditChange}
                                />
                                {editErrors.address && <div className='text-danger' style={{ fontSize : "12px"}}>{editErrors.address}</div>}
                            </div>
                        </CModalBody>
                        <CModalFooter>
                            <PrimaryButton title="Save Changes" />
                        </CModalFooter>
                    </CForm>
                </CModal>
            ) : null}

            {/* <------------------- Delete Storage Location Modal ---------------------> */}
            {openDeleteModal ? (
                <DeleteModal isVisible={openDeleteModal} onClose={handleCloseDeleteModal} onConfirm={handleDeleteStorageLocation} isLoading={isDeleteBtnLoading} title="DELETE STORAGE LOCATION" />
            ) : null}

        </CContainer>
    )
}

export default StorageLocation