import React, { useEffect, useState } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { toast } from "react-toastify";
import axios from "../../api/axios";
import {
  CCol,
  CContainer,
  CRow,
  CButton,
  CModal,
  CModalHeader,
  CModalTitle,
  CForm,
  CModalBody,
  CModalFooter,
  CFormLabel,
  CFormInput,
  CFormSelect,
  CFormTextarea,
} from "@coreui/react";
import { format } from "date-fns";
import { DeleteModal, PrimaryButton } from "../../components";
import DatePicker from "react-datepicker";
import "./CouponCard.css"

const CouponCard = () => {
  const accessToken = localStorage.getItem("authToken");
  const animatedComponents = makeAnimated();
  const [couponsData, setCouponsData] = useState([]);
  const [activeTab, setActiveTab] = useState("ACTIVE");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [isDeleteBtnLoading, setIsDeleteBtnLoading] = useState(false);
  const [couponId, setCouponId] = useState("");
  const [productsList, setProductsList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [singleCouponDetails, setSingleCouponDetails] = useState({});
  const [outlets, setOutlets] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectExcludedProducts, setSelectExcludedProducts] = useState([]);
  const [isEditSizeUpgradeCouponModalOpen, setIsEditSizeUpgradeCouponModalOpen] = useState(false)
  const [editSizeUpgradeCouponData, setEditSizeUpgradeCouponData] = useState({})
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("ALL");
  const [searchText, setSearchText] = useState('');
  const [addonList, setAddonList] = useState([]);
  const outletData = outlets.map((out) => ({
    value: out._id,
    label: `${out.name} (${out.city})`
  }))
  const productsData = productsList.map((p) => ({
    value: p._id,
    label: `${p.name}`,
    catId: p?.category?._id
  }))
  const filteredProductsData = productsData.filter( product => 
    selectedCategories.some(category => product.catId === category.value)
  )
  const categoriesData = categoryList.map((c) => ({
    value: c._id,
    label: `${c.name}`
  }))
  const [isEditBogoCouponModalOpen, setIsEditBogoCouponModalOpen] =
    useState(false);
  const [editedBogoCouponData, setEditedBogoCouponData] = useState({
    isBogoCoupon: true,
    isHidden: "",
    title: "",
    couponCode: "",
    description: "",
    usageType: "",
    validityStartDate: "",
    validityEndDate: "",
  });

  const [isEditCategoryCouponModalOpen, setIsEditCategoryCouponModalOpen] = useState(false);
  const [editedCategoryCouponData, setEditedCategoryCouponData] = useState({
    isHidden: "",
    title: "",
    couponCode: "",
    description: "",
    usageType: "",
    discountPercent: "",
    discountAmount: "",
    maxDiscountAmount: "",
    validityStartDate: "",
    validityEndDate: "",
  });

  const [isEditSpecialCategoryCouponModalOpen, setIsEditSpecialCategoryCouponModalOpen] = useState(false);
  const [editedSpecialCategoryCouponData, setEditedSpecialCategoryCouponData] = useState({
    isHidden: "",
    title: "",
    couponCode: "",
    description: "",
    usageType: "",
    discountPrice: "",
    applicableQuantityType: "",
    validityStartDate: "",
    validityEndDate: "",
  });

  const [isEditProductCouponModalOpen, setIsEditProductCouponModalOpen] = useState(false);
  const [editedProductCouponData, setEditedProductCouponData] = useState({
    isHidden: "",
    title: "",
    couponCode: "",
    description: "",
    usageType: "",
    discountPercent: "",
    discountAmount: "",
    maxDiscountAmount: "",
    validityStartDate: "",
    validityEndDate: "",
  });

  const [isEditSpecialProductCouponModalOpen, setIsEditSpecialProductCouponModalOpen] = useState(false);
  const [editedSpecialProductCouponData, setEditedSpecialProductCouponData] = useState({
    isHidden: "",
    title: "",
    couponCode: "",
    description: "",
    usageType: "",
    discountPrice: "",
    applicableQuantityType: "",
    validityStartDate: "",
    validityEndDate: "",
  })

  const [isEditCartCouponModalOpen, setIsEditCartCouponModalOpen] =
    useState(false);

  const [editedCartCouponDetails, setEditedCartCouponDetails] = useState({
    isHidden: "",
    title: "",
    couponCode: "",
    description: "",
    usageType: "",
    discountPercent: "",
    discountAmount: "",
    maxDiscountAmount: "",
    minOrderAmount: "",
    validityStartDate: "",
    validityEndDate: "",
  });

  const [isEditUniversalCouponModalOpen, setIsEditUniversalCouponModalOpen] = useState(false);
  const [editedUniversalCouponDetails, setEditedUniversalCouponDetails] = useState({
    isHidden: "",
    title: "",
    couponCode: "",
    description: "",
    usageType: "",
    discountPercent: "",
    validityStartDate: "",
    validityEndDate: "",
  })

  const activeCoupons = couponsData
    .filter((coupon) => {
      if (!coupon.validityEndDate && !coupon.validityStartDate) {
        return true;
      }
      const validityEndDate = new Date(coupon.validityEndDate);
      return validityEndDate >= new Date();
    })
    .sort((a, b) => {
      if (!a.validityEndDate && !b.validityEndDate) return 0;
      if (!a.validityEndDate) return -1;
      if (!b.validityEndDate) return 1;
      return new Date(a.validityEndDate) - new Date(b.validityEndDate);
    });

  const inactiveCoupons = couponsData
    .filter((coupon) => {
      if (!coupon.validityEndDate && !coupon.validityStartDate) {
        return false;
      }
      const validityEndDate = new Date(coupon.validityEndDate);
      return validityEndDate < new Date();
    })
    .sort((a, b) => {
      if (!a.validityEndDate && !b.validityEndDate) return 0;
      if (!a.validityEndDate) return -1;
      if (!b.validityEndDate) return 1;
      return new Date(a.validityEndDate) - new Date(b.validityEndDate);
    });

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      width: "100%", // Adjust width based on your requirement
      borderRadius: '6px',
      minWidth: "200px", // Add a minimum width to avoid layout issues
      borderColor: state.isFocused ? "#80bdff" : "#ced4da",
      boxShadow: state.isFocused ? "0 0 0 0.2rem rgba(0,123,255,.25)" : null,
      "&:hover": {
        borderColor: state.isFocused ? "#80bdff" : "#ced4da",
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#6c757d"
    }),
  };

  const getCategoryList = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get("masters/category/get", { headers });

      if (response?.data?.status === true) {
        setCategoryList(response?.data?.data?.categories);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const getProductsList = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get("product", { headers });
      if (response?.data?.status === true) {
        setProductsList(response?.data?.data?.products);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const getAllCouponsData = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get("coupons", { headers });

      if (response?.data?.status === true) {
        setCouponsData(response?.data?.data?.coupons);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const formatDate = (date) => {
    const validityEndDate = new Date(date);
    const formattedDate = format(validityEndDate, "yyyy-MM-dd");

    return formattedDate;
  };

  const handleDeleteModal = async (data) => {
    setOpenDeleteModal(true);
    setCouponId(data._id);
  };

  const handleDeleteCoupon = async (e) => {
    e.preventDefault();
    setIsDeleteBtnLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      const data = {
        _id: couponId,
      };

      const response = await axios.delete(`coupons`, { data, headers });
      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
        getAllCouponsData();
        setOpenDeleteModal(false);
      }
    } catch (error) {
      if (error.response.status === 403) {
        toast.error(error?.response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }

      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      setIsDeleteBtnLoading(false);
    }
  };

  const handleCartValidStartDateChange = (date) => {
    if (date) {
      const formattedDate = format(new Date(date), "yyyy-MM-dd");
      setEditedCartCouponDetails((prev) => ({
        ...prev,
        validityStartDate: formattedDate,
      }));
    } else {
      setEditedCartCouponDetails((prev) => ({
        ...prev,
        validityStartDate: null,
      }));
    }
  };

  const handleCartValidEndDateChange = (date) => {
    if (date) {
      const formattedDate = format(new Date(date), "yyyy-MM-dd");
      setEditedCartCouponDetails((prev) => ({
        ...prev,
        validityEndDate: formattedDate,
      }));
    } else {
      setEditedCartCouponDetails((prev) => ({
        ...prev,
        validityEndDate: null,
      }));
    }
  };

  const handleUniversalValidStartDateChange = (date) => {
    if (date) {
      const formattedDate = format(new Date(date), "yyyy-MM-dd");
      setEditedCartCouponDetails((prev) => ({
        ...prev,
        validityStartDate: formattedDate,
      }));
    } else {
      setEditedCartCouponDetails((prev) => ({
        ...prev,
        validityStartDate: null,
      }));
    }
  };

  const handleUniversalValidEndDateChange = (date) => {
    if (date) {
      const formattedDate = format(new Date(date), "yyyy-MM-dd");
      setEditedCartCouponDetails((prev) => ({
        ...prev,
        validityEndDate: formattedDate,
      }));
    } else {
      setEditedCartCouponDetails((prev) => ({
        ...prev,
        validityEndDate: null,
      }));
    }
  };

  const handleBogoValidityStartDateChange = (date) => {
    if (date) {
      const formattedDate = format(new Date(date), "yyyy-MM-dd");
      setEditedBogoCouponData((prev) => ({
        ...prev,
        validityStartDate: formattedDate,
      }));
    } else {
      setEditedBogoCouponData((prev) => ({
        ...prev,
        validityStartDate: null,
      }));
    }
  };

  const handleBogoValidityEndDateChange = (date) => {
    if (date) {
      const formattedDate = format(new Date(date), "yyyy-MM-dd");
      setEditedBogoCouponData((prev) => ({
        ...prev,
        validityEndDate: formattedDate,
      }));
    } else {
      setEditedBogoCouponData((prev) => ({
        ...prev,
        validityEndDate: null,
      }));
    }
  };

  const getOutlets = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get("outlet", { headers });
      if (response?.data?.status === true) {
        setOutlets(response?.data?.data?.outlets);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  }

  const handleProductValidityStartDateChange = (date) => {
    if (date) {
      const formattedDate = format(new Date(date), "yyyy-MM-dd");
      setEditedSpecialProductCouponData((prev) => ({
        ...prev,
        validityStartDate: formattedDate,
      }));
      setEditedProductCouponData((prev) => ({
        ...prev,
        validityStartDate: formattedDate,
      }));
    } else {
      setEditedProductCouponData((prev) => ({
        ...prev,
        validityStartDate: null,
      }));
      setEditedSpecialProductCouponData((prev) => ({
        ...prev,
        validityStartDate: null,
      }))
    }
  };

  const handleProductValidityEndDateChange = (date) => {
    if (date) {
      const formattedDate = format(new Date(date), "yyyy-MM-dd");
      setEditedProductCouponData((prev) => ({
        ...prev,
        validityEndDate: formattedDate,
      }));
      setEditedSpecialProductCouponData((prev) => ({
        ...prev,
        validityEndDate: formattedDate,
      }));
    } else {
      setEditedProductCouponData((prev) => ({
        ...prev,
        validityEndDate: null,
      }));
      setEditedSpecialProductCouponData((prev) => ({
        ...prev,
        validityEndDate: null,
      }));
    }
  };

  const handleCategoryValidityStartDateChange = (date) => {
    if (date) {
      const formattedDate = format(new Date(date), "yyyy-MM-dd");
      setEditedSpecialCategoryCouponData((prev) => ({
        ...prev,
        validityStartDate: formattedDate,
      }));
      setEditedCategoryCouponData((prev) => ({
        ...prev,
        validityStartDate: formattedDate,
      }));
    } else {
      setEditedSpecialCategoryCouponData((prev) => ({
        ...prev,
        validityStartDate: null,
      }));
      setEditedCategoryCouponData((prev) => ({
        ...prev,
        validityStartDate: null,
      }));
    }
  };

  const handleCategoryValidityEndDateChange = (date) => {
    if (date) {
      const formattedDate = format(new Date(date), "yyyy-MM-dd");
      setEditedCategoryCouponData((prev) => ({
        ...prev,
        validityEndDate: formattedDate,
      }));
      setEditedSpecialCategoryCouponData((prev) => ({
        ...prev,
        validityEndDate: formattedDate,
      }));
    } else {
      setEditedCategoryCouponData((prev) => ({
        ...prev,
        validityEndDate: null,
      }));
      setEditedSpecialCategoryCouponData((prev) => ({
        ...prev,
        validityEndDate: null,
      }));
    }
  };

  // VERY OLD ONE
  // const filteredAddons = editSizeUpgradeCouponData?.applicableProducts ? addonList?.filter(rm => rm.product._id === editSizeUpgradeCouponData?.applicableProducts) : [];
  
  // BEFORE ONE 
  // const filteredAddons = selectedProduct ? addonList?.filter(rm => rm?.product?._id === selectedProduct) : [];
  
  const filteredAddons = selectedProduct 
  ? addonList?.filter(rm => {
      return String(rm?.product?._id) === String(selectedProduct);
    })
  : [];

  const getAddonProductsList = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get("product-addon", { headers });
      if (response?.data?.status === true) {
        const addOns = response?.data?.data?.productAddons;
        const productsWithSizeAddon = addOns.filter((item) =>
          item.addons.some((addon) => addon.addonTitle === 'Size')
        );
        
        // const sizeAddons = addOns.filter((item) => item?.addonTitle === "Size");
        setAddonList(productsWithSizeAddon)
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };
  
  const handleEditModal = async (data) => {
    const formattedStartDate = data?.validityStartDate ? format(data?.validityStartDate, "yyyy-MM-dd") : null;
    const formattedEndDate = data?.validityEndDate ? format(data?.validityEndDate, "yyyy-MM-dd") : null;

    if (data?.sizeUpgradeAddon) {
      if (data?.sizeUpgradeAddon !== null || data?.sizeUpgradeAddon !== undefined) {
        setIsEditSizeUpgradeCouponModalOpen(true)
        setEditSizeUpgradeCouponData({
          _id: data?._id,
          isHidden: data?.isHidden,
          title: data?.title,
          couponCode: data?.couponCode,
          sizeUpgradeAddon: data?.sizeUpgradeAddon?._id,
          description: data?.description,
          usageType: data?.usageType,
          validityStartDate: formattedStartDate,
          validityEndDate: formattedEndDate,
          // applicableProducts: data?.applicableProducts[0]?._id,
        })
        let selecteData = data?.excludedOutlets;
        let mappedSelectedData = selecteData.map((m) =>
          ({
            value: m?._id,
            label: m?.name
          })
        )
        setSelectedProduct(data?.applicableProducts[0]?._id);
        setSelectedOptions(mappedSelectedData);
      }
    }

    if (data?.isBogoCoupon === true) {
      setIsEditBogoCouponModalOpen(true);
      setEditedBogoCouponData({
        _id: data?._id,
        isHidden: data?.isHidden,
        isBogoCoupon: true,
        couponCode: data.couponCode,
        title: data?.title,
        description: data?.description,
        usageType: data?.usageType,
        validityStartDate: formattedStartDate,
        validityEndDate: formattedEndDate,
        excludedOutlets: data?.excludedOutlets
      });

      let selecteData = data?.excludedOutlets;
      let mappedSelectedData = selecteData.map((m) =>
      ({
        value: m?._id,
        label: m?.name
      })
      )
      setSelectedOptions(mappedSelectedData)
    }

    if (data?.applicableCategories !== null && data?.applicableCategories.length > 0 && data?.applicableQuantityType === null) {
      setIsEditCategoryCouponModalOpen(true);
      setEditedCategoryCouponData({
        _id: data?._id,
        isHidden: data?.isHidden,
        title: data?.title,
        couponCode: data?.couponCode,
        description: data?.description,
        usageType: data?.usageType,
        discountPercent: data?.discountPercent,
        discountAmount: data?.discountAmount,
        maxDiscountAmount: data?.maxDiscountAmount,
        // applicableCategory: data?.applicableCategory?._id,
        validityStartDate: formattedStartDate,
        validityEndDate: formattedEndDate,
      });

      let selectedEditedCategory = data?.excludedOutlets;
      let selectedUpdatedCategories = data?.applicableCategories;
      let selectEditedExProducts = data?.excludedProducts;
      
      let mappedSelectedEditedCategoryData = selectedEditedCategory?.map((m) =>
      ({
        value: m?._id,
        label: m?.name
      })
      )
      let mappedSelectedUpdatedProducts = selectedUpdatedCategories?.map((uc) => ({
        value: uc?._id,
        label: uc?.name
      }))
      let mappedSelectEditedExProducts = selectEditedExProducts?.map((e) => ({
        value: e?._id,
        label: e?.name
      }))      
      setSelectedOptions(mappedSelectedEditedCategoryData);
      setSelectedCategories(mappedSelectedUpdatedProducts);
      setSelectExcludedProducts(mappedSelectEditedExProducts)
    }

    if (data?.applicableCategories?.length > 0 && data?.applicableQuantityType !== null) {
      setIsEditSpecialCategoryCouponModalOpen(true);
      setEditedSpecialCategoryCouponData({
        _id: data?._id,
        isHidden: data?.isHidden,
        title: data?.title,
        couponCode: data?.couponCode,
        description: data?.description,
        usageType: data?.usageType,
        discountPrice: data?.discountPrice,
        applicableQuantityType: data?.applicableQuantityType,
        validityStartDate: formattedStartDate,
        validityEndDate: formattedEndDate,
      })
      let selectedEditedCategory = data?.excludedOutlets;
      let selectedUpdatedCategories = data?.applicableCategories;
      let selectEditedExProducts = data?.excludedProducts;
      
      let mappedSelectedEditedCategoryData = selectedEditedCategory?.map((m) =>
      ({
        value: m?._id,
        label: m?.name
      })
      )
      let mappedSelectedUpdatedProducts = selectedUpdatedCategories?.map((uc) => ({
        value: uc?._id,
        label: uc?.name
      }))
      let mappedSelectEditedExProducts = selectEditedExProducts?.map((e) => ({
        value: e?._id,
        label: e?.name
      }))      
      setSelectedOptions(mappedSelectedEditedCategoryData);
      setSelectedCategories(mappedSelectedUpdatedProducts);
      setSelectExcludedProducts(mappedSelectEditedExProducts)
    }

    if (data?.applicableProducts?.length > 0 && data?.applicableQuantityType !==null){
      setIsEditSpecialProductCouponModalOpen(true);
      setEditedSpecialProductCouponData({
        _id: data?._id,
        isHidden: data?.isHidden,
        title: data?.title,
        couponCode: data?.couponCode,
        description: data?.description,
        usageType: data?.usageType,
        discountPrice: data?.discountPrice,
        applicableQuantityType: data?.applicableQuantityType,
        validityStartDate: formattedStartDate,
        validityEndDate: formattedEndDate,
      })
      let selectedEditedProduct = data?.excludedOutlets;
      let selectedUpdatedProducts = data?.applicableProducts;
      let mappedSelectedEditedProductData = selectedEditedProduct.map((m) =>
      ({
        value: m?._id,
        label: m?.name
      })
      )
      let mappedSelectedUpdatedProducts = selectedUpdatedProducts.map((up) => ({
        value: up?._id,
        label: up?.name
      }))
      setSelectedOptions(mappedSelectedEditedProductData);
      setSelectedProducts(mappedSelectedUpdatedProducts);
    }

    if (!data?.sizeUpgradeAddon) {
      if (data?.applicableProducts !== null && data?.applicableProducts.length > 0 && data?.applicableQuantityType === null) {
        setIsEditProductCouponModalOpen(true);

        setEditedProductCouponData({
          _id: data?._id,
          isHidden: data?.isHidden,
          title: data?.title,
          couponCode: data?.couponCode,
          description: data?.description,
          usageType: data?.usageType,
          discountPercent: data?.discountPercent,
          discountAmount: data?.discountAmount,
          maxDiscountAmount: data?.maxDiscountAmount,
          // applicableProduct: data?.applicableProduct?._id,
          validityStartDate: formattedStartDate,
          validityEndDate: formattedEndDate,
        });

        let selectedEditedProduct = data?.excludedOutlets;
        let selectedUpdatedProducts = data?.applicableProducts;
        let mappedSelectedEditedProductData = selectedEditedProduct.map((m) =>
        ({
          value: m?._id,
          label: m?.name
        })
        )
        let mappedSelectedUpdatedProducts = selectedUpdatedProducts.map((up) => ({
          value: up?._id,
          label: up?.name
        }))
        setSelectedOptions(mappedSelectedEditedProductData);
        setSelectedProducts(mappedSelectedUpdatedProducts);
      }
    }
    
    if (
      data?.applicableProducts.length === 0 &&
      data?.applicableQuantityType === null &&
      data?.applicableCategories.length === 0 &&
      data?.isBogoCoupon === false && data?.minOrderAmount !== null
    ) {
      setIsEditCartCouponModalOpen(true);
      setEditedCartCouponDetails({
        _id: data?._id,
        isHidden: data?.isHidden,
        title: data?.title,
        couponCode: data?.couponCode,
        description: data?.description,
        usageType: data?.usageType,
        discountPercent: data?.discountPercent,
        discountAmount: data?.discountAmount,
        maxDiscountAmount: data?.maxDiscountAmount,
        minOrderAmount: data?.minOrderAmount,
        validityStartDate: formattedStartDate,
        validityEndDate: formattedEndDate,
      });

      let selectedEditGeneral = data?.excludedOutlets;
      let mappedSelectedEditGeneralData = selectedEditGeneral.map((m) =>
      ({
        value: m?._id,
        label: m?.name
      })
      )
      setSelectedOptions(mappedSelectedEditGeneralData);
    }

    if (
      data?.applicableProducts.length === 0 &&
      data?.applicableQuantityType === null &&
      data?.applicableCategories.length === 0 &&
      data?.minOrderAmount === null &&
      data?.isBogoCoupon === false
    ) {
      setIsEditUniversalCouponModalOpen(true);
      setEditedUniversalCouponDetails({
        _id: data?._id,
        isHidden: data?.isHidden,
        title: data?.title,
        couponCode: data?.couponCode,
        description: data?.description,
        usageType: data?.usageType,
        discountPercent: data?.discountPercent,
        validityStartDate: formattedStartDate,
        validityEndDate: formattedEndDate,
      });

      let selectedEditGeneral = data?.excludedOutlets;
      let mappedSelectedEditGeneralData = selectedEditGeneral.map((m) =>
      ({
        value: m?._id,
        label: m?.name
      })
      )
      setSelectedOptions(mappedSelectedEditGeneralData);
    }
  };

  const handleSizeUpgradeInputChange = (e) => {
    const { name, value } = e.target;
    
    if (name === "applicableProducts") {
      setSelectedProduct([value])
    }

    // if(name==="applicableProducts"){
    //   setEditSizeUpgradeCouponData((prev) => ({ ...prev, [name]: [value] }));
    // }
    else{
      setEditSizeUpgradeCouponData((prev) => ({ ...prev, [name]: value }));
    }

    // setEditSizeUpgradeCouponData((prev) => ({ ...prev, [name]: value }));
  }
  
  const handleSizeUpgradeValidityStartDateChange = (date) => {
    if (date) {
      const formattedDate = format(new Date(date), "yyyy-MM-dd");
      setEditSizeUpgradeCouponData((prev) => ({
        ...prev,
        validityStartDate: formattedDate,
      }));
    } else {
      setEditSizeUpgradeCouponData((prev) => ({
        ...prev,
        validityStartDate: null,
      }));
    }
  };

  const handleSizeUpgradeValidityEndDateChange = (date) => {
    if (date) {
      const formattedDate = format(new Date(date), "yyyy-MM-dd");
      setEditSizeUpgradeCouponData((prev) => ({
        ...prev,
        validityEndDate: formattedDate,
      }));
    } else {
      setEditSizeUpgradeCouponData((prev) => ({
        ...prev,
        validityEndDate: null,
      }));
    }
  };

  const submitSizeUpgradeCouponDetails = async (e) => {
    e.preventDefault();
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };      
      let selectedOutlet = selectedOptions.map((options) => options.value);
      let selectedAppProd = Array.isArray(selectedProduct) 
          ? selectedProduct  // If it's already an array, use it as-is
          : [selectedProduct];  // If it's a single value, wrap it in an array
      let editedSizeUpgradeData = {
        ...editSizeUpgradeCouponData,
        excludedOutlets: selectedOutlet,
        applicableProducts: selectedAppProd
      }
      const response = await axios.put("coupons", editedSizeUpgradeData, {
        headers,
      });

      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
        setEditSizeUpgradeCouponData({
          title: "",
          couponCode: "",
          description: "",
          usageType: "",
          validityStartDate: "",
          validityEndDate: "",
        });
        getAllCouponsData();
        setIsEditSizeUpgradeCouponModalOpen(false);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;

        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const handleBogoInputChange = (e) => {
    const { name, value } = e.target;
    setEditedBogoCouponData((prev) => ({ ...prev, [name]: value }));
  };

  const submitBogoCouponDetails = async (e) => {
    e.preventDefault();
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      let selectedOutlet = selectedOptions.map((options) => options.value);

      let editedBogoData = {
        ...editedBogoCouponData,
        excludedOutlets: selectedOutlet
      }

      const response = await axios.put("coupons", editedBogoData, {
        headers,
      });

      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });

        setEditedBogoCouponData({
          title: "",
          couponCode: "",
          description: "",
          usageType: "",
          validityStartDate: "",
          validityEndDate: "",
        });
        getAllCouponsData();
        setIsEditBogoCouponModalOpen(false);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;

        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const handleCategoryCouponChange = (e) => {
    const { name, value } = e.target;
    setEditedCategoryCouponData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSpecialCategoryCouponChange = (e) => {
    const { name, value } = e.target;
    setEditedSpecialCategoryCouponData((prev) => ({ ...prev, [name]: value }));
  }

  const submitCategoryCouponDetails = async (e) => {
    e.preventDefault();

    if (editedCategoryCouponData?.discountAmount !== null) {
      delete editedCategoryCouponData?.discountPercent;
      delete editedCategoryCouponData?.maxDiscountAmount
    } else if (editedCategoryCouponData?.discountPercent !== null) {
      delete editedCategoryCouponData?.discountAmount;
    }

  if (editedCategoryCouponData?.maxDiscountAmount === null) {
    delete editedCategoryCouponData.maxDiscountAmount;
  }

    let selectedOutlet = selectedOptions.map((option) => option.value);
    let selectedCategoriesInfo = selectedCategories.map((cat) => cat.value);
    let selectedExProdcutsInfo = selectExcludedProducts.map((ep) => ep.value);
    let editedCategoryData = {
      ...editedCategoryCouponData,
      excludedOutlets: selectedOutlet,
      applicableCategories: selectedCategoriesInfo,
      excludedProductList: selectedExProdcutsInfo
    }
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      

      const response = await axios.put("coupons", editedCategoryData, {
        headers,
      });

      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });

        setEditedCategoryCouponData({
          title: "",
          couponCode: "",
          description: "",
          usageType: "",
          discountPercent: "",
          discountAmount: "",
          applicableCategory: "",
          validityStartDate: "",
          validityEndDate: "",
        });

        getAllCouponsData();
        setIsEditCategoryCouponModalOpen(false);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = '';
        if ( error.response?.status === 406 ) {
          errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
        } else if ( error.response?.status === 404 ) {
          errorMessage = error?.response?.data?.data?.title;
        } else if ( error.response?.status === 412 ) {
            errorMessage = error?.response?.data?.data?.title;
        } else if (error.response?.status === 500) {
          errorMessage = error?.response?.data?.error
        }
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const submitSpecialCategoryCouponDetails = async (e) => {
    e.preventDefault();
    let selectedOutlet = selectedOptions.map((option) => option.value);
    let selectedCategoriesInfo = selectedCategories.map((cat) => cat.value);
    let editedSpecialCategoryData = {
      ...editedSpecialCategoryCouponData,
      excludedOutlets: selectedOutlet,
      applicableCategories: selectedCategoriesInfo,
    }
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      const response = await axios.put("coupons", editedSpecialCategoryData, {
        headers,
      });

      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });

        setEditedSpecialCategoryCouponData({
          title: "",
          couponCode: "",
          description: "",
          usageType: "",
          discountPrice: "",
          applicableQuantityType: "",
          validityStartDate: "",
          validityEndDate: "",
        });

        getAllCouponsData();
        setIsEditSpecialCategoryCouponModalOpen(false);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = '';
        if ( error.response?.status === 406 ) {
          errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
        } else if ( error.response?.status === 404 ) {
          errorMessage = error?.response?.data?.data?.title;
        } else if ( error.response?.status === 412 ) {
            errorMessage = error?.response?.data?.data?.title;
        } else if (error.response?.status === 500) {
          errorMessage = error?.response?.data?.error
        }
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  }

  const handleProductCouponChange = (e) => {
    const { name, value } = e.target;
    setEditedProductCouponData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSpecialProductCouponChange = (e) => {
    const { name, value } = e.target;
    setEditedSpecialProductCouponData((prev) => ({ ...prev, [name]: value }));
  }

  const submitProductCouponDetails = async (e) => {
    e.preventDefault();

    if (editedProductCouponData?.discountAmount !== null) {
      delete editedProductCouponData?.discountPercent;
      delete editedProductCouponData?.maxDiscountAmount;
    } else if (editedProductCouponData?.discountPercent !== null) {
      delete editedProductCouponData?.discountAmount;
    }

    if(editedProductCouponData?.maxDiscountAmount === null) {
      delete editedProductCouponData?.maxDiscountAmount;
    }

    let selectedOutlet = selectedOptions.map((option) => option.value);
    let selectedProductsInfo = selectedProducts.map((prods) => prods.value);
    let editedProductData = {
      ...editedProductCouponData,
      excludedOutlets: selectedOutlet,
      applicableProducts: selectedProductsInfo
    }
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      const response = await axios.put("coupons", editedProductData, {
        headers,
      });

      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });

        setEditedProductCouponData({
          title: "",
          couponCode: "",
          description: "",
          usageType: "",
          discountPercent: "",
          discountAmount: "",
          applicableProduct: "",
          validityStartDate: "",
          validityEndDate: "",
        });

        getAllCouponsData();
        setIsEditProductCouponModalOpen(false);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = '';
        if ( error.response?.status === 406 ) {
          errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
        } else if ( error.response?.status === 404 ) {
          errorMessage = error?.response?.data?.data?.title;
        } else if ( error.response?.status === 412 ) {
            errorMessage = error?.response?.data?.data?.title;
        } else if (error.response?.status === 500) {
          errorMessage = error?.response?.data?.error
        }
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const submitSpecialProductCouponDetails = async (e) => {
    e.preventDefault();
    let selectedOutlet = selectedOptions.map((option) => option.value);
    let selectedProductsInfo = selectedProducts.map((prods) => prods.value);
    let editedSpecialProductData = {
      ...editedSpecialProductCouponData,
      excludedOutlets: selectedOutlet,
      applicableProducts: selectedProductsInfo
    }
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      const response = await axios.put("coupons", editedSpecialProductData, {
        headers,
      });

      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });

        setEditedSpecialProductCouponData({
          title: "",
          couponCode: "",
          description: "",
          usageType: "",
          discountPrice: "",
          applicableQuantityType: "",
          validityStartDate: "",
          validityEndDate: "",
        });

        getAllCouponsData();
        setIsEditSpecialProductCouponModalOpen(false);
      }
    } catch (error) {
      let errorMessage = '';
        if ( error.response?.status === 406 ) {
          errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
        } else if ( error.response?.status === 404 ) {
          errorMessage = error?.response?.data?.data?.title;
        } else if ( error.response?.status === 412 ) {
            errorMessage = error?.response?.data?.data?.title;
        } else if (error.response?.status === 500) {
          errorMessage = error?.response?.data?.error
        }
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
    }
  }

  const handleCartInputChange = (e) => {
    const { name, value } = e.target;
    setEditedCartCouponDetails((prev) => ({ ...prev, [name]: value }));
  };

  const submitCartCouponDetails = async (e) => {
    e.preventDefault();

    if (editedCartCouponDetails?.discountPercent) {
      delete editedCartCouponDetails?.discountAmount;
      delete editedCartCouponDetails?.maxDiscountAmount;
    } else if (editedCartCouponDetails?.discountAmount) {
      delete editedCartCouponDetails?.discountPercent;
    }

    if(editedCartCouponDetails?.maxDiscountAmount === null) {
      delete editedCartCouponDetails?.maxDiscountAmount
    }

    let selectedOutlet = selectedOptions.map((option) => option.value);
    let editedGeneralData = {
      ...editedCartCouponDetails,
      excludedOutlets: selectedOutlet
    }
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.put("coupons", editedGeneralData, {
        headers,
      });

      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });

        getAllCouponsData();
        setEditedCartCouponDetails({
          title: "",
          couponCode: "",
          description: "",
          usageType: "",
          discountPercent: "",
          discountAmount: "",
          maxDiscountAmount: "",
          minOrderAmount: "",
          validityStartDate: "",
          validityEndDate: "",
        });
        setIsEditCartCouponModalOpen(false);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;

        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const handleUniversalInputChange = (e) => {
    const { name, value } = e.target;
    setEditedUniversalCouponDetails((prev) => ({ ...prev, [name]: value }));
  };

  const submitUniversalCouponDetails = async (e) => {
    e.preventDefault();

    let selectedOutlet = selectedOptions.map((option) => option.value);
    let editedGeneralData = {
      ...editedUniversalCouponDetails,
      excludedOutlets: selectedOutlet
    }
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.put("coupons", editedGeneralData, {
        headers,
      });

      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });

        getAllCouponsData();
        setEditedCartCouponDetails({
          title: "",
          couponCode: "",
          description: "",
          usageType: "",
          discountPercent: "",
          validityStartDate: "",
          validityEndDate: "",
        });
        setIsEditUniversalCouponModalOpen(false);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = '';
        if ( error.response?.status === 406 ) {
          errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
        } else if ( error.response?.status === 404 ) {
          errorMessage = error?.response?.data?.data?.title;
        } else if ( error.response?.status === 412 ) {
            errorMessage = error?.response?.data?.data?.title;
        } else if (error.response?.status === 500) {
          errorMessage = error?.response?.data?.error
        }
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const handleOpenViewModal = (data) => {
    getSingleCouponDetails(data?._id);
    setOpenViewModal(true);
  };

  const getSingleCouponDetails = async (couponId) => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get(`coupons?_id=${couponId}`, { headers });

      if (response?.data?.status === true) {
        setSingleCouponDetails(response?.data?.data?.coupons);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const updatedDate = (date) => {
    const formattedDate = date?.split("T")[0];
    return formattedDate;
  };

  const handleSelectChange = (selected) => {
    setSelectedOptions(selected);
  }

  const handleSelectProductChange = (prods) => {
    setSelectedProducts(prods);
  }

  const handleSelectCategoriesChange = (cats) => {
    setSelectedCategories(cats);
  }

  const handleSelectExProductChannge = (ex) => {
    setSelectExcludedProducts(ex);
  }

  const convertDate = (dateString) => {
    if (!dateString) {
      return "Invalid Date";
    }

    const months = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];

    const dateParts = dateString.split("-");
    if (dateParts.length !== 3) {
      return "Invalid Date";
    }

    const year = dateParts[0];
    const month = parseInt(dateParts[1], 10) - 1;
    const day = dateParts[2];

    if (isNaN(month) || isNaN(day) || isNaN(year)) {
      return "Invalid Date";
    }

    const formattedDate = `${day} ${months[month]} ${year}`;

    return formattedDate;
  }

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false)
  }

  const handleFilterChange = (event) => {
    setSelectedFilter(event.target.value);
  };

  const filterCoupons = (coupons) => {
    if (selectedFilter === "ALL") {
      return coupons;
    }
    return coupons.filter((coupon) => {
      if (selectedFilter === "BOGO" && coupon?.isBogoCoupon) return true;
      if (selectedFilter === "UNIVERSAL" && coupon?.applicableProducts.length === 0 && coupon?.applicableQuantityType === null && coupon?.applicableCategories.length === 0 && coupon?.minOrderAmount === null && coupon?.isBogoCoupon === false) return true;
      if (selectedFilter === "CATEGORY" && coupon?.applicableCategories !== null && coupon?.applicableCategories.length > 0 && coupon?.applicableQuantityType === null) return true;
      if (selectedFilter === "PRODUCT" && coupon?.applicableProducts !== null && coupon?.applicableProducts.length > 0 && coupon?.applicableQuantityType === null && !coupon?.sizeUpgradeAddon) return true;
      if (selectedFilter === "SIZEUPGRADE" && coupon?.sizeUpgradeAddon) return true;
      if (selectedFilter === "CARTCOUPON" && coupon?.applicableProducts.length === 0 && coupon?.applicableQuantityType === null && coupon?.applicableCategories.length === 0 && coupon?.isBogoCoupon === false && coupon?.minOrderAmount !== null) return true;
      if (selectedFilter === "SPECIALPRODUCT" && coupon?.applicableProducts?.length > 0 && coupon?.applicableQuantityType !==null) return true;
      if (selectedFilter === "SPECIALCATEGORY" && coupon?.applicableCategories?.length > 0 && coupon?.applicableQuantityType !== null) return true;   
    });
  };

  const handleSearchProducts = (e) => {
    e.preventDefault();
  }

  const filteredActiveCoupons = filterCoupons(activeCoupons);
  const filteredInactiveCoupons = filterCoupons(inactiveCoupons);  

  useEffect(() => {
    getAllCouponsData();
    getProductsList();
    getCategoryList();
    getOutlets();
    getAddonProductsList()
  }, []);

  return (
    <CContainer className="mb-3">
      <div>

        {/* Dropdown for Filtering */}
        {/* <div className="d-flex justify-content-center mb-3" style={{marginTop: '-10px', alignItems: 'center'}}>
          <CFormSelect
            aria-label="Filter coupons"
            onChange={handleFilterChange}
            value={selectedFilter}
            className="w-50 ms-2 text-center"
            style={{ textAlignLast: 'center' }}            
          >
            <option value="ALL">ALL COUPONS</option>
            <option value="CARTCOUPON">CART COUPON</option>
            <option value="BOGO">BOGO COUPON</option>
            <option value="PRODUCT">PRODUCT COUPON</option>
            <option value="CATEGORY">CATEGORY COUPON</option>
            <option value="UNIVERSAL">UNIVERSAL COUPON</option>
            <option value="SIZEUPGRADE">SIZE UPGRADE COUPON</option>
            <option value="SPECIALPRODUCT">SPECIAL PRODUCT COUPON</option>
            <option value="SPECIALCATEGORY">SPECIAL CATEGORY COUPON</option>
          </CFormSelect>
        </div> */}

        <CRow className="mb-3">
            <CCol md="5" lg="6" >
              <CFormLabel className="fw-semibold">Filter By Coupon Type</CFormLabel>
              <CFormSelect
                aria-label="Filter coupons"
                onChange={handleFilterChange}
                value={selectedFilter}
                className="ms-2 text-center"
                style={{ textAlignLast: 'center' }}
              >
                <option value="ALL">ALL COUPONS</option>
                <option value="CARTCOUPON">CART COUPON</option>
                <option value="BOGO">BOGO COUPON</option>
                <option value="PRODUCT">PRODUCT COUPON</option>
                <option value="CATEGORY">CATEGORY COUPON</option>
                <option value="UNIVERSAL">UNIVERSAL COUPON</option>
                <option value="SIZEUPGRADE">SIZE UPGRADE COUPON</option>
                <option value="SPECIALPRODUCT">SPECIAL PRODUCT COUPON</option>
                <option value="SPECIALCATEGORY">SPECIAL CATEGORY COUPON</option>
              </CFormSelect>
            </CCol>
            <CCol md="5" lg="4"> 
                <CFormLabel className="fw-semibold text-white">.</CFormLabel>
                <CFormInput
                  type="text"
                  placeholder="Search By Coupon Name Or Coupon Code"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
            </CCol>
            <CCol md="2" lg="2" className="text-center">
                <CFormLabel className="fw-semibold text-white">.</CFormLabel>
                <CButton className="btn rounded-pill w-100" disabled={!searchText} onClick={handleSearchProducts}>
                    Search
                </CButton>
            </CCol>
        </CRow>

        <div
          className="d-flex justify-content-center gap-2 mb-2"
          style={{ cursor: "pointer" }}
        >
          <div
            color="none"
            className={`w-50 ${activeTab === "ACTIVE" ? "text-secondary" : "text-dark"
              } text-center p-2 fw-semibold`}
            onClick={() => handleTabChange("ACTIVE")}
            style={{
              borderBottom:
                activeTab === "ACTIVE"
                  ? "2px solid #bcbcbd"
                  : "1px solid #bcbcbd",
              transition: "border-bottom 0.3s ease-in-out",
              background: activeTab === "ACTIVE" ? "#e7e9eb" : "none",
              border: "1px solid #bcbcbd",
              borderRadius: "5px",
            }}
          >
            ACTIVE
          </div>
          <div
            color="none"
            className={`w-50 ${activeTab === "INACTIVE" ? "text-secondary" : "text-dark"
              } text-center p-2 fw-semibold`}
            onClick={() => handleTabChange("INACTIVE")}
            style={{
              borderBottom:
                activeTab === "INACTIVE"
                  ? "2px solid #bcbcbd"
                  : "1px solid #bcbcbd",
              transition: "border-bottom 0.3s ease-in-out",
              background: activeTab === "INACTIVE" ? "#e7e9eb" : "none",
              border: "1px solid #bcbcbd",
              borderRadius: "5px",
            }}
          >
            INACTIVE
          </div>
        </div>

        {activeTab === "ACTIVE" && (
          <CRow>
            {/* filteredActiveCoupons, activeCoupons */}
            {filteredActiveCoupons?.map((item, index) => (
              <CCol key={index} sm="12" md="6" lg="3" className="mb-4 mt-2">
                <div className="coupon-card">
                  <div className="coupon-header mb-3 text-center">
                    <h6 className="coupon-title">{item?.title}</h6>
                  </div>
                  <div className="coupon-body">
                    <h6 className="coupon-discount text-center text-success mb-3">
                      {item?.discountAmount !== null && `₹. ${item?.discountAmount} OFF`}
                      {item?.discountPrice !== null && `₹. ${item?.discountPrice} `}
                      {item?.discountPercent !== null && `${item?.discountPercent} % OFF`}
                      {item?.sizeUpgradeAddon && "Size Upgrade Available"}
                      {item?.isBogoCoupon && "BOGO"}
                    </h6>
                    <p className="coupon-details">
                      {item?.applicableCategories?.length === 0 &&
                        item?.applicableProducts?.length === 0 &&
                        !item?.isBogoCoupon ? (
                        <span style={{ fontSize: "13px" }} className="text-muted">
                          On all items in our store
                        </span>
                      ) : null}
                      {item?.applicableCategories?.length > 0 && (
                        <span style={{ fontSize: "13px" }} className="d-block mb-2">
                          <strong>Applicable Categories:</strong> {" "}
                          {item?.applicableCategories.map((cat, index) => (
                            <span key={index} className="fw-semibold">
                              {cat?.name}
                              {index < item.applicableCategories.length - 1 ? ", " : "."}
                            </span>
                          ))}
                        </span>
                      )}
                      {item?.applicableProducts?.length > 0 && (
                        <span style={{ fontSize: "12px" }} className="d-block mb-2">
                          <strong style={{ fontWeight: '600' }}>Applicable Products:</strong> {" "}
                          {item?.applicableProducts.map((prod, index) => (
                            <span key={index} className="fw-semibold">
                              {prod?.name}
                              {index < item.applicableProducts.length - 1 ? ", " : "."}
                            </span>
                          ))}
                        </span>
                      )}
                    </p>
                    <p className="validity text-center" style={{ fontSize: '13px' }}>
                      {item?.validityEndDate ? (
                        <><strong>Valid until:</strong> <span className="text-danger">{convertDate(formatDate(item.validityEndDate))}</span></>
                      ) : (
                        <div style={{ color: 'darkgoldenrod', fontWeight: '500' }}>Valid For Unlimited Duration</div>
                      )}
                    </p>
                    <div className="coupon-code text-center mt-2">
                      <p>Coupon Code: <strong>{item?.couponCode}</strong></p>
                    </div>
                    <div className="coupon-hidden-status text-center mt-2">
                      <p style={{ fontSize: "13px", fontWeight: "bold", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <span className="me-2">Coupon Hidden:</span>
                        <span
                          className={`d-flex align-items-center ${item?.isHidden ? "text-danger" : "text-success"}`}
                        >
                          {item?.isHidden ? (
                            <>
                              <i className="fa fa-times-circle me-1"></i> YES
                            </>
                          ) : (
                            <>
                              <i className="fa fa-check-circle me-1"></i> NO
                            </>
                          )}
                        </span>
                      </p>
                    </div>

                  </div>
                  <div className="coupon-footer d-flex justify-content-around mt-1">
                    <button className="redeem-button details-button btn btn-primary btn-sm" onClick={() => handleOpenViewModal(item)}>
                      Details
                    </button>
                    <button className="redeem-button modify-button btn btn-warning btn-sm" onClick={() => handleEditModal(item)}>
                      Update
                    </button>
                    <button className="redeem-button remove-button btn btn-danger btn-sm" onClick={() => handleDeleteModal(item)}>
                      Remove
                    </button>
                  </div>
                </div>
              </CCol>
            ))}
          </CRow>
        )}

        {activeTab === "INACTIVE" && (
          <CRow>
            {/* filteredInactiveCoupons, inactiveCoupons */}
            {filteredInactiveCoupons?.map((item, index) => (
              <CCol key={index} sm="12" md="6" lg="3" className="mb-4 mt-2">
                <div className="coupon-card">
                  <div className="coupon-header mb-3 text-center">
                    <h6 className="coupon-title">{item?.title}</h6>
                  </div>
                  <div className="coupon-body">
                    <h3 style={{ fontSize: '16px' }} className="coupon-discount text-center text-muted mb-3">
                      {item?.discountAmount !== null && `₹. ${item?.discountAmount} OFF`}
                      {item?.discountPercent !== null && `${item?.discountPercent} % OFF`}
                      {item?.discountPrice !== null && `₹. ${item?.discountPrice}`}
                      {item?.sizeUpgradeAddon && "Size Upgrade Available"}
                      {item?.isBogoCoupon && "BOGO"}
                    </h3>
                    <p className="coupon-details">
                      {item?.applicableCategories?.length === 0 &&
                        item?.applicableProducts?.length === 0 &&
                        !item?.isBogoCoupon ? (
                        <span style={{ fontSize: "13px" }} className="text-muted">
                          On all items in our store
                        </span>
                      ) : null}
                      {item?.applicableCategories?.length > 0 && (
                        <span style={{ fontSize: "13px" }} className="d-block mb-2">
                          <strong>Applicable Categories:</strong> {" "}
                          {item?.applicableCategories.map((cat, index) => (
                            <span key={index} className="fw-semibold">
                              {cat?.name}
                              {index < item.applicableCategories.length - 1 ? ", " : "."}
                            </span>
                          ))}
                        </span>
                      )}
                      {item?.applicableProducts?.length > 0 && (
                        <span style={{ fontSize: "13px" }} className="d-block mb-2">
                          <strong>Applicable Products:</strong> {" "}
                          {item?.applicableProducts.map((prod, index) => (
                            <span key={index} className="fw-semibold">
                              {prod?.name}
                              {index < item.applicableProducts.length - 1 ? ", " : "."}
                            </span>
                          ))}
                        </span>
                      )}
                    </p>
                    <p className="validity text-center" style={{ fontSize: '13px' }}>
                      {item?.validityEndDate ? (
                        <><strong>Valid until:</strong> <span>{convertDate(formatDate(item.validityEndDate))}</span></>
                      ) : (
                        <>No End Date</>
                      )}
                    </p>
                    <div className="coupon-code text-center mt-2">
                      <p>Coupon Code: <strong>{item?.couponCode}</strong></p>
                    </div>
                    <div className="coupon-hidden-status text-center mt-2">
                      <p style={{ fontSize: "13px", fontWeight: "bold", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <span className="me-2">Coupon Hidden:</span>
                        <span
                          className={`d-flex align-items-center ${item?.isHidden ? "text-danger" : "text-success"}`}
                        >
                          {item?.isHidden ? (
                            <>
                              <i className="fa fa-times-circle me-1"></i> YES
                            </>
                          ) : (
                            <>
                              <i className="fa fa-check-circle me-1"></i> NO
                            </>
                          )}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="coupon-footer d-flex justify-content-around mt-1">
                    <button className="redeem-button details-button btn btn-primary btn-sm" onClick={() => handleOpenViewModal(item)}>
                      Details
                    </button>
                    <button className="redeem-button modify-button btn btn-warning btn-sm" onClick={() => handleEditModal(item)}>
                      Update
                    </button>
                    <button className="redeem-button remove-button btn btn-danger btn-sm" onClick={() => handleDeleteModal(item)}>
                      Remove
                    </button>
                  </div>
                </div>
              </CCol>
            ))}
          </CRow>
        )}
        
      </div>

      {openDeleteModal ? (
        <DeleteModal isVisible={openDeleteModal} onClose={handleCloseDeleteModal} onConfirm={handleDeleteCoupon} isLoading={isDeleteBtnLoading} title="DELETE COUPON" />
      ) : null}

      {/* {openViewModal ? (
        <CModal
          alignment="center"
          visible={openViewModal}
          onClose={() => setOpenViewModal(false)}
          aria-labelledby="VerticallyCenteredExample"          
        >
          <div className="view-coupon-card">
            <div className="coupon-header">
              <h4>{singleCouponDetails?.title}</h4>
            </div>
            <div className="coupon-body">
              {singleCouponDetails?.discountAmount !== null ? (
                <h3>
                  {singleCouponDetails?.discountAmount} .Rs OFF
                </h3>
              ) : null}
              {singleCouponDetails?.discountPercent !== null ? (
                <>
                  <h3>{singleCouponDetails?.discountPercent}% OFF</h3>
                </>
              ) : null}
              <p className="applicable">
                {singleCouponDetails?.applicableCategories !== null && singleCouponDetails?.applicableCategories?.length > 0 && (
                  <>
                    <i>
                      Applicable Categories :
                      {singleCouponDetails?.applicableCategories?.map((cat, index) => {
                        return <i>{" "}{cat?.name}{index < singleCouponDetails?.applicableCategories?.length - 1 ? ", " : "."}</i> 
                      })}
                    </i> <br />
                    {singleCouponDetails?.excludedProducts?.length > 0 && (
                      <i>Excluding Products :
                        {singleCouponDetails?.excludedProducts?.map((ep, index) => {
                          return <i>{" "}{ep?.name}{ index < singleCouponDetails?.excludedProducts?.length - 1 ? ", " : ".  "}</i>
                        })}
                      </i>
                    )}
                  </>
                )}
                {singleCouponDetails?.applicableProducts !== null && singleCouponDetails?.applicableProducts?.length > 0 && (
                  <i>
                    Applicable Products : 
                    { singleCouponDetails?.applicableProducts?.map((prod) => {
                      return <i>{" "}{prod?.name}</i>
                    })}
                  </i>
                )}
                {singleCouponDetails?.applicableCategories?.length === 0 &&
                  singleCouponDetails?.applicableProducts?.length === 0 &&
                  singleCouponDetails?.isBogoCoupon === false && (
                    <span className="fw-semibold">
                      On all items in our store
                    </span>
                  )}
              </p>
              <p className="validity">
                {singleCouponDetails?.validityEndDate ? (
                  <>Valid until: <span>{convertDate(updatedDate(singleCouponDetails?.validityEndDate))}</span></>
                ) : (
                  <>No Validity</>
                )}
              </p>
              <div >
                {
                  singleCouponDetails?.maxDiscountAmount && singleCouponDetails?.minOrderAmount && (
                    <p className="special-offer">
                      {`Max ${singleCouponDetails?.maxDiscountAmount} Rs OFF on minimum purchase of ${singleCouponDetails?.minOrderAmount} Rs`}
                    </p>
                  )
                }
              </div>

              <div className="coupon-code">
                <p>Coupon Code: <strong>{singleCouponDetails?.couponCode}</strong></p>
              </div>
              <div className="coupon-details">
                <div className="detail-item">
                  <span className="detail-title">Description:</span>
                  <span className="ps-1">{singleCouponDetails?.description}</span>
                </div>
                {singleCouponDetails?.excludedOutlets && singleCouponDetails?.excludedOutlets?.length > 0 && (
                  <div className="detail-item">
                    <span className="detail-title">Outlets Excluded:</span>
                    <span>
                      {singleCouponDetails.excludedOutlets.map(outlet => outlet.name).join(', ')}
                    </span>
                  </div>
                )}
                <div className="detail-item">
                  <span className="detail-title">Usage Type:</span>
                  <span>{singleCouponDetails?.usageType}</span>
                </div>
                <div className="detail-item">
                  <span className="detail-title">Is Coupon Hidden :</span>
                  <span>{singleCouponDetails?.isHidden ? "Yess" : "No"}</span>
                </div>
                <div className="detail-item">
                  <span className="detail-title">Valid From:</span>
                  <span>{convertDate(updatedDate(singleCouponDetails?.validityStartDate))}</span>
                </div>
                <div className="detail-item">
                  <span className="detail-title">Addon Name</span>
                  <span>{singleCouponDetails?.sizeUpgradeAddon?.addonTitle}</span>
                </div>

              </div>
            </div>
          </div>
          <CModalFooter>
            <CButton
              className="btn btn-primary"
              onClick={() => setOpenViewModal(false)}
            >
              CLOSE
            </CButton>
          </CModalFooter>
        </CModal>
      ) : null} */}

{openViewModal ? (
  <CModal
    alignment="center"
    visible={openViewModal}
    onClose={() => setOpenViewModal(false)}
    aria-labelledby="VerticallyCenteredExample"
  >
    <div
      style={{
        padding: "10px",
        // backgroundColor: "#ffffff",
        borderRadius: "8px",
        // boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
        maxWidth: "600px",
        margin: "10px"
      }}
    >
      <h4 style={{ textAlign: "center", color: "purple", marginBottom: "15px" }}>
        {singleCouponDetails?.title}
      </h4>

      <div style={{ marginBottom: "10px", color: "#28a745", fontWeight: "bold", fontSize: "1.2em", textAlign: "center" }}>
        {singleCouponDetails?.discountAmount ? (
          `${singleCouponDetails.discountAmount} Rs OFF`
        ) : (
          `${singleCouponDetails.discountPercent}% OFF`
        )}
      </div>

      <p style={{ fontSize: "0.95em", color: "#666", textAlign: "center" }}>
        {singleCouponDetails?.applicableCategories?.length ? (
          <span>
            Applicable Categories: {singleCouponDetails.applicableCategories.map((cat, index) => (
              <span key={index}>{cat.name}{index < singleCouponDetails.applicableCategories.length - 1 ? ", " : "."}</span>
            ))}
          </span>
        ) : (
          "On all items in our store"
        )}
      </p>

      <div style={{ borderTop: "1px solid #ddd", paddingTop: "15px", marginTop: "10px" }}>
        <CRow className="mb-3">
          <CCol md="6">
            <h6 className="text-secondary">Coupon Code : <span className="text-info" style={{ fontSize: '14px' }}>{singleCouponDetails?.couponCode}</span></h6>
            {/* <p style={{ fontSize: "0.9em", color: "#444", marginBottom: "8px" }}>
              <strong>Coupon Code :</strong> {singleCouponDetails?.couponCode}
            </p> */}
          </CCol>
          <CCol md="6">
            <h6 className="text-secondary">Usage Type : <span style={{ fontSize: '14px'}}>{singleCouponDetails?.usageType}</span></h6>
            {/* <p style={{ fontSize: "0.9em", color: "#444", marginBottom: "8px" }}>
              <strong>Usage Type :</strong> {singleCouponDetails?.usageType}
            </p> */}
          </CCol>
        </CRow>

        <CRow className="mb-3">
          <CCol>
            <h6 className="text-secondary">Is Coupon Hidden : <span style={{ fontSize: '14px'}}>{singleCouponDetails?.isHidden ? "Yes" : "No"}</span></h6>
            {/* <p style={{ fontSize: "0.9em", color: "#444", marginBottom: "8px" }}>
              <strong>Is Coupon Hidden :</strong> {singleCouponDetails?.isHidden ? "Yes" : "No"}
            </p> */}
          </CCol>
          <CCol>
            <h6 className="text-secondary">Addon Name : <span style={{ fontSize: '14px'}}>{singleCouponDetails?.sizeUpgradeAddon?.addonTitle || "N/A"}</span></h6>
            {/* <p style={{ fontSize: "0.9em", color: "#444", marginBottom: "8px" }}>
              <strong>Addon Name :</strong> {singleCouponDetails?.sizeUpgradeAddon?.addonTitle || "N/A"}
            </p> */}
          </CCol>
        </CRow>

        <CRow className="mb-3">
          <CCol>
            <h6 className="text-secondary">Valid From : <span style={{ fontSize: '14px'}}>{singleCouponDetails?.validityStartDate ? convertDate(updatedDate(singleCouponDetails?.validityStartDate)) : "No Validity"}</span></h6>
            {/* <p style={{ fontSize: "0.9em", color: "#444", marginBottom: "8px" }}>
              <strong>Valid From :</strong> {singleCouponDetails?.validityStartDate ? convertDate(updatedDate(singleCouponDetails?.validityStartDate)) : "No Validity"}
            </p> */}
          </CCol>
          <CCol>
            <h6 className="text-secondary">Valid Until : <span style={{ fontSize: '14px'}}>{singleCouponDetails.validityEndDate ? convertDate(updatedDate(singleCouponDetails.validityEndDate)) : "No Validity"}</span></h6>
            {/* <p style={{ fontSize: "0.9em", color: "#444", marginBottom: "8px" }}>
              <strong>Valid Until :</strong> {singleCouponDetails.validityEndDate ? convertDate(updatedDate(singleCouponDetails.validityEndDate)) : "No Validity"}
            </p> */}
          </CCol>
        </CRow>
        {/* <p style={{ fontSize: "0.9em", color: "#444", marginBottom: "8px" }}>
          <strong>Valid Until:</strong> {singleCouponDetails.validityEndDate ? convertDate(updatedDate(singleCouponDetails.validityEndDate)) : "No Validity"}
        </p>
        <p style={{ fontSize: "0.9em", color: "#444", marginBottom: "8px" }}>
          <strong>Coupon Code:</strong> {singleCouponDetails?.couponCode}
        </p> */}
        <CRow className="mb-3">
          <h6 className="text-secondary">Description : <span style={{ fontSize: '14px' }}>{singleCouponDetails?.description}</span></h6>
          {/* <p style={{ fontSize: "0.9em", color: "#444", marginBottom: "8px" }}>
            <strong>Description:</strong> {singleCouponDetails?.description}
          </p> */}
        </CRow>

      </div>
    </div>

    <CModalFooter style={{ justifyContent: "center", paddingTop: "10px" }}>
      <CButton
        style={{
          backgroundColor: "#007bff",
          color: "#fff",
          padding: "8px 16px",
          borderRadius: "4px",
          fontSize: "0.9em",
          fontWeight: "bold"
        }}
        onClick={() => setOpenViewModal(false)}
      >
        CLOSE
      </CButton>
    </CModalFooter>
  </CModal>
) : null}




      {isEditBogoCouponModalOpen ? (
        <CModal
          size="lg"
          alignment="center"
          visible={isEditBogoCouponModalOpen}
          onClose={() => setIsEditBogoCouponModalOpen(false)}
          aria-labelledby="VerticallyCenteredExample"
        >
          <CModalHeader>
            <CModalTitle>EDIT BOGO COUPON</CModalTitle>
          </CModalHeader>
          <CForm onSubmit={submitBogoCouponDetails}>
            <CModalBody>
              <CRow className="mb-3">
                <CCol>
                  <div>
                    <CFormLabel className="fw-semibold">
                      Title <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      placeholder="Title"
                      name="title"
                      value={editedBogoCouponData.title}
                      onChange={handleBogoInputChange}
                    />
                  </div>
                </CCol>
                <CCol>
                  <div>
                    <CFormLabel className="fw-semibold">
                      Coupon Code <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      placeholder="Coupon Code"
                      name="couponCode"
                      value={editedBogoCouponData.couponCode}
                      onChange={handleBogoInputChange}
                    />
                  </div>
                </CCol>
                <CCol>
                  <div>
                    <CFormLabel className="fw-semibold">
                      Usage Type <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormSelect
                      name="usageType"
                      value={editedBogoCouponData.usageType}
                      onChange={handleBogoInputChange}
                    >
                      <option value="">Select Usage</option>
                      <option value="MULTIPLE">MULTIPLE</option>
                      <option value="ONE-TIME">ONE-TIME</option>
                    </CFormSelect>
                  </div>
                </CCol>
              </CRow>

              <div className="mb-3">
                <CFormLabel className="fw-semibold">
                  Select Excluded Outlets
                </CFormLabel>

                <Select
                  isMulti
                  components={animatedComponents}
                  value={selectedOptions}
                  onChange={handleSelectChange}
                  options={outletData}
                  styles={customStyles}
                  placeholder="Select options" // You can also use a dynamic placeholder from props or state
                  closeMenuOnSelect={false}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#e9ecef",
                      primary: "#007bff",
                    },
                  })}
                />

              </div>

              <div className="mb-3">
                <CFormLabel className="fw-semibold">
                  Description <span className="text-danger">*</span>
                </CFormLabel>
                <CFormTextarea
                  rows={4}
                  placeholder="Description"
                  name="description"
                  value={editedBogoCouponData.description}
                  onChange={handleBogoInputChange}
                />
              </div>

              <CRow>
                <CCol>
                  <div>
                    <CFormLabel className="fw-semibold">
                      Hide Coupon
                    </CFormLabel>
                    <CFormSelect
                      name="isHidden"
                      value={editedBogoCouponData?.isHidden}
                      onChange={handleBogoInputChange}
                    >
                      <option value="">Select Option</option>
                      <option value="true">YES</option>
                      <option value="false">NO</option>
                    </CFormSelect>
                  </div>
                </CCol>
                <CCol>
                  <div>
                    <CFormLabel className="fw-semibold">
                      Validity Start Date
                    </CFormLabel>
                    <DatePicker
                      onChange={handleBogoValidityStartDateChange}
                      placeholderText="Validity Start Date"
                      className="form-control"
                      selected={editedBogoCouponData?.validityStartDate ? new Date(editedBogoCouponData?.validityStartDate) : null}
                    />
                  </div>
                </CCol>
                <CCol>
                  <div>
                    <CFormLabel className="fw-semibold">
                      Validity End Date
                    </CFormLabel>
                    <DatePicker
                      onChange={handleBogoValidityEndDateChange}
                      placeholderText="Validity End Date"
                      className="form-control"
                      value={editedBogoCouponData?.validityEndDate}
                      selected={editedBogoCouponData?.validityEndDate ? new Date(editedBogoCouponData?.validityEndDate) : null}
                    />
                  </div>
                </CCol>
              </CRow>
            </CModalBody>
            <CModalFooter>
              <PrimaryButton
                title="SAVE CHANGES"
                style={{ borderRadius: "18px" }}
              />
            </CModalFooter>
          </CForm>
        </CModal>
      ) : null}

      {isEditCategoryCouponModalOpen ? (
        <CModal
          size="lg"
          alignment="center"
          visible={isEditCategoryCouponModalOpen}
          onClose={() => setIsEditCategoryCouponModalOpen(false)}
          aria-labelledby="VerticallyCenteredExample"
        >
          <CModalHeader>
            <CModalTitle>EDIT CATEGORY COUPON</CModalTitle>
          </CModalHeader>
          <CForm onSubmit={submitCategoryCouponDetails}>
            <CModalBody>
              <CRow>
                <CCol className="col-md-4">
                  <div>
                    <CFormLabel className="fw-semibold">
                      Title <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      placeholder="Title"
                      name="title"
                      value={editedCategoryCouponData.title}
                      onChange={handleCategoryCouponChange}
                    />
                  </div>
                </CCol>
                <CCol className="col-md-4">
                  <div>
                    <CFormLabel className="fw-semibold">
                      Coupon Code <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      placeholder="Coupon Code"
                      name="couponCode"
                      value={editedCategoryCouponData.couponCode}
                      onChange={handleCategoryCouponChange}
                    />
                  </div>
                </CCol>
                <CCol className="col-md-4">
                  <div>
                    <CFormLabel className="fw-semibold">
                      Usage Type <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormSelect
                      name="usageType"
                      value={editedCategoryCouponData.usageType}
                      onChange={handleCategoryCouponChange}
                    >
                      <option value="">Select Usage</option>
                      <option value="MULTIPLE">MULTIPLE</option>
                      <option value="ONE-TIME">ONE-TIME</option>
                    </CFormSelect>
                  </div>
                </CCol>
              </CRow>

              <CRow className="mb-3 mt-2">
                <CCol className="col-md-6">
                  <CFormLabel className="fw-semibold">
                    Select Categories <span className="text-danger">*</span>
                  </CFormLabel>
                  {/* <CFormSelect
                    name="applicableCategory"
                    value={editedCategoryCouponData.applicableCategory}
                    onChange={handleCategoryCouponChange}
                  >
                    <option value="">Select Category</option>
                    {categoryList.map((rm) => (
                      <option key={rm._id} value={rm._id}>
                        {rm.name}
                      </option>
                    ))}
                  </CFormSelect> */}
                  <Select 
                    isMulti
                    components={animatedComponents}
                    value={selectedCategories}
                    options={categoriesData}
                    onChange={handleSelectCategoriesChange}
                    styles={customStyles}
                    placeholder="Select Categories" // You can also use a dynamic placeholder from props or state
                    closeMenuOnSelect={false}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        primary25: "#e9ecef",
                        primary: "#007bff",
                      },
                    })}
                  />
                </CCol>
                
                <CCol className="col-md-6">
                  <CFormLabel className="fw-semibold">
                    Select Excluded Products 
                  </CFormLabel>
                  <Select 
                    isMulti
                    options={filteredProductsData}
                    value={selectExcludedProducts}
                    onChange={handleSelectExProductChannge}
                    components={animatedComponents}
                    styles={customStyles}
                    placeholder="Select Excluded Products" // You can also use a dynamic placeholder from props or state
                    closeMenuOnSelect={false}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        primary25: "#e9ecef",
                        primary: "#007bff",
                      },
                    })}
                  />
                </CCol>
              </CRow>

              <div className="mb-3">
                <CFormLabel className="fw-semibold">
                  Select Excluded Outlets
                </CFormLabel>

                <Select
                  isMulti
                  components={animatedComponents}
                  value={selectedOptions}
                  onChange={handleSelectChange}
                  options={outletData}
                  styles={customStyles}
                  placeholder="Select options" // You can also use a dynamic placeholder from props or state
                  closeMenuOnSelect={false}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#e9ecef",
                      primary: "#007bff",
                    },
                  })}
                />
              </div>

              <div className="mb-3">
                <CFormLabel className="fw-semibold">
                  Description <span className="text-danger">*</span>
                </CFormLabel>
                <CFormTextarea
                  rows={4}
                  placeholder="Description"
                  name="description"
                  value={editedCategoryCouponData.description}
                  onChange={handleCategoryCouponChange}
                />
              </div>

              <CRow className="mb-3">
                <CCol>
                  <div>
                    <CFormLabel className="fw-semibold">
                      Hide Coupon
                    </CFormLabel>
                    <CFormSelect
                      name="isHidden"
                      value={editedCategoryCouponData?.isHidden}
                      onChange={handleCategoryCouponChange}
                    >
                      <option value="">Select Option</option>
                      <option value="true">YES</option>
                      <option value="false">NO</option>
                    </CFormSelect>
                  </div>
                </CCol>
                <CCol>
                  <div>
                    <CFormLabel className="fw-semibold">
                      Validity Start Date
                    </CFormLabel>
                    <DatePicker
                      onChange={handleCategoryValidityStartDateChange}
                      placeholderText="Validity Start Date"
                      className="form-control"
                      selected={editedCategoryCouponData?.validityStartDate ? new Date(editedCategoryCouponData?.validityStartDate) : null}
                    />
                  </div>
                </CCol>
                <CCol>
                  <div>
                    <CFormLabel className="fw-semibold">
                      Validity End Date
                    </CFormLabel>
                    <DatePicker
                      onChange={handleCategoryValidityEndDateChange}
                      placeholderText="Validity End Date"
                      className="form-control"
                      selected={editedCategoryCouponData?.validityEndDate ? new Date(editedCategoryCouponData?.validityEndDate) : null}
                    />
                  </div>
                </CCol>
              </CRow>
              <CRow>
                {
                  // editedCategoryCouponData.discountPercent &&
                  <CCol>
                    <CFormLabel className="fw-semibold">
                      Discount Percent <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      disabled={
                        editedCategoryCouponData?.discountAmount
                      }
                      placeholder="Discount Percent"
                      type="number"
                      name="discountPercent"
                      value={editedCategoryCouponData.discountPercent}
                      onChange={handleCategoryCouponChange}
                      className="no-arrows"
                    />
                  </CCol>
                }

                {
                  // editedCategoryCouponData.discountAmount &&
                  <CCol>
                    <CFormLabel className="fw-semibold">
                      Discount Amount <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      disabled={
                        editedCategoryCouponData?.discountPercent
                      }
                      placeholder="Discount Amount"
                      type="number"
                      name="discountAmount"
                      value={editedCategoryCouponData.discountAmount}
                      onChange={handleCategoryCouponChange}
                      className="no-arrows"
                    />
                  </CCol>
                }

                {
                  // editedCategoryCouponData.maxDiscountAmount &&
                  <CCol>
                    <CFormLabel className="fw-semibold">
                      Max Discount Amount 
                    </CFormLabel>
                    <CFormInput
                      disabled={
                        editedCategoryCouponData?.discountAmount || editedCategoryCouponData?.maxDiscountAmount === null
                      }
                      placeholder="Max. Discount Amount"
                      type="number"
                      name="maxDiscountAmount"
                      value={editedCategoryCouponData.maxDiscountAmount}
                      onChange={handleCategoryCouponChange}
                      className="no-arrows"
                    />
                  </CCol>
                }
              </CRow>
            </CModalBody>
            <CModalFooter>
              <PrimaryButton
                title="SAVE CHANGES"
                style={{ borderRadius: "18px" }}
              />
            </CModalFooter>
          </CForm>
        </CModal>
      ) : null}

      {isEditSpecialCategoryCouponModalOpen? (
        <CModal
          size="lg"
          alignment="center"
          visible={isEditSpecialCategoryCouponModalOpen}
          onClose={() => setIsEditSpecialCategoryCouponModalOpen(false)}
          aria-labelledby="VerticallyCenteredExample"
        >
          <CModalHeader>
            <CModalTitle>EDIT SPECIAL CATEGORY COUPON</CModalTitle>
          </CModalHeader>
          <CForm onSubmit={submitSpecialCategoryCouponDetails}>
            <CModalBody>
              <CRow>
                <CCol className="col-md-4">
                  <div>
                    <CFormLabel className="fw-semibold">
                      Title <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      placeholder="Title"
                      name="title"
                      value={editedSpecialCategoryCouponData?.title}
                      onChange={handleSpecialCategoryCouponChange}
                    />
                  </div>
                </CCol>
                <CCol className="col-md-4">
                  <div>
                    <CFormLabel className="fw-semibold">
                      Coupon Code <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      placeholder="Coupon Code"
                      name="couponCode"
                      value={editedSpecialCategoryCouponData?.couponCode}
                      onChange={handleSpecialCategoryCouponChange}
                    />
                  </div>
                </CCol>
                <CCol className="col-md-4">
                  <div>
                    <CFormLabel className="fw-semibold">
                      Usage Type <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormSelect
                      name="usageType"
                      value={editedSpecialCategoryCouponData?.usageType}
                      onChange={handleSpecialCategoryCouponChange}
                    >
                      <option value="">Select Usage</option>
                      <option value="MULTIPLE">MULTIPLE</option>
                      <option value="ONE-TIME">ONE-TIME</option>
                    </CFormSelect>
                  </div>
                </CCol>
              </CRow>

              <CRow className="mb-3 mt-2">
                <CCol className="col-md-6">
                  <CFormLabel className="fw-semibold">
                    Select Categories <span className="text-danger">*</span>
                  </CFormLabel>
                  <Select 
                    isMulti
                    components={animatedComponents}
                    value={selectedCategories}
                    options={categoriesData}
                    onChange={handleSelectCategoriesChange}
                    styles={customStyles}
                    placeholder="Select Categories" // You can also use a dynamic placeholder from props or state
                    closeMenuOnSelect={false}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        primary25: "#e9ecef",
                        primary: "#007bff",
                      },
                    })}
                  />
                </CCol>
                <CCol className="col-md-6">
                  <CFormLabel className="fw-semibold">
                    Select Excluded Outlets
                  </CFormLabel>
                  <Select
                    isMulti
                    components={animatedComponents}
                    value={selectedOptions}
                    onChange={handleSelectChange}
                    options={outletData}
                    styles={customStyles}
                    placeholder="Select options" // You can also use a dynamic placeholder from props or state
                    closeMenuOnSelect={false}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        primary25: "#e9ecef",
                        primary: "#007bff",
                      },
                    })}
                  />
                </CCol>
              </CRow>

              <div className="mb-3">
                <CFormLabel className="fw-semibold">
                  Description <span className="text-danger">*</span>
                </CFormLabel>
                <CFormTextarea
                  rows={4}
                  placeholder="Description"
                  name="description"
                  value={editedSpecialCategoryCouponData.description}
                  onChange={handleSpecialCategoryCouponChange}
                />
              </div>

              <CRow className="mb-3">
                <CCol>
                  <div>
                    <CFormLabel className="fw-semibold">
                      Hide Coupon
                    </CFormLabel>
                    <CFormSelect
                      name="isHidden"
                      value={editedSpecialCategoryCouponData?.isHidden}
                      onChange={handleSpecialCategoryCouponChange}
                    >
                      <option value="">Select Option</option>
                      <option value="true">YES</option>
                      <option value="false">NO</option>
                    </CFormSelect>
                  </div>
                </CCol>
                <CCol>
                  <div>
                    <CFormLabel className="fw-semibold">
                      Validity Start Date
                    </CFormLabel>
                    <DatePicker
                      onChange={handleCategoryValidityStartDateChange}
                      placeholderText="Validity Start Date"
                      className="form-control"
                      selected={editedSpecialCategoryCouponData?.validityStartDate ? new Date(editedSpecialCategoryCouponData?.validityStartDate) : null}
                    />
                  </div>
                </CCol>
                <CCol>
                  <div>
                    <CFormLabel className="fw-semibold">
                      Validity End Date
                    </CFormLabel>
                    <DatePicker
                      onChange={handleCategoryValidityEndDateChange}
                      placeholderText="Validity End Date"
                      className="form-control"
                      selected={editedSpecialCategoryCouponData?.validityEndDate ? new Date(editedSpecialCategoryCouponData?.validityEndDate) : null}
                    />
                  </div>
                </CCol>
              </CRow>
              <CRow>
                {
                  editedSpecialCategoryCouponData?.applicableQuantityType &&
                  <CCol>
                      <CFormLabel className="fw-semibold">
                        Applicable Quantity Type <span className="text-danger">*</span>
                      </CFormLabel>
                      <CFormSelect
                        name="applicableQuantityType"
                        value={editedSpecialCategoryCouponData?.applicableQuantityType}
                        onChange={handleSpecialCategoryCouponChange}
                      >
                        <option value="">Select Applicable Quantity Type</option>
                        <option value="SINGLE">SINGLE</option>
                        <option value="MULTIPLE">MULTIPLE</option>
                      </CFormSelect>
                  </CCol>
                }

                <CCol>
                  <CFormLabel className="fw-semibold">
                    Discount Price <span className="text-danger">*</span>
                  </CFormLabel>
                  <CFormInput
                    placeholder="Discount Price"
                    type="number"
                    name="discountPrice"
                    value={editedSpecialCategoryCouponData?.discountPrice}
                    onChange={handleSpecialCategoryCouponChange}
                    className="no-arrows"
                  />
                </CCol>

              </CRow>
            </CModalBody>
            <CModalFooter>
              <PrimaryButton
                title="SAVE CHANGES"
                style={{ borderRadius: "18px" }}
              />
            </CModalFooter>
          </CForm>
        </CModal>
      ) : null}

      {isEditProductCouponModalOpen ? (
        <CModal
          size="lg"
          alignment="center"
          visible={isEditProductCouponModalOpen}
          onClose={() => setIsEditProductCouponModalOpen(false)}
          aria-labelledby="VerticallyCenteredExample"
        >
          <CModalHeader>
            <CModalTitle>EDIT PRODUCT COUPON</CModalTitle>
          </CModalHeader>
          <CForm onSubmit={submitProductCouponDetails}>
            <CModalBody>
              <CRow>
                <CCol className="col-md-4">
                  <div>
                    <CFormLabel className="fw-semibold">
                      Title <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      placeholder="Title"
                      name="title"
                      value={editedProductCouponData.title}
                      onChange={handleProductCouponChange}
                    />
                  </div>
                </CCol>
                <CCol className="col-md-4">
                  <div>
                    <CFormLabel className="fw-semibold">
                      Coupon Code <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      placeholder="Coupon Code"
                      name="couponCode"
                      value={editedProductCouponData.couponCode}
                      onChange={handleProductCouponChange}
                    />
                  </div>
                </CCol>
                <CCol className="col-md-4">
                  <div>
                    <CFormLabel className="fw-semibold">
                      Usage Type <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormSelect
                      name="usageType"
                      value={editedProductCouponData.usageType}
                      onChange={handleProductCouponChange}
                    >
                      <option value="">Select Usage</option>
                      <option value="MULTIPLE">MULTIPLE</option>
                      <option value="ONE-TIME">ONE-TIME</option>
                    </CFormSelect>
                  </div>
                </CCol>
              </CRow>

              <CRow className="mt-2 mb-2">
                <CCol className="col-md-6">
                  <CFormLabel className="fw-semibold">
                    Select Products <span className="text-danger">*</span>
                  </CFormLabel>
                  {/* <CFormSelect
                    name="applicableCategory"
                    value={editedProductCouponData.applicableProduct}
                    onChange={handleProductCouponChange}
                  >
                    <option value="">Select Product</option>
                    {productsList.map((rm) => (
                      <option key={rm._id} value={rm._id}>
                        {rm.name}
                      </option>
                    ))}
                  </CFormSelect> */}
                  <Select 
                    isMulti
                    components={animatedComponents}
                    value={selectedProducts}
                    options={productsData}
                    onChange={handleSelectProductChange}
                    styles={customStyles}
                    placeholder="Select Products"
                    closeMenuOnSelect={false}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        primary25: "#e9ecef",
                        primary: "#007bff",
                      },
                    })}
                  />
                </CCol>

                <CCol className="col-md-6">
                  <CFormLabel className="fw-semibold">
                    Select Excluded Outlets
                  </CFormLabel>

                  <Select
                    isMulti
                    components={animatedComponents}
                    value={selectedOptions}
                    onChange={handleSelectChange}
                    options={outletData}
                    styles={customStyles}
                    placeholder="Select options" // You can also use a dynamic placeholder from props or state
                    closeMenuOnSelect={false}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        primary25: "#e9ecef",
                        primary: "#007bff",
                      },
                    })}
                  />
                </CCol>

              </CRow>

              <div className="mb-3">
                <CFormLabel className="fw-semibold">
                  Description <span className="text-danger">*</span>
                </CFormLabel>
                <CFormTextarea
                  rows={4}
                  placeholder="Description"
                  name="description"
                  value={editedProductCouponData.description}
                  onChange={handleProductCouponChange}
                />
              </div>

              <CRow className="mb-3">
                <CCol md="4" className="mb-3">
                  <div>
                    <CFormLabel className="fw-semibold">
                      Hide Coupon
                    </CFormLabel>
                    <CFormSelect
                      name="isHidden"
                      value={editedProductCouponData?.isHidden}
                      onChange={handleProductCouponChange}
                    >
                      <option value="">Select Option</option>
                      <option value="true">YES</option>
                      <option value="false">NO</option>
                    </CFormSelect>
                  </div>
                </CCol>
                <CCol md="4">
                  <div>
                    <CFormLabel className="fw-semibold">
                      Validity Start Date
                    </CFormLabel>
                    <DatePicker
                      onChange={handleProductValidityStartDateChange}
                      placeholderText="Validity Start Date"
                      className="form-control"
                      selected={editedProductCouponData?.validityStartDate ? new Date(editedProductCouponData?.validityStartDate) : null}
                    />
                  </div>
                </CCol>
                <CCol md="4">
                  <div>
                    <CFormLabel className="fw-semibold">
                      Validity End Date
                    </CFormLabel>
                    <DatePicker
                      onChange={handleProductValidityEndDateChange}
                      placeholderText="Validity End Date"
                      className="form-control"
                      selected={editedProductCouponData?.validityEndDate ? new Date(editedProductCouponData?.validityEndDate) : null}
                    />
                  </div>
                </CCol>
                {
                  // editedProductCouponData?.discountPercent ? (
                    <CCol md="4">
                      <CFormLabel className="fw-semibold">
                        Discount Percent <span className="text-danger">*</span>
                      </CFormLabel>
                      <CFormInput
                        disabled={
                          editedProductCouponData?.discountAmount
                        }
                        placeholder="Discount Percent"
                        type="number"
                        name="discountPercent"
                        value={editedProductCouponData.discountPercent}
                        onChange={handleProductCouponChange}
                        className="no-arrows"
                      />
                    </CCol>
                  // ) : null
                }

                {
                  // editedProductCouponData?.discountAmount ? (
                    <CCol md="4">
                      <CFormLabel className="fw-semibold">
                        Discount Amount <span className="text-danger">*</span>
                      </CFormLabel>
                      <CFormInput
                        disabled={
                          editedProductCouponData?.discountPercent
                        }
                        placeholder="Discount Amount"
                        type="number"
                        name="discountAmount"
                        value={editedProductCouponData.discountAmount}
                        onChange={handleProductCouponChange}
                        className="no-arrows"
                      />
                    </CCol>
                  // ) : null
                }


                {
                  // editedProductCouponData?.maxDiscountAmount ? (
                    <CCol md="4">
                      <CFormLabel className="fw-semibold">
                        Max Discount Amount 
                      </CFormLabel>
                      <CFormInput
                        placeholder="Max. Discount Amount"
                        disabled={editedProductCouponData.maxDiscountAmount === null}
                        type="number"
                        name="maxDiscountAmount"
                        value={editedProductCouponData.maxDiscountAmount}
                        onChange={handleProductCouponChange}
                        className="no-arrows"
                      />
                    </CCol>
                  // ) : null
                }
              </CRow>
            </CModalBody>
            <CModalFooter>
              <PrimaryButton
                title="SAVE CHANGES"
                style={{ borderRadius: "18px" }}
              />
            </CModalFooter>
          </CForm>
        </CModal>
      ) : null}

      {isEditSpecialProductCouponModalOpen ? (
        <CModal
        size="lg"
        alignment="center"
        visible={isEditSpecialProductCouponModalOpen}
        onClose={() => setIsEditSpecialProductCouponModalOpen(false)}
        aria-labelledby="VerticallyCenteredExample"
      >
        <CModalHeader>
          <CModalTitle>EDIT SPECIAL PRODUCT COUPON</CModalTitle>
        </CModalHeader>
        <CForm onSubmit={submitSpecialProductCouponDetails}>
          <CModalBody>
            <CRow>
              <CCol className="col-md-4">
                <div>
                  <CFormLabel className="fw-semibold">
                    Title <span className="text-danger">*</span>
                  </CFormLabel>
                  <CFormInput
                    placeholder="Title"
                    name="title"
                    value={editedSpecialProductCouponData?.title}
                    onChange={handleSpecialProductCouponChange}
                  />
                </div>
              </CCol>
              <CCol className="col-md-4">
                <div>
                  <CFormLabel className="fw-semibold">
                    Coupon Code <span className="text-danger">*</span>
                  </CFormLabel>
                  <CFormInput
                    placeholder="Coupon Code"
                    name="couponCode"
                    value={editedSpecialProductCouponData?.couponCode}
                    onChange={handleSpecialProductCouponChange}
                  />
                </div>
              </CCol>
              <CCol className="col-md-4">
                <div>
                  <CFormLabel className="fw-semibold">
                    Usage Type <span className="text-danger">*</span>
                  </CFormLabel>
                  <CFormSelect
                    name="usageType"
                    value={editedSpecialProductCouponData?.usageType}
                    onChange={handleSpecialProductCouponChange}
                  >
                    <option value="">Select Usage</option>
                    <option value="MULTIPLE">MULTIPLE</option>
                    <option value="ONE-TIME">ONE-TIME</option>
                  </CFormSelect>
                </div>
              </CCol>
            </CRow>

            <CRow className="mt-2 mb-2">
              <CCol className="col-md-6">
                <CFormLabel className="fw-semibold">
                  Select Products <span className="text-danger">*</span>
                </CFormLabel>
                <Select 
                  isMulti
                  components={animatedComponents}
                  value={selectedProducts}
                  options={productsData}
                  onChange={handleSelectProductChange}
                  styles={customStyles}
                  placeholder="Select Products"
                  closeMenuOnSelect={false}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#e9ecef",
                      primary: "#007bff",
                    },
                  })}
                />
              </CCol>

              <CCol className="col-md-6">
                <CFormLabel className="fw-semibold">
                  Select Excluded Outlets
                </CFormLabel>

                <Select
                  isMulti
                  components={animatedComponents}
                  value={selectedOptions}
                  onChange={handleSelectChange}
                  options={outletData}
                  styles={customStyles}
                  placeholder="Select options" // You can also use a dynamic placeholder from props or state
                  closeMenuOnSelect={false}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#e9ecef",
                      primary: "#007bff",
                    },
                  })}
                />
              </CCol>

            </CRow>

            <div className="mb-3">
              <CFormLabel className="fw-semibold">
                Description <span className="text-danger">*</span>
              </CFormLabel>
              <CFormTextarea
                rows={4}
                placeholder="Description"
                name="description"
                value={editedSpecialProductCouponData?.description}
                onChange={handleSpecialProductCouponChange}
              />
            </div>

            <CRow className="mb-3">
              <CCol md="4" className="mb-3">
                <div>
                  <CFormLabel className="fw-semibold">
                    Hide Coupon
                  </CFormLabel>
                  <CFormSelect
                    name="isHidden"
                    value={editedSpecialProductCouponData?.isHidden}
                    onChange={handleSpecialProductCouponChange}
                  >
                    <option value="">Select Option</option>
                    <option value="true">YES</option>
                    <option value="false">NO</option>
                  </CFormSelect>
                </div>
              </CCol>
              <CCol md="4">
                <div>
                  <CFormLabel className="fw-semibold">
                    Validity Start Date
                  </CFormLabel>
                  <DatePicker
                    onChange={handleProductValidityStartDateChange}
                    placeholderText="Validity Start Date"
                    className="form-control"
                    selected={editedSpecialProductCouponData?.validityStartDate ? new Date(editedSpecialProductCouponData?.validityStartDate) : null}
                  />
                </div>
              </CCol>
              <CCol md="4">
                <div>
                  <CFormLabel className="fw-semibold">
                    Validity End Date
                  </CFormLabel>
                  <DatePicker
                    onChange={handleProductValidityEndDateChange}
                    placeholderText="Validity End Date"
                    className="form-control"
                    selected={editedSpecialProductCouponData?.validityEndDate ? new Date(editedSpecialProductCouponData?.validityEndDate) : null}
                  />
                </div>
              </CCol>
                <CCol md="6">
                  <CFormLabel className="fw-semibold">
                    Discount Price <span className="text-danger">*</span>
                  </CFormLabel>
                  <CFormInput
                    placeholder="Discount Price"
                    type="number"
                    name="discountPrice"
                    value={editedSpecialProductCouponData?.discountPrice}
                    onChange={handleSpecialProductCouponChange}
                    className="no-arrows"
                  />
                </CCol>

              {editedSpecialProductCouponData?.applicableQuantityType ? (
                <CCol md="6">
                  <div>
                    <CFormLabel className="fw-semibold">
                      Applicable Quantity Type <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormSelect
                      name="applicableQuantityType"
                      value={editedSpecialProductCouponData?.applicableQuantityType}
                      onChange={handleSpecialProductCouponChange}
                    >
                      <option value="">Select Applicable Quantity Type</option>
                      <option value="SINGLE">SINGLE</option>
                      <option value="MULTIPLE">MULTIPLE</option>
                    </CFormSelect>
                  </div>
                </CCol>
              ) : null}

            </CRow>
          </CModalBody>
          <CModalFooter>
            <PrimaryButton
              title="SAVE CHANGES"
              style={{ borderRadius: "18px" }}
            />
          </CModalFooter>
        </CForm>
        </CModal>
      ) : null }

      {isEditCartCouponModalOpen ? (
        <CModal
          size="lg"
          alignment="center"
          visible={isEditCartCouponModalOpen}
          onClose={() => setIsEditCartCouponModalOpen(false)}
          aria-labelledby="VerticallyCenteredExample"
        >
          <CModalHeader>
            <CModalTitle>EDIT CART COUPON</CModalTitle>
          </CModalHeader>
          <CForm onSubmit={submitCartCouponDetails}>
            <CModalBody>
              <CRow className="mb-2">
                <CCol>
                  <div>
                    <CFormLabel className="fw-semibold">
                      Title <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      placeholder="Title"
                      name="title"
                      value={editedCartCouponDetails.title}
                      onChange={handleCartInputChange}
                    />
                  </div>
                </CCol>
                <CCol>
                  <div>
                    <CFormLabel className="fw-semibold">
                      Coupon Code <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      placeholder="Coupon Code"
                      name="couponCode"
                      value={editedCartCouponDetails.couponCode}
                      onChange={handleCartInputChange}
                    />
                  </div>
                </CCol>
                <CCol>
                  <div>
                    <CFormLabel className="fw-semibold">
                      Usage Type <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormSelect
                      name="usageType"
                      value={editedCartCouponDetails.usageType}
                      onChange={handleCartInputChange}
                    >
                      <option value="">Select Usage</option>
                      <option value="MULTIPLE">MULTIPLE</option>
                      <option value="ONE-TIME">ONE-TIME</option>
                    </CFormSelect>
                  </div>
                </CCol>
              </CRow>

              <CRow className="mb-2">
                <CCol md="8">
                  <div>
                    <CFormLabel className="fw-semibold">
                      Select Excluded Outlets
                    </CFormLabel>
                    <Select
                      isMulti
                      components={animatedComponents}
                      value={selectedOptions}
                      onChange={handleSelectChange}
                      options={outletData}
                      styles={customStyles}
                      placeholder="Select options"
                      closeMenuOnSelect={false}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                          ...theme.colors,
                          primary25: "#e9ecef",
                          primary: "#007bff",
                        },
                      })}
                    />
                  </div>
                </CCol>
                <CCol md="4">
                  <div>
                    <CFormLabel className="fw-semibold">
                      Hide Coupon
                    </CFormLabel>
                    <CFormSelect
                      name="isHidden"
                      value={editedCartCouponDetails?.isHidden}
                      onChange={handleCartInputChange}
                    >
                      <option value="">Select Option</option>
                      <option value="true">YES</option>
                      <option value="false">NO</option>
                    </CFormSelect>
                  </div>
                </CCol>
              </CRow>
              

              <div className="mb-3">
                <CFormLabel className="fw-semibold">
                  Description <span className="text-danger">*</span>
                </CFormLabel>
                <CFormTextarea
                  rows={4}
                  placeholder="Description"
                  name="description"
                  value={editedCartCouponDetails.description}
                  onChange={handleCartInputChange}
                />
              </div>

              <CRow className="mb-3">
                <CCol md='4'>
                  <div>
                    <CFormLabel className="fw-semibold">
                      Validity Start Date
                    </CFormLabel>
                    <DatePicker
                      onChange={handleCartValidStartDateChange}
                      placeholderText="Validity Start Date"
                      className="form-control"
                      selected={editedCartCouponDetails?.validityStartDate ? new Date(editedCartCouponDetails?.validityStartDate) : null}
                    />
                  </div>
                </CCol>

                <CCol md='4' className="mb-3">
                  <div>
                    <CFormLabel className="fw-semibold">
                      Validity End Date
                    </CFormLabel>
                    <DatePicker
                      onChange={handleCartValidEndDateChange}
                      placeholderText="Validity End Date"
                      className="form-control"
                      selected={editedCartCouponDetails?.validityEndDate ? new Date(editedCartCouponDetails?.validityEndDate) : null}
                    />
                  </div>
                </CCol>

                {
                  // editedCartCouponDetails.hasOwnProperty('discountPercent') &&
                  <CCol md='4'>
                    <CFormLabel className="fw-semibold">
                      Discount Percent <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      disabled={
                        editedCartCouponDetails?.discountAmount
                      }
                      placeholder="Discount Percent"
                      type="number"
                      name="discountPercent"
                      value={editedCartCouponDetails.discountPercent}
                      onChange={handleCartInputChange}
                      className="no-arrows"
                    />
                  </CCol>
                }
                {
                  // editedCartCouponDetails.hasOwnProperty('discountAmount') &&
                  <CCol md='4'>
                    <CFormLabel className="fw-semibold">
                      Discount Amount <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      disabled={
                        editedCartCouponDetails?.discountPercent
                      }
                      placeholder="Discount Amount"
                      type="number"
                      name="discountAmount"
                      value={editedCartCouponDetails.discountAmount}
                      onChange={handleCartInputChange}
                      className="no-arrows"
                    />
                  </CCol>
                }

                {
                  // editedCartCouponDetails?.maxDiscountAmount &&
                  <CCol md='4'>
                    <CFormLabel className="fw-semibold">
                      Max. Dis. Amount
                    </CFormLabel>
                    <CFormInput
                      disabled={
                        editedCartCouponDetails?.discountAmount || editedCartCouponDetails?.maxDiscountAmount === null
                      }
                      placeholder="Max. Dis. Amount"
                      type="number"
                      name="maxDiscountAmount"
                      value={editedCartCouponDetails.maxDiscountAmount}
                      onChange={handleCartInputChange}
                      className="no-arrows"
                    />
                  </CCol>
                }

                {
                  // editedCartCouponDetails.minOrderAmount &&
                  <CCol md='4'>
                    <CFormLabel className="fw-semibold">
                      Min. Order Amount <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      placeholder="Min. Order Amount"
                      type="number"
                      name="minOrderAmount"
                      value={editedCartCouponDetails.minOrderAmount}
                      onChange={handleCartInputChange}
                      className="no-arrows"
                    />
                  </CCol>
                }
              </CRow>
            </CModalBody>
            <CModalFooter>
              <PrimaryButton
                title="SAVE CHANGES"
                style={{ borderRadius: "18px" }}
              />
            </CModalFooter>
          </CForm>
        </CModal>
      ) : null}

      {isEditSizeUpgradeCouponModalOpen && (
        <CModal
          size="lg"
          alignment="center"
          visible={isEditSizeUpgradeCouponModalOpen}
          onClose={() => setIsEditSizeUpgradeCouponModalOpen(false)}
          aria-labelledby="VerticallyCenteredExample"
        >
          <CModalHeader>
            <CModalTitle>EDIT SIZE UPGRADE COUPON</CModalTitle>
          </CModalHeader>
          <CForm onSubmit={submitSizeUpgradeCouponDetails}>
            <CModalBody>
              <CRow className="mb-3">
                <CCol md='4'>
                  <div>
                    <CFormLabel className="fw-semibold">
                      Title <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      placeholder="Title"
                      name="title"
                      value={editSizeUpgradeCouponData?.title}
                      onChange={handleSizeUpgradeInputChange}
                    />
                  </div>
                </CCol>
                <CCol md='4' className='mb-3'>
                  <div>
                    <CFormLabel className="fw-semibold">
                      Coupon Code <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      placeholder="Coupon Code"
                      name="couponCode"
                      value={editSizeUpgradeCouponData?.couponCode}
                      onChange={handleSizeUpgradeInputChange}
                    />
                  </div>
                </CCol>
                <CCol md='4'>
                  <div>
                    <CFormLabel className="fw-semibold">
                      Usage Type <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormSelect
                      name="usageType"
                      value={editSizeUpgradeCouponData?.usageType}
                      onChange={handleSizeUpgradeInputChange}
                    >
                      <option value="">Select Usage</option>
                      <option value="MULTIPLE">MULTIPLE</option>
                      <option value="ONE-TIME">ONE-TIME</option>
                    </CFormSelect>
                  </div>
                </CCol>
                {selectedProduct && (
                  <CCol md="6">
                    <CFormLabel className="fw-semibold">
                      Select Product <span className="text-danger">*</span>
                    </CFormLabel>
                    <Select
                      options={addonList.map((rm) => ({
                        value: rm?.product?._id,
                        label: rm?.product?.name,
                      }))}
                      // options= {productsData}
                      value={
                        selectedProduct
                          ? addonList
                              .map((rm) => ({ value: rm?.product?._id, label: rm?.product?.name }))
                              .find((option) => option.value === selectedProduct)
                          : null
                      }
                      onChange={(selectedOption) => {
                        if (typeof selectedOption === 'object' && selectedOption !== null) {
                          handleSizeUpgradeInputChange({ target: { name: 'applicableProducts', value: selectedOption.value } });
                        } else {
                          handleSizeUpgradeInputChange({ target: { name: 'applicableProducts', value: '' } });
                        }
                      }}
                      classNamePrefix="react-select"
                      isClearable
                      placeholder="Select Product"
                    />
                  </CCol>
                )}

                <CCol md="6">
                  <CFormLabel className="fw-semibold">
                    Select Size Upgrade Addon
                  </CFormLabel>
                  <CFormSelect
                    name="sizeUpgradeAddon"
                    value={editSizeUpgradeCouponData?.sizeUpgradeAddon}
                    onChange={handleSizeUpgradeInputChange}
                  >
                    <option value="">Select Size Upgrade Addon</option>
                    {filteredAddons?.map((fa, index) => {
                      return (
                        fa.addons.map((ufa, ufaIndex) => {
                          return (
                            <option key={ufa?.addonId} value={ufa?.addonId}>{ufa?.addonTitle}</option>
                          );
                        })
                      );
                    })}
                    {/* <option key={filteredAddons[0]?._id} value={filteredAddons[0]?._id}>
                          <option key={ufa?._id} value={ufa?._id}>{ufa?.addonTitle}</option> => IMP PUT TOP

                      {filteredAddons[0]?.addonTitle}
                    </option> */}
                  </CFormSelect>
                </CCol>
              </CRow>

              <div className="mb-3">
                <CFormLabel className="fw-semibold">
                  Select Excluded Outlets <span className="text-danger">*</span>
                </CFormLabel>
                <Select
                  isMulti
                  components={animatedComponents}
                  value={selectedOptions}
                  onChange={handleSelectChange}
                  options={outletData}
                  styles={customStyles}
                  placeholder="Select Excluded Outlets"
                  closeMenuOnSelect={false}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#e9ecef",
                      primary: "#007bff",
                    },
                  })}
                />
              </div>

              <div className="mb-3">
                <CFormLabel className="fw-semibold">
                  Description <span className="text-danger">*</span>
                </CFormLabel>
                <CFormTextarea
                  rows={4}
                  placeholder="Description"
                  name="description"
                  value={editSizeUpgradeCouponData.description}
                  onChange={handleSizeUpgradeInputChange}
                />
              </div>

              <CRow>
                <CCol md='4'>
                  <div>
                    <CFormLabel className="fw-semibold">
                      Hide Coupon
                    </CFormLabel>
                    <CFormSelect
                      name="isHidden"
                      value={editSizeUpgradeCouponData?.isHidden}
                      onChange={handleSizeUpgradeInputChange}
                    >
                      <option value="">Select Option</option>
                      <option value="true">YES</option>
                      <option value="false">NO</option>
                    </CFormSelect>
                  </div>
                </CCol>
                <CCol md='4'>
                  <div>
                    <CFormLabel className="fw-semibold">
                      Validity Start Date
                    </CFormLabel>
                    <DatePicker
                      onChange={handleSizeUpgradeValidityStartDateChange}
                      placeholderText="Validity Start Date"
                      className="form-control"
                      selected={editSizeUpgradeCouponData?.validityStartDate ? new Date(editSizeUpgradeCouponData?.validityStartDate) : null}
                    />
                  </div>
                </CCol>
                <CCol md='4'>
                  <div>
                    <CFormLabel className="fw-semibold">
                      Validity End Date
                    </CFormLabel>
                    <DatePicker
                      onChange={handleSizeUpgradeValidityEndDateChange}
                      placeholderText="Validity End Date"
                      className="form-control"
                      selected={editSizeUpgradeCouponData?.validityEndDate ? new Date(editSizeUpgradeCouponData?.validityEndDate) : null}
                    />
                  </div>
                </CCol>
              </CRow>
            </CModalBody>
            <CModalFooter>
              <PrimaryButton
                title="SAVE CHANGES"
                style={{ borderRadius: "18px" }}
              />
            </CModalFooter>
          </CForm>
        </CModal>
        )
      }

      {isEditUniversalCouponModalOpen && (
          <CModal
            size="lg"
            alignment="center"
            visible={isEditUniversalCouponModalOpen}
            onClose={() => setIsEditUniversalCouponModalOpen(false)}
            aria-labelledby="VerticallyCenteredExample"
          >
            <CModalHeader>
              <CModalTitle>EDIT UNIVERSAL COUPON</CModalTitle>
            </CModalHeader>
            <CForm onSubmit={submitUniversalCouponDetails}>
              <CModalBody>
                <CRow className="mb-2">
                  <CCol>
                    <div>
                      <CFormLabel className="fw-semibold">
                        Title <span className="text-danger">*</span>
                      </CFormLabel>
                      <CFormInput
                        placeholder="Title"
                        name="title"
                        value={editedUniversalCouponDetails.title}
                        onChange={handleUniversalInputChange}
                      />
                    </div>
                  </CCol>
                  <CCol>
                    <div>
                      <CFormLabel className="fw-semibold">
                        Coupon Code <span className="text-danger">*</span>
                      </CFormLabel>
                      <CFormInput
                        placeholder="Coupon Code"
                        name="couponCode"
                        value={editedUniversalCouponDetails.couponCode}
                        onChange={handleUniversalInputChange}
                      />
                    </div>
                  </CCol>
                  <CCol>
                    <div>
                      <CFormLabel className="fw-semibold">
                        Usage Type <span className="text-danger">*</span>
                      </CFormLabel>
                      <CFormSelect
                        name="usageType"
                        value={editedUniversalCouponDetails.usageType}
                        onChange={handleUniversalInputChange}
                      >
                        <option value="">Select Usage</option>
                        <option value="MULTIPLE">MULTIPLE</option>
                        <option value="ONE-TIME">ONE-TIME</option>
                      </CFormSelect>
                    </div>
                  </CCol>
                </CRow>

                <CRow className="mb-2">
                  <CCol md="8">
                    <div>
                      <CFormLabel className="fw-semibold">
                        Select Excluded Outlets
                      </CFormLabel>
                      <Select
                        isMulti
                        components={animatedComponents}
                        value={selectedOptions}
                        onChange={handleSelectChange}
                        options={outletData}
                        styles={customStyles}
                        placeholder="Select options"
                        closeMenuOnSelect={false}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 0,
                          colors: {
                            ...theme.colors,
                            primary25: "#e9ecef",
                            primary: "#007bff",
                          },
                        })}
                      />
                    </div>
                  </CCol>
                  <CCol md="4">
                    <div>
                      <CFormLabel className="fw-semibold">
                        Hide Coupon
                      </CFormLabel>
                      <CFormSelect
                        name="isHidden"
                        value={editedUniversalCouponDetails?.isHidden}
                        onChange={handleUniversalInputChange}
                      >
                        <option value="">Select Option</option>
                        <option value="true">YES</option>
                        <option value="false">NO</option>
                      </CFormSelect>
                    </div>
                  </CCol>
                </CRow>

                <div className="mb-3">
                  <CFormLabel className="fw-semibold">
                    Description <span className="text-danger">*</span>
                  </CFormLabel>
                  <CFormTextarea
                    rows={4}
                    placeholder="Description"
                    name="description"
                    value={editedUniversalCouponDetails.description}
                    onChange={handleUniversalInputChange}
                  />
                </div>

                <CRow className="mb-3">
                  <CCol md='4'>
                    <div>
                      <CFormLabel className="fw-semibold">
                        Validity Start Date
                      </CFormLabel>
                      <DatePicker
                        onChange={handleUniversalValidStartDateChange}
                        placeholderText="Validity Start Date"
                        className="form-control"
                        selected={editedUniversalCouponDetails?.validityStartDate ? new Date(editedUniversalCouponDetails?.validityStartDate) : null}
                      />
                    </div>
                  </CCol>

                  <CCol md='4'>
                    <div>
                      <CFormLabel className="fw-semibold">
                        Validity End Date
                      </CFormLabel>
                      <DatePicker
                        onChange={handleUniversalValidEndDateChange}
                        placeholderText="Validity End Date"
                        className="form-control"
                        selected={editedUniversalCouponDetails?.validityEndDate ? new Date(editedUniversalCouponDetails?.validityEndDate) : null}
                      />
                    </div>
                  </CCol>

                  <CCol md='4'>
                    <CFormLabel className="fw-semibold">
                      Discount Percent <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      placeholder="Discount Percent"
                      type="number"
                      name="discountPercent"
                      value={editedUniversalCouponDetails.discountPercent}
                      onChange={handleUniversalInputChange}
                      className="no-arrows"
                    />
                  </CCol>
                </CRow>
              </CModalBody>
              <CModalFooter>
                <PrimaryButton
                  title="SAVE CHANGES"
                  style={{ borderRadius: "18px" }}
                />
              </CModalFooter>
            </CForm>
          </CModal>
        )
      }
      
    </CContainer>
  );
};

export default CouponCard;
