import React, { useEffect, useState } from "react";
import {
  CCol,
  CContainer,
  CRow,
  CCard,
  CCardBody,
  CCardHeader,
  CButton,
  CForm,
  CFormInput,
  CFormLabel,
  CFormInputGroup,
  CFormSwitch,
  CFormSelect,
  CFormTextarea,
  CInputGroup,
} from "@coreui/react";
import {
  DataTableComponent,
  DeleteModal,
  PrimaryButton,
} from "../../components";
import axios from "../../api/axios";
import { toast } from "react-toastify";
import { FaEye, FaPencilAlt, FaPlus, FaTrash } from "react-icons/fa";
import { Button, Card, Col, Image, Modal, Row } from "react-bootstrap";

const Assets = () => {
  const accessToken = localStorage.getItem("authToken");
  const [assets, setAssets] = useState([]);
  const [currentAsset, setCurrentAsset] = useState(null);
  const [IsLoading, setIsLoading] = useState(false);
  const [vendors, setVendors] = useState([]);
  const [assetType, setAssetType] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [viewOpen, setViewOpen] = useState(false);
  const [openUpdateImageModal, setOpenUpdateImageModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setopenDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [editedData, setEditedData] = useState(null);
  const [nameSideImg, setNameSideImg] = useState("");
  const [imageIndex, setImageIndex] = useState("");
  const [isDeleteBtnLoading, setIsDeleteBtnLoading] = useState(false);
  const [isEditBtnLoading, setIsEditBtnLoading] = useState(false);
  const [assetImageID, setAssetImageID] = useState("");
  const [assetId, setAssetId] = useState("");
  const [imageSides, setImageSides] = useState("");
  const [updatedImage, setUpdatedImage] = useState("");
  const [searchText, setSearchText] = useState("");
  const [errors, setErrors] = useState({});
  const [editModalErrors, setEditModalErrors] = useState({})
  const [openInovieModal, setOpenInovieModal] = useState(false);
  const [purchaseInoviceId, setPurchaseInoviceId] = useState("")
  const [updatedInvoice, setUpdatedInvoice] = useState("");
  const [invoiceBtnLoading, setInvoiceBtnLoading] = useState(false);
  const [isSaveUploadImageBtn, setIsSaveUploadImageBtn] = useState(false);
  const [OpenAddModal, setOpenAddModal] = useState(false);
  const [pagination, setPagination] = useState({ page: 1, rowsPerPage: 10 });
  const [formData, setFormData] = useState({
    name: "",
    model: "",
    make: "",
    unique_id: "",
    asset_type: "",
    description: "",
    purchase_date: "",
    purchase_type: "",
    purchase_vendor: "",
    purchase_price: "",
    status: "",
    warranty_period: "",
    warranty_status: "",
    has_amc: false,
    amc_start_date: "",
    amc_end_date: "",
    amc_amount: "",
    amc_coverage_details: "",
    amc_taken_with: "",
    amc_contact: "",
    additional_notes: "",
    asset_image: {
      side_1: null,
      side_2: null,
      front: null,
      back: null,
    },
    purchase_invoice_file: null,
  });
  const [showAmcFields, setShowAmcFields] = useState(
    formData?.has_amc || false
  );
  const [hasAmc, setHasAmc] = useState(formData.has_amc || false);
  const [showUpdateAmcFields, setShowUpdateAmcFields] = useState();

  const columns = [
    {
      name: "Sr.No",
      width: "70px",
      cell: (row, index) => (
        <div className="fs-7">
          {row.serialNumber ? row.serialNumber : "----"}
        </div>
      ),
      // center: true
    },
    {
      name: "Name",
      selector: (row) => row.name,
      cell: (row) => (
        <div className="fs-7">{row.name ? row.name : "-----"}</div>
      ),
    },
    {
      name: "Asset Type",
      selector: (row) => row.asset_type.title,
      cell: (row) => (
        <div className="fs-7">
          {row.asset_type.title ? row.asset_type.title : "-----"}
        </div>
      ),
    },
    {
      name: "Asset Model",
      selector: (row) => row.model,
      cell: (row) => (
        <div className="fs-7">{row.model ? row.model : "-------"}</div>
      ),
    },
    {
      name: "Purchased Vendor",
      selector: (row) => row.purchase_vendor.name,
      cell: (row) => (
        <div className="fs-7">
          {row.purchase_vendor.name ? row.purchase_vendor.name : "-----"}
        </div>
      ),
    },
    {
      name: "Pur. Type",
      selector: (row) => row.purchase_type,
      cell: (row) => (
        <div className="fs-7">
          {row.purchase_type ? row.purchase_type : "------"}
        </div>
      ),
      width: "110px",
    },
    {
      name: "Pur. Price",
      selector: (row) => row.purchase_price,
      cell: (row) => (
        <div className="fs-7">
          {row.purchase_price ? row.purchase_price : "------"}
        </div>
      ),
      width: "110px",
    },
    {
      name: "Warranty Period",
      selector: (row) => row.warranty_period,
      cell: (row) => (
        <div className="fs-7">
          {row.warranty_period ? row.warranty_period : "-------"}
        </div>
      ),
    },
    {
      name: "Warranty Type",
      selector: (row) => row.warranty_status,
      cell: (row) => (
        <div className="fs-7">
          {row.warranty_status ? row.warranty_status : "-------"}
        </div>
      ),
    },
    {
      name: "ACTIONS",
      right: "true",
      cell: (row) => (
        <div className="d-flex justify-content-around align-items-center">
          <a
            href="#"
            className="text-info ms-2 me-2 "
            style={{ cursor: "pointer", textDecoration: "none" }}
            onClick={() => handleOpenViewModal(row)}
          >
            <FaEye /> View
          </a>{" "}
          |
          <a
            href="#"
            className="text-primary ms-2 me-2 "
            style={{ cursor: "pointer", textDecoration: "none" }}
            onClick={() => handleEditModal(row)}
          >
            <FaPencilAlt /> Edit
          </a>{" "}
          |
          <a
            href="#"
            className="text-danger ms-2 "
            style={{ cursor: "pointer", textDecoration: "none" }}
            onClick={() => handleOpenDeleteModal(row)}
          >
            <FaTrash /> Delete
          </a>
        </div>
      ),
      minWidth: "250px",
    },
  ];

  const updatedColumns = columns.map((column) => ({
    ...column,
    name: (
      <div style={{ fontSize: "13px", fontWeight: "bold" }}>{column.name}</div>
    ),
  }));
  
  const validateForm = () => {
    const newErrors = {};

    // Validate name
    if (!formData.name) {
      newErrors.name = "Name is required";
    } else if (!isNaN(formData.name) || /^-\d+$/.test(formData.name)) {
      newErrors.name = "Name should not be numeric or a negative integer";
    } else if (formData.name.trim().length < 2) {
      newErrors.name = "Name must contain at least 2 letters";
    } else if (/^\s+$/.test(formData.name)) {
      newErrors.name = "Name should not be only spaces";
    }

    // Validate model
    if (!formData.model) newErrors.model = "Model is required";

    // Validate make
    if (!formData.make) newErrors.make = "Make is required";

    // Validate asset_type
    if (!formData.asset_type) newErrors.asset_type = "Asset Type is required";

    // Validate unique_id
    if (!formData.unique_id) newErrors.unique_id = "Unique ID is required";

    // Validate status
    if (!formData.status) newErrors.status = "Status is required";

    // Validate description
    if (!formData.description) {
      newErrors.description = "Description is required";
    } else if (formData.description.trim().length < 2) {
      newErrors.description = "Description must contain at least 2 letters";
    } else if (/^\s+$/.test(formData.description)) {
      newErrors.description = "Description should not be only spaces";
    }

    // Validate purchase_vendor
    if (!formData.purchase_vendor)
      newErrors.purchase_vendor = "Purchase Vendor is required";

    // Validate purchase_type
    if (!formData.purchase_type)
      newErrors.purchase_type = "Purchase Type is required";

    // Validate purchase_date
    if (!formData.purchase_date)
      newErrors.purchase_date = "Purchase Date is required";

    // Validate purchase_price
    if (!formData.purchase_price)
      newErrors.purchase_price = "Purchase Price is required";

    // Validate warranty_period
    if (!formData.warranty_period)
      newErrors.warranty_period = "Warranty Period is required";

    // Validate warranty_status
    if (!formData.warranty_status)
      newErrors.warranty_status = "Warranty Status is required";

    // Set errors
    setErrors(newErrors);

    // Return whether the form is valid (no errors)
    return Object.keys(newErrors).length === 0;
  };

  const validateField = (name, value) => {
    const newErrors = { ...errors };
  
    if (formData?.has_amc === true ) {
      switch (name) {
        case "name":
          if (!value) {
            newErrors[name] = "Name is required";
          } else if (!isNaN(value) || /^-\d+$/.test(value)) {
            newErrors[name] = "Name should not be numeric or a negative integer or spaces";
          } else if (value.trim().length < 2) {
            newErrors[name] = "Name must contain at least 2 letters";
          } else if (/^\s+$/.test(value)) {
            newErrors[name] = "Name should not be only spaces";
          } else if (value.startsWith(" ")) {
            newErrors[name] = "Name should not have leading spaces";
          } else {
            delete newErrors[name];
          }
          break;
    
        case "model":
          if (!value) {
            newErrors[name] = "Model is required";
          } else if (value.trim().length < 2) {
            newErrors[name] = "Model must contain at least 2 letters"
          } else if (/^\s+$/.test(value)) {
            newErrors[name] = "Model should not be only spaces";
          } else if (value.startsWith(" ")) {
            newErrors[name] = "Model should not have leading spaces";
          } else {
            delete newErrors[name];
          }
          break;
        
        case "make":
          if (!value) {
            newErrors[name] = "Brand Name is required"
          } else if (value.trim().length < 2) {
            newErrors[name] = "Brand name must contain atleast 2 letters"
          } else if (!isNaN(value) || /^-\d+$/.test(value)) {
            newErrors[name] = "Brand Name should not be numeric or a negative integer or spaces";
          } else if (/^\s+$/.test(value)) {
            newErrors[name] = "Brand Name should not be only spaces";
          } else if (value.startsWith(" ")) {
            newErrors[name] = "Brand Name should not have leading spaces";
          }else {
            delete newErrors[name];
          }
          break;
  
        case "asset_type":
          if (!value) {
            newErrors[name] = "Asset Type is required"
          } else {
            delete newErrors[name];
          }
          break;
  
        case "unique_id":
          if (!value) {
          newErrors[name] = "Unique ID is required";
          } else if (/^\s+$/.test(value)) {
            newErrors[name] = "Unique ID should not be only spaces";
          } else if (value.startsWith(" ")) {
            newErrors[name] = "Unique ID should not have leading spaces";
          } else if (value.trim().length < 2) {
            newErrors[name] = "Unique ID must contain atleast 2 letters";
          } else {
            delete newErrors[name];
          }
          break;
        
        case "status":
          if(!value) {
            newErrors[name] = "Status is required";
          } else {
            delete newErrors[name];
          }
          break;
  
        case "description":
          if (!value) {
            newErrors[name] = "Description is required";
          } else if (!isNaN(value) || /^-\d+$/.test(value)) {
            newErrors[name] = "Description should not be numeric or a negative integer or spaces";
          } else if (value.trim().length < 2) {
            newErrors[name] = "Description must contain at least 2 letters";
          } else if (/^\s+$/.test(value)) {
            newErrors[name] = "Description should not be only spaces";
          } else if (value.startsWith(" ")) {
            newErrors[name] = "Description should not have leading spaces";
          } else {
            delete newErrors[name];
          }
          break;
        
        case "purchase_vendor":
          if(!value) {
            newErrors[name] = "Purchase Vendor is required";
          } else {
            delete newErrors[name];
          }
          break;
        
        case "purchase_type":
            if(!value) {
              newErrors[name] = "Purchase Type is required";
            } else {
              delete newErrors[name];
            }
            break;
  
        case "purchase_date":
          if(!value) {
            newErrors[name] = "Purchase Date is required";
          } else {
            delete newErrors[name];
          }
          break;
  
        case "purchase_price":
          if(!value) {
            newErrors[name] = "Purchase Date is required";
          } else {
            delete newErrors[name];
          }
          break;
  
        case "warranty_period":
          if(!value) {
            newErrors[name] = "Warranty period is required";
          } else if (value.trim().length < 2) {
            newErrors[name] = "Warranty period must contain at least 2 letters";
          } else if (/^\s+$/.test(value)) {
            newErrors[name] = "Warranty period should not be only spaces";
          } else if (value.startsWith(" ")) {
            newErrors[name] = "Warranty period should not have leading spaces";
          } else {
            delete newErrors[name];
          }
          break;
  
        case "warranty_status":
          if(!value) {
            newErrors[name] = "Warranty status is required";
          } else {
            delete newErrors[name];
          }
          break;

        case "amc_start_date":
          if (!value) {
            newErrors[name] = "Amc start date required"
          } else {
            delete newErrors[name];
          }
          break;

        case "amc_end_date":
          if (!value) {
            newErrors[name] = "Amc end date required"
          } else {
            delete newErrors[name];
          }
          break;
  
        case "amc_amount":
          if(!value) {
            newErrors[name] = "AMC amount is required"
          } else {
            delete newErrors[name];
          }
          break;

        case "amc_taken_with":
          if (!value) {
            newErrors[name] = "AMC taken with is required"
          } else {
            delete newErrors[name];
          }
          break;

        case "additional_notes":
          if (!value) {
            newErrors[name] = "Additional notes is required";
          } else {
            delete newErrors[name];
          }
          break;

        case "amc_coverage_details":
          if (!value) {
            newErrors[name] = "AMC coverage details is required";
          } else {
            delete newErrors[name];
          }

        case "amc_contact":
        if (!value) {
          newErrors[name] = "AMC contact details is required";
        } else {
          delete newErrors[name];
        }
        // Add cases for other fields with similar structure
        // ...

        default:
        break;
      }
    } else {
      switch (name) {
        case "name":
          if (!value) {
            newErrors[name] = "Name is required";
          } else if (!isNaN(value) || /^-\d+$/.test(value)) {
            newErrors[name] = "Name should not be numeric or a negative integer or spaces";
          } else if (value.trim().length < 2) {
            newErrors[name] = "Name must contain at least 2 letters";
          } else if (/^\s+$/.test(value)) {
            newErrors[name] = "Name should not be only spaces";
          } else if (value.startsWith(" ")) {
            newErrors[name] = "Name should not have leading spaces";
          } else {
            delete newErrors[name];
          }
          break;
    
        case "model":
          if (!value) {
            newErrors[name] = "Model is required";
          } else if (value.trim().length < 2) {
            newErrors[name] = "Model must contain at least 2 letters"
          } else if (/^\s+$/.test(value)) {
            newErrors[name] = "Model should not be only spaces";
          } else if (value.startsWith(" ")) {
            newErrors[name] = "Model should not have leading spaces";
          } else {
            delete newErrors[name];
          }
          break;
        
        case "make":
          if (!value) {
            newErrors[name] = "Brand Name is required"
          } else if (value.trim().length < 2) {
            newErrors[name] = "Brand name must contain atleast 2 letters"
          } else if (!isNaN(value) || /^-\d+$/.test(value)) {
            newErrors[name] = "Brand Name should not be numeric or a negative integer or spaces";
          } else if (/^\s+$/.test(value)) {
            newErrors[name] = "Brand Name should not be only spaces";
          } else if (value.startsWith(" ")) {
            newErrors[name] = "Brand Name should not have leading spaces";
          }else {
            delete newErrors[name];
          }
          break;
  
        case "asset_type":
          if (!value) {
            newErrors[name] = "Asset Type is required"
          } else {
            delete newErrors[name];
          }
          break;
  
        case "unique_id":
          if (!value) {
          newErrors[name] = "Unique ID is required";
          } else if (/^\s+$/.test(value)) {
            newErrors[name] = "Unique ID should not be only spaces";
          } else if (value.startsWith(" ")) {
            newErrors[name] = "Unique ID should not have leading spaces";
          } else if (value.trim().length < 2) {
            newErrors[name] = "Unique ID must contain atleast 2 letters";
          } else {
            delete newErrors[name];
          }
          break;
        
        case "status":
          if(!value) {
            newErrors[name] = "Status is required";
          } else {
            delete newErrors[name];
          }
          break;
  
        case "description":
          if (!value) {
            newErrors[name] = "Description is required";
          } else if (!isNaN(value) || /^-\d+$/.test(value)) {
            newErrors[name] = "Description should not be numeric or a negative integer or spaces";
          } else if (value.trim().length < 2) {
            newErrors[name] = "Description must contain at least 2 letters";
          } else if (/^\s+$/.test(value)) {
            newErrors[name] = "Description should not be only spaces";
          } else if (value.startsWith(" ")) {
            newErrors[name] = "Description should not have leading spaces";
          } else {
            delete newErrors[name];
          }
          break;
        
        case "purchase_vendor":
          if(!value) {
            newErrors[name] = "Purchase Vendor is required";
          } else {
            delete newErrors[name];
          }
          break;
        
        case "purchase_type":
            if(!value) {
              newErrors[name] = "Purchase Type is required";
            } else {
              delete newErrors[name];
            }
            break;
  
        case "purchase_date":
          if(!value) {
            newErrors[name] = "Purchase Date is required";
          } else {
            delete newErrors[name];
          }
          break;
  
        case "purchase_price":
          if(!value) {
            newErrors[name] = "Purchase Date is required";
          } else {
            delete newErrors[name];
          }
          break;
  
        case "warranty_period":
          if(!value) {
            newErrors[name] = "Warranty period is required";
          } else if (value.trim().length < 2) {
            newErrors[name] = "Warranty period must contain at least 2 letters";
          } else if (/^\s+$/.test(value)) {
            newErrors[name] = "Warranty period should not be only spaces";
          } else if (value.startsWith(" ")) {
            newErrors[name] = "Warranty period should not have leading spaces";
          } else {
            delete newErrors[name];
          }
          break;
  
        case "warranty_status":
          if(!value) {
            newErrors[name] = "Warranty status is required";
          } else {
            delete newErrors[name];
          }
          break;
  
        // Add cases for other fields with similar structure
        // ...
    
        default:
          break;
      }
    }
  
    setErrors(newErrors);
  };

  const editValidateField = (name, value) => {
    const newEditErrors = { ...errors };
  
    if (editedData?.has_amc === true ) {
      switch (name) {
        case "name":
          if (!value) {
            newEditErrors[name] = "Name is required";
          } else if (!isNaN(value) || /^-\d+$/.test(value)) {
            newEditErrors[name] = "Name should not be numeric or a negative integer or spaces";
          } else if (value.trim().length < 2) {
            newEditErrors[name] = "Name must contain at least 2 letters";
          } else if (/^\s+$/.test(value)) {
            newEditErrors[name] = "Name should not be only spaces";
          } else if (value.startsWith(" ")) {
            newEditErrors[name] = "Name should not have leading spaces";
          } else {
            delete newEditErrors[name];
          }
          break;
    
        case "model":
          if (!value) {
            newEditErrors[name] = "Model is required";
          } else if (value.trim().length < 2) {
            newEditErrors[name] = "Model must contain at least 2 letters"
          } else if (/^\s+$/.test(value)) {
            newEditErrors[name] = "Model should not be only spaces";
          } else if (value.startsWith(" ")) {
            newEditErrors[name] = "Model should not have leading spaces";
          } else {
            delete newEditErrors[name];
          }
          break;
        
        case "make":
          if (!value) {
            newEditErrors[name] = "Brand Name is required"
          } else if (value.trim().length < 2) {
            newEditErrors[name] = "Brand name must contain atleast 2 letters"
          } else if (!isNaN(value) || /^-\d+$/.test(value)) {
            newEditErrors[name] = "Brand Name should not be numeric or a negative integer or spaces";
          } else if (/^\s+$/.test(value)) {
            newEditErrors[name] = "Brand Name should not be only spaces";
          } else if (value.startsWith(" ")) {
            newEditErrors[name] = "Brand Name should not have leading spaces";
          }else {
            delete newEditErrors[name];
          }
          break;
  
        case "asset_type":
          if (!value) {
            newEditErrors[name] = "Asset Type is required"
          } else {
            delete newEditErrors[name];
          }
          break;
  
        case "unique_id":
          if (!value) {
            newEditErrors[name] = "Unique ID is required";
          } else if (/^\s+$/.test(value)) {
            newEditErrors[name] = "Unique ID should not be only spaces";
          } else if (value.startsWith(" ")) {
            newEditErrors[name] = "Unique ID should not have leading spaces";
          } else if (value.trim().length < 2) {
            newEditErrors[name] = "Unique ID must contain atleast 2 letters";
          } else {
            delete newEditErrors[name];
          }
          break;
        
        case "status":
          if(!value) {
            newEditErrors[name] = "Status is required";
          } else {
            delete newEditErrors[name];
          }
          break;
  
        case "description":
          if (!value) {
            newEditErrors[name] = "Description is required";
          } else if (!isNaN(value) || /^-\d+$/.test(value)) {
            newEditErrors[name] = "Description should not be numeric or a negative integer or spaces";
          } else if (value.trim().length < 2) {
            newEditErrors[name] = "Description must contain at least 2 letters";
          } else if (/^\s+$/.test(value)) {
            newEditErrors[name] = "Description should not be only spaces";
          } else if (value.startsWith(" ")) {
            newEditErrors[name] = "Description should not have leading spaces";
          } else {
            delete newEditErrors[name];
          }
          break;
        
        case "purchase_vendor":
          if(!value) {
            newEditErrors[name] = "Purchase Vendor is required";
          } else {
            delete newEditErrors[name];
          }
          break;
        
        case "purchase_type":
            if(!value) {
              newEditErrors[name] = "Purchase Type is required";
            } else {
              delete newEditErrors[name];
            }
            break;
  
        case "purchase_date":
          if(!value) {
            newEditErrors[name] = "Purchase Date is required";
          } else {
            delete newEditErrors[name];
          }
          break;
  
        case "purchase_price":
          if(!value) {
            newEditErrors[name] = "Purchase Date is required";
          } else {
            delete newEditErrors[name];
          }
          break;
  
        case "warranty_period":
          if(!value) {
            newEditErrors[name] = "Warranty period is required";
          } else if (value.trim().length < 2) {
            newEditErrors[name] = "Warranty period must contain at least 2 letters";
          } else if (/^\s+$/.test(value)) {
            newEditErrors[name] = "Warranty period should not be only spaces";
          } else if (value.startsWith(" ")) {
            newEditErrors[name] = "Warranty period should not have leading spaces";
          } else {
            delete newEditErrors[name];
          }
          break;
  
        case "warranty_status":
          if(!value) {
            newEditErrors[name] = "Warranty status is required";
          } else {
            delete newEditErrors[name];
          }
          break;

        case "amc_start_date":
          if (!value) {
            newEditErrors[name] = "Amc start date required"
          } else {
            delete newEditErrors[name];
          }
          break;

        case "amc_end_date":
          if (!value) {
            newEditErrors[name] = "Amc end date required"
          } else {
            delete newEditErrors[name];
          }
          break;
  
        case "amc_amount":
          if(!value) {
            newEditErrors[name] = "AMC amount is required"
          } else {
            delete newEditErrors[name];
          }
          break;

        case "amc_taken_with":
          if (!value) {
            newEditErrors[name] = "AMC taken with is required"
          } else {
            delete newEditErrors[name];
          }
          break;

        case "additional_notes":
          if (!value) {
            newEditErrors[name] = "Additional notes is required";
          } else {
            delete newEditErrors[name];
          }
          break;

        case "amc_coverage_details":
          if (!value) {
            newEditErrors[name] = "AMC coverage details is required";
          } else {
            delete newEditErrors[name];
          }

        case "amc_contact":
        if (!value) {
          newEditErrors[name] = "AMC contact details is required";
        } else {
          delete newEditErrors[name];
        }
        // Add cases for other fields with similar structure
        // ...

        default:
        break;
      }
    } else {
      switch (name) {
        case "name":
          if (!value) {
            newEditErrors[name] = "Name is required";
          } else if (!isNaN(value) || /^-\d+$/.test(value)) {
            newEditErrors[name] = "Name should not be numeric or a negative integer or spaces";
          } else if (value.trim().length < 2) {
            newEditErrors[name] = "Name must contain at least 2 letters";
          } else if (/^\s+$/.test(value)) {
            newEditErrors[name] = "Name should not be only spaces";
          } else if (value.startsWith(" ")) {
            newEditErrors[name] = "Name should not have leading spaces";
          } else {
            delete newEditErrors[name];
          }
          break;
    
        case "model":
          if (!value) {
            newEditErrors[name] = "Model is required";
          } else if (value.trim().length < 2) {
            newEditErrors[name] = "Model must contain at least 2 letters"
          } else if (/^\s+$/.test(value)) {
            newEditErrors[name] = "Model should not be only spaces";
          } else if (value.startsWith(" ")) {
            newEditErrors[name] = "Model should not have leading spaces";
          } else {
            delete newEditErrors[name];
          }
          break;
        
        case "make":
          if (!value) {
            newEditErrors[name] = "Brand Name is required"
          } else if (value.trim().length < 2) {
            newEditErrors[name] = "Brand name must contain atleast 2 letters"
          } else if (!isNaN(value) || /^-\d+$/.test(value)) {
            newEditErrors[name] = "Brand Name should not be numeric or a negative integer or spaces";
          } else if (/^\s+$/.test(value)) {
            newEditErrors[name] = "Brand Name should not be only spaces";
          } else if (value.startsWith(" ")) {
            newEditErrors[name] = "Brand Name should not have leading spaces";
          }else {
            delete newEditErrors[name];
          }
          break;
  
        case "asset_type":
          if (!value) {
            newEditErrors[name] = "Asset Type is required"
          } else {
            delete newEditErrors[name];
          }
          break;
  
        case "unique_id":
          if (!value) {
            newEditErrors[name] = "Unique ID is required";
          } else if (/^\s+$/.test(value)) {
            newEditErrors[name] = "Unique ID should not be only spaces";
          } else if (value.startsWith(" ")) {
            newEditErrors[name] = "Unique ID should not have leading spaces";
          } else if (value.trim().length < 2) {
            newEditErrors[name] = "Unique ID must contain atleast 2 letters";
          } else {
            delete newEditErrors[name];
          }
          break;
        
        case "status":
          if(!value) {
            newEditErrors[name] = "Status is required";
          } else {
            delete newEditErrors[name];
          }
          break;
  
        case "description":
          if (!value) {
            newEditErrors[name] = "Description is required";
          } else if (!isNaN(value) || /^-\d+$/.test(value)) {
            newEditErrors[name] = "Description should not be numeric or a negative integer or spaces";
          } else if (value.trim().length < 2) {
            newEditErrors[name] = "Description must contain at least 2 letters";
          } else if (/^\s+$/.test(value)) {
            newEditErrors[name] = "Description should not be only spaces";
          } else if (value.startsWith(" ")) {
            newEditErrors[name] = "Description should not have leading spaces";
          } else {
            delete newEditErrors[name];
          }
          break;
        
        case "purchase_vendor":
          if(!value) {
            newEditErrors[name] = "Purchase Vendor is required";
          } else {
            delete newEditErrors[name];
          }
          break;
        
        case "purchase_type":
            if(!value) {
              newEditErrors[name] = "Purchase Type is required";
            } else {
              delete newEditErrors[name];
            }
            break;
  
        case "purchase_date":
          if(!value) {
            newEditErrors[name] = "Purchase Date is required";
          } else {
            delete newEditErrors[name];
          }
          break;
  
        case "purchase_price":
          if(!value) {
            newEditErrors[name] = "Purchase Date is required";
          } else {
            delete newEditErrors[name];
          }
          break;
  
        case "warranty_period":
          if(!value) {
            newEditErrors[name] = "Warranty period is required";
          } else if (value.trim().length < 2) {
            newEditErrors[name] = "Warranty period must contain at least 2 letters";
          } else if (/^\s+$/.test(value)) {
            newEditErrors[name] = "Warranty period should not be only spaces";
          } else if (value.startsWith(" ")) {
            newEditErrors[name] = "Warranty period should not have leading spaces";
          } else {
            delete newEditErrors[name];
          }
          break;
  
        case "warranty_status":
          if(!value) {
            newEditErrors[name] = "Warranty status is required";
          } else {
            delete newEditErrors[name];
          }
          break;
  
        // Add cases for other fields with similar structure
        // ...
    
        default:
          break;
      }
    }
  
    setEditModalErrors(newEditErrors);
  };
  
  const handleChange = (e) => {
    const { name, value, type, checked, files } = e.target;
    setFormData({
      ...formData,
      [name]:
        type === "checkbox" ? checked : type === "file" ? files[0] : value,
    });

    // Trigger validation for the field that was touched
    validateField(name, type === "checkbox" ? checked : type === "file" ? files[0] : value);
  };

  const today = new Date().toISOString().split('T')[0];

  const handleAmcChange = (e) => {
    const { checked } = e.target;
    setShowAmcFields(checked);
    handleChange(e); // Ensure handleChange updates the formData as well
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const a = validateForm();    
    if (validateForm()) {
      setIsLoading(true);
      try {
        const headers = {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        };
        const data = new FormData();
        for (const key in formData) {
          if (key === "asset_image") {
            for (const side in formData.asset_image) {
              // data.append(`asset_image.${side}`, formData.asset_image[side])
              if (formData.asset_image[side]) {
                // Only append if not null or undefined
                data.append(`asset_image.${side}`, formData.asset_image[side]);
              }
            }
          } else {
            data.append(key, formData[key]);
          }
        }
        const response = await axios.post("asset", data, { headers });
        if (response?.data?.status === true) {
          toast.success(response?.data?.data?.message, {
            position: "top-right",
            autoClose: 3000,
            theme: "colored",
          });
          getAssets();
          setFormData({
            name: "",
            model: "",
            make: "",
            unique_id: "",
            asset_type: "",
            description: "",
            purchase_date: "",
            purchase_type: "",
            purchase_vendor: "",
            purchase_price: "",
            status: "",
            warranty_period: "",
            warranty_status: "",
            has_amc: false,
            amc_start_date: "",
            amc_end_date: "",
            amc_amount: "",
            amc_coverage_details: "",
            amc_taken_with: "",
            amc_contact: "",
            additional_notes: "",
            asset_image: {
              side_1: null,
              side_2: null,
              front: null,
              back: null,
            },
            purchase_invoice_file: null,
          });
        }
        // Clear form or show success message
      } catch (error) {
        if (error?.response) {
          let errorMessage = "";
          if (error.response?.status === 404) {
            errorMessage = error.response.data.error;
          } else if (error.response?.status === 406) {
            errorMessage =
              error?.response?.data?.error?.message ||
              error?.response?.data?.error;
          } else if (error.response?.status === 412) {
            errorMessage = error?.response?.data?.data?.message;
          } else if (error.response?.status === 500) {
            errorMessage = error?.response?.data?.error;
          }
          toast.error(errorMessage, {
            position: "top-right",
            autoClose: 3000,
            theme: "colored",
          });
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  const getAssetType = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get("asset-type", { headers });
      setAssetType(response.data?.data?.assetTypes);
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const getVendors = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get("vendor", { headers });

      if (response?.data?.status === true) {
        // response?.data?.data?.vendors.forEach((item, index) => {
        //   item.serialNumber = index + 1;
        // });

        setVendors(response?.data?.data?.vendors);
        // setFilteredData(response?.data?.data?.vendors);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const getAssets = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get("asset", { headers });
      response?.data?.data?.assets.forEach((item, index) => {
        item.serialNumber = index + 1;
      });
      setAssets(response.data?.data?.assets);
      setFilteredData(response.data?.data?.assets);
    } catch (error) {}
  };

  const handleOpenViewModal = (row) => {
    setSelectedRow(row);
    setViewOpen(true);
  };

  const handleCloseViewModal = () => {
    setViewOpen(false);
    setSelectedRow(null);
  };

  const handleOpenUpdateImageModal = (imageSideName, id, imageSide) => {
    setOpenUpdateImageModal(true);
    setNameSideImg(imageSideName);
    setAssetImageID(id);
    setImageSides(imageSide);
    // setImageIndex(index)
  };

  const handleCloseUpdateImageModal = () => {
    setOpenUpdateImageModal(false);
  };

  const handleImageChange = (e, index) => {
    const { name, files } = e.target;
    setUpdatedImage(files[0]);
  };

  const handleEditModal = (row) => {
    const { serialNumber, sr_no, asset_image, ...filteredRow } = row; // Destructure to exclude serialNumber
    setSelectedRow(filteredRow);
    setEditedData(filteredRow, { _id: row?._id });
    setOpenEditModal(true);
    setShowUpdateAmcFields(filteredRow.has_amc);    
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
    setSelectedRow(null);
    setEditModalErrors({}); // Clear all errors
  };

  const handleEditChange = (e) => {
    const { name, value, checked, type } = e.target;
    setEditedData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));

    // Trigger validation for the field that was touched
    editValidateField(name, type === "checkbox" ? checked :  value);
  };

  const handleUpdateAmcChange = (e) => {
    const { checked } = e.target;
    setShowUpdateAmcFields(checked);
    handleEditChange(e);
  };

  const editAssetSubmit = async (e) => {
    e.preventDefault();
    setIsEditBtnLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const updateData = {
        _id: editedData._id,
        name: editedData.name,
        model: editedData.model,
        make: editedData.make,
        // asset_type: editedData.asset_type,
        unique_id: editedData.unique_id,
        status: editedData.status,
        description: editedData.description,
        purchase_vendor: editedData.purchase_vendor._id,
        purchase_type: editedData.purchase_type,
        purchase_date: editedData.purchase_date.substring(0, 10),
        purchase_price: editedData.purchase_price,
        warranty_period: editedData.warranty_period,
        warranty_status: editedData.warranty_status,
        has_amc: editedData.has_amc,
      };
      if (editedData.has_amc) {
        updateData.amc_start_date = editedData.amc_start_date.substring(0, 10);
        updateData.amc_taken_with = editedData.amc_taken_with;
        updateData.amc_amount = editedData.amc_amount;
        updateData.amc_end_date = editedData.amc_end_date.substring(0, 10);
        updateData.amc_contact = editedData.amc_contact;
        updateData.amc_coverage_details = editedData.amc_coverage_details;
        updateData.additional_notes = editedData.additional_notes;
      }
      const response = await axios.put("asset", updateData, { headers });
      toast.success(response?.data?.data?.message, {
        position: "top-right",
        autoClose: 3000,
        theme: "colored",
      });
      setOpenEditModal(false);
      getAssets();
    } catch (error) {
      if (error?.response) {
        let errorMessage = "";
        if (error.response?.status === 406) {
          errorMessage =
            error?.response?.data?.error?.message ||
            error?.response?.data?.error;
        } else if (error.response?.status === 412) {
          errorMessage = error?.response?.data?.data?.message;
        } else if (error.response?.status === 500) {
          errorMessage = error?.response?.data?.error;
        }
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      setIsEditBtnLoading(false);
    }
  };

  const handleOpenDeleteModal = (row) => {
    setopenDeleteModal(true);
    setAssetId(row._id);
  };

  const handleCloseDeleteModal = () => {
    setopenDeleteModal(false);
  };

  const handleDeleteRole = async (e) => {
    e.preventDefault();
    setIsDeleteBtnLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const data = {
        _id: assetId,
      };
      const response = await axios.delete(`asset`, { data, headers });
      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
        getAssets();
        setopenDeleteModal(false);
      }
    } catch (error) {
      if (error.response.status === 403) {
        toast.error(error?.response?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }

      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      setIsDeleteBtnLoading(false);
    }
  };

  // Function to format date to YYYY-MM-DD For Edit Modal to set in date input field
  const formatDate = (date) => {
    if (!date) return "";
    const d = new Date(date);
    const year = d.getFullYear();
    const month = ("0" + (d.getMonth() + 1)).slice(-2);
    const day = ("0" + d.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  };

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const updateImage = async () => {
    setIsSaveUploadImageBtn(true);
    try {
      const headers = {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${accessToken}`,
      };
      const formData = new FormData();
      formData.append(imageSides, updatedImage); // Append the image file with the required key
      formData.append("_id", assetImageID); // Append the asset image id with the required key
      const response = await axios.put("asset/update-images", formData, {
        headers,
      });
      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
        getAssets();
        setOpenUpdateImageModal(false);
        setViewOpen(false);
      }
    } catch (error) {
      if (error?.response) {
        let errorMessage = "";
        if (error.response?.status === 406) {
          errorMessage =
            error?.response?.data?.error?.message ||
            error?.response?.data?.error;
        } else if (error.response?.status === 412) {
          errorMessage = error?.response?.data?.data?.message;
        } else if (error.response?.status === 500) {
          errorMessage = error?.response?.data?.error;
        }

        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      {
        setIsSaveUploadImageBtn(false);
      }
    }
  };

  const handleOpenUpdateInvoiceModal = (assetId) => {
    setOpenInovieModal(true);
    setPurchaseInoviceId(assetId);
  }

  const handleInvoiceChange = (e) => {
    const { files } = e.target;
    setUpdatedInvoice(files[0]);
  }

  const handleSubmitUpdateInvoice = async(e) => {
    e.preventDefault();
    setInvoiceBtnLoading(true);
    try {
      const headers = {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${accessToken}`
      }
      const formData = new FormData();
      formData.append('purchase_invoice_file', updatedInvoice);
      formData.append('_id', purchaseInoviceId);
      const response = await axios.put('asset/update-images', formData, { headers });
      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
        getAssets();
        setOpenInovieModal(false);
      }
    } catch (error) {
      if (error?.response) {
        let errorMessage = "";
        if (error.response?.status === 404) {
          errorMessage = error?.response?.data?.error
        } else if (error.response?.status === 406) {
          errorMessage =
            error?.response?.data?.error?.message ||
            error?.response?.data?.error;
        } else if (error.response?.status === 412) {
          errorMessage = error?.response?.data?.data?.message;
        } else if (error.response?.status === 500) {
          errorMessage = error?.response?.data?.error;
        }
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      setInvoiceBtnLoading(false);
    }
  }

  const handleClosePurchaseInvoiceModal = () => {
    setOpenInovieModal(false);
  }

  const handleOpenAddModal = () => {
    setOpenAddModal(true);
  }

  const handleCloseAddModal = () => {
    setOpenAddModal(false);
  }

  const handlePageChange = (page) => {
    setPagination({ ...pagination, page });
  };
  
  const handleRowsPerPageChange = (rowsPerPage) => {
    setPagination({ page: 1, rowsPerPage }); // Reset to the first page when changing rows per page
  };
  
  // Function to get paginated data
  const getPaginatedData = () => {
    const startIndex = (pagination.page - 1) * pagination.rowsPerPage;
    const endIndex = startIndex + pagination.rowsPerPage;
    return filteredData.slice(startIndex, endIndex);
  };

  useEffect(() => {
    const results = assets.filter((item) => {
      return item.name?.toLowerCase().match(searchText.toLocaleLowerCase());
    });
    setFilteredData(results);
  }, [searchText]);

  useEffect(() => {
    getVendors();
    getAssetType();
    getAssets();
  }, []);

  return (
    <CContainer>
      <CRow>
        {/* <------------------ Add Assets Form -------------------> */}
        {/* <CCol className="col-md-12 col-lg-12">
          <CCard style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}>
            <CCardHeader className="fw-semibold">
              {currentAsset ? "EDIT ASSET" : "ADD NEW ASSET"}
            </CCardHeader>
            <CCardBody>
              <CForm onSubmit={handleSubmit}>
                <CRow>
                  <CCol md="4">
                    <CInputGroup className="flex-column">
                      <CFormLabel className="fw-semibold" htmlFor="name">
                        Name <span className="text-danger">*</span>
                      </CFormLabel>
                      <CFormInput
                        className="w-100"
                        id="name"
                        placeholder="Name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                      />
                      {errors.name && (
                        <small className="text-danger">{errors.name}</small>
                      )}
                    </CInputGroup>
                  </CCol>
                  <CCol md="4">
                    <CInputGroup className="flex-column">
                      <CFormLabel className="fw-semibold" htmlFor="model">
                        Model <span className="text-danger">*</span>
                      </CFormLabel>
                      <CFormInput
                        className="w-100"
                        id="model"
                        placeholder="Model"
                        name="model"
                        value={formData.model}
                        onChange={handleChange}
                      />
                      {errors.model && (
                        <small className="text-danger">{errors.model}</small>
                      )}
                    </CInputGroup>
                  </CCol>
                  <CCol md="4">
                    <CInputGroup className="flex-column">
                      <CFormLabel className="fw-semibold" htmlFor="make">
                        Brand Name <span className="text-danger">*</span>
                      </CFormLabel>
                      <CFormInput
                        className="w-100"
                        id="make"
                        placeholder="Brand Name"
                        name="make"
                        value={formData.make}
                        onChange={handleChange}
                      />
                      {errors.make && (
                        <small className="text-danger">{errors.make}</small>
                      )}
                    </CInputGroup>
                  </CCol>
                </CRow>
                <CRow className="mt-2">
                  <CCol md="4">
                    <CInputGroup className="flex-column">
                      <CFormLabel className="fw-semibold" htmlFor="asset_type">
                        Asset Type <span className="text-danger">*</span>
                      </CFormLabel>
                      <CFormSelect
                        className="w-100"
                        id="asset_type"
                        name="asset_type"
                        value={formData.asset_type}
                        onChange={handleChange}
                      >
                        <option value="" disabled>
                          Select Asset Type
                        </option>
                        {assetType?.map((asset) => {
                          return (
                            <option key={asset?._id} value={asset?._id}>
                              {asset?.title}
                            </option>
                          );
                        })}
                      </CFormSelect>
                      {errors.asset_type && (
                        <small className="text-danger">
                          {errors.asset_type}
                        </small>
                      )}
                    </CInputGroup>
                  </CCol>
                  <CCol md="4">
                    <CInputGroup className="flex-column">
                      <CFormLabel className="fw-semibold" htmlFor="unique_id">
                        Unique ID <span className="text-danger">*</span>
                      </CFormLabel>
                      <CFormInput
                        className="w-100"
                        id="unique_id"
                        placeholder="Unique ID"
                        name="unique_id"
                        value={formData.unique_id}
                        onChange={handleChange}
                      />
                      {errors.unique_id && (
                        <small className="text-danger">
                          {errors.unique_id}
                        </small>
                      )}
                    </CInputGroup>
                  </CCol>
                  <CCol md="4">
                    <CInputGroup className="flex-column">
                      <CFormLabel className="fw-semibold" htmlFor="status">
                        Status <span className="text-danger">*</span>
                      </CFormLabel>
                      <CFormSelect
                        className="w-100"
                        id="status"
                        name="status"
                        value={formData.status}
                        onChange={handleChange}
                      >
                        <option value="" disabled>
                          Select Status
                        </option>
                        <option value="ACTIVE">ACTIVE</option>
                        <option value="INACTIVE">INACTIVE</option>
                      </CFormSelect>
                      {errors.status && (
                        <small className="text-danger">{errors.status}</small>
                      )}
                    </CInputGroup>
                  </CCol>
                </CRow>
                <CRow className="mt-2">
                  <CCol md="6">
                    <CInputGroup className="flex-column">
                      <CFormLabel className="fw-semibold" htmlFor="description">
                        Description <span className="text-danger">*</span>
                      </CFormLabel>
                      <CFormTextarea
                        className="w-100"
                        id="description"
                        placeholder="Description"
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                      />
                      {errors.description && (
                        <small className="text-danger">
                          {errors.description}
                        </small>
                      )}
                    </CInputGroup>
                  </CCol>
                  <CCol md="6">
                    <CInputGroup className="flex-column">
                      <CFormLabel
                        className="fw-semibold"
                        htmlFor="purchase_vendor"
                      >
                        Purchase Vendor <span className="text-danger">*</span>
                      </CFormLabel>
                      <CFormSelect
                        className="w-100"
                        id="purchase_vendor"
                        name="purchase_vendor"
                        value={formData.purchase_vendor}
                        onChange={handleChange}
                      >
                        <option value="" disabled>
                          Select Vendor
                        </option>
                        {vendors.map((vendor) => {
                          return (
                            <option key={vendor._id} value={vendor._id}>
                              {vendor.name}
                            </option>
                          );
                        })}
                      </CFormSelect>
                      {errors.purchase_vendor && (
                        <small className="text-danger">
                          {errors.purchase_vendor}
                        </small>
                      )}
                    </CInputGroup>
                  </CCol>
                </CRow>
                <CRow className="mt-2">
                  <CCol md="3">
                    <CInputGroup className="flex-column">
                      <CFormLabel
                        className="fw-semibold"
                        htmlFor="purchase_type"
                      >
                        Purchase Type <span className="text-danger">*</span>
                      </CFormLabel>
                      <CFormSelect
                        className="w-100"
                        id="purchase_type"
                        name="purchase_type"
                        value={formData.purchase_type}
                        onChange={handleChange}
                      >
                        <option value="" disabled>
                          Select Purchase Type
                        </option>
                        <option value="NEW">NEW</option>
                        <option value="USED">USED</option>
                      </CFormSelect>
                      {errors.purchase_type && (
                        <small className="text-danger">
                          {errors.purchase_type}
                        </small>
                      )}
                    </CInputGroup>
                  </CCol>
                  <CCol md="3">
                    <CInputGroup className="flex-column">
                      <CFormLabel
                        className="fw-semibold"
                        htmlFor="purchase_date"
                      >
                        Purchase Date <span className="text-danger">*</span>
                      </CFormLabel>
                      <CFormInput
                        className="w-100"
                        type="date"
                        id="purchase_date"
                        name="purchase_date"
                        value={formData.purchase_date}
                        onChange={handleChange}
                        max={today}
                      />
                      {errors.purchase_date && (
                        <small className="text-danger">
                          {errors.purchase_date}
                        </small>
                      )}
                    </CInputGroup>
                  </CCol>
                  <CCol md="3">
                    <CInputGroup className="flex-column">
                      <CFormLabel
                        className="fw-semibold"
                        htmlFor="purchase_price"
                      >
                        Purchase Price <span className="text-danger">*</span>
                      </CFormLabel>
                      <CFormInput
                        className="w-100"
                        type="text"
                        id="purchase_price"
                        placeholder="Purchase Price"
                        name="purchase_price"
                        value={formData.purchase_price}
                        onChange={handleChange}
                        onInput={(e) => {
                          e.target.value = e.target.value
                            .replace(/[^0-9.]/g, '')
                            .replace(/(\..*)\./g, '$1');
                        }}
                        pattern="^\d+(\.\d{1,2})?$"
                        title="Purchase price must be a number with up to two decimal places."
                      />
                      {errors.purchase_price && (
                        <small className="text-danger">
                          {errors.purchase_price}
                        </small>
                      )}
                    </CInputGroup>
                  </CCol>
                  <CCol md="3">
                    <CInputGroup className="flex-column">
                      <CFormLabel
                        className="fw-semibold"
                        htmlFor="purchase_invoice_file"
                      >
                        Purchase Invoice File
                      </CFormLabel>
                      <CFormInput
                        className="w-100"
                        type="file"
                        id="purchase_invoice_file"
                        name="purchase_invoice_file"
                        onChange={handleChange}
                      />
                    </CInputGroup>
                  </CCol>
                </CRow>
                <CRow className="mt-2">
                  <CCol md="6">
                    <CInputGroup className="flex-column">
                      <CFormLabel
                        className="fw-semibold"
                        htmlFor="warranty_period"
                      >
                        Warranty Period <span className="text-danger">*</span>
                      </CFormLabel>
                      <CFormInput
                        className="w-100"
                        id="warranty_period"
                        placeholder="Warranty Period"
                        name="warranty_period"
                        value={formData.warranty_period}
                        onChange={handleChange}
                      />
                      {errors.warranty_period && (
                        <small className="text-danger">
                          {errors.warranty_period}
                        </small>
                      )}
                    </CInputGroup>
                  </CCol>
                  <CCol md="6">
                    <CInputGroup className="flex-column">
                      <CFormLabel
                        className="fw-semibold"
                        htmlFor="warranty_status"
                      >
                        Warranty Status <span className="text-danger">*</span>
                      </CFormLabel>
                      <CFormSelect
                        className="w-100"
                        id="warranty_status"
                        name="warranty_status"
                        value={formData.warranty_status}
                        onChange={handleChange}
                      >
                        <option value="" disabled>Select Warranty Status</option>
                        <option value="IN-WARRANTY">IN-WARRANTY</option>
                        <option value="OUT-OF-WARRANTY">OUT-OF-WARRANTY</option>
                      </CFormSelect>
                      {errors.warranty_status && (
                        <small className="text-danger">
                          {errors.warranty_status}
                        </small>
                      )}
                    </CInputGroup>
                  </CCol>
                </CRow>
                <CRow className="mt-2">
                  <CCol>
                    <CInputGroup className="flex-column w-25">
                      <CFormLabel className="fw-semibold" htmlFor="has_amc">
                        Has AMC
                      </CFormLabel>
                      <CFormSwitch
                        className="w-25"
                        id="has_amc"
                        name="has_amc"
                        checked={formData.has_amc}
                        onChange={handleAmcChange}
                      />
                      {errors.has_amc && (
                        <small className="text-danger">{errors.has_amc}</small>
                      )}
                    </CInputGroup>
                  </CCol>
                </CRow>

                Conditional rendering of AMC fields
                {showAmcFields && (
                  <>
                    <CRow className="mt-2">
                      <CCol md="4">
                        <CInputGroup className="flex-column">
                          <CFormLabel
                            className="fw-semibold"
                            htmlFor="amc_start_date"
                          >
                            AMC Start Date{" "}
                            <span className="text-danger">*</span>
                          </CFormLabel>
                          <CFormInput
                            className="w-100"
                            type="date"
                            id="amc_start_date"
                            name="amc_start_date"
                            value={formData.amc_start_date}
                            onChange={handleChange}
                          />
                          {errors.amc_start_date && (
                            <small className="text-danger">
                              {errors.amc_start_date}
                            </small>
                          )}
                        </CInputGroup>
                      </CCol>
                      <CCol md="4">
                        <CInputGroup className="flex-column">
                          <CFormLabel
                            className="fw-semibold"
                            htmlFor="amc_end_date"
                          >
                            AMC End Date <span className="text-danger">*</span>
                          </CFormLabel>
                          <CFormInput
                            className="w-100"
                            type="date"
                            id="amc_end_date"
                            name="amc_end_date"
                            value={formData.amc_end_date}
                            onChange={handleChange}
                          />
                          {errors.amc_end_date && (
                            <small className="text-danger">
                              {errors.amc_end_date}
                            </small>
                          )}
                        </CInputGroup>
                      </CCol>
                      <CCol md="4">
                        <CInputGroup className="flex-column">
                          <CFormLabel
                            className="fw-semibold"
                            htmlFor="amc_amount"
                          >
                            AMC Amount <span className="text-danger">*</span>
                          </CFormLabel>
                          <CFormInput
                            className="w-100"
                            type="number"
                            id="amc_amount"
                            placeholder="AMC Amount"
                            name="amc_amount"
                            value={formData.amc_amount}
                            onChange={handleChange}
                            onInput={(e) => {
                              e.target.value = e.target.value.replace(
                                /[^0-9]/g,
                                ""
                              );
                            }}
                            pattern="^\d+(\.\d+){0,2}$"
                            title="AMC amount must be integer and must be in the format x, x.x, or x.x.x where x is a number"
                          />
                          {errors.amc_amount && (
                            <small className="text-danger">
                              {errors.amc_amount}
                            </small>
                          )}
                        </CInputGroup>
                      </CCol>
                    </CRow>
                    <CRow className="mt-2">
                      <CCol md="6">
                        <CInputGroup className="flex-column">
                          <CFormLabel
                            className="fw-semibold"
                            htmlFor="amc_taken_with"
                          >
                            AMC Taken With{" "}
                            <span className="text-danger">*</span>
                          </CFormLabel>
                          <CFormInput
                            className="w-100"
                            id="amc_taken_with"
                            name="amc_taken_with"
                            placeholder="AMC Taken With"
                            value={formData.amc_taken_with}
                            onChange={handleChange}
                          />
                          {errors.amc_taken_with && (
                            <small className="text-danger">
                              {errors.amc_taken_with}
                            </small>
                          )}
                        </CInputGroup>
                      </CCol>
                      <CCol md="6">
                        <CInputGroup className="flex-column">
                          <CFormLabel
                            className="fw-semibold"
                            htmlFor="amc_contact"
                          >
                            AMC Contact <span className="text-danger">*</span>
                          </CFormLabel>
                          <CFormInput
                            className="w-100"
                            id="amc_contact"
                            name="amc_contact"
                            placeholder="AMC Contact"
                            value={formData.amc_contact}
                            onChange={handleChange}
                          />
                          {errors.amc_contact && (
                            <small className="text-danger">
                              {errors.amc_contact}
                            </small>
                          )}
                        </CInputGroup>
                      </CCol>
                    </CRow>
                    <CRow className="mt-2">
                      <CCol CCol md="6">
                        <CInputGroup className="flex-column">
                          <CFormLabel
                            className="fw-semibold"
                            htmlFor="amc_coverage_details"
                          >
                            AMC Coverage Details{" "}
                            <span className="text-danger">*</span>
                          </CFormLabel>
                          <CFormTextarea
                            className="w-100"
                            id="amc_coverage_details"
                            placeholder="AMC Coverage Details"
                            name="amc_coverage_details"
                            value={formData.amc_coverage_details}
                            onChange={handleChange}
                          />
                          {errors.amc_coverage_details && (
                            <small className="text-danger">
                              {errors.amc_coverage_details}
                            </small>
                          )}
                        </CInputGroup>
                      </CCol>
                      <CCol md="6">
                        <CInputGroup className="flex-column">
                          <CFormLabel
                            className="fw-semibold"
                            htmlFor="additional_notes"
                          >
                            Additional Notes{" "}
                            <span className="text-danger">*</span>
                          </CFormLabel>
                          <CFormTextarea
                            className="w-100"
                            id="additional_notes"
                            placeholder="Additional Notes"
                            name="additional_notes"
                            value={formData.additional_notes}
                            onChange={handleChange}
                          />
                          {errors.additional_notes && (
                            <small className="text-danger">
                              {errors.additional_notes}
                            </small>
                          )}
                        </CInputGroup>
                      </CCol>
                    </CRow>
                  </>
                )}
                <CRow className="mt-2">
                  <CCol md="3">
                    <CInputGroup className="flex-column">
                      <CFormLabel
                        className="fw-semibold"
                        htmlFor="asset_image.front"
                      >
                        Asset Image Front <span className="text-danger">*</span>
                      </CFormLabel>
                      <CFormInput
                        className="w-100"
                        type="file"
                        accept="image/*"
                        id="asset_image.front"
                        name="asset_image.front"
                        value={formData?.asset_image?.front}
                        onChange={handleChange}
                      />
                    </CInputGroup>
                  </CCol>
                  <CCol md="3">
                    <CInputGroup className="flex-column">
                      <CFormLabel
                        className="fw-semibold"
                        htmlFor="asset_image.side_1"
                      >
                        Asset Image Side 1
                      </CFormLabel>
                      <CFormInput
                        className="w-100"
                        type="file"
                        accept="image/*"
                        id="asset_image.side_1"
                        name="asset_image.side_1"
                        value={formData?.asset_image?.side_1}
                        onChange={handleChange}
                      />
                    </CInputGroup>
                  </CCol>
                  <CCol md="3">
                    <CInputGroup className="flex-column">
                      <CFormLabel
                        className="fw-semibold"
                        htmlFor="asset_image.side_2"
                      >
                        Asset Image Side 2
                      </CFormLabel>
                      <CFormInput
                        className="w-100"
                        type="file"
                        accept="image/*"
                        id="asset_image.side_2"
                        name="asset_image.side_2"
                        value={formData?.asset_image?.side_2}
                        onChange={handleChange}
                      />
                    </CInputGroup>
                  </CCol>
                  <CCol md="3">
                    <CInputGroup className="flex-column">
                      <CFormLabel
                        className="fw-semibold"
                        htmlFor="asset_image.back"
                      >
                        Asset Image Back <span className="text-danger">*</span>
                      </CFormLabel>
                      <CFormInput
                        className="w-100"
                        type="file"
                        id="asset_image.back"
                        accept="image/*"
                        name="asset_image.back"
                        value={formData?.asset_image?.back}
                        onChange={handleChange}
                      />
                    </CInputGroup>
                  </CCol>
                </CRow>
                <div className="mt-3">
                  <PrimaryButton title="ADD ASSET" isLoading={IsLoading} />
                </div>
              </CForm>
            </CCardBody>
          </CCard>
        </CCol> */}

        <div className="mb-1 text-end">
          <button
            className="btn btn-warning w-25"
            onClick={handleOpenAddModal}
            style={{ borderRadius: "18px" }}
          >
            ADD NEW ASSET <FaPlus style={{ marginTop: "-4px" }} size={15} />
          </button>
        </div>

        {/* <------------------ Assets Display Table --------------------> */}
        <CCol className="col-md-12 col-lg-12 mt-4">
          <DataTableComponent
            columns={updatedColumns}
            // data={filteredData}
            data={getPaginatedData()}
            title="ASSETS LIST"
            searchText={searchText}
            onChange={handleSearchChange}
            pagination
            totalRows={filteredData?.length}
            paginationTotalRows={filteredData.length} // Total number of items
            onChangeRowsPerPage={handleRowsPerPageChange}
            onChangePage={handlePageChange}
            paginationServer={false} // Set to false for client-side pagination
          />
        </CCol>
      </CRow>

      {/* <------------------ Add New Asset Modal -------------------> */}
      <Modal
        show={OpenAddModal}
        onHide={handleCloseAddModal}
        centered
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Asset</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CRow>
            <CCol className="col-md-12 col-lg-12">
            {/* <CCard style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}> */}
              {/* <CCardHeader className="fw-semibold">
                {currentAsset ? "EDIT ASSET" : "ADD NEW ASSET"}
              </CCardHeader> */}
              {/* <CCardBody> */}
                <CForm onSubmit={handleSubmit}>
                  <CRow>
                    <CCol md="4">
                      <CInputGroup className="flex-column">
                        <CFormLabel className="fw-semibold" htmlFor="name">
                          Name <span className="text-danger">*</span>
                        </CFormLabel>
                        <CFormInput
                          className="w-100"
                          id="name"
                          placeholder="Name"
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                        />
                        {errors.name && (
                          <small className="text-danger">{errors.name}</small>
                        )}
                      </CInputGroup>
                    </CCol>
                    <CCol md="4">
                      <CInputGroup className="flex-column">
                        <CFormLabel className="fw-semibold" htmlFor="model">
                          Model <span className="text-danger">*</span>
                        </CFormLabel>
                        <CFormInput
                          className="w-100"
                          id="model"
                          placeholder="Model"
                          name="model"
                          value={formData.model}
                          onChange={handleChange}
                        />
                        {errors.model && (
                          <small className="text-danger">{errors.model}</small>
                        )}
                      </CInputGroup>
                    </CCol>
                    <CCol md="4">
                      <CInputGroup className="flex-column">
                        <CFormLabel className="fw-semibold" htmlFor="make">
                          Brand Name <span className="text-danger">*</span>
                        </CFormLabel>
                        <CFormInput
                          className="w-100"
                          id="make"
                          placeholder="Brand Name"
                          name="make"
                          value={formData.make}
                          onChange={handleChange}
                        />
                        {errors.make && (
                          <small className="text-danger">{errors.make}</small>
                        )}
                      </CInputGroup>
                    </CCol>
                  </CRow>
                  <CRow className="mt-2">
                    <CCol md="4">
                      <CInputGroup className="flex-column">
                        <CFormLabel className="fw-semibold" htmlFor="asset_type">
                          Asset Type <span className="text-danger">*</span>
                        </CFormLabel>
                        <CFormSelect
                          className="w-100"
                          id="asset_type"
                          name="asset_type"
                          value={formData.asset_type}
                          onChange={handleChange}
                          // required
                        >
                          <option value="" disabled>
                            Select Asset Type
                          </option>
                          {assetType?.map((asset) => {
                            return (
                              <option key={asset?._id} value={asset?._id}>
                                {asset?.title}
                              </option>
                            );
                          })}
                        </CFormSelect>
                        {errors.asset_type && (
                          <small className="text-danger">
                            {errors.asset_type}
                          </small>
                        )}
                      </CInputGroup>
                    </CCol>
                    <CCol md="4">
                      <CInputGroup className="flex-column">
                        <CFormLabel className="fw-semibold" htmlFor="unique_id">
                          Unique ID <span className="text-danger">*</span>
                        </CFormLabel>
                        <CFormInput
                          className="w-100"
                          id="unique_id"
                          placeholder="Unique ID"
                          name="unique_id"
                          value={formData.unique_id}
                          onChange={handleChange}
                          // required
                        />
                        {errors.unique_id && (
                          <small className="text-danger">
                            {errors.unique_id}
                          </small>
                        )}
                      </CInputGroup>
                    </CCol>
                    <CCol md="4">
                      <CInputGroup className="flex-column">
                        <CFormLabel className="fw-semibold" htmlFor="status">
                          Status <span className="text-danger">*</span>
                        </CFormLabel>
                        <CFormSelect
                          className="w-100"
                          id="status"
                          name="status"
                          value={formData.status}
                          onChange={handleChange}
                          // required
                        >
                          <option value="" disabled>
                            Select Status
                          </option>
                          <option value="ACTIVE">ACTIVE</option>
                          <option value="INACTIVE">INACTIVE</option>
                        </CFormSelect>
                        {errors.status && (
                          <small className="text-danger">{errors.status}</small>
                        )}
                      </CInputGroup>
                    </CCol>
                  </CRow>
                  <CRow className="mt-2">
                    <CCol md="6">
                      <CInputGroup className="flex-column">
                        <CFormLabel className="fw-semibold" htmlFor="description">
                          Description <span className="text-danger">*</span>
                        </CFormLabel>
                        <CFormTextarea
                          className="w-100"
                          id="description"
                          placeholder="Description"
                          name="description"
                          value={formData.description}
                          onChange={handleChange}
                          // required
                        />
                        {errors.description && (
                          <small className="text-danger">
                            {errors.description}
                          </small>
                        )}
                      </CInputGroup>
                    </CCol>
                    <CCol md="6">
                      <CInputGroup className="flex-column">
                        <CFormLabel
                          className="fw-semibold"
                          htmlFor="purchase_vendor"
                        >
                          Purchase Vendor <span className="text-danger">*</span>
                        </CFormLabel>
                        <CFormSelect
                          className="w-100"
                          id="purchase_vendor"
                          name="purchase_vendor"
                          value={formData.purchase_vendor}
                          onChange={handleChange}
                          // required
                        >
                          <option value="" disabled>
                            Select Vendor
                          </option>
                          {vendors.map((vendor) => {
                            return (
                              <option key={vendor._id} value={vendor._id}>
                                {vendor.name}
                              </option>
                            );
                          })}
                        </CFormSelect>
                        {errors.purchase_vendor && (
                          <small className="text-danger">
                            {errors.purchase_vendor}
                          </small>
                        )}
                      </CInputGroup>
                    </CCol>
                  </CRow>
                  <CRow className="mt-2">
                    <CCol md="3">
                      <CInputGroup className="flex-column">
                        <CFormLabel
                          className="fw-semibold"
                          htmlFor="purchase_type"
                        >
                          Purchase Type <span className="text-danger">*</span>
                        </CFormLabel>
                        <CFormSelect
                          className="w-100"
                          id="purchase_type"
                          name="purchase_type"
                          value={formData.purchase_type}
                          onChange={handleChange}
                          // required
                        >
                          <option value="" disabled>
                            Select Purchase Type
                          </option>
                          <option value="NEW">NEW</option>
                          <option value="USED">USED</option>
                        </CFormSelect>
                        {errors.purchase_type && (
                          <small className="text-danger">
                            {errors.purchase_type}
                          </small>
                        )}
                      </CInputGroup>
                    </CCol>
                    <CCol md="3">
                      <CInputGroup className="flex-column">
                        <CFormLabel
                          className="fw-semibold"
                          htmlFor="purchase_date"
                        >
                          Purchase Date <span className="text-danger">*</span>
                        </CFormLabel>
                        <CFormInput
                          className="w-100"
                          type="date"
                          id="purchase_date"
                          name="purchase_date"
                          value={formData.purchase_date}
                          onChange={handleChange}
                          max={today}
                          // required
                        />
                        {errors.purchase_date && (
                          <small className="text-danger">
                            {errors.purchase_date}
                          </small>
                        )}
                      </CInputGroup>
                    </CCol>
                    <CCol md="3">
                      <CInputGroup className="flex-column">
                        <CFormLabel
                          className="fw-semibold"
                          htmlFor="purchase_price"
                        >
                          Purchase Price <span className="text-danger">*</span>
                        </CFormLabel>
                        <CFormInput
                          className="w-100"
                          type="text"
                          id="purchase_price"
                          placeholder="Purchase Price"
                          name="purchase_price"
                          value={formData.purchase_price}
                          onChange={handleChange}
                          // required
                          // onInput={(e) => {
                          //   e.target.value = e.target.value.replace(
                          //     /[^0-9]/g,
                          //     ""
                          //   );
                          // }}
                          // pattern="^\d+(\.\d+){0,2}$"
                          // title="Purchase price must be integer and must be in the format x, x.x, or x.x.x where x is a number"
                          onInput={(e) => {
                            e.target.value = e.target.value
                              .replace(/[^0-9.]/g, '') // Allow only numbers and a decimal point
                              .replace(/(\..*)\./g, '$1'); // Prevent multiple decimal points
                          }}
                          pattern="^\d+(\.\d{1,2})?$"
                          title="Purchase price must be a number with up to two decimal places."
                        />
                        {errors.purchase_price && (
                          <small className="text-danger">
                            {errors.purchase_price}
                          </small>
                        )}
                      </CInputGroup>
                    </CCol>
                    <CCol md="3">
                      <CInputGroup className="flex-column">
                        <CFormLabel
                          className="fw-semibold"
                          htmlFor="purchase_invoice_file"
                        >
                          Purchase Invoice File
                        </CFormLabel>
                        <CFormInput
                          className="w-100"
                          type="file"
                          id="purchase_invoice_file"
                          name="purchase_invoice_file"
                          onChange={handleChange}
                        />
                      </CInputGroup>
                    </CCol>
                  </CRow>
                  <CRow className="mt-2">
                    <CCol md="6">
                      <CInputGroup className="flex-column">
                        <CFormLabel
                          className="fw-semibold"
                          htmlFor="warranty_period"
                        >
                          Warranty Period <span className="text-danger">*</span>
                        </CFormLabel>
                        <CFormInput
                          className="w-100"
                          id="warranty_period"
                          placeholder="Warranty Period"
                          name="warranty_period"
                          value={formData.warranty_period}
                          onChange={handleChange}
                        />
                        {errors.warranty_period && (
                          <small className="text-danger">
                            {errors.warranty_period}
                          </small>
                        )}
                      </CInputGroup>
                    </CCol>
                    <CCol md="6">
                      <CInputGroup className="flex-column">
                        <CFormLabel
                          className="fw-semibold"
                          htmlFor="warranty_status"
                        >
                          Warranty Status <span className="text-danger">*</span>
                        </CFormLabel>
                        <CFormSelect
                          className="w-100"
                          id="warranty_status"
                          name="warranty_status"
                          value={formData.warranty_status}
                          onChange={handleChange}
                        >
                          <option value="" disabled>Select Warranty Status</option>
                          <option value="IN-WARRANTY">IN-WARRANTY</option>
                          <option value="OUT-OF-WARRANTY">OUT-OF-WARRANTY</option>
                        </CFormSelect>
                        {errors.warranty_status && (
                          <small className="text-danger">
                            {errors.warranty_status}
                          </small>
                        )}
                      </CInputGroup>
                    </CCol>
                  </CRow>
                  <CRow className="mt-2">
                    <CCol>
                      <CInputGroup className="flex-column w-25">
                        <CFormLabel className="fw-semibold" htmlFor="has_amc">
                          Has AMC
                        </CFormLabel>
                        <CFormSwitch
                          className="w-25"
                          id="has_amc"
                          name="has_amc"
                          checked={formData.has_amc}
                          onChange={handleAmcChange}
                        />
                        {errors.has_amc && (
                          <small className="text-danger">{errors.has_amc}</small>
                        )}
                      </CInputGroup>
                    </CCol>
                  </CRow>

                  {/* Conditional rendering of AMC fields */}
                  {showAmcFields && (
                    <>
                      <CRow className="mt-2">
                        <CCol md="4">
                          <CInputGroup className="flex-column">
                            <CFormLabel
                              className="fw-semibold"
                              htmlFor="amc_start_date"
                            >
                              AMC Start Date{" "}
                              <span className="text-danger">*</span>
                            </CFormLabel>
                            <CFormInput
                              className="w-100"
                              type="date"
                              id="amc_start_date"
                              name="amc_start_date"
                              value={formData.amc_start_date}
                              onChange={handleChange}
                            />
                            {errors.amc_start_date && (
                              <small className="text-danger">
                                {errors.amc_start_date}
                              </small>
                            )}
                          </CInputGroup>
                        </CCol>
                        <CCol md="4">
                          <CInputGroup className="flex-column">
                            <CFormLabel
                              className="fw-semibold"
                              htmlFor="amc_end_date"
                            >
                              AMC End Date <span className="text-danger">*</span>
                            </CFormLabel>
                            <CFormInput
                              className="w-100"
                              type="date"
                              id="amc_end_date"
                              name="amc_end_date"
                              value={formData.amc_end_date}
                              onChange={handleChange}
                            />
                            {errors.amc_end_date && (
                              <small className="text-danger">
                                {errors.amc_end_date}
                              </small>
                            )}
                          </CInputGroup>
                        </CCol>
                        <CCol md="4">
                          <CInputGroup className="flex-column">
                            <CFormLabel
                              className="fw-semibold"
                              htmlFor="amc_amount"
                            >
                              AMC Amount <span className="text-danger">*</span>
                            </CFormLabel>
                            <CFormInput
                              className="w-100"
                              type="number"
                              id="amc_amount"
                              placeholder="AMC Amount"
                              name="amc_amount"
                              value={formData.amc_amount}
                              onChange={handleChange}
                              onInput={(e) => {
                                e.target.value = e.target.value.replace(
                                  /[^0-9]/g,
                                  ""
                                );
                              }}
                              pattern="^\d+(\.\d+){0,2}$"
                              title="AMC amount must be integer and must be in the format x, x.x, or x.x.x where x is a number"
                            />
                            {errors.amc_amount && (
                              <small className="text-danger">
                                {errors.amc_amount}
                              </small>
                            )}
                          </CInputGroup>
                        </CCol>
                      </CRow>
                      <CRow className="mt-2">
                        <CCol md="6">
                          <CInputGroup className="flex-column">
                            <CFormLabel
                              className="fw-semibold"
                              htmlFor="amc_taken_with"
                            >
                              AMC Taken With{" "}
                              <span className="text-danger">*</span>
                            </CFormLabel>
                            <CFormInput
                              className="w-100"
                              id="amc_taken_with"
                              name="amc_taken_with"
                              placeholder="AMC Taken With"
                              value={formData.amc_taken_with}
                              onChange={handleChange}
                            />
                            {errors.amc_taken_with && (
                              <small className="text-danger">
                                {errors.amc_taken_with}
                              </small>
                            )}
                          </CInputGroup>
                        </CCol>
                        <CCol md="6">
                          <CInputGroup className="flex-column">
                            <CFormLabel
                              className="fw-semibold"
                              htmlFor="amc_contact"
                            >
                              AMC Contact <span className="text-danger">*</span>
                            </CFormLabel>
                            <CFormInput
                              className="w-100"
                              id="amc_contact"
                              name="amc_contact"
                              placeholder="AMC Contact"
                              value={formData.amc_contact}
                              onChange={handleChange}
                            />
                            {errors.amc_contact && (
                              <small className="text-danger">
                                {errors.amc_contact}
                              </small>
                            )}
                          </CInputGroup>
                        </CCol>
                      </CRow>
                      <CRow className="mt-2">
                        <CCol CCol md="6">
                          <CInputGroup className="flex-column">
                            <CFormLabel
                              className="fw-semibold"
                              htmlFor="amc_coverage_details"
                            >
                              AMC Coverage Details{" "}
                              <span className="text-danger">*</span>
                            </CFormLabel>
                            <CFormTextarea
                              className="w-100"
                              id="amc_coverage_details"
                              placeholder="AMC Coverage Details"
                              name="amc_coverage_details"
                              value={formData.amc_coverage_details}
                              onChange={handleChange}
                            />
                            {errors.amc_coverage_details && (
                              <small className="text-danger">
                                {errors.amc_coverage_details}
                              </small>
                            )}
                          </CInputGroup>
                        </CCol>
                        <CCol md="6">
                          <CInputGroup className="flex-column">
                            <CFormLabel
                              className="fw-semibold"
                              htmlFor="additional_notes"
                            >
                              Additional Notes{" "}
                              <span className="text-danger">*</span>
                            </CFormLabel>
                            <CFormTextarea
                              className="w-100"
                              id="additional_notes"
                              placeholder="Additional Notes"
                              name="additional_notes"
                              value={formData.additional_notes}
                              onChange={handleChange}
                            />
                            {errors.additional_notes && (
                              <small className="text-danger">
                                {errors.additional_notes}
                              </small>
                            )}
                          </CInputGroup>
                        </CCol>
                      </CRow>
                    </>
                  )}
                  <CRow className="mt-2 mb-2">
                    <CCol md="3">
                      <CInputGroup className="flex-column">
                        <CFormLabel
                          className="fw-semibold"
                          htmlFor="asset_image.front"
                        >
                          Asset Image Front <span className="text-danger">*</span>
                        </CFormLabel>
                        <CFormInput
                          className="w-100"
                          type="file"
                          accept="image/*"
                          id="asset_image.front"
                          name="asset_image.front"
                          value={formData?.asset_image?.front}
                          onChange={handleChange}
                        />
                      </CInputGroup>
                    </CCol>
                    <CCol md="3">
                      <CInputGroup className="flex-column">
                        <CFormLabel
                          className="fw-semibold"
                          htmlFor="asset_image.side_1"
                        >
                          Asset Image Side 1
                        </CFormLabel>
                        <CFormInput
                          className="w-100"
                          type="file"
                          accept="image/*"
                          id="asset_image.side_1"
                          name="asset_image.side_1"
                          value={formData?.asset_image?.side_1}
                          onChange={handleChange}
                        />
                      </CInputGroup>
                    </CCol>
                    <CCol md="3">
                      <CInputGroup className="flex-column">
                        <CFormLabel
                          className="fw-semibold"
                          htmlFor="asset_image.side_2"
                        >
                          Asset Image Side 2
                        </CFormLabel>
                        <CFormInput
                          className="w-100"
                          type="file"
                          accept="image/*"
                          id="asset_image.side_2"
                          name="asset_image.side_2"
                          value={formData?.asset_image?.side_2}
                          onChange={handleChange}
                        />
                      </CInputGroup>
                    </CCol>
                    <CCol md="3">
                      <CInputGroup className="flex-column">
                        <CFormLabel
                          className="fw-semibold"
                          htmlFor="asset_image.back"
                        >
                          Asset Image Back <span className="text-danger">*</span>
                        </CFormLabel>
                        <CFormInput
                          className="w-100"
                          type="file"
                          id="asset_image.back"
                          accept="image/*"
                          name="asset_image.back"
                          value={formData?.asset_image?.back}
                          onChange={handleChange}
                        />
                      </CInputGroup>
                    </CCol>
                  </CRow>
                  <hr />
                  <div className="mt-2 text-end">
                    <PrimaryButton style={{ width: '20%' }} title="ADD ASSET" isLoading={IsLoading} />
                  </div>
                </CForm>
              {/* </CCardBody> */}
            {/* </CCard> */}
            </CCol>
          </CRow>
        </Modal.Body>
      </Modal>

      {/* <------------------ Assets View Modal -------------------> */}
      <Modal show={viewOpen} onHide={handleCloseViewModal} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "18px" }}>
            View Asset Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedRow && (
            <Card>
              <Card.Body>
                {/* Basic Information */}
                <Row>
                  <Col md={6}>
                    <h5 className="text-primary">Basic Information</h5>
                    <hr />
                    <p>
                      <strong>Name:</strong> {selectedRow.name}
                    </p>
                    <p>
                      <strong>Model:</strong> {selectedRow.model}
                    </p>
                    <p>
                      <strong>Brand Name:</strong> {selectedRow.make}
                    </p>
                    <p>
                      <strong>Unique ID:</strong> {selectedRow.unique_id}
                    </p>
                    <p>
                      <strong>Asset Type:</strong> {selectedRow.asset_type.title}
                    </p>
                    <p style={{ textAlign: "justify", hyphens: "auto" }}>
                      <strong>Description:</strong> {selectedRow.description}
                    </p>
                  </Col>

                  {/* Purchase and Warranty */}
                  <Col md={6}>
                    <h5 className="text-primary">Purchase and Warranty</h5>
                    <hr />
                    <p>
                      <strong>Purchase Vendor:</strong>{" "}
                      {selectedRow.purchase_vendor.name}
                    </p>
                    <p>
                      <strong>Purchase Date:</strong>{" "}
                      {selectedRow.purchase_date
                        ? new Date(selectedRow.purchase_date).toLocaleDateString()
                        : "Not specified"}
                    </p>
                    <p>
                      <strong>Purchase Type:</strong> {selectedRow.purchase_type}
                    </p>
                    <p>
                      <strong>Purchase Price:</strong>{" "}
                      {selectedRow.purchase_price}
                    </p>
                    <p>
                      <strong>Status:</strong> {selectedRow.status}
                    </p>
                    <p>
                      <strong>Warranty Period:</strong>{" "}
                      {selectedRow.warranty_period}
                    </p>
                    <p>
                      <strong>Warranty Status:</strong>{" "}
                      {selectedRow.warranty_status}
                    </p>
                    {/* Displaying the purchase_invoice_file */}
                    <p className="d-flex align-items-center">
                      <strong>Purchase Invoice: </strong>{" "}
                      {selectedRow.purchase_invoice_file ? (
                        <div className="d-flex align-items-center">
                          <a
                            href={selectedRow.purchase_invoice_file}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="ms-2"
                            style={{
                              // display: "inline-block",
                              color: "#007bff",
                              paddingRight: "10px",
                            }}
                          >
                            <i className="fas fa-file-invoice"></i> View Invoice
                          </a>
                          <Button
                            variant="outline-primary"
                            size="sm"
                            onClick={() => handleOpenUpdateInvoiceModal(selectedRow._id)}
                          >
                            <i className="fas fa-edit"></i> Update
                          </Button>
                        </div>
                      ) : (
                        <div className="d-flex align-items-center">
                          <span className="ms-1" style={{ color: "#dc3545", fontWeight: "500", paddingRight: "10px" }}>
                            No invoice available
                          </span>
                          <Button
                            variant="outline-success"
                            size="sm"
                            onClick={() => handleOpenUpdateInvoiceModal(selectedRow._id)}
                          >
                            <i className="fas fa-upload"></i> Upload
                          </Button>
                        </div>
                      )}
                    </p>
                  </Col>
                </Row>

                {/* Additional AMC Information */}
                <Row>
                  <hr />
                  <h5 className="text-primary">Additional AMC Information</h5>
                  <hr />
                  <Col md={6}>
                    <p>
                      <strong>Has AMC:</strong>{" "}
                      {selectedRow.has_amc ? "Yes" : "No"}
                    </p>
                    {selectedRow.has_amc && (
                      <>
                        <p>
                          <strong>AMC Start Date:</strong>{" "}
                          {selectedRow.amc_start_date
                            ? new Date(
                                selectedRow.amc_start_date
                              ).toLocaleDateString(undefined, {
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                              })
                            : "Not specified"}
                        </p>
                        <p>
                          <strong>AMC End Date:</strong>{" "}
                          {selectedRow.amc_end_date
                            ? new Date(
                                selectedRow.amc_end_date
                              ).toLocaleDateString(undefined, {
                                year: "numeric",
                                month: "long",
                                day: "numeric"
                              })
                            : "Not specified"}
                        </p>
                        <p>
                          <strong>AMC Amount:</strong>{" "}
                          {selectedRow.amc_amount}
                        </p>
                      </>
                    )}
                  </Col>
                  <Col md={6}>
                    {selectedRow.has_amc && (
                      <>
                        <p>
                          <strong>AMC Coverage Details:</strong>{" "}
                          {selectedRow.amc_coverage_details}
                        </p>
                        <p>
                          <strong>AMC Taken With:</strong>{" "}
                          {selectedRow.amc_taken_with}
                        </p>
                        <p>
                          <strong>AMC Contact:</strong>{" "}
                          {selectedRow.amc_contact}
                        </p>
                        <p>
                          <strong>Additional Notes:</strong>{" "}
                          {selectedRow.additional_notes}
                        </p>
                      </>
                    )}
                  </Col>
                </Row>

                {/* Asset Images */}
                <Row className="mt-4">
                  <Col>
                    <h5 className="text-primary">Asset Images</h5>
                    <hr />
                    <div className="d-flex flex-wrap justify-content-between align-items-center">
                      {selectedRow?.asset_image?.front && (
                        <div className="mb-3 me-3 d-flex flex-column align-items-center">
                          <h6>Front Image</h6>
                          <Image
                            src={selectedRow?.asset_image?.front}
                            alt="Front"
                            className="img-thumbnail mr-3 mb-3"
                            style={{ height: "100px", width: "100px" }}
                          />
                          <Button
                            onClick={() =>
                              handleOpenUpdateImageModal(
                                "Front Asset Image",
                                selectedRow._id,
                                "asset_image.front"
                              )
                            }
                          >
                            Update Photo
                          </Button>
                        </div>
                      )}
                      {selectedRow?.asset_image?.side_1 && (
                        <div className="mb-3 me-3 d-flex flex-column align-items-center">
                          <h6>Side Image 1</h6>
                          <Image
                            src={selectedRow?.asset_image?.side_1}
                            alt="Side 1"
                            className="img-thumbnail mr-3 mb-3"
                            style={{ height: "100px", width: "100px" }}
                          />
                          <Button
                            onClick={() =>
                              handleOpenUpdateImageModal(
                                "Side Image 1",
                                selectedRow._id,
                                "asset_image.side_1"
                              )
                            }
                          >
                            Update Photo
                          </Button>
                        </div>
                      )}
                      {selectedRow?.asset_image?.side_2 && (
                        <div className="mb-3 me-3 d-flex flex-column align-items-center">
                          <h6>Side Image 2</h6>
                          <Image
                            src={selectedRow?.asset_image?.side_2}
                            alt="Side 2"
                            className="img-thumbnail mr-3 mb-3"
                            style={{ height: "100px", width: "100px" }}
                          />
                          <Button
                            onClick={() =>
                              handleOpenUpdateImageModal(
                                "Side Image 2",
                                selectedRow._id,
                                "asset_image.side_2"
                              )
                            }
                          >
                            Update Photo
                          </Button>
                        </div>
                      )}
                      {selectedRow?.asset_image?.back && (
                        <div className="mb-3 me-3 d-flex flex-column align-items-center">
                          <h6>Back Image</h6>
                          <Image
                            src={selectedRow?.asset_image?.back}
                            alt="Back"
                            className="img-thumbnail mr-3 mb-3"
                            style={{ height: "100px", width: "100px" }}
                          />
                          <Button
                            onClick={() =>
                              handleOpenUpdateImageModal(
                                "Back Image",
                                selectedRow._id,
                                "asset_image.back"
                              )
                            }
                          >
                            Update Photo
                          </Button>
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleCloseViewModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* <----------------- Update Image Modal ----------------> */}
      <Modal
        show={openUpdateImageModal}
        onHide={handleCloseUpdateImageModal}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "18px" }}>Update Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CContainer>
            <CForm>
              <CInputGroup className="flex-column mb-3">
                <CFormLabel className="fw-semibold" htmlFor="asset_image.front">
                  {nameSideImg} <span className="text-danger">*</span>
                </CFormLabel>
                <CFormInput
                  className="w-100"
                  type="file"
                  id="asset_image.front"
                  name="asset_image.front"
                  accept="image/*"
                  value={formData?.asset_image?.front}
                  onChange={(e) => handleImageChange(e, imageIndex)}
                />
              </CInputGroup>
              <div className="d-flex">
                <div className="me-3">
                  <CButton
                    color="primary"
                    onClick={updateImage}
                    isLoading={isSaveUploadImageBtn}
                  >
                    Save
                  </CButton>
                </div>
                <CButton
                  type="submit"
                  color="danger"
                  onClick={handleCloseUpdateImageModal}
                >
                  Close
                </CButton>
              </div>
            </CForm>
          </CContainer>
        </Modal.Body>
        {/* <Modal.Footer> */}

        {/* </Modal.Footer> */}
      </Modal>

      {/* <---------------- Assets Edit Modal -------------------> */}
      <Modal
        show={openEditModal}
        onHide={handleCloseEditModal}
        centered
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "18px" }}>Edit Asset</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CRow>
            <CCol className="col-md-12 col-lg-12 ">
              <CContainer>
                <CForm onSubmit={editAssetSubmit}>
                  <CRow>
                    <CCol md="4">
                      <CInputGroup className="flex-column">
                        <CFormLabel className="fw-semibold" htmlFor="name">
                          Name <span className="text-danger">*</span>
                        </CFormLabel>
                        <CFormInput
                          className="w-100"
                          id="name"
                          name="name"
                          value={editedData?.name}
                          onChange={handleEditChange}
                          required
                        />
                        {editModalErrors.name && (
                          <small className="text-danger">{editModalErrors.name}</small>
                        )}
                      </CInputGroup>
                    </CCol>
                    <CCol md="4">
                      <CInputGroup className="flex-column">
                        <CFormLabel className="fw-semibold" htmlFor="model">
                          Model <span className="text-danger">*</span>
                        </CFormLabel>
                        <CFormInput
                          className="w-100"
                          id="model"
                          name="model"
                          value={editedData?.model}
                          onChange={handleEditChange}
                          required
                        />
                        {editModalErrors.model && (
                          <small className="text-danger">{editModalErrors.model}</small>
                        )}
                      </CInputGroup>
                    </CCol>
                    <CCol md="4">
                      <CInputGroup className="flex-column">
                        <CFormLabel className="fw-semibold" htmlFor="make">
                          Brand Name <span className="text-danger">*</span>
                        </CFormLabel>
                        <CFormInput
                          className="w-100"
                          id="make"
                          name="make"
                          value={editedData?.make}
                          onChange={handleEditChange}
                          required
                        />
                        {editModalErrors.make && (
                          <small className="text-danger">{editModalErrors.make}</small>
                        )}
                      </CInputGroup>
                    </CCol>
                  </CRow>
                  <CRow className="mt-2">
                    <CCol md="4">
                      <CInputGroup className="flex-column">
                        <CFormLabel
                          className="fw-semibold"
                          htmlFor="asset_type"
                        >
                          Asset Type <span className="text-danger">*</span>
                        </CFormLabel>
                        <CFormSelect
                          className="w-100"
                          id="asset_type"
                          name="asset_type"
                          value={editedData?.asset_type?._id}
                          onChange={handleEditChange}
                          required
                        >
                          <option value="" disabled>
                            Select Asset Type
                          </option>
                          {assetType.map((asset) => {
                            return (
                              <option key={asset._id} value={asset._id}>
                                {asset.title}
                              </option>
                            );
                          })}
                        </CFormSelect>
                        {editModalErrors.asset_type && (
                          <small className="text-danger">{editModalErrors.asset_type}</small>
                        )}
                      </CInputGroup>
                    </CCol>
                    <CCol md="4">
                      <CInputGroup className="flex-column">
                        <CFormLabel className="fw-semibold" htmlFor="unique_id">
                          Unique ID <span className="text-danger">*</span>
                        </CFormLabel>
                        <CFormInput
                          className="w-100"
                          id="unique_id"
                          name="unique_id"
                          value={editedData?.unique_id}
                          onChange={handleEditChange}
                          required
                        />
                        {editModalErrors.unique_id && (
                          <small className="text-danger">{editModalErrors.unique_id}</small>
                        )}
                      </CInputGroup>
                    </CCol>
                    <CCol md="4">
                      <CInputGroup className="flex-column">
                        <CFormLabel className="fw-semibold" htmlFor="status">
                          Status <span className="text-danger">*</span>
                        </CFormLabel>
                        <CFormSelect
                          className="w-100"
                          id="status"
                          name="status"
                          value={editedData?.status}
                          onChange={handleEditChange}
                          required
                        >
                          <option defaultValue="ACTIVE">ACTIVE</option>
                          <option value="INACTIVE">INACTIVE</option>
                        </CFormSelect>
                        {editModalErrors.status && (
                          <small className="text-danger">{editModalErrors.status}</small>
                        )}
                      </CInputGroup>
                    </CCol>
                  </CRow>
                  <CRow className="mt-2">
                    <CCol md="6">
                      <CInputGroup className="flex-column">
                        <CFormLabel
                          className="fw-semibold"
                          htmlFor="description"
                        >
                          Description <span className="text-danger">*</span>
                        </CFormLabel>
                        <CFormTextarea
                          className="w-100"
                          id="description"
                          name="description"
                          value={editedData?.description}
                          onChange={handleEditChange}
                          required
                        />
                        {editModalErrors.description && (
                          <small className="text-danger">{editModalErrors.description}</small>
                        )}
                      </CInputGroup>
                    </CCol>
                    <CCol md="6">
                      <CInputGroup className="flex-column">
                        <CFormLabel
                          className="fw-semibold"
                          htmlFor="purchase_vendor"
                        >
                          Purchase Vendor <span className="text-danger">*</span>
                        </CFormLabel>
                        <CFormSelect
                          className="w-100"
                          id="purchase_vendor"
                          name="purchase_vendor"
                          value={editedData?.purchase_vendor?._id}
                          onChange={handleEditChange}
                          required
                        >
                          <option value="" disabled>
                            Select Vendor
                          </option>
                          {vendors.map((vendor) => {
                            return (
                              <option key={vendor._id} value={vendor._id}>
                                {vendor.name}
                              </option>
                            );
                          })}
                        </CFormSelect>
                        {editModalErrors.purchase_vendor && (
                          <small className="text-danger">{editModalErrors.purchase_vendor}</small>
                        )}
                      </CInputGroup>
                    </CCol>
                  </CRow>
                  <CRow className="mt-2">
                    <CCol md="4">
                      <CInputGroup className="flex-column">
                        <CFormLabel
                          className="fw-semibold"
                          htmlFor="purchase_type"
                        >
                          Purchase Type <span className="text-danger">*</span>
                        </CFormLabel>
                        <CFormSelect
                          className="w-100"
                          id="purchase_type"
                          name="purchase_type"
                          value={editedData?.purchase_type}
                          onChange={handleEditChange}
                          required
                        >
                          <option defaultValue="NEW">NEW</option>
                          <option value="USED">USED</option>
                        </CFormSelect>
                        {editModalErrors.purchase_type && (
                          <small className="text-danger">{editModalErrors.purchase_type}</small>
                        )}
                      </CInputGroup>
                    </CCol>
                    <CCol md="4">
                      <CInputGroup className="flex-column">
                        <CFormLabel
                          className="fw-semibold"
                          htmlFor="purchase_date"
                        >
                          Purchase Date <span className="text-danger">*</span>
                        </CFormLabel>
                        <CFormInput
                          className="w-100"
                          type="date"
                          id="purchase_date"
                          name="purchase_date"
                          max={today}
                          value={formatDate(editedData?.purchase_date)}
                          onChange={handleEditChange}
                          required
                        />
                        {editModalErrors.purchase_date && (
                          <small className="text-danger">{editModalErrors.purchase_date}</small>
                        )}
                      </CInputGroup>
                    </CCol>
                    <CCol md="4">
                      <CInputGroup className="flex-column">
                        <CFormLabel
                          className="fw-semibold"
                          htmlFor="purchase_price"
                        >
                          Purchase Price <span className="text-danger">*</span>
                        </CFormLabel>
                        <CFormInput
                          className="w-100"
                          type="number"
                          min={0}
                          id="purchase_price"
                          name="purchase_price"
                          value={editedData?.purchase_price}
                          onChange={handleEditChange}
                          required
                          onInput={(e) => {
                            e.target.value = e.target.value
                              .replace(/[^0-9.]/g, '') // Allow only numbers and a decimal point
                              .replace(/(\..*)\./g, '$1'); // Prevent multiple decimal points
                          }}
                          pattern="^\d+(\.\d{1,2})?$"
                          title="Purchase price must be a number with up to two decimal places."
                        />
                        {editModalErrors.purchase_type && (
                          <small className="text-danger">{editModalErrors.purchase_type}</small>
                        )}
                      </CInputGroup>
                    </CCol>
                  </CRow>
                  <CRow className="mt-2">
                    <CCol md="6">
                      <CInputGroup className="flex-column">
                        <CFormLabel
                          className="fw-semibold"
                          htmlFor="warranty_period"
                        >
                          Warranty Period <span className="text-danger">*</span>
                        </CFormLabel>
                        <CFormInput
                          className="w-100"
                          id="warranty_period"
                          name="warranty_period"
                          value={editedData?.warranty_period}
                          onChange={handleEditChange}
                          required
                        />
                        {editModalErrors.warranty_period && (
                          <small className="text-danger">{editModalErrors.warranty_period}</small>
                        )}
                      </CInputGroup>
                    </CCol>
                    <CCol md="6">
                      <CInputGroup className="flex-column">
                        <CFormLabel
                          className="fw-semibold"
                          htmlFor="warranty_status"
                        >
                          Warranty Status <span className="text-danger">*</span>
                        </CFormLabel>
                        <CFormSelect
                          className="w-100"
                          id="warranty_status"
                          name="warranty_status"
                          value={editedData?.warranty_status}
                          onChange={handleEditChange}
                          required
                        >
                          <option defaultValue="IN-WARRANTY">
                            IN-WARRANTY
                          </option>
                          <option value="OUT-OF-WARRANTY">
                            OUT-OF-WARRANTY
                          </option>
                        </CFormSelect>
                        {editModalErrors.warranty_status && (
                          <small className="text-danger">{editModalErrors.warranty_status}</small>
                        )}
                      </CInputGroup>
                    </CCol>
                  </CRow>
                  <CRow className="mt-2">
                    {/* <CCol md="6">
                      <CInputGroup className='flex-column'>
                        <CFormLabel htmlFor="has_amc">Has AMC</CFormLabel>
                        <CFormInput className='w-100' type="checkbox" id="has_amc" name="has_amc" checked={formData.has_amc} onChange={handleChange} />
                      </CInputGroup>
                    </CCol> */}
                    <CCol>
                      <CInputGroup className="flex-column">
                        <CFormLabel className="fw-semibold" htmlFor="has_amc">
                          Has AMC <span className="text-danger">*</span>
                        </CFormLabel>
                        <CFormSwitch
                          className="w-100"
                          id="has_amc"
                          name="has_amc"
                          checked={editedData?.has_amc}
                          onChange={handleUpdateAmcChange}
                        />
                      </CInputGroup>
                    </CCol>
                  </CRow>

                  {showUpdateAmcFields && (
                    <>
                      <CRow className="mt-2">
                        <CCol md="4">
                          <CInputGroup className="flex-column">
                            <CFormLabel
                              className="fw-semibold"
                              htmlFor="amc_start_date"
                            >
                              AMC Start Date{" "}
                              <span className="text-danger">*</span>
                            </CFormLabel>
                            <CFormInput
                              className="w-100"
                              type="date"
                              id="amc_start_date"
                              name="amc_start_date"
                              value={formatDate(editedData?.amc_start_date)}
                              onChange={handleEditChange}
                            />
                            {editModalErrors.amc_start_date && (
                              <small className="text-danger">{editModalErrors.amc_start_date}</small>
                            )}
                          </CInputGroup>
                        </CCol>
                        <CCol md="4">
                          <CInputGroup className="flex-column">
                            <CFormLabel
                              className="fw-semibold"
                              htmlFor="amc_end_date"
                            >
                              AMC End Date{" "}
                              <span className="text-danger">*</span>
                            </CFormLabel>
                            <CFormInput
                              className="w-100"
                              type="date"
                              id="amc_end_date"
                              name="amc_end_date"
                              value={formatDate(editedData?.amc_end_date)}
                              onChange={handleEditChange}
                            />
                            {editModalErrors.amc_end_date && (
                              <small className="text-danger">{editModalErrors.amc_end_date}</small>
                            )}
                          </CInputGroup>
                        </CCol>
                        <CCol md="4">
                          <CInputGroup className="flex-column">
                            <CFormLabel
                              className="fw-semibold"
                              htmlFor="amc_amount"
                            >
                              AMC Amount <span className="text-danger">*</span>
                            </CFormLabel>
                            <CFormInput
                              className="w-100"
                              type="number"
                              min={0}
                              id="amc_amount"
                              name="amc_amount"
                              value={editedData?.amc_amount}
                              onChange={handleEditChange}
                              onInput={(e) => {
                                e.target.value = e.target.value.replace(
                                  /[^0-9]/g,
                                  ""
                                );
                              }}
                              pattern="^\d+(\.\d+){0,2}$"
                              title="AMC amount must be integer and must be in the format x, x.x, or x.x.x where x is a number"
                            />
                            {editModalErrors.amc_amount && (
                              <small className="text-danger">{editModalErrors.amc_amount}</small>
                            )}
                          </CInputGroup>
                        </CCol>
                      </CRow>
                      <CRow className="mt-2">
                        <CCol md="6">
                          <CInputGroup className="flex-column">
                            <CFormLabel
                              className="fw-semibold"
                              htmlFor="amc_taken_with"
                            >
                              AMC Taken With{" "}
                              <span className="text-danger">*</span>
                            </CFormLabel>
                            <CFormInput
                              className="w-100"
                              id="amc_taken_with"
                              name="amc_taken_with"
                              value={editedData?.amc_taken_with}
                              onChange={handleEditChange}
                            />
                            {editModalErrors.amc_taken_with && (
                              <small className="text-danger">{editModalErrors.amc_taken_with}</small>
                            )}
                          </CInputGroup>
                        </CCol>
                        <CCol md="6">
                          <CInputGroup className="flex-column">
                            <CFormLabel
                              className="fw-semibold"
                              htmlFor="amc_contact"
                            >
                              AMC Contact <span className="text-danger">*</span>
                            </CFormLabel>
                            <CFormInput
                              className="w-100"
                              id="amc_contact"
                              name="amc_contact"
                              value={editedData?.amc_contact}
                              onChange={handleEditChange}
                            />
                            {editModalErrors.amc_contact && (
                              <small className="text-danger">{editModalErrors.amc_contact}</small>
                            )}
                          </CInputGroup>
                        </CCol>
                      </CRow>
                      <CRow className="mt-2">
                        <CCol CCol md="6">
                          <CInputGroup className="flex-column">
                            <CFormLabel
                              className="fw-semibold"
                              htmlFor="amc_coverage_details"
                            >
                              AMC Coverage Details{" "}
                              <span className="text-danger">*</span>
                            </CFormLabel>
                            <CFormTextarea
                              className="w-100"
                              id="amc_coverage_details"
                              name="amc_coverage_details"
                              value={editedData?.amc_coverage_details}
                              onChange={handleEditChange}
                            />
                            {editModalErrors.amc_coverage_details && (
                              <small className="text-danger">{editModalErrors.amc_coverage_details}</small>
                            )}
                          </CInputGroup>
                        </CCol>
                        <CCol md="6">
                          <CInputGroup className="flex-column">
                            <CFormLabel
                              className="fw-semibold"
                              htmlFor="additional_notes"
                            >
                              Additional Notes{" "}
                              <span className="text-danger">*</span>
                            </CFormLabel>
                            <CFormTextarea
                              className="w-100"
                              id="additional_notes"
                              name="additional_notes"
                              value={editedData?.additional_notes}
                              onChange={handleEditChange}
                            />
                            {editModalErrors.additional_notes && (
                              <small className="text-danger">{editModalErrors.additional_notes}</small>
                            )}
                          </CInputGroup>
                        </CCol>
                      </CRow>
                    </>
                  )}
                  <div className="mt-3">
                    {/* <CButton type="submit" color="primary">Update Asset</CButton> */}
                    <PrimaryButton
                      title="Update Asset"
                      isLoading={isEditBtnLoading}
                    />
                  </div>
                </CForm>
              </CContainer>
            </CCol>
          </CRow>
        </Modal.Body>
      </Modal>

      {/* <---------------- Purchase Invoice Modal --------------> */}
      <Modal show={openInovieModal} onHide={handleClosePurchaseInvoiceModal} centered>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: '18px' }}>Update Purchase Invoice File</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CContainer>
            <CForm onSubmit={handleSubmitUpdateInvoice}>
            <CInputGroup className="flex-column mb-3">
                <CFormLabel className="fw-semibold" htmlFor="asset_image.front">
                  Purchase Invoice
                </CFormLabel>
                <CFormInput
                  className="w-100"
                  type="file"
                  id="purchase_invoice_file "
                  name="purchase_invoice_file "
                  value={formData?.purchase_invoice_file}
                  onChange={handleInvoiceChange}
                />
              </CInputGroup>
              <div className="d-flex">
                <div className="me-3">
                  <PrimaryButton title="Save" isLoading={invoiceBtnLoading} />
                </div>
                <CButton
                  style={{ borderRadius: '20px' }}
                  type="submit"
                  color="danger"
                  onClick={handleClosePurchaseInvoiceModal}
                >
                  Close
                </CButton>
              </div>
            </CForm>
          </CContainer>
        </Modal.Body>
      </Modal>

      {/* <---------------- Assets Delete Modal --------------> */}
      {openDeleteModal ? (
        <DeleteModal
          isVisible={openDeleteModal}
          onClose={handleCloseDeleteModal}
          onConfirm={handleDeleteRole}
          isLoading={isDeleteBtnLoading}
          title="DELETE ASSET"
        />
      ) : null}
    </CContainer>
  );
};

export default Assets;
