import axios from "axios";
// const BASE_URL = 'https://xpanse.dev.bexcart.com/'
// const BASE_URL = 'https://xpansedev.theuplife.in/'

// <-------- Development URL --------> 
// const BASE_URL = 'https://xpanse.dev.bexcart.com/'

// <-------- Production URL -------->
const BASE_URL = 'https://api.xpansecafe.com/'

export default axios.create({
    baseURL: BASE_URL,
})