import React, { useEffect, useState } from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCardTitle,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CFormLabel,
  CFormRange,
  CFormSelect,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  COffcanvas,
  COffcanvasBody,
  CRow,
} from "@coreui/react";
import axios from "../../api/axios";
import { toast } from "react-toastify";
import { PrimaryButton } from "../../components";
import { FaMinus, FaPlus } from "react-icons/fa";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import ReactInstaStories from "react-insta-stories";
import { MdOutlineCancel } from "react-icons/md";
import { PiDotsThreeBold } from "react-icons/pi";
import { MdClose } from "react-icons/md";

const AddStory = () => {
  const accessToken = localStorage.getItem("authToken");
  const [productsList, setProductsList] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [dates, setDates] = useState({
    startDate: "",
    endDate: "",
  });
  const [storyData, setStoryData] = useState([
    {
      supportingHeadline: "",
      product: "",
      media: null,
      supportingImage: "",
      attributes: [
        {
          name: "",
          value: "",
        },
      ],
    },
  ]);
  const [stories, setStories] = useState([]);
  const [singleStoryData, setSingleStoryData] = useState({});
  const [thumbnailImage, setThumbnailImage] = useState(null);
  const [openedStory, setOpenedStory] = useState({});
  const [selectedStory, setSelectedStory] = useState(null);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [modifiedStories, setModifiedStories] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [visible, setVisible] = useState(false);
  const [storyId, setStoryId] = useState("");
  const [openEditModal, setOpenEditModal] = useState(false);
  const [editThumbnailImage, setEditThumbnailImage] = useState(null);
  const [isAddBtnLoading, setIsAddBtnLoading] = useState(false);
  const [isEditBtnloading, setIsEditBtnLoading] = useState(false);
  const [isDeleteBtnLoading, setIsDeleteBtnLoading] = useState(false);

  const addNewStory = () => {
    setStoryData((prev) => [
      ...prev,
      {
        supportingHeadline: "",
        product: "",
        media: null,
        supportingImage: "",
        attributes: [
          {
            name: "",
            value: "",
          },
        ],
      },
    ]);
  };

  const removeStory = (index) => {
    const filteredStoryData = storyData.filter((_, i) => i !== index);
    setStoryData(filteredStoryData);
  };

  const handleVideoChange = (index, event) => {
    const newStoryData = [...storyData];
    newStoryData[index].media = event.target.files[0];
    setStoryData(newStoryData);
  };

  const handleProductChange = (index, event) => {
    const newStoryData = [...storyData];
    newStoryData[index].product = event.target.value;
    setStoryData(newStoryData);
  };

  const handleImageChange = (index, event) => {
    const newStoryData = [...storyData];
    newStoryData[index].supportingImage = event.target.files[0];
    setStoryData(newStoryData);
  };

  const handleThumbnail = (event) => {
    const thumbnail = event.target.files[0];
    setThumbnailImage(thumbnail);
  };

  const handleHeadlineChange = (index, event) => {
    const newStoryData = [...storyData];
    newStoryData[index].supportingHeadline = event.target.value;
    setStoryData(newStoryData);
  };

  const handleAttributeChange = (index, attributeIndex, event) => {
    const newStoryData = [...storyData];
    newStoryData[index].attributes[attributeIndex][event.target.name] =
      event.target.value;
    setStoryData(newStoryData);
  };

  const handleAddItem = (index) => {
    setStoryData((prev) => {
      const newStoryData = [...prev];
      const currentAttributesLength = newStoryData[index].attributes.length;
      const lastSet =
        newStoryData[index].attributes[currentAttributesLength - 1];
      if (lastSet && lastSet.name === "" && lastSet.value === "") {
        return newStoryData;
      }
      newStoryData[index].attributes = [
        ...newStoryData[index].attributes,
        {
          name: "",
          value: "",
        },
      ];

      return newStoryData;
    });
  };

  const handleRemoveItem = (storyIndex, attributeIndex) => {
    setStoryData((prevData) => {
      const updatedItems = prevData.map((item, index) => {
        if (index === storyIndex) {
          const updatedAttributes = item.attributes.filter(
            (_, idx) => idx !== attributeIndex
          );

          return {
            ...item,
            attributes: updatedAttributes,
          };
        }
        return item;
      });

      return updatedItems;
    });
  };

  const handleStartDateChange = (date) => {
    if (date) {
      setStartDate(date);
    } else {
      console.error("Received null or undefined date");
    }
  };

  const handleEndDateChange = (date) => {
    if (date) {
      setEndDate(date);
    } else {
      console.error("Received null or undefined date");
    }
  };

  const getProductsList = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get("product", { headers });
      if (response?.data?.status === true) {
        setProductsList(response?.data?.data?.products);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsAddBtnLoading(true);
    try {
      const headers = {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${accessToken}`,
      };

      const formData = new FormData();

      storyData.forEach((item, index) => {
        formData.append(`story[${index}][media]`, item.media);

        if (item.supportingImage) {
          formData.append(
            `story[${index}][supportingImage]`,
            item.supportingImage
          );
        }

        if (item?.supportingHeadline) {
          formData.append(
            `story[${index}][supportingHeadline]`,
            item.supportingHeadline
          );
        }

        if (item.product) {
          formData.append(`story[${index}][product]`, item.product);
        }

        item.attributes.forEach((attr, number) => {
          if (attr.name) {
            formData.append(
              `story[${index}][attributes][${number}][name]`,
              attr.name
            );
          }
          if (attr.value) {
            formData.append(
              `story[${index}][attributes][${number}][value]`,
              attr.value
            );
          }
        });
      });

      if (dates.startDate) {
        formData.append(`startDate`, dates.startDate);
      }

      if (dates.endDate) {
        formData.append(`endDate`, dates.endDate);
      }

      if (thumbnailImage !== null) {
        formData.append("thumbnail", thumbnailImage);
      }

      formData.append(`isActive`, true);

      const response = await axios.post("stories", formData, { headers });
      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });

        e.target.reset();

        setStoryData([
          {
            supportingHeadline: "",
            product: "",
            media: null,
            supportingImage: "",
            attributes: [
              {
                name: "",
                value: "",
              },
            ],
          },
        ]);

        setThumbnailImage(null);
        setStartDate(null);
        setEndDate(null);
        getAllStories();
      }
    } catch (error) {
      if (error?.response) {
        let errorMessage = '';
        if (
          error.response?.status === 406
        ) {
          errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
        }
        else if (
          error.response?.status === 412
        ) {
          errorMessage = error?.response?.data?.data?.message;
        } else if (error.response?.status === 500) {
          errorMessage = error?.response?.data?.error
        }

        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      setIsAddBtnLoading(false);
    }
  };

  const getAllStories = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get("stories/active", { headers });

      if (response?.data?.status === true) {
        setStories(response?.data?.data?.stories);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const getSingleStoryData = async (id) => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get(`stories?_id=${id}`, { headers });

      if (response?.data?.status === true) {
        setSingleStoryData(response?.data?.data?.stories);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const handleImageClick = (story) => {
    getSingleStoryData(story?._id);
    setOpenedStory(story);
    setSelectedStory(story?.story);
    modifiedArray(story?.story);
    setOpenViewModal(true);
  };

  const modifiedArray = (story) => {
    const newArray = story.map((item) => ({
      url: item.mediaPath,
      type: item.mediaPath.endsWith("4") ? "video" : "image",
      duration: item.mediaPath.endsWith("4") ? 10000 : 6000,
    }));
    setModifiedStories(newArray);
  };

  const handleDeleteModal = async (data) => {
    setSelectedStory(data);
    setOpenDeleteModal(true);
    setVisible(false);
    setStoryId(data?._id);
  };

  const handleDeleteStory = async (e) => {
    e.preventDefault();
    setIsDeleteBtnLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      const data = {
        _id: singleStoryData?._id,
      };

      const response = await axios.delete(`stories`, { data, headers });
      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
        getAllStories();
        setOpenDeleteModal(false);
        setOpenViewModal(false);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      setIsDeleteBtnLoading(false);
    }
  };

  const handleOpenEditModal = (data) => {
    const formattedStartDate = format(data?.startDate, "yyyy-MM-dd");
    const formattedEndDate = format(data?.endDate, "yyyy-MM-dd");
    setOpenEditModal(true);
    setOpenedStory((prev) => ({
      ...prev,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    }));
    setOpenViewModal(false);
    setVisible(false);
  };

  const handleVideoFileChange = (event, storyIndex) => {
    const videoFile = event.target.files[0];

    setOpenedStory((prevStory) => ({
      ...prevStory,
      story: prevStory.story.map((story, index) =>
        index === storyIndex
          ? {
              ...story,
              mediaPath: videoFile,
            }
          : story
      ),
    }));
  };

  const handleImageFileChange = (event, storyIndex) => {
    const imageFile = event.target.files[0];

    setOpenedStory((prevStory) => ({
      ...prevStory,
      story: prevStory.story.map((story, index) =>
        index === storyIndex
          ? {
              ...story,
              supportingImage: imageFile,
            }
          : story
      ),
    }));
  };

  const handleSupportingHeadlineChange = (event, storyIndex) => {
    const newSupportingHeadline = event.target.value;

    setOpenedStory((prevStory) => ({
      ...prevStory,
      story: prevStory.story.map((story, index) =>
        index === storyIndex
          ? {
              ...story,
              supportingHeadline: newSupportingHeadline,
            }
          : story
      ),
    }));
  };

  const handleAttributeNameChange = (event, storyIndex, attributeIndex) => {
    const newName = event.target.value;

    setOpenedStory((prevStory) => ({
      ...prevStory,
      story: prevStory.story.map((story, index) =>
        index === storyIndex
          ? {
              ...story,
              attributes: story.attributes.map((attr, attrIndex) =>
                attrIndex === attributeIndex ? { ...attr, name: newName } : attr
              ),
            }
          : story
      ),
    }));
  };

  const handleAttributeValueChange = (event, storyIndex, attributeIndex) => {
    const newValue = event.target.value;

    setOpenedStory((prevStory) => ({
      ...prevStory,
      story: prevStory.story.map((story, index) =>
        index === storyIndex
          ? {
              ...story,
              attributes: story.attributes.map((attr, attrIndex) =>
                attrIndex === attributeIndex
                  ? { ...attr, value: newValue }
                  : attr
              ),
            }
          : story
      ),
    }));
  };

  const handleEditProductChange = (event, storyIndex) => {
    const newProductId = event.target.value;
    setOpenedStory((prevStory) => ({
      ...prevStory,
      story: prevStory.story.map((story, index) =>
        index === storyIndex
          ? {
              ...story,
              product: newProductId,
            }
          : story
      ),
    }));
  };

  const handleEditStartDateChange = (date) => {
    if (date) {
      const validityEndDate = new Date(date);
      const formattedDate = format(validityEndDate, "yyyy-MM-dd");
      setOpenedStory((prev) => ({
        ...prev,
        startDate: formattedDate,
      }));
    } else {
      console.error("Received null or undefined date");
    }
  };

  const handleEditEndDateChange = (date) => {
    if (date) {
      const validityEndDate = new Date(date);
      const formattedDate = format(validityEndDate, "yyyy-MM-dd");
      setOpenedStory((prev) => ({
        ...prev,
        endDate: formattedDate,
      }));
    } else {
      console.error("Received null or undefined date");
    }
  };

  const handleEditThumbnail = (event) => {
    const thumbnail = event.target.files[0];
    setEditThumbnailImage(thumbnail);
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    setIsEditBtnLoading(true);
    try {
      const headers = {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${accessToken}`,
      };

      const formData = new FormData();

      formData.append("startDate", openedStory.startDate);
      formData.append("endDate", openedStory.endDate);
      formData.append("_id", openedStory._id);

      if (editThumbnailImage !== null) {
        formData.append("thumbnail", editThumbnailImage);
      }

      for (let i = 0; i < openedStory?.story.length; i++) {
        const {
          _id,
          mediaPath,
          supportingHeadline,
          supportingImage,
          product,
          attributes,
        } = openedStory?.story[i];
        formData.append(`story[${i}][_id]`, _id);
        if (typeof mediaPath !== "string")
          formData.append(`story[${i}][media]`, mediaPath);
        if (supportingImage !== null)
          formData.append(`story[${i}][supportingImage]`, supportingImage);
        if (supportingHeadline)
          formData.append(
            `story[${i}][supportingHeadline]`,
            supportingHeadline
          );
        if (product) formData.append(`story[${i}][product]`, product._id);

        if (attributes && attributes.length > 0) {
          for (let j = 0; j < attributes.length; j++) {
            const { _id, name, value } = attributes[j];
            formData.append(`story[${i}][attributes][${j}][_id]`, _id);
            formData.append(`story[${i}][attributes][${j}][name]`, name);
            formData.append(`story[${i}][attributes][${j}][value]`, value);
          }
        }
      }

      formData.append("isActive", true);

      const response = await axios.put("stories", formData, { headers });
      if (response?.data?.status === true) {
        const successMessage = response?.data?.data?.message;
        toast.success(successMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });

        setOpenEditModal(false);
        setVisible(false);
        setOpenViewModal(false);
        getAllStories();
      }
    } catch (error) {
      if (error?.response) {
        let errorMessage = '';
        if (
          error.response?.status === 406
        ) {
          errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
        }
        else if (
          error.response?.status === 412
        ) {
          errorMessage = error?.response?.data?.data?.message;
        } else if (error.response?.status === 500) {
          errorMessage = error?.response?.data?.error
        }

        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      setIsEditBtnLoading(false);
    }
  };

  useEffect(() => {
    getProductsList();
    getAllStories();
  }, []);

  useEffect(() => {
    const formattedStartDate = startDate ? format(startDate, "yyyy-MM-dd") : "";
    const formattedEndDate = endDate ? format(endDate, "yyyy-MM-dd") : "";

    setDates((prev) => ({
      ...prev,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    }));
  }, [startDate, endDate]);

  return (
    <CContainer>
      {stories && stories.length > 0 && (
        <div
          style={{
            height: "70px",
            display: "flex",
            gap: "10px",
            alignItems: "center",
            justifyContent: "center",
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          }}
          className="mb-3"
        >
          {stories &&
            stories.length > 0 &&
            stories.map((story, index) => (
              <div
                key={index}
                style={{
                  width: "60px",
                  height: "60px",
                  border: "1px solid green",
                  borderRadius: "50%",
                  marginLeft: "10px",
                }}
                onClick={() => handleImageClick(story)}
              >
                <img
                  src={story?.thumbnailPath}
                  alt=""
                  width="100%"
                  height="100%"
                  style={{ borderRadius: "50%" }}
                />
              </div>
            ))}
        </div>
      )}

      <CCard className="w-75 m-auto">
        <CCardHeader>
          <CCardTitle className=" text-center pt-1">ADD NEW STORY</CCardTitle>
        </CCardHeader>
        <CForm onSubmit={handleSubmit}>
          <CCardBody>
            <CRow className="mb-3">
              <CCol className="col-md-3">
                <>
                  <CFormLabel className="fw-semibold">
                    From Date <span className="text-danger">*</span>
                  </CFormLabel>
                  <DatePicker
                    selected={startDate}
                    onChange={handleStartDateChange}
                    placeholderText="From Date"
                    className="form-control"
                  />
                </>
              </CCol>
              <CCol className="col-md-3">
                <>
                  <CFormLabel className="fw-semibold">
                    End Date <span className="text-danger">*</span>
                  </CFormLabel>
                  <DatePicker
                    selected={endDate}
                    onChange={handleEndDateChange}
                    placeholderText="End Date"
                    className="form-control"
                  />
                </>
              </CCol>
              <CCol>
                <>
                  <CFormLabel className="fw-semibold">
                    Select a Thumbnail (Image){" "}
                    <span className="text-danger">*</span>
                  </CFormLabel>
                  <CFormInput
                    type="file"
                    accept="image/*"
                    onChange={handleThumbnail}
                  />
                </>
              </CCol>
            </CRow>
            <div>
              {storyData?.map((item, index) => (
                <>
                  {index !== 0 && <hr />}
                  <CRow className="mb-3">
                    <CCol className={index !== 0 && "col-md-6"}>
                      <div>
                        <CFormLabel className="fw-semibold">
                          Select a Media file{" "}
                          <span className="text-danger">*</span>
                        </CFormLabel>
                        <CFormInput
                          type="file"
                          onChange={(e) => handleVideoChange(index, e)}
                        />
                      </div>
                    </CCol>
                    <CCol className={index !== 0 && "col-md-5"}>
                      <CFormLabel className="fw-semibold">Select a Product</CFormLabel>
                      <CFormSelect
                        value={item?.product}
                        onChange={(e) => handleProductChange(index, e)}
                      >
                        <option value="">Select a Product</option>
                        {productsList?.map((product) => (
                          <option key={product?._id} value={product?._id}>
                            {product?.name}
                          </option>
                        ))}
                      </CFormSelect>
                    </CCol>

                    {index !== 0 && (
                      <CCol className="col-md-1">
                        <CButton onClick={() => removeStory(index)}>
                          <FaMinus />
                        </CButton>
                      </CCol>
                    )}
                  </CRow>
                  {item?.product !== "" && (
                    <>
                      <CRow>
                        <CCol>
                          <>
                            <CFormLabel className="fw-semibold">
                              Select a Image File{" "}
                              <span className="text-danger">*</span>
                            </CFormLabel>
                            <CFormInput
                              type="file"
                              accept="image/*"
                              onChange={(e) => handleImageChange(index, e)}
                            />
                          </>
                        </CCol>
                        <CCol>
                          <>
                            <CFormLabel className="fw-semibold">
                              Supporting Headline{" "}
                              <span className="text-danger">*</span>
                            </CFormLabel>
                            <CFormInput
                              value={item?.supportingHeadline}
                              placeholder="Supporting Headline"
                              onChange={(e) => handleHeadlineChange(index, e)}
                            />
                          </>
                        </CCol>
                      </CRow>

                      <h6 className="mt-3">Attributes:</h6>
                      {item?.attributes?.map((attr, idx) => (
                        <CRow key={idx} className="mb-3">
                          <CCol className="col-md-6">
                            <CFormInput
                              name="name"
                              placeholder="Name"
                              value={attr.name}
                              onChange={(e) =>
                                handleAttributeChange(index, idx, e)
                              }
                            />
                          </CCol>
                          <CCol className="col-md-5">
                            <CFormInput
                              name="value"
                              placeholder="Value"
                              value={attr.value}
                              onChange={(e) =>
                                handleAttributeChange(index, idx, e)
                              }
                            />
                          </CCol>
                          <CCol className="col-md-1">
                            {idx === item?.attributes.length - 1 ? (
                              <CButton
                                onClick={() => handleAddItem(index, idx)}
                              >
                                <FaPlus />
                              </CButton>
                            ) : (
                              <CButton
                                onClick={() => handleRemoveItem(index, idx)}
                              >
                                <FaMinus />
                              </CButton>
                            )}
                          </CCol>
                        </CRow>
                      ))}
                    </>
                  )}
                  <div className="mb-3 text-center">
                    {index === storyData.length - 1 && (
                      <CButton
                        className="btn btn-warning"
                        style={{ borderRadius: "18px" }}
                        onClick={() => addNewStory()}
                      >
                        ADD NEW{" "}
                        <FaPlus style={{ marginTop: "-4px" }} size={15} />
                      </CButton>
                    )}
                  </div>
                </>
              ))}
            </div>
          </CCardBody>
          <CCardFooter>
            <PrimaryButton title="ADD STORY" isLoading={isAddBtnLoading} />
          </CCardFooter>
        </CForm>
      </CCard>

      {openDeleteModal ? (
        <CModal
          alignment="center"
          visible={openDeleteModal}
          onClose={() => setOpenDeleteModal(false)}
          aria-labelledby="VerticallyCenteredExample"
        >
          <CModalHeader>
            <CModalTitle id="VerticallyCenteredExample" className="fs-6">
              DELETE STORY
            </CModalTitle>
          </CModalHeader>
          <CForm onSubmit={handleDeleteStory}>
            <CModalBody>
              Are you sure you want to delete this story ?
            </CModalBody>
            <CModalFooter>
              <CButton
                color="warning"
                onClick={() => setOpenDeleteModal(false)}
                style={{
                  borderRadius: '18px'
                }}
              >
                CLOSE
              </CButton>
              <PrimaryButton title="CONFIRM" isLoading={isDeleteBtnLoading} />
            </CModalFooter>
          </CForm>
        </CModal>
      ) : null}

      {openViewModal && (
        <CModal
          alignment="center"
          visible={openViewModal}
          onClose={() => setOpenViewModal(false)}
          aria-labelledby="VerticallyCenteredExample"
        >
          <CModalBody>
            <div>
              <div
                className="d-flex flex-row-reverse"
                onClick={() => setVisible(true)}
                style={{ cursor: "pointer" }}
              >
                <PiDotsThreeBold size={25} />
              </div>
              <COffcanvas
                style={{
                  width: "200px",
                  margin: "auto",
                  background: "rgba(0, 0, 0, 0.5)",
                }}
                placement="bottom"
                visible={visible}
                onHide={() => setVisible(false)}
              >
                <COffcanvasBody>
                  <div className="text-center mb-3">
                    <CButton
                      color="warning"
                      onClick={() => handleOpenEditModal(openedStory)}
                    >
                      EDIT
                    </CButton>
                  </div>
                  <hr style={{ color: "white" }} />
                  <div className="text-center">
                    <CButton color="danger" onClick={() => handleDeleteModal()}>
                      DELETE
                    </CButton>
                  </div>
                </COffcanvasBody>
              </COffcanvas>
            </div>
            {selectedStory && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  marginBottom: "16px",
                  marginTop: "15px",
                }}
              >
                <ReactInstaStories
                  stories={selectedStory.map((item) => ({
                    url: item.mediaPath,
                    type: item.mediaPath.endsWith("4") ? "video" : "image",
                    duration: item.mediaDuration,
                    seeMore:
                      item?.product !== null
                        ? ({ close }) => (
                            <div
                              style={{
                                marginTop: "50%",
                                overflowY: "auto",
                                maxHeight: "400px",
                                scrollbarWidth: "thin",
                                scrollbarColor: "transparent transparent",
                              }}
                            >
                              <div
                                className="d-flex justify-content-end"
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={close}
                              >
                                <MdClose size={20} />{" "}
                              </div>

                              <div
                                style={{
                                  width: "95%",
                                  margin: "auto",
                                  height: "150px",
                                  borderRadius: "5px",
                                  position: "relative",
                                  overflowY: "auto",
                                  WebkitOverflowScrolling: "touch",
                                }}
                              >
                                <img
                                  src={`${item?.supportingImagePath}`}
                                  alt=""
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    borderRadius: "5px",
                                  }}
                                />
                                <div
                                  style={{
                                    position: "absolute",
                                    top: "55%",
                                    left: "50%",
                                    transform: "translate(-50%, 0%)",
                                    textAlign: "center",
                                    color: "whiteSmoke",
                                    width: "100%",
                                    paddingTop: "10px",
                                  }}
                                >
                                  <h4>{item?.supportingHeadline}</h4>
                                </div>
                              </div>

                              <div
                                style={{
                                  width: "99%",
                                  margin: "auto",
                                  borderRadius: "5px",
                                  background: "#44494d",
                                  marginBottom: "10px",
                                  minHeight: "230px",
                                }}
                              >
                                <h4
                                  className="text-center p-3"
                                  style={{ color: "whiteSmoke" }}
                                >
                                  {item?.product?.name}
                                </h4>

                                {item?.attributes?.map(
                                  (attribute, attributeIndex) => (
                                    <React.Fragment key={attributeIndex}>
                                      <CRow className="d-flex justify-content-around align-items-center">
                                        <CCol className="mb-2 mt-2 col-md-4 text-left">
                                          <h6 style={{ color: "whitesmoke" }}>
                                            {attribute.name}
                                          </h6>
                                        </CCol>
                                        <CCol className="d-flex align-items-center col-md-4">
                                          <div>
                                            <CFormRange
                                              min={0}
                                              max={100}
                                              defaultValue={attribute.value}
                                              readOnly={true}
                                              disabled
                                            />
                                          </div>
                                        </CCol>
                                      </CRow>
                                    </React.Fragment>
                                  )
                                )}

                                <div className="d-flex justify-content-center">
                                  <CButton
                                    className="w-75 m-3"
                                    style={{
                                      borderRadius: "18px",
                                      marginBottom: "10px",
                                    }}
                                  >
                                    Order Now
                                  </CButton>
                                </div>
                              </div>
                            </div>
                          )
                        : false,
                  }))}
                  defaultInterval={5000}
                  width={400}
                  height={600}
                  loop={true}
                />
              </div>
            )}
          </CModalBody>
        </CModal>
      )}

      {openEditModal && (
        <CModal
          size="lg"
          alignment="center"
          visible={openEditModal}
          onClose={() => setOpenEditModal(false)}
          aria-labelledby="VerticallyCenteredExample"
        >
          <CForm onSubmit={handleEditSubmit}>
            <CModalBody>
              <CRow className="mb-3">
                <CCol>
                  <div>
                    <CFormLabel>
                      From Date <span className="text-danger">*</span>
                    </CFormLabel>
                    <DatePicker
                      onChange={handleEditStartDateChange}
                      placeholderText="From Date"
                      className="form-control"
                      value={openedStory?.startDate}
                    />
                  </div>
                </CCol>
                <CCol>
                  <div>
                    <CFormLabel>
                      End Date <span className="text-danger">*</span>
                    </CFormLabel>
                    <DatePicker
                      onChange={handleEditEndDateChange}
                      placeholderText="From Date"
                      className="form-control"
                      value={openedStory?.endDate}
                    />
                  </div>
                </CCol>
                <CCol>
                  <CFormLabel>
                    Select a Thumbnail <span className="text-danger">*</span>
                  </CFormLabel>
                  <CFormInput type="file" onChange={handleEditThumbnail} />
                </CCol>
              </CRow>
              {openedStory.story.map((item, index) => (
                <>
                  <CRow>
                    <CCol>
                      <div key={item._id} className="mb-3">
                        {item.mediaPath !== null && (
                          <>
                            <CFormLabel>Select a Video File</CFormLabel>
                            <CFormInput
                              type="file"
                              onChange={(e) => handleVideoFileChange(e, index)}
                            />
                            <p
                              className="text-danger"
                              style={{ fontSize: "12px" }}
                            >
                              {/* {item.media} */}
                            </p>
                          </>
                        )}
                      </div>
                    </CCol>
                    <CCol>
                      {" "}
                      <div key={item._id}>
                        {item.supportingImagePath !== null && (
                          <>
                            <CFormLabel>Select a Image File</CFormLabel>
                            <CFormInput
                              type="file"
                              onChange={(e) => handleImageFileChange(e, index)}
                            />
                            <p
                              className="text-danger"
                              style={{ fontSize: "12px" }}
                            ></p>
                          </>
                        )}
                      </div>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol>
                      <div className="mt-3">
                        {item?.supportingHeadline !== null && (
                          <>
                            <CFormLabel>Supporting Headline</CFormLabel>
                            <CFormInput
                              type="text"
                              placeholder="Headline"
                              value={item?.supportingHeadline}
                              onChange={(e) =>
                                handleSupportingHeadlineChange(e, index)
                              }
                            />
                          </>
                        )}
                      </div>
                    </CCol>
                    <CCol>
                      <div className="mt-3 mb-3">
                        {item?.product !== null && (
                          <>
                            <CFormLabel>Select a Product</CFormLabel>
                            <CFormSelect
                              value={item.product ? item.product._id : ""}
                              onChange={(e) =>
                                handleEditProductChange(e, index)
                              }
                            >
                              <option value="">Select a Product</option>
                              {productsList?.map((product) => (
                                <option key={product?._id} value={product?._id}>
                                  {product?.name}
                                </option>
                              ))}
                            </CFormSelect>
                          </>
                        )}
                      </div>
                    </CCol>
                  </CRow>

                  <div>
                    {item.attributes.length > 0 && <h6>Attributes:</h6>}
                    {item.attributes.map((attribute, attrIndex) => (
                      <>
                        <CRow>
                          <CCol>
                            {" "}
                            <div key={attribute._id}>
                              <CFormLabel>Name</CFormLabel>
                              <CFormInput
                                type="text"
                                value={attribute.name}
                                onChange={(e) =>
                                  handleAttributeNameChange(e, index, attrIndex)
                                }
                              />
                            </div>
                          </CCol>
                          <CCol>
                            <div key={attribute._id}>
                              <CFormLabel>Value</CFormLabel>
                              <CFormInput
                                type="text"
                                value={attribute.value}
                                onChange={(e) =>
                                  handleAttributeValueChange(
                                    e,
                                    index,
                                    attrIndex
                                  )
                                }
                              />
                            </div>
                          </CCol>
                        </CRow>
                      </>
                    ))}
                  </div>
                  {item.attributes.length > 0 && <hr />}
                </>
              ))}
            </CModalBody>
            <CModalFooter>
              <PrimaryButton
                title="SAVE CHANGES"
                isLoading={isEditBtnloading}
              />
            </CModalFooter>
          </CForm>
        </CModal>
      )}
    </CContainer>
  );
};

export default AddStory;
