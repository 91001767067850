import React, { useEffect, useState } from "react";
import {
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCardTitle,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CFormTextarea,
  CRow,
} from "@coreui/react";
import axios from "../../api/axios";
import { toast } from "react-toastify";
import { PrimaryButton } from "../../components";
import { TagsInput } from "react-tag-input-component";

const Products = () => {
  const accessToken = localStorage.getItem("authToken");
  const [inputData, setInputData] = useState({
    sequenceNumber: "",
    category: "",
    name: "",
    description: "",
    hsn: "",
    taxPercent: "",
    makingTime: "",
    mrp: "",
    offerPrice: "",
    productTagId: "",
    productTagName: "",
    keywords: [],
    foodType: "",
    classType: "",
    calories: "",
    weight: "",
    weightUnit: "",
    isEventProduct: "",
  });
  const [categoryList, setCategoryList] = useState([]);
  const [image, setImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [productTags, setProductTags] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [isSelected, setIsSelected] = useState(false);
  const [selected, setSelected] = useState([]);

  const handleSearchText = (e) => {
    setSearchText(e.target.value)
    setInputData((prev) => ({ ...prev, productTagName: e.target.value }))
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputData((prevState) => {
      if (name === "productTagId") {
        const selectedTag = productTags.find((tag) => tag._id === value);
        return {
          ...prevState,
          [name]: value,
          productTagName: selectedTag ? selectedTag.tagName : "",
        };
      }
      return { ...prevState, [name]: value };
    });
  };

  const handleImageChange = (e) => {
    const input = document.getElementById("imageInput");

    const file = input.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        const image = new Image();
        image.src = reader.result;

        image.onload = () => {
          const width = image.width;
          const height = image.height;

          const maxWidth = 400;
          const maxHeight = 400;

          if (width === maxWidth && height === maxHeight) {
            alert("Image dimensions are valid!");
            setImage(file);
          } else {
            alert(
              "Image dimensions are not valid. Please choose an image with dimensions equal to " +
              maxWidth +
              "x" +
              maxHeight
            );
            input.value = "";
          }
        };
      };

      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const formData = new FormData();
      let _prodKeywords;
      if (selected.length > 0) {
        const _selectedodified = selected.map(val => val.trim());
        _prodKeywords = _selectedodified.join(',');
      }
      else {
        toast.error("Product keywords is required", {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
        return;
      }

      if (image) {
        formData.append("displayImage", image);
      }
      formData.append("sequenceNumber", inputData.sequenceNumber);
      formData.append("category", inputData.category);
      formData.append("name", inputData.name);
      formData.append("description", inputData.description);
      formData.append("hsn", inputData.hsn);
      formData.append("taxPercent", inputData.taxPercent);
      formData.append("makingTime", inputData.makingTime);
      formData.append("mrp", inputData.mrp);
      formData.append("offerPrice", inputData.offerPrice);
      formData.append("isEventProduct", inputData.isEventProduct);
      formData.append("keywords", _prodKeywords);
      if (inputData?.productTagId) {
        formData.append("productTag[productTagId]", inputData.productTagId);
      }
      formData.append("productTag[productTagName]", inputData.productTagName);
      formData.append("foodType", inputData.foodType);
      formData.append("classType", inputData.classType);
      formData.append("calories", inputData.calories);
      formData.append("weight", inputData.weight);
      formData.append("weightUnit", inputData.weightUnit);

      const headers = {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${accessToken}`,
      };

      const response = await axios.post("product", formData, { headers });

      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });

        e.target.reset();

        setInputData({
          sequenceNumber: "",
          category: "",
          name: "",
          description: "",
          hsn: "",
          taxPercent: "",
          makingTime: "",
          mrp: "",
          offerPrice: "",
          hasAddon: "",
          status: "",
          classType: "",
          isEventProduct: ""
        });
        setSelected([])
        const input = document.getElementById("imageInput");
        input.value = "";
        setImage("");
      }
    } catch (error) {
      if (error?.response) {
        let errorMessage = '';
        if (
          error.response?.status === 406
        ) {
          errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
        }
        else if (
          error.response?.status === 412
        ) {
          errorMessage = error?.response?.data?.data?.message;
        } else if (error.response?.status === 500) {
          errorMessage = error?.response?.data?.error
        }

        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getCategoryList = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get("masters/category/get", { headers });

      if (response?.data?.status === true) {
        setCategoryList(response?.data?.data?.categories);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const getProductTags = async (text) => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get(`/product/tags?search=${text}`, { headers });

      if (response?.data?.status === true) {
        setIsSelected(true)
        setProductTags(response?.data?.data?.productTags);
      } else {
        setProductTags([])
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const handleOptionSelect = (selectedOption, selectedName) => {
    setInputData((prev) => ({ ...prev, productTagName: selectedName, productTagId: selectedOption }));
    setProductTags([]);
  };

  useEffect(() => {
    getCategoryList();
  }, []);

  useEffect(() => {
    getProductTags(searchText);
  }, [searchText]);

  return (
    <CContainer>
      <CRow className="mb-3">
        <CCol className="m-auto col-md-12">
          <CCard style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}>
            <CCardHeader>
              <CCardTitle className="fs-6 fw-semibold pt-1">
                ADD PRODUCT
              </CCardTitle>
            </CCardHeader>
            <CForm onSubmit={handleSubmit}>
              <CCardBody>
              
              <CRow className="gy-3"> {/* Adding consistent vertical spacing */}
                  {/* Sequence Number */}
                  <CCol md={4} lg={3}>
                    <CFormLabel className="fw-semibold">Sequence Number <span className="text-danger">*</span></CFormLabel>
                    <CFormInput
                      min={0}
                      type="number"
                      step= "any" // Allows decimal values
                      placeholder="Sequence Number"
                      className="form-control no-arrows"
                      name="sequenceNumber"
                      value={inputData?.sequenceNumber}
                      onChange={handleChange}
                    />
                  </CCol>

                  {/* Select Category */}
                  <CCol md={4} lg={3}>
                    <CFormLabel className="fw-semibold">Select Category <span className="text-danger">*</span></CFormLabel>
                    <CFormSelect
                      size="sm"
                      onChange={handleChange}
                      name="category"
                      value={inputData.category}
                    >
                      <option value="">Select Category</option>
                      {categoryList.map((rm) => (
                        <option key={rm._id} value={rm._id}>{rm.name}</option>
                      ))}
                    </CFormSelect>
                  </CCol>

                  {/* Name */}
                  <CCol md={4} lg={3}>
                    <CFormLabel className="fw-semibold">Name <span className="text-danger">*</span></CFormLabel>
                    <CFormInput
                      type="text"
                      placeholder="Name"
                      name="name"
                      value={inputData.name}
                      onChange={handleChange}
                    />
                  </CCol>

                  {/* Class Type */}
                  <CCol md={6} lg={3}>
                    <CFormLabel className="fw-semibold">Class Type <span className="text-danger">*</span></CFormLabel>
                    <CFormSelect
                      size="sm"
                      onChange={handleChange}
                      name="classType"
                      value={inputData.classType}
                    >
                      <option value="">Class Type</option>
                      <option value="FOOD">FOOD</option>
                      <option value="BEVERAGE">BEVERAGE</option>
                    </CFormSelect>
                  </CCol>

                  {/* Food Type */}
                  <CCol md={6} lg={3}>
                    <CFormLabel className="fw-semibold">Food Type <span className="text-danger">*</span></CFormLabel>
                    <CFormSelect
                      size="sm"
                      onChange={handleChange}
                      name="foodType"
                      value={inputData.foodType}
                    >
                      <option value="">Food Type</option>
                      <option value="VEG">VEG</option>
                      <option value="NON-VEG">NON-VEG</option>
                      <option value="VEGAN">VEGAN</option>
                      <option value="VEG/VEGAN">VEG/VEGAN</option>
                    </CFormSelect>
                  </CCol>

                  {/* HSN */}
                  <CCol md={4} lg={3}>
                    <CFormLabel className="fw-semibold">HSN <span className="text-danger">*</span></CFormLabel>
                    <CFormInput
                      type="text"
                      placeholder="HSN"
                      name="hsn"
                      value={inputData.hsn}
                      onChange={handleChange}
                    />
                  </CCol>  

                  {/* Weight */}
                  <CCol md={4} lg={3}>
                    <CFormLabel className="fw-semibold">Weight <span className="text-danger">*</span></CFormLabel>
                    <CFormInput
                      min={0}
                      step= "any" // Allows decimal values
                      type="number"
                      placeholder="Weight"
                      className="form-control no-arrows"
                      name="weight"
                      value={inputData.weight}
                      onChange={handleChange}
                    />
                  </CCol>

                  {/* Weight Unit */}
                  <CCol md={4} lg={3}>
                    <CFormLabel className="fw-semibold">Weight Unit <span className="text-danger">*</span></CFormLabel>
                    <CFormSelect
                      size="sm"
                      onChange={handleChange}
                      name="weightUnit"
                      value={inputData.weightUnit}
                    >
                      <option value="">Weight Unit</option>
                      <option value="KG">KILOGRAMS</option>
                      <option value="GMS">GRAMS</option>
                      <option value="LITRES">LITRES</option>
                      <option value="ML">MILLI-LITRE</option>
                    </CFormSelect>
                  </CCol>

                  {/* Calories */}
                  <CCol md={4} lg={3}>
                    <CFormLabel className="fw-semibold">Calories <span className="text-danger">*</span></CFormLabel>
                    <CFormInput
                      min={0}
                      step= "any" // Allows decimal values
                      type="number"
                      placeholder="Calories"
                      className="form-control no-arrows"
                      name="calories"
                      value={inputData.calories}
                      onChange={handleChange}
                    />
                  </CCol>

                  {/* Tax Percent */}
                  <CCol md={4} lg={3}>
                    <CFormLabel className="fw-semibold">Tax Percent <span className="text-danger">*</span></CFormLabel>
                    <CFormInput
                      min={0}
                      max={100}
                      step= "any" // Allows decimal values
                      type="number"
                      placeholder="Tax Percent"
                      name="taxPercent"
                      value={inputData.taxPercent}
                      onChange={handleChange}
                    />
                  </CCol>

                  {/* Making Time */}
                  <CCol md={4} lg={3}>
                    <CFormLabel className="fw-semibold">Making Time <span className="text-danger">*</span></CFormLabel>
                    <CFormInput
                      min={0}
                      type="number"
                      step= "any" // Allows decimal values
                      placeholder="In Mins"
                      name="makingTime"
                      value={inputData.makingTime}
                      onChange={handleChange}
                    />
                  </CCol>

                  {/* MRP */}
                  <CCol md={4} lg={3}>
                    <CFormLabel className="fw-semibold">MRP <span className="text-danger">*</span></CFormLabel>
                    <CFormInput
                      min={0}
                      type="number"
                      step= "any" // Allows decimal values
                      placeholder="In Rupees"
                      name="mrp"
                      value={inputData.mrp}
                      onChange={handleChange}
                    />
                  </CCol>

                  {/* Offer Price */}
                  <CCol md={4} lg={3}>
                    <CFormLabel className="fw-semibold">Offer Price <span className="text-danger">*</span></CFormLabel>
                    <CFormInput
                      min={0}
                      type="number"
                      step= "any" // Allows decimal values
                      placeholder="In Rupees"
                      name="offerPrice"
                      value={inputData.offerPrice}
                      onChange={handleChange}
                    />
                  </CCol>

                  {/* Product Tag Name */}
                  <CCol md={4} lg={3}>
                    <CFormLabel className="fw-semibold">Product Tag Name <span className="text-danger">*</span></CFormLabel>
                    <CFormInput
                      type="text"
                      placeholder="Product Tag Name"
                      name="productTagName"
                      onChange={handleSearchText}
                      value={inputData?.productTagName}
                    />
                    {productTags && productTags.length > 0 && (
                      <div className="dropdown-menu show">
                        {productTags.map((prod) => (
                          <div style={{ marginTop: '-10px', padding: '5px', cursor: 'pointer' }} key={prod._id} onClick={() => handleOptionSelect(prod._id, prod.tagName)}>
                            {prod.tagName}
                          </div>
                        ))}
                      </div>
                    )}
                  </CCol>

                  {/* Is Product Event */}
                  <CCol md={4} lg={3}>
                    <CFormLabel className="fw-semibold">Outlet Type <span className="text-danger">*</span></CFormLabel>
                    <CFormSelect
                      size="sm"
                      onChange={handleChange}
                      name="isEventProduct"
                      value={inputData.isEventProduct}
                    >
                      <option value="" disabled>Select Outlet Type</option>
                      <option value="false">Regular Outlet</option>
                      <option value="true">Event Outlet</option>
                    </CFormSelect>
                  </CCol>

                  {/* Image Upload */}
                  <CCol md={12} lg={3}>
                    <CFormLabel className="fw-semibold">Choose File <span className="text-danger">*</span></CFormLabel>
                    <CFormInput
                      id="imageInput"
                      accept="image/*"
                      type="file"
                      onChange={handleImageChange}
                    />
                  </CCol>

                  {/* Product Keywords */}
                  <CCol md={12} lg={6}>
                    <CFormLabel className="fw-semibold">Product Keywords <span className="text-danger">*</span> <span style={{ fontSize: '13px', color: 'purple', fontWeight: 'normal' }}>(Press Enter After Adding Each Keyword).</span></CFormLabel>
                    <TagsInput
                      value={selected}
                      onChange={setSelected}
                      name="keywords"
                      placeHolder="Product Keywords"
                    />
                  </CCol>

                  {/* Description */}
                  <CCol md={12} lg={6}>
                    <CFormLabel className="fw-semibold">Description <span className="text-danger">*</span></CFormLabel>
                    <CFormTextarea
                      rows="2"
                      placeholder="Description"
                      name="description"
                      value={inputData.description}
                      onChange={handleChange}
                    />
                  </CCol>
              </CRow>

              </CCardBody>
              <CCardFooter>
                <PrimaryButton
                  // isDisable={btnDisable()}
                  title="ADD PRODUCT"
                  isLoading={isLoading}
                />
              </CCardFooter>
            </CForm>
          </CCard>
        </CCol>
      </CRow>
    </CContainer>
  );
};

export default Products;
