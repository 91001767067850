import React, { useEffect, useState } from "react";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCardTitle,
  CCol,
  CContainer,
  CFormLabel,
  CFormSelect,
  CRow,
} from "@coreui/react";
import { toast } from "react-toastify";
import axios from "../../api/axios";
import SizeUpgradeCoupon from "./SizeUpgradeCoupon";
import CategoryCoupon from "./CategoryCoupon";
import BOGOCoupon from "./BOGOCoupon";
import ProductCoupon from "./ProductCoupon";
import CartCoupon from "./CartCoupon";
import UniversalCoupon from "./UniversalCoupon";
import SpecialCategoryCoupon from "./SpecialCategoryCoupon";
import SpecialProductCoupon from "./SpecialProductCoupon";

const AddCoupon = () => {
  const accessToken = localStorage.getItem("authToken");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedCouponOption, setSelectedCouponOption] = useState("");
  const [outlets, setOutlets] = useState([]);

  const getOutlets = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get("outlet", { headers });

      if (response?.data?.status === true) {
        setOutlets(response?.data?.data?.outlets);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  useEffect(() => {
    getOutlets();
  }, []);

  return (
    <CContainer>
      <CRow>
        <CCol
          className={`${selectedCouponOption === "" ? "col-lg-6 col-md-12 mx-md-auto" : "col-lg-4 col-md-12 mx-auto mb-3"}`}
        >
          <CCard>
            <CCardHeader>
              <CCardTitle className="fs-6 pt-1">SELECT COUPON</CCardTitle>
            </CCardHeader>
            <CCardBody>
              <div className="mb-5">
                <CFormLabel className="fw-semibold">
                  Select a Coupon <span className="text-danger">*</span>
                </CFormLabel>
                <CFormSelect
                  onChange={(e) => setSelectedCouponOption(e.target.value)}
                  value={selectedCouponOption}
                >
                  <option value="">Select a Coupon</option>
                  <option value="BOGO">BOGO COUPON</option>
                  <option value="UNIVERSAL">UNIVERSAL COUPON</option>
                  <option value="CARTCOUPON">CART COUPON</option>
                  <option value="PRODUCT">PRODUCT COUPON</option>
                  <option value="CATEGORY">CATEGORY COUPON</option>
                  <option value="SIZEUPGRADE">SIZE UPGRADE COUPON</option>
                  <option value="SPECIALCATEGORY">SPECIAL CATEGORY COUPON</option>
                  <option value="SPECIALPRODUCT">SPECIAL PRODUCT COUPON</option>
                  <option value="FREECOMPLIMENTARY">FREE COMPLIMENTARY COUPON</option>
                </CFormSelect>
              </div>
            </CCardBody>
          </CCard>
        </CCol>
        <CCol>
          {selectedCouponOption === "BOGO" && (
            <BOGOCoupon selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions} setSelectedCouponOption={setSelectedCouponOption} outlets={outlets} />
          )}

          {selectedCouponOption === "CARTCOUPON" && (
            <CartCoupon selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions} setSelectedCouponOption={setSelectedCouponOption} outlets={outlets} />
          )}

          {selectedCouponOption === "PRODUCT" && (
            <ProductCoupon selectedProducts={selectedProducts} setSelectedProducts={setSelectedProducts} selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions} setSelectedCouponOption={setSelectedCouponOption} outlets={outlets} />
          )}

          {selectedCouponOption === "CATEGORY" && (
            <CategoryCoupon selectedCategories={selectedCategories} setSelectedCategories={setSelectedCategories}  selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions} setSelectedCouponOption={setSelectedCouponOption} outlets={outlets} />
          )}

          {
            selectedCouponOption === "SIZEUPGRADE" &&
            <SizeUpgradeCoupon selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions} setSelectedCouponOption={setSelectedCouponOption} outlets={outlets} />
          }
          {
            selectedCouponOption === "UNIVERSAL" &&
            <UniversalCoupon selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions} setSelectedCouponOption={setSelectedCouponOption} outlets={outlets} />
          }
          {
            selectedCouponOption === "SPECIALCATEGORY" && 
            <SpecialCategoryCoupon selectedCategories={selectedCategories} setSelectedCategories={setSelectedCategories} selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions} setSelectedCouponOption={setSelectedCouponOption} outlets={outlets} />
          }
          {
            selectedCouponOption === "SPECIALPRODUCT" &&
            <SpecialProductCoupon selectedProducts={selectedProducts} setSelectedProducts={setSelectedProducts} selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions} setSelectedCouponOption={setSelectedCouponOption} outlets={outlets} />
          }
        </CCol>
      </CRow>
    </CContainer>
  );
};

export default AddCoupon;
