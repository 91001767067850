import React, { useEffect, useState } from 'react'
import { DataTableComponent } from '../../components'
import axios from '../../api/axios';
import { toast } from 'react-toastify';
import { CButton, CCol, CFormInput, CFormLabel, CFormSelect, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle, CRow, CSpinner, CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow } from '@coreui/react';
import ReactDatePicker from "react-datepicker";

const CancellationRequests = () => {

    const accessToken = localStorage.getItem("authToken");
    const [cancelationList, setCancelationList] = useState([]);
    const [outletList, setOutletList] = useState([]);
    const [pagination, setPagination] = useState({ page: 1, rowsPerPage: 10 });
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [loading, setLoading] = useState(false);
    const [selectedOutlet, setSelectedOutlet] = useState('');
    const [selectedStatus, setSelectedStatus] = useState('');
    const [error, setError] = useState('');
    const [submitBtnLoading, setSubmitBtnLoading] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [openViewModal, setOpenViewModal] = useState(false);
    const [selectedRequest, setSelectedRequest] = useState({});

    const columns = [
        {
            name: "Sr.No",
            cell: (row, index) => <div className="fs-7">{row.serialNumber ? row.serialNumber : "--------"}</div>,
            center: true,
        },
        {
            name: "Order NO",
            width: "120px",
            selector: (row) => row.orderDetails?.order_no,
            cell: (row) => <div className="fs-7 fw-semibold">A{row.orderDetails?.order_no ? row.orderDetails?.order_no : "--------"}</div>,
        },
        // {
        //     name: "Cancellation Req ID",
        //     selector: (row) => row._id,
        //     cell: (row) => <div className="fs-7">{row._id ? row._id : "--------"}</div>,
        // },
        {
            name: "Order Created At",
            selector: (row) => row.createdAt,
            cell: (row) => <div className="fs-7">{row.createdAt ? new Date(row.createdAt).toLocaleString() : "--------"}</div>,
        },
        {
            name: "Customer Name",
            width: "200px",
            selector: (row) => row.customerDetails?.name,
            cell: (row) => <div className="fs-7"><span className='text-success fw-semibold'>{row.customerDetails?.name ? row.customerDetails?.name : "--------"}</span>
                </div>,
        },
        
        {
            name: "Refund Status",
            selector: (row) => row.refundStatus,
            cell: (row) => <div className="fs-7" style={{ color: row?.refundStatus === false ? 'red' : 'green' }}>{row.refundStatus === false ? "PENDING" : "----"}</div>,
        },
        {
            name: "Refund Response",
            selector: (row) => row.refundResponse,
            cell: (row) => <div className="fs-7">{row.refundResponse ? row.refundResponse : "--------"}</div>,
        },
        {
            name: "ACTIONS",
            center: "true",
            cell: (row) => (
                <div className='w-100' style={{ display: 'flex', alignItems:"center" }}>
                    <CButton
                    size="sm"
                    className="w-100 fw-semibold"
                    style={{
                        borderRadius: '20px',
                        fontSize: '11px',
                        backgroundColor: '#1E9CED',
                        color: 'white',
                        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                        transition: 'background-color 0.3s, transform 0.3s',
                        transform: 'scale(1.05)',
                    }}
                    onClick={() => handleViewDetails(row)}
                    >
                    View Details
                    </CButton>
                </div>
            ),
            minWidth: "120px",
          },
    ];
    
    const updatedColumns = columns.map((column) => ({
        ...column,
        name: (
          <div style={{ fontSize: "13px", fontWeight: "bold" }}>{column.name}</div>
        ),
    }));

    const getCancellationRequests = async(startDate, endDate, outlet, status, search) => {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`
            }

            let url= `order-cancellation-requests?startDate=${startDate.toISOString().split("T")[0]}&endDate=${endDate.toISOString().split("T")[0]}`

            if(outlet && outlet !== '') {
                url += `&outletId=${outlet}`
            }

            if(status && status !== '') {
                url += `&refundStatus=${status}`
            }

            if(search && search !== '') {
                url += `&search=${search}`
            }

            const response = await axios.get(url, { headers } );

            // const response = await axios.get("order-cancellation-requests?outletId=6565d4f6fb3c3c42d48bab29&refundStatus=false&startDate=2024-08-08&endDate=2024-10-17", { headers } );
            response?.data?.data?.forEach((item, index) => {
                item.serialNumber = index + 1;
            });
            console.log("response :",response);
            
            if (response?.data?.status === true) {
                toast.success("Data Fetched Successfully", {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            } else {
                toast.error(response?.data?.message, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
            setCancelationList(response?.data?.data);
            setFilteredData(response?.data?.data);

        } catch (error) {
            console.log("error :", error);
            
            if (error?.response) {
                let errorMessage = '';
                if ( error.response?.status === 406 ) {
                errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
                }
                else if ( error?.response?.status === 412 ) {
                errorMessage = error?.response?.data?.data?.message;
                } else if (error?.response?.status === 500) {
                errorMessage = error?.response?.data?.error
                }
        
                toast.error(errorMessage, {
                position: "top-right",
                autoClose: 3000,
                theme: "colored",
                });
            }
        }
    }

    const getOutlets = async () => {
        try {
          const headers = {
            Authorization: `Bearer ${accessToken}`,
          };
          const response = await axios.get("outlet", { headers });
    
          if (response?.data?.status === true) {
            setOutletList(response?.data?.data?.outlets);
          }
        } catch (error) {
            if (error?.response) {
                let errorMessage = '';
                if (
                error.response?.status === 406
                ) {
                errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
                }
                else if (
                error.response?.status === 412
                ) {
                errorMessage = error?.response?.data?.data?.message;
                } else if (error.response?.status === 500) {
                errorMessage = error?.response?.data?.error
                }
        
                toast.error(errorMessage, {
                position: "top-right",
                autoClose: 3000,
                theme: "colored",
                });
            }
        }
    };

    const handleViewDetails = async (data) => {
        setOpenViewModal(true);
        setSelectedRequest(data);
    }

    const handleCloseViewModal = () => {
        setOpenViewModal(false);
    }

    const handlePageChange = (page) => {
        setPagination({ ...pagination, page });
    };
      
    const handleRowsPerPageChange = (rowsPerPage) => {
        setPagination({ page: 1, rowsPerPage }); // Reset to the first page when changing rows per page
    };

    const getPaginatedData = () => {
        const startIndex = (pagination.page - 1) * pagination.rowsPerPage;
        const endIndex = startIndex + pagination.rowsPerPage;
        return cancelationList?.slice(startIndex, endIndex);
    };

    const handleSubmit = async(e) => {
        e.preventDefault();
        setSubmitBtnLoading(true);
        const adjustedStartDate = new Date(startDate.setHours(23, 59, 59, 999));
        const adjustedEndDate = new Date(endDate.setHours(23, 59, 59, 999)); 

        await getCancellationRequests(adjustedStartDate, adjustedEndDate, selectedOutlet, selectedStatus, searchText);
        // toast.success("Data Fetched Successfully", {
        //     position: "top-right",
        //     autoClose: 3000,
        //     theme: "colored",
        // });
        setSubmitBtnLoading(false);
    };

    useEffect(() => {
        const now = new Date();
        const firstOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
        const currentDate = new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate()
        );
    
        const start = new Date(currentDate.setHours(23, 59, 59, 999)); // start of day
        const end = new Date(currentDate.setHours(23, 59, 59, 999)); // end of day
        // Set the start date and end date
        setStartDate(start);
        setEndDate(end);

        getCancellationRequests(start, end, selectedOutlet, selectedStatus);
    }, []);

    useEffect(() => {
        if(searchText === ""){
            getCancellationRequests();
        }
      }, [searchText]);

    useEffect(() => {
        getOutlets();
    }, []);

    return (
        <div className='container'>
            <CRow className="mb-4">
                <CCol md={2}>
                    <CFormLabel className='fw-semibold'>Select Outlet</CFormLabel>
                    <CFormSelect
                        id="selectField"
                        name="selectField"
                        value={selectedOutlet}
                        onChange={(e) => setSelectedOutlet(e.target.value)}
                    >
                        <option value="">Selet Outlet</option>
                        {
                            outletList?.map((out) => 
                                <option value={out?._id} id={out?._id}>{out?.name}</option>
                            )
                        }
                    </CFormSelect>
                </CCol>
                <CCol md={2}>
                    <CFormLabel className='fw-semibold'>Select Refund Status</CFormLabel>
                    <CFormSelect
                        id="selectField"
                        name="selectField"
                        value={selectedStatus}
                        onChange={(e) => setSelectedStatus(e.target.value)}
                    >
                        <option value="">Selet Refund Status</option>
                        <option value="true">COMPLETED</option>
                        <option value="false">PENDING</option>

                    </CFormSelect>
                </CCol>
                <CCol className="col-md-3" md={2} lg={2}>
                    <CFormLabel className='fw-semibold'>Start Date</CFormLabel>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <ReactDatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            placeholderText="Select Start Date"
                            className="form-control"
                            dateFormat="dd-MM-yyyy"  // Set the date format
                        />
                    </div>
                </CCol>
                <CCol className="col-md-3" md={2} lg={2}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <CFormLabel className='fw-semibold'>End Date</CFormLabel>
                        <ReactDatePicker
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            dateFormat="dd-MM-yyyy"  // Set the date format
                            placeholderText="Select End Date"
                            className="form-control"
                        />
                        {error && <div className="text-danger" style={{ fontSize: '14px' }}>{error}</div>}
                    </div>
                </CCol>

                <CCol md="2" lg="2">
                    <CFormLabel className='fw-semibold'>Search</CFormLabel>
                    <CFormInput
                        type="text"
                        placeholder="Search By Name"
                        onChange={(e) => setSearchText(e.target.value)}
                    />
                </CCol>

                <CCol xs={12} md={2}>
                    <CFormLabel className='text-white'>.</CFormLabel>
                    <CButton color="primary" className="w-100"
                        onClick={handleSubmit}
                        disabled={submitBtnLoading}
                    >
                        {submitBtnLoading ? <CSpinner size="sm" /> : 'Submit'}
                    </CButton>
                </CCol>
            </CRow>

            <DataTableComponent 
                title="CANCELATION REQUESTS"
                columns={updatedColumns}
                data={getPaginatedData()}
                striped
                itemsPerPage={10}
                // searchText={searchText}
                // onChange={handleSearchChange}
                pagination
                totalRows={cancelationList?.length}
                paginationTotalRows={cancelationList?.length} // Total number of items
                onChangeRowsPerPage={handleRowsPerPageChange}
                onChangePage={handlePageChange}
                paginationServer={false} // Set to false for client-side pagination
            />

            <CModal
                visible={openViewModal}
                onClose={handleCloseViewModal}
                className="modal-xl"                
            >
                <CModalHeader onClose={handleCloseViewModal}>
                    <CModalTitle className="text-center w-100">ORDER CANCELLATION REQUEST DETAILS</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    {selectedRequest ? (
                        <div className="p-3">
                            <h6>CUSTOMER DETAILS -</h6>
                            <CRow>
                                <CCol xs={4}>
                                    <h6 className='text-secondary'>Customer Name :</h6>
                                    <p>{selectedRequest?.customerDetails?.name}</p>
                                </CCol>
                                <CCol xs={4}>
                                    <h6 className='text-secondary'>Email :</h6>
                                    <p>{selectedRequest?.customerDetails?.email?.includes("_deleted_")
                                        ? `${selectedRequest?.customerDetails?.email?.split("_deleted_")[0]} [Deleted]`
                                        : selectedRequest?.customerDetails?.email}
                                    </p>
                                </CCol>
                                <CCol xs={4}>
                                    <h6 className='text-secondary'>Mobile :</h6>
                                    <p>{selectedRequest?.customerDetails?.mobile?.includes("_deleted_")
                                        ? `${selectedRequest?.customerDetails?.mobile?.split("_deleted_")[0]} [Deleted]`
                                        : selectedRequest?.customerDetails?.mobile}
                                    </p>
                                </CCol>
                            </CRow>
<hr />
                            {/* <h5 className='text-secondary'>OUTLET DETAILS -</h5>
                            <CRow>
                                <CCol xs={4}>
                                    <h6>Outlet Name :</h6>
                                    <p>{selectedRequest?.orderDetails?.outlet?.name}</p>
                                </CCol>
                                <CCol xs={8}>
                                    <h6>Outlet Address :</h6>
                                    <p>{selectedRequest?.orderDetails?.outlet?.address}</p>
                                </CCol>
                            </CRow> */}

                            <h6>CANCELLATION DETAILS -</h6>
                            <CRow className="mb-2">
                                <CCol xs={4}>
                                    <h6 className='text-secondary'>Cancellation Req Id :</h6>
                                    <p>{selectedRequest?._id}</p>
                                </CCol>
                                <CCol xs={4}>
                                    <h6 className='text-secondary'>Request Created On :</h6>
                                    <p> 
                                        {new Date(selectedRequest?.createdAt).toLocaleDateString('en-US', {
                                            year: 'numeric',
                                            month: 'long',
                                            day: 'numeric',
                                        })}
                                        {" / "}
                                        {new Date(selectedRequest?.createdAt).toLocaleTimeString('en-US', {
                                            hour: 'numeric',
                                            minute: 'numeric',
                                            hour12: true,
                                        })}                   
                                    </p>
                                </CCol>
                            </CRow>
                            <hr />

                            <h6 >PAYMENT DETAILS -</h6>
                            <CRow>
                                <CCol xs={4}>
                                    <h6 className='text-secondary'>Razorpay Payment Id :</h6>
                                    <p>{selectedRequest?.paymentDetails?.gatewayResponse?.razorpayPaymentId}</p>
                                </CCol>
                                <CCol xs={4}>
                                    <h6 className='text-secondary'>Razorpay Order Id :</h6>
                                    <p>{selectedRequest?.paymentDetails?.gatewayResponse?.razorpayOrderId}</p>
                                </CCol>
                                {/* <CCol xs={4}>
                                    <h6>Payment Status :</h6>
                                    <p className='text-success fw-semibold'>{selectedRequest?.orderDetails?.paymentStatus}</p>
                                </CCol> */}
                                <CCol xs={4}>   
                                    <h6 className='text-secondary'>Razorpay Receipt Id :</h6>
                                    <p>{selectedRequest?.paymentDetails?.receiptId}</p>
                                </CCol>
                                
                                <CCol xs={4}>
                                    <h6 className='text-secondary'>Refund Status :</h6>
                                    <p className={`${selectedRequest?.refundStatus === false ? 'text-warning' : 'text-success'} `}>{selectedRequest?.refundStatus === false ? "PENDING" : "COMPLETED"}</p>
                                </CCol>
                                <CCol xs={4}>
                                    <h6 className='text-secondary'>Refund Response :</h6>
                                    <p>{selectedRequest?.refundResponse === null ? "Waiting For Response" : "DONE"}</p>
                                </CCol>
                                    <CCol xs={4}>
                                    <h6 className='text-secondary'>Status Updated On :</h6>
                                    <p> 
                                        {
                                        selectedRequest?.updatedAt !== selectedRequest?.createdAt ? (
                                            <>
                                            {new Date(selectedRequest?.updatedAt).toLocaleDateString('en-US', {
                                                year: 'numeric',
                                                month: 'long',
                                                day: 'numeric',
                                            })}
                                            {" / "}
                                            {new Date(selectedRequest?.updatedAt).toLocaleTimeString('en-US', {
                                                hour: 'numeric',
                                                minute: 'numeric',
                                                hour12: true,
                                            })}
                                            </>
                                        ) : (
                                            "Yet To Be Updated"
                                        )
                                        }                        
                                    </p>
                                </CCol>                           
                            </CRow>
                            <hr />

                            <h6 >ORDER DETAILS -</h6>
                            <CRow>
                                <CCol xs={4}>
                                    <h6 className='text-secondary'>Order No :</h6>
                                    <p >{selectedRequest?.orderDetails?.order_sequence}{selectedRequest?.orderDetails?.order_no}</p>
                                </CCol>
                                <CCol xs={4}>
                                    <h6 className='text-secondary'>Order Type :</h6>
                                    <p >{selectedRequest?.orderDetails?.ordersType}</p>
                                </CCol>
                                <CCol xs={4}>
                                    <h6 className='text-secondary'>Date & Time :</h6>
                                    <p >
                                        {new Date(selectedRequest?.orderDetails?.createdAt).toLocaleDateString('en-US', {
                                            year: 'numeric',
                                            month: 'long',
                                            day: 'numeric',
                                        })}
                                        {" / "}
                                        {new Date(selectedRequest?.orderDetails?.createdAt).toLocaleTimeString('en-US', {
                                            hour: 'numeric',
                                            minute: 'numeric',
                                            hour12: true,
                                        })} 
                                    </p>
                                </CCol>
                                <CCol xs={4}>
                                    <h6 className='text-secondary'>Coupon Applied :</h6>
                                    <p >{selectedRequest?.couponDetails ? "YES" : "NO"}</p>
                                </CCol>
                                {selectedRequest?.couponDetails ? 
                                    (<>
                                        <CCol xs={4}>
                                            <h6 className='text-secondary'>Coupon Name :</h6>
                                            <p >{selectedRequest?.couponDetails?.title}</p>
                                        </CCol>
                                        <CCol xs={4}>
                                            <h6 className='text-secondary'>Coupon Code :</h6>
                                            <p className='fw-semibold' style={{ color: 'purple' }}>{selectedRequest?.couponDetails?.couponCode}</p>
                                        </CCol>
                                        <CCol xs={4}>
                                            <h6 className='text-secondary'>Usage Type :</h6>
                                            <p >{selectedRequest?.couponDetails?.usageType}</p>
                                        </CCol>
                                    </>) : null
                                }
                                <CCol xs={4}>
                                    <h6 className='text-secondary'>Grand Total :</h6>
                                    <p className='text-success fw-semibold'>₹. {selectedRequest?.orderDetails?.grandTotal.toFixed(2)}</p>
                                </CCol>
                            </CRow>
                            <hr />


                            <CRow className="mb-2">
                                <h6>ORDERD ITEMS -</h6>
                                <CTable striped bordered hover responsive>
                                    <CTableHead>
                                        <CTableRow>
                                        <CTableHeaderCell className="fw-semibold">Product</CTableHeaderCell>
                                        <CTableHeaderCell className="text-center fw-semibold">Quantity</CTableHeaderCell>
                                        <CTableHeaderCell className="text-center fw-semibold">Price</CTableHeaderCell>
                                        {/* <CTableHeaderCell>Add-ons</CTableHeaderCell> */}
                                        </CTableRow>
                                    </CTableHead>
                                    <CTableBody>
                                        {selectedRequest?.orderItems?.map((item) => (
                                            <CTableRow key={item?._id}>
                                                <CTableDataCell>{item?.product?.name}
                                                    {item?.addons?.length > 0 ? (
                                                    <ul style={{ fontSize: '14px' }}>
                                                        {item?.addons?.map((addon) => (
                                                        <li key={addon?._id}>
                                                            {addon?.selectedValue} (₹{addon?.offerPrice})
                                                        </li>
                                                        ))}
                                                    </ul>
                                                    ) : (
                                                    ""
                                                    )}
                                                </CTableDataCell>
                                                <CTableDataCell className="text-center">{item?.quantity}</CTableDataCell>
                                                <CTableDataCell className="text-end">
                                                    ₹{item?.rate?.toFixed(2)}
                                                    <ul  style={{ fontSize: '14px', listStyleType: 'none' }}>
                                                        {item?.addons?.map((addon) => (
                                                        <li key={addon?._id}>
                                                            + (₹{addon?.offerPrice})
                                                        </li>
                                                        ))}
                                                    </ul>
                                                </CTableDataCell>
                                            </CTableRow>
                                        ))}
                                        {/* Other Charges */}
                                        { selectedRequest?.orderDetails?.platformFees !== 0 ? (
                                            <CTableRow>
                                            <CTableDataCell colSpan={2} className="text-end" style={{ fontSize: '14px' }}>
                                                Platform Fees:
                                            </CTableDataCell>
                                            <CTableDataCell style={{ fontSize: '14px' }} className="text-end">
                                                ₹ {Number(selectedRequest?.orderDetails?.platformFees).toFixed(2)}
                                            </CTableDataCell>
                                            </CTableRow>
                                        ) : null }
                                        
                                        { selectedRequest?.orderDetails?.packingCharges !== 0 && null ? (
                                            <CTableRow>
                                            <CTableDataCell colSpan={2} className="text-end" style={{ fontSize: '14px' }}>Packing Fees:</CTableDataCell>
                                            <CTableDataCell style={{ fontSize: '14px' }} className="text-end">
                                                ₹ {selectedRequest?.orderDetails?.packingCharges?.toFixed(2)}
                                            </CTableDataCell>
                                            </CTableRow>
                                        ) : null }

                                        { selectedRequest?.orderDetails?.deliveryCharges !== 0 && null ? (
                                            <CTableRow>
                                            <CTableDataCell colSpan={2} className="text-end" style={{ fontSize: '14px' }}>Delivery Charges:</CTableDataCell>
                                            <CTableDataCell style={{ fontSize: '14px' }} className="text-end">
                                                ₹ {selectedRequest?.orderDetails?.deliveryCharges?.toFixed(2)}
                                            </CTableDataCell>
                                            </CTableRow>
                                        ) : null }

                                        <CTableRow>
                                            <CTableDataCell colSpan={2} className="text-end" style={{ fontSize: '14px' }}>Total Tax Amount:</CTableDataCell>
                                            <CTableDataCell style={{ fontSize: '14px' }} className="text-end">
                                            ₹ {selectedRequest?.orderDetails?.totalTaxAmount?.toFixed(2)}
                                            </CTableDataCell>
                                        </CTableRow>

                                        <CTableRow>
                                            <CTableDataCell colSpan={2} className="text-end" style={{ fontSize: '14px' }}>Total Discount:</CTableDataCell>
                                            <CTableDataCell style={{ fontSize: '14px' }} className="text-end">
                                            ₹ {selectedRequest?.orderDetails?.totalDiscount?.toFixed(2)}
                                            </CTableDataCell>
                                        </CTableRow>

                                        {/* Calculate grand total */}
                                        <CTableRow>
                                            <CTableDataCell colSpan={2} className="text-end"><strong>Grand Total:</strong></CTableDataCell>
                                            <CTableDataCell className="text-end text-success fw-semibold">
                                                ₹. {selectedRequest?.orderDetails?.grandTotal.toFixed(2)}
                                            </CTableDataCell>
                                        </CTableRow>
                                    </CTableBody>
                                </CTable>
                            </CRow>
                        </div>
                        ) : (
                        <p>No details available.</p>
                        )
                    }
                </CModalBody>
                <CModalFooter>
                    <CButton color="secondary" onClick={handleCloseViewModal}>
                    Close
                    </CButton>
                </CModalFooter>
            </CModal>
        </div>
    )
}

export default CancellationRequests