import React from "react";
import { FaRegUser } from "react-icons/fa";
import {
  CButton,
  CDropdown,
  CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from "@coreui/react";
import { useNavigate } from "react-router-dom";

const AppHeaderDropDown = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.clear("authToken");
    navigate("/");
  };

  return (
    <CDropdown variant="nav-item">
      <CDropdownToggle placement="bottom-end" className="py-0" caret={false}>
        <FaRegUser size={25} />
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        <CDropdownHeader className="bg-light fw-semibold py-2">
          Account
        </CDropdownHeader>
        <CDropdownItem>
          {/* <CButton
            style={{
              width: "100%",
              backgroundColor: 'white',
              color: "red",
              border: "none",
              cursor: "pointer",
              textDecoration: "underline",
              outline: "none", 
            }}
            onClick={handleLogout}
          >
            Log Out
          </CButton> */}

          <a href="#" onClick={handleLogout} style={{ color: 'red'}}>LogOut</a>

        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  );
};

export default AppHeaderDropDown;
