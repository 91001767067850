import React from "react";
import { CNavGroup, CNavItem } from "@coreui/react";
import { FaRegDotCircle } from "react-icons/fa";
import { GrProjects } from "react-icons/gr";
import { RiAdminLine, RiOutlet2Line, RiCoupon3Line } from "react-icons/ri";
import { TbShoppingCartStar } from "react-icons/tb";
import { SiAwslambda, SiMaterialdesignicons } from "react-icons/si";
import { LiaUsersSolid } from "react-icons/lia";
import { LiaUsersCogSolid } from "react-icons/lia";
import { RiCustomerService2Line } from "react-icons/ri";
import { MdOutlineDashboardCustomize } from "react-icons/md";
import { AiOutlineControl } from "react-icons/ai";
import { CiSettings } from "react-icons/ci";
import { TbSquareRoundedLetterM } from "react-icons/tb";
import { MdOutlineDiscount } from "react-icons/md";
import { FaBorderNone } from "react-icons/fa";
import { FaUsersRays } from "react-icons/fa6";
import { TbReportAnalytics } from "react-icons/tb";
import { ImCancelCircle } from "react-icons/im";

const _nav = [
  {
    component: CNavItem,
    name: "DASHBOARD",
    to: "/dashboard",
    icon: (
      <MdOutlineDashboardCustomize
        size={20}
        style={{
          margin: "0 20px 0 7px",
        }}
        customclassname="nav-icon"
      />
    ),
  },
  {
    component: CNavGroup,
    name: "MASTERS",
    to: "/masters",
    icon: (
      <TbSquareRoundedLetterM
        size={21}
        style={{
          margin: "0 20px 0 7px"
        }}
      />
    ),
    items: [
      {
        component: CNavItem,
        name: "ADMIN ROLES",
        to: "/masters/admin-roles",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
            customclassname="nav-icon"
          />
        ),
      },
      {
        component: CNavItem,
        name: "ASSET TYPE",
        to: "/masters/asset-type",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
          />
        ),
      },
      {
        component: CNavItem,
        name: "CATEGORY MGT.",
        to: "/masters/categories",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
          />
        ),
      },
      {
        component: CNavItem,
        name: "LEVEL",
        to: "/masters/level",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
            customclassname="nav-icon"
          />
        ),
      },
      {
        component: CNavItem,
        name: "RM MAIN CATEGORY",
        to: "/masters/rm-main-category",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
          />
        ),
      },
      {
        component: CNavItem,
        name: "RM SUB CATEGORY",
        to: "/masters/rm-sub-category",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
          />
        ),
      },
      {
        component: CNavItem,
        name: "RAW MATERIALS",
        to: "/masters/rawmaterials",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
          />
        ),
      },
    ],
  },

  // {
  //   component: CNavItem,
  //   name: "ADMIN ROLES",
  //   to: "/admin-roles",
  //   icon: (
  //     <RiAdminLine
  //       size={20}
  //       style={{
  //         margin: "0 20px 0 7px",
  //       }}
  //       customclassname="nav-icon"
  //     />
  //   ),
  // },

  {
    component: CNavGroup,
    name: "ASSETS MANAGEMENT",
    to: "/raw-materials-management",
    icon: (
      <SiAwslambda
        size={18}
        style={{
          margin: "0 20px 0 7px"
        }}
      />
    ),
    items: [
      // {
      //   component: CNavItem,
      //   name: "ASSET TYPE",
      //   to: "/asset-management/asset-type",
      //   icon: (
      //     <FaRegDotCircle
      //       size={10}
      //       style={{
      //         margin: "0 5px 0 7px",
      //       }}
      //     />
      //   ),
      // },
      {
        component: CNavItem,
        name: "ASSETS",
        to: "/asset-management/assets",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
          />
        ),
      },
      {
        component: CNavItem,
        name: "STORAGE LOCATION",
        to: "/asset-management/storage-type",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
          />
        ),
      },
      {
        component: CNavItem,
        name: "AST. STOCK TRANSACTION",
        to: "/asset-management/assets-stock-transaction",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
          />
        ),
      },
      {
        component: CNavItem,
        name: "ASS TRANSACTION REPORT",
        to: "/asset-management/asset-stock-transaction-report",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
          />
        ),
      }
    ],
  },

  // {
  //   component: CNavGroup,
  //   name: "RAW MATERIALS MGT",
  //   to: "/raw-materials-management",
  //   icon: (
  //     <SiMaterialdesignicons
  //       size={18}
  //       style={{
  //         margin: "0 20px 0 7px",
  //       }}
  //     />
  //   ),
  //   items: [
  //     {
  //       component: CNavItem,
  //       name: "RM MAIN CATEGORY",
  //       to: "/raw-materials-management/rm-main-category",
  //       icon: (
  //         <FaRegDotCircle
  //           size={10}
  //           style={{
  //             margin: "0 5px 0 7px",
  //           }}
  //         />
  //       ),
  //     },
  //     {
  //       component: CNavItem,
  //       name: "RM SUB CATEGORY",
  //       to: "/raw-materials-management/rm-sub-category",
  //       icon: (
  //         <FaRegDotCircle
  //           size={10}
  //           style={{
  //             margin: "0 5px 0 7px",
  //           }}
  //         />
  //       ),
  //     },
  //     {
  //       component: CNavItem,
  //       name: "RAW MATERIALS",
  //       to: "/raw-materials-management/rawmaterials",
  //       icon: (
  //         <FaRegDotCircle
  //           size={10}
  //           style={{
  //             margin: "0 5px 0 7px",
  //           }}
  //         />
  //       ),
  //     },
  //   ],
  // },

  {
    component: CNavGroup,
    name: "PRODUCT MGT.",
    to: "/product-management",
    icon: (
      <GrProjects
        size={18}
        style={{
          margin: "0 20px 0 7px",
        }}
      />
    ),
    items: [
      // {
      //   component: CNavItem,
      //   name: "CATEGORY MGT.",
      //   to: "/product-management/categories",
      //   icon: (
      //     <FaRegDotCircle
      //       size={10}
      //       style={{
      //         margin: "0 5px 0 7px",
      //       }}
      //     />
      //   ),
      // },
      {
        component: CNavItem,
        name: "ADD PRODUCT",
        to: "/product-management/add-product",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
          />
        ),
      },
      {
        component: CNavItem,
        name: "PRODUCT CATALOG",
        to: "/product-management/products-catalog",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
          />
        ),
      },
      {
        component: CNavItem,
        name: "ADDONS MGT.",
        to: "/product-management/product-addon",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
          />
        ),
      },
      {
        component: CNavItem,
        name: "PRODUCT INGREDIENTS",
        to: "/product-management/product-ingredients",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
          />
        ),
      },
    ],
  },

  {
    component: CNavGroup,
    name: "REPORTS",
    to: "/reports",
    icon: (
      <TbReportAnalytics 
        size={20}
        style={{
          margin: "0 20px 0 7px",
        }}
        customclassname="nav-icon"
      />
    ),
    items: [
      {
        component: CNavItem,
        name: "CUSTOMER REPORTS",
        to: "/reports/customer-report",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
          />
        ),
      },
      {
        component: CNavItem,
        name: "ORDER REPORTS",
        to: "/reports/order-report",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
          />
        ),
      },
      {
        component: CNavItem,
        name: "KISOK USERS REPORT",
        to: "/reports/kiosk-user-report",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
            customclassname="nav-icon"
          />
        ),
      },
      {
        component: CNavItem,
        name: "PRODUCT SALES REPORT",
        to: "/reports/product-report",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
            customclassname="nav-icon"
          />
        ),
      },
      {
        component: CNavItem,
        name: "ADDON SALES REPORT",
        to: "/reports/addon-report",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
            customclassname="nav-icon"
          />
        ),
      },
      {
        component: CNavItem,
        name: "DAY SUMMARY REPORT",
        to: "/reports/day-summary-report",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
            customclassname="nav-icon"
          />
        ),
      },
      {
        component: CNavItem,
        name: "MONTH SUMMARY REPORT",
        to: "/reports/month-summary-report",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
            customclassname="nav-icon"
          />
        ),
      },
      {
        component: CNavItem,
        name: "USER COUPON REPORTS",
        to: "/reports/user-coupon-report",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
          />
        ),
      },
      {
        component: CNavItem,
        name: "RM REORDER LEV. REPORT",
        to: "/reports/rm-reorder-level-report",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
          />
        ),
      },
      {
        component: CNavItem,
        name: "STOCK TRANS. REPORTS",
        to: "/reports/stock-transaction-report",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
          />
        ),
      },
    ],
  },

  {
    component: CNavItem,
    name: "CUSTOMER SUPPORT MGT",
    to: "/customer-support",
    icon: (
      <RiCustomerService2Line
        size={20}
        style={{
          margin: "0 20px 0 7px",
        }}
        customclassname="nav-icon"
      />
    ),
  },

  {
    component: CNavItem,
    name: "CANCELATION REQUEST",
    to: "/cancellation-request",
    icon: (
      <ImCancelCircle
        size={18}
        style={{
          margin: "0 20px 0 7px",
        }}
        customclassname="nav-icon"
      />
    ),
  },

  {
    component: CNavItem,
    name: "VENDOR MGT.",
    to: "/vendors",
    icon: (
      <LiaUsersSolid
        size={20}
        style={{
          margin: "0 20px 0 7px",
        }}
        customclassname="nav-icon"
      />
    ),
  },

  // {
  //   component: CNavItem,
  //   name: "KISOK OUTLET USER MGT.",
  //   to: "/kisok-outlet-user",
  //   icon: (
  //     <LiaUsersCogSolid
  //       size={20}
  //       style={{
  //         margin: "0 20px 0 7px",
  //       }}
  //       customclassname="nav-icon"
  //     />
  //   ),
  // },

  {
    component: CNavGroup,
    name: "OUTLET MGT.",
    to: "/outlet-management",
    icon: (
      <RiOutlet2Line
        size={20}
        style={{
          margin: "0 20px 0 7px",
        }}
        customclassname="nav-icon"
      />
    ),

    items: [
      {
        component: CNavItem,
        name: "ADD OUTLETS",
        to: "/outlet-management/outlets",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
          />
        ),
      },
      {
        component: CNavItem,
        name: "OUTLET USER MGT.",
        to: "/outlet-management/outletusers",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
          />
        ),
      },
      {
        component: CNavItem,
        name: "KISOK USERS MGT.",
        to: "/kisok-outlet-user",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
            customclassname="nav-icon"
          />
        ),
      },
    ],
  },
  {
    component: CNavGroup,
    name: "CENTRAL INVENTORY",
    to: "/central-inventory",
    icon: (
      <TbShoppingCartStar
        size={18}
        style={{
          margin: "0 20px 0 7px",
        }}
      />
    ),
    items: [
      {
        component: CNavItem,
        name: "ADD PROCUREMENT",
        to: "/central-inventory/add-procurement",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
          />
        ),
      },
      {
        component: CNavItem,
        name: "PROCUREMENT REPORTS",
        to: "/central-inventory/procurements-list",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
          />
        ),
      },
      {
        component: CNavItem,
        name: "OUTLET STOCK TRANSFER",
        to: "/central-inventory/stock-transfer",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
          />
        ),
      },
      // {
      //   component: CNavItem,
      //   name: "CENTRAL TRANSACTIONS",
      //   to: "/central-inventory/central-transactions",
      //   icon: (
      //     <FaRegDotCircle
      //       size={10}
      //       style={{
      //         margin: "0 5px 0 7px",
      //       }}
      //     />
      //   ),
      // },
      {
        component: CNavItem,
        name: "STOCK REPORTS",
        to: "/central-inventory/rm-stock-reports",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
          />
        ),
      },
    ],
  },

  {
    component: CNavGroup,
    name: "COUPONS",
    to: "/coupons",
    icon: (
      <RiCoupon3Line
        size={18}
        style={{
          margin: "0 20px 0 7px",
        }}
        customclassname="nav-icon"
      />
    ),
    items: [
      {
        component: CNavItem,
        name: "ADD",
        to: "/coupons/add",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 8px",
            }}
          />
        ),
      },
      {
        component: CNavItem,
        name: "VIEW",
        to: "/coupons/view",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 8px",
            }}
          />
        ),
      },
    ],
  },

  {
    component: CNavItem,
    name: "USER COUPON ALLOCATION",
    to: "/user-coupon-allocation",
    icon: (
      <MdOutlineDiscount
        size={20}
        style={{
          margin: "0 20px 0 7px",
        }}
        customclassname="nav-icon"
      />
    ),
  },


  // {
  //   component: CNavGroup,
  //   name: "USER COUPON ALLOCATION",
  //   to: "/user-coupon-allocation",
  //   icon: (
  //     <MdOutlineDiscount 
  //       size={18}
  //       style={{
  //         margin: "0 20px 0 7px",
  //       }}
  //       customclassname="nav-icon"
  //     />
  //   ),
  //   items: [
  //     {
  //       component: CNavItem,
  //       name: "ADD",
  //       to: "/user-coupon-allocation/add",
  //       icon: (
  //         <FaRegDotCircle
  //           size={10}
  //           style={{
  //             margin: "0 5px 0 8px",
  //           }}
  //         />
  //       ),
  //     },
  //   ],
  // },
  
  {
    component: CNavGroup,
    name: "CONTENT MANAGEMENT",
    to: "/content-management",
    icon: (
      <TbShoppingCartStar
        size={18}
        style={{
          margin: "0 20px 0 7px",
        }}
      />
    ),
    items: [
      {
        component: CNavItem,
        name: "STORIES",
        to: "/content-management/stories",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
          />
        ),
      },
      {
        component: CNavItem,
        name: "ADD ADVERTISEMENT",
        to: "/content-management/add-advertisement",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
          />
        ),
      },
      {
        component: CNavItem,
        name: "VIEW ADVERTISEMENT",
        to: "/content-management/view-advertisement",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
          />
        ),
      },

    ],
  },
  // {
  //   component: CNavItem,
  //   name: "LEVEL",
  //   to: "/level",
  //   icon: (
  //     <MdOutlineDashboardCustomize
  //       size={20}
  //       style={{
  //         margin: "0 20px 0 7px",
  //       }}
  //       customclassname="nav-icon"
  //     />
  //   ),
  // },
  {
    component: CNavGroup,
    name: "LEVEL REWARDS",
    to: "/level-rewards",
    icon: (
      <MdOutlineDashboardCustomize
        size={18}
        style={{
          margin: "0 20px 0 7px",
        }}
      />
    ),
    items: [
      {
        component: CNavItem,
        name: "ADD",
        to: "/level-rewards/add",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
          />
        ),
      },
      {
        component: CNavItem,
        name: "VIEW",
        to: "/level-rewards/view",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
          />
        ),
      },
    ],
  },
  {
    component: CNavItem,
    name: "SETTINGS",
    to: "/settings",
    icon: (
      <CiSettings 
        size={20}
        style={{
          margin: "0 20px 0 7px",
        }}
        customclassname="nav-icon"
      />
    ),
  },
  {
    component: CNavItem,
    name: "VERSION CONTROL",
    to: "/version-control",
    icon: (
      <AiOutlineControl 
        size={20}
        style={{
          margin: "0 20px 0 7px",
        }}
        customclassname="nav-icon"
      />
    ),
  },
];

export default _nav;
