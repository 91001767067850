import React, { useEffect, useState } from "react";
import { DataTableComponent, PrimaryButton } from "../../components";
import {
  CButton,
  CCol,
  CContainer,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CForm,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CFormTextarea,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableFoot,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CTooltip,
} from "@coreui/react";
import axios from "../../api/axios";
import { toast } from "react-toastify";
import { FaCog, FaEye, FaTrash, FaPencilAlt } from "react-icons/fa";

const ProcurementCatalog = () => {
  const accessToken = localStorage.getItem("authToken");
  const [procurementsList, setProcurementsList] = useState([]);
  const [pagination, setPagination] = useState({ page: 1, rowsPerPage: 10 });
  const [openViewModal, setOpenViewModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [singleProcurementDetails, setSingleProcurementDetails] = useState({});
  const [singleProcurementItemsDetails, setSingleProcurementItemsDetails] =
    useState([]);
  const [editedData, setEditedData] = useState({});
  const [isEditBtnLoading, setIsEditBtnLoading] = useState(false);
  const [vendors, setVendors] = useState([]);
  const [openAddProcurementItemModal, setOpenAddProcurementItemModal] =
    useState(false);
  const [procurementId, setProcurementId] = useState("");
  const [isAddItemBtnLoading, setIsAddItemBtnLoading] = useState(false);
  const [addProcurementItemDetails, setAddProcurementItemDetails] = useState({
    material: "",
    quantity: "",
    rate: "",
    taxPercent: "",
    taxAmount: "",
    taxable: "",
    grandTotal: ""
  });
  const [rawMaterialList, setRawMaterialList] = useState([]);
  const [isDeleteBtnLoading, setIsDeleteBtnLoading] = useState(false);
  const [isStatusBtnLoading, setIsStatusBtnLoading] = useState(false);
  const [openUpdateStatusModal, setOpenUpdateStatusModal] = useState(false);
  const [openProcurementItemEditModal, setOpenProcurementItemEditModal] =
    useState(false);
  const [editProcurementItemDetails, setEditProcurementItemDetails] = useState(
    {}
  );
  const [isEditItemBtnLoading, setIsEditItemBtnLoading] = useState(false);
  const [openProcurementItemDeleteModal, setOpenProcurementDeleteModal] =
    useState(false);
  const [procurementItemIds, setProcurmentItemIds] = useState([]);
  const [isItemDeleteBtnLoading, setIsItemDeleteBtnLoading] = useState(false);
  const [openUpdateStatusItemModal, setOpenUpdateStatusItemModal] =
    useState(false);
  const [itemId, setItemId] = useState([]);
  const [isItemStatusBtnLoading, setIsItemStatusBtnLoading] = useState(false);
  const [invoiceFile, setInvoiceFile] = useState(null);
  const [openEditStatusWithRemarksModal, setOpenEditStatusWithRemarksModal] =
    useState(false);
  const [remark, setRemark] = useState("");
  const [isEditwithRemarkBtnLoading, setIsEditWithRemarkBtnLoading] =
    useState(false);

  const [editValidationErrors, setEditValidationErrors] = useState({
    vendor: "",
    idNumber: "",
    additionalCharges: "",
    scannedInvoice: "",
  });

  const [validateRemark, setValidateRemark] = useState("");
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const [
    editProcurementItemValidationErrors,
    setEditProcurementItemValidationErrors,
  ] = useState({
    material: "",
    quantity: "",
    rate: "",
  });

  const columns = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      cell: (row) => (
        <div className="fs-7" style={{ cursor: 'pointer' }}>
          {row.serialNumber ? row.serialNumber : "--------"}
        </div>
      ),
      width: "50px",
    },

    {
      name: "FINANCIAL YEAR",
      selector: (row) => row.financialYear,
      cell: (row) => (
        <div className="fs-7" style={{ cursor: 'pointer' }}>
          {row.financialYear ? row.financialYear : "--------"}
        </div>
      ),
      width: "160px",
    },

    {
      name: "DATE",
      selector: (row) => row.createdAt,
      cell: (row) => (
        <div className="fs-7" style={{ cursor: 'pointer' }}>
          {row.createdAt
            ? new Date(row.createdAt).toLocaleDateString()
            : "--------"}
        </div>
      ),
      width: "110px",
    },

    {
      name: "ID NUMBER",
      selector: (row) => row.idNumber,
      cell: (row) => (
        <div style={{ cursor: 'pointer' }}>
          <div className="fs-7">{row.idNumber ? row.idNumber : "--------"}</div>
        </div>
      ),
      width: "120px",
    },

    {
      name: "VENDOR NAME",
      selector: (row) => row.vendor.name,
      cell: (row) => (
        <div className="fs-7" style={{ cursor:'pointer' }}>
          {row.vendor.name ? row.vendor.name : "--------"}
        </div>
      ),
      width: "200px",
    },

    {
      name: "VENDOR CONTACT",
      selector: (row) => row.vendor.email,
      cell: (row) => (
        <div style={{ cursor: 'pointer' }}>
          <a href="#" className="fs-7" style={{ textDecoration: "none" }}>
            {row.vendor.email ? row.vendor.email : "--------"}
          </a>{" "}
          <br />
          <a href="#" className="fs-7" style={{ textDecoration: "none" }}>
            {row.vendor.phone ? row.vendor.phone : "--------"}
          </a>
        </div>
      ),
      width: "220px",
    },

    {
      name: "STATUS",
      selector: (row) => row.taxable,
      cell: (row) => (
        <div
          style={{ cursor: 'pointer' }}
          className={`fs-7 ${row.status === "PENDING" ? "text-danger" : "text-success"
            }`}
        >
          {row.status ? row.status : "--------"}
        </div>
      ),
      width: "100px",
    },

    {
      name: "ACTIONS",
      right: "true",
      cell: (row) => (
        <div className="d-flex justify-content-around align-items-center">
          {row.status === "PENDING" && (
            <a
              href="#"
              className="text-danger"
              style={{ cursor: "pointer", textDecoration: "none" }}
              onClick={() => handleDeleteModal(row)}
            >
              <FaTrash /> Delete
            </a>
          )}
          {row.status === "PENDING" && (
            <a
              href="#"
              className="text-primary ms-2"
              style={{ cursor: "pointer", textDecoration: "none" }}
              onClick={() => handleOpenUpdateStatusModal(row)}
            >
              | <FaCog /> Update |
            </a>
          )}
          <a
            href="#"
            className="text-info ms-2"
            style={{ cursor: "pointer", textDecoration: "none" }}
            onClick={() => handleOpenViewModal(row)}
          >
            <FaEye /> View
          </a>
        </div>
      ),
    },
  ];

  const updatedColumns = columns.map((column) => ({
    ...column,
    name: (
      <div style={{ fontSize: "13px", fontWeight: "bold" }}>{column.name}</div>
    ),
  }));

  const getProcurements = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get("procurement", { headers });
      if (response?.data?.status === true) {
        response?.data?.data?.procurements.forEach((item, index) => {
          item.serialNumber = index + 1;
        });

        setProcurementsList(response?.data?.data?.procurements);
        setFilteredData(response?.data?.data?.procurements);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const handleOpenViewModal = (data) => {
    getSingleProcurementDetails(data?._id);
    setOpenViewModal(true);
  };

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const getSingleProcurementDetails = async (procurementId) => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get(`procurement?_id=${procurementId}`, {
        headers,
      });

      if (response?.data?.status === true) {
        setSingleProcurementDetails(response?.data?.data?.procurements);
        setSingleProcurementItemsDetails(
          response?.data?.data?.procurementItems
        );
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const handleEditModal = async (data) => {
    setOpenViewModal(false);
    setEditedData({
      _id: data._id,
      vendor: data.vendor._id,
      idNumber: data.idNumber,
      scannedInvoice: data.scannedInvoice,
      additionalCharges: data.additionalCharges,
    });
    setOpenEditModal(true);
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setInvoiceFile(selectedFile);
    setEditValidationErrors((prevErrors) => ({
      ...prevErrors,
      scannedInvoice: "",
    }));
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    let sanitizedValue = value;

    // if (name === "additionalCharges") {
    //   // Ensure the value is a positive integer, removing any non-digit characters
    //   sanitizedValue = value.replace(/[^0-9]/g, '');
    // }

    setEditedData((prevState) => ({ ...prevState, [name]: sanitizedValue }));
    setEditValidationErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const handleIdNumberChange = (e) => {
    const { name, value } = e.target;
    // Allow only underscores (_) and alphanumeric characters
    const sanitizedValue = value.replace(/[^a-zA-Z0-9_]/g, '');
    setEditedData((prevState) => ({ ...prevState, [name]: sanitizedValue }));
    setEditValidationErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();

    if (!editedData.vendor) {
      setEditValidationErrors((prevErrors) => ({
        ...prevErrors,
        vendor: "Vendor is required.",
      }));
      return;
    }

    if (!editedData.idNumber) {
      setEditValidationErrors((prevErrors) => ({
        ...prevErrors,
        idNumber: "ID Number is required.",
      }));
      return;
    }

    if (invoiceFile === null) {
      setEditValidationErrors((prevErrors) => ({
        ...prevErrors,
        scannedInvoice: "Scanned Invoice File is required.",
      }));
      return;
    }

    if (!editedData.additionalCharges) {
      setEditValidationErrors((prevErrors) => ({
        ...prevErrors,
        additionalCharges: "Additional Charges is required.",
      }));
      return;
    }

    if (Object.values(editValidationErrors).some((error) => error !== "")) {
      return;
    }

    setIsEditBtnLoading(true);

    try {
      const formData = new FormData();
      formData.append(`procurement[_id]`, editedData._id);
      formData.append(`procurement[vendor]`, editedData.vendor);
      formData.append(`procurement[idNumber]`, editedData.idNumber);
      formData.append(
        `procurement[additionalCharges]`,
        editedData.additionalCharges
      );
      formData.append(`procurement.scannedInvoice`, invoiceFile);

      const headers = {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${accessToken}`,
      };
      const data = {
        procurement: editedData,
      };

      const response = await axios.put("procurement", data, { headers });
      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
        getProcurements();
        getSingleProcurementDetails(editedData._id);
        setOpenEditModal(false);
        setOpenViewModal(true);
        setInvoiceFile(null);
      }
    } catch (error) {
      if (error?.response) {
        let errorMessage = '';
        if (
          error.response?.status === 406
        ) {
          errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
        }
        else if (
          error.response?.status === 412
        ) {
          errorMessage = error?.response?.data?.data?.message;
        } else if (error.response?.status === 500) {
          errorMessage = error?.response?.data?.error
        }

        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      setIsEditBtnLoading(false);
    }
  };

  const handleOpenAddProcurementItemModal = (data) => {
    setOpenViewModal(false);
    setOpenAddProcurementItemModal(true);
    setProcurementId(data._id);
  };

  const handleAddProcurementItemChanges = (e) => {
    const { name, value } = e.target;

    if (name === "material") {
      getSingleRawMaterialDetail(value)
    }

    setAddProcurementItemDetails((prevState) => {
      const updatedState = { ...prevState, [name]: value };
      const { taxAmount, taxable, grandTotal } = calculateTaxAmount(
        updatedState.rate,
        updatedState.quantity,
        updatedState.taxPercent
      );
      return {
        ...updatedState,
        taxAmount,
        taxable,
        grandTotal
      };
    });
  };

  const addProcurementItem = async (e) => {
    e.preventDefault();
    setIsAddItemBtnLoading(true);
    try {
      const formData = new FormData();
      formData.append(`procurement[_id]`, procurementId);
      formData.append(
        `procurementItem[material]`,
        addProcurementItemDetails.material
      );
      formData.append(
        `procurementItem[quantity]`,
        addProcurementItemDetails.quantity
      );
      formData.append(`procurementItem[rate]`, addProcurementItemDetails.rate);

      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      const response = await axios.put("procurement", formData, { headers });

      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
        setAddProcurementItemDetails({
          material: "",
          quantity: "",
          rate: "",
        });
        getProcurements();
        getSingleProcurementDetails(procurementId);
        setOpenAddProcurementItemModal(false);
        setOpenViewModal(true);
        setProcurementId("");
      }
    } catch (error) {
      if (error?.response) {
        let errorMessage = '';
        if (
          error.response?.status === 406
        ) {
          errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
        }
        else if (
          error.response?.status === 412
        ) {
          errorMessage = error?.response?.data?.data?.message;
        } else if (error.response?.status === 500) {
          errorMessage = error?.response?.data?.error
        }

        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      setIsAddItemBtnLoading(false);
    }
  };

  const handleDeleteModal = async (data) => {
    setOpenDeleteModal(true);
    setProcurementId(data?._id);
  };

  const handleDeleteProcurement = async (e) => {
    e.preventDefault();
    setIsDeleteBtnLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      const data = {
        procurementId,
      };

      const response = await axios.delete(`procurement`, { data, headers });
      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
        setProcurementId("");
        getProcurements();
        setOpenDeleteModal(false);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      setIsDeleteBtnLoading(false);
    }
  };

  const handleOpenUpdateStatusModal = (data) => {
    setOpenUpdateStatusModal(true);
    setProcurementId(data._id);
  };

  const handleProcurementUpdateStatus = async (e) => {
    e.preventDefault();
    setIsStatusBtnLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      const data = {
        procurementId,
      };

      const response = await axios.put("procurement/status", data, { headers });
      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });

        setProcurementId("");
        getProcurements();
        setOpenUpdateStatusModal(false);
      }
    } catch (error) {
      if (error?.response) {
        let errorMessage = '';
        if (
          error.response?.status === 406
        ) {
          errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
        }
        else if (
          error.response?.status === 412
        ) {
          errorMessage = error?.response?.data?.data?.message;
        } else if (error.response?.status === 500) {
          errorMessage = error?.response?.data?.error
        }

        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      setIsStatusBtnLoading(false);
    }
  };

  const handleOpenProcurementItemEditModal = (data) => {
    setOpenViewModal(false);
    setOpenProcurementItemEditModal(true);
    setProcurementId(singleProcurementDetails._id);
    setEditProcurementItemDetails({
      _id: data._id,
      material: data.material._id,
      quantity: data.quantity,
      rate: data.rate,
      taxAmount: data?.taxAmount,
      taxable: data?.taxable,
      grandTotal: data?.grandTotal,
      taxPercent: data?.material?.taxPercent
    });
  };

  const handleEditProcurementItemChange = (e) => {
    const { name, value } = e.target;

    if (name === "material") {
      getSingleRawMaterialDetail(value)
    }

    setEditProcurementItemDetails((prevState) => {
      const updatedState = { ...prevState, [name]: value }
      const { taxAmount, taxable, grandTotal } = calculateTaxAmount(
        updatedState.rate,
        updatedState.quantity,
        updatedState.taxPercent
      );
      return {
        ...updatedState,
        taxAmount,
        taxable,
        grandTotal
      };

    });
    setEditProcurementItemValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleEditProcurementItemSubmit = async (e) => {
    e.preventDefault();
    if (!editProcurementItemDetails.material) {
      setEditProcurementItemValidationErrors((prevErrors) => ({
        ...prevErrors,
        material: "Material is required.",
      }));
      return;
    }
    if (!editProcurementItemDetails.quantity) {
      setEditProcurementItemValidationErrors((prevErrors) => ({
        ...prevErrors,
        quantity: "Quantity is required.",
      }));
      return;
    }
    if (!editProcurementItemDetails.rate) {
      setEditProcurementItemValidationErrors((prevErrors) => ({
        ...prevErrors,
        rate: "Rate is required.",
      }));
      return;
    }
    setIsEditItemBtnLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      const formData = new FormData();
      formData.append(`procurement[_id]`, procurementId);
      formData.append(`procurementItem[_id]`, editProcurementItemDetails._id);
      formData.append(
        `procurementItem[material]`,
        editProcurementItemDetails.material
      );
      formData.append(
        `procurementItem[quantity]`,
        editProcurementItemDetails.quantity
      );
      formData.append(`procurementItem[rate]`, editProcurementItemDetails.rate);

      const response = await axios.put("procurement", formData, { headers });
      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
        getProcurements();
        setOpenProcurementItemEditModal(false);
        setOpenViewModal(true);
        getSingleProcurementDetails(singleProcurementDetails._id);
        setProcurementId("");
      }
    } catch (error) {
      if (error?.response) {
        let errorMessage = '';
        if (
          error.response?.status === 406
        ) {
          errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
        }
        else if (
          error.response?.status === 412
        ) {
          errorMessage = error?.response?.data?.data?.message;
        } else if (error.response?.status === 500) {
          errorMessage = error?.response?.data?.error
        }

        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      setIsEditItemBtnLoading(false);
    }
  };

  const handleOpenProcurementItemDeleteModal = (data) => {
    setOpenViewModal(false);
    setOpenProcurementDeleteModal(true);
    setProcurementId(data.procurement);
    setProcurmentItemIds((prevIds) => [...prevIds, data._id]);
  };

  const handleDeleteProcurementItems = async (e) => {
    e.preventDefault();
    setIsItemDeleteBtnLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const data = {
        procurementItemIds: procurementItemIds,
      };

      const response = await axios.delete("procurement", { data, headers });
      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
        getProcurements();
        setProcurmentItemIds([]);
        setOpenProcurementDeleteModal(false);
        getSingleProcurementDetails(procurementId);
        setOpenViewModal(true);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      setIsItemDeleteBtnLoading(false);
    }
  };

  const handleOpenProcurementItemUpdateStatus = (data) => {
    setOpenViewModal(false);
    setOpenUpdateStatusItemModal(true);
    setItemId((prevIds) => [...prevIds, data._id]);
  };

  const handleProcurementItemUpdateStatus = async (e) => {
    e.preventDefault();
    setIsItemStatusBtnLoading(true);

    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      const data = {
        procurementItemIds: itemId,
      };

      const response = await axios.put("procurement/status", data, { headers });
      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });

        setProcurementId("");
        setItemId([]);
        getProcurements();
        setOpenUpdateStatusItemModal(false);
      }
    } catch (error) {
      if (error?.response) {
        let errorMessage = '';
        if (
          error.response?.status === 406
        ) {
          errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
        }
        else if (
          error.response?.status === 412
        ) {
          errorMessage = error?.response?.data?.data?.message;
        } else if (error.response?.status === 500) {
          errorMessage = error?.response?.data?.error
        }

        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      setIsItemStatusBtnLoading(false);
    }
  };

  const handleOpenEditStatusWithRemarksModal = (data) => {
    setProcurementId(data._id);
    setOpenViewModal(false);
    setOpenEditStatusWithRemarksModal(true);
  };

  const handleEditStatusWithRemarksSubmit = async (e) => {
    e.preventDefault();

    if (!remark) {
      setValidateRemark("Remark is required");
      return;
    }

    setIsEditWithRemarkBtnLoading(true);

    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      const data = {
        procurementId: procurementId,
        remark: remark,
      };

      const response = await axios.put("procurement/status", data, { headers });
      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });

        getProcurements();
        getSingleProcurementDetails(procurementId);
        setOpenEditStatusWithRemarksModal(false);
        setOpenViewModal(true);
        setProcurementId("");
      }
    } catch (error) {
      if (error?.response) {
        let errorMessage = '';
        if (
          error.response?.status === 406
        ) {
          errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
        }
        else if (
          error.response?.status === 412
        ) {
          errorMessage = error?.response?.data?.data?.message;
        } else if (error.response?.status === 500) {
          errorMessage = error?.response?.data?.error
        }

        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      setIsEditWithRemarkBtnLoading(false);
    }
  };

  const getVendors = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get("vendor", { headers });
      if (response?.data?.status === true) {
        setVendors(response?.data?.data?.vendors);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const getRawMaterials = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get("raw-material", { headers });

      if (response?.data?.status === true) {
        setRawMaterialList(response?.data?.data?.rawMaterials);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const getSingleRawMaterialDetail = async (materialId) => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get(`raw-material?_id=${materialId}`, {
        headers,
      });

      if (response?.data?.status === true) {
        const taxPercent = response?.data?.data?.rawMaterials?.taxPercent
        setAddProcurementItemDetails((prevState) => ({
          ...prevState,
          taxPercent: taxPercent,
        }));
        setEditProcurementItemDetails((prevState) => ({
          ...prevState,
          taxPercent: taxPercent,
        }))
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const calculateTaxAmount = (rate, quantity, taxPercent) => {
    const subTotal = rate * quantity;
    const taxAmount = (subTotal * taxPercent) / (100 + taxPercent);
    const taxable = subTotal - taxAmount;
    const grandTotal = taxAmount + taxable;
    return {
      taxAmount,
      taxable,
      grandTotal,
    };
  };

  const handlePageChange = (page) => {
    setPagination({ ...pagination, page });
  };
    
  const handleRowsPerPageChange = (rowsPerPage) => {
      setPagination({ page: 1, rowsPerPage }); // Reset to the first page when changing rows per page
  };
    
    // Function to get paginated data
  const getPaginatedData = () => {
      const startIndex = (pagination.page - 1) * pagination.rowsPerPage;
      const endIndex = startIndex + pagination.rowsPerPage;
      return filteredData?.slice(startIndex, endIndex);
  };

  useEffect(() => {
    getProcurements();
    getVendors();
    getRawMaterials();
  }, []);

  useEffect(() => {
    const result = procurementsList.filter((item) => {
      return (
        (item.vendor.name &&
          item.vendor.name.toLowerCase().includes(searchText.toLowerCase())) ||
        (item.vendor.email &&
          item.vendor.email.toLowerCase().includes(searchText.toLowerCase())) ||
        (item.vendor.phone &&
          item.vendor.phone.toLowerCase().includes(searchText.toLowerCase())) ||
        (item.scannedInvoice &&
          item.scannedInvoice
            .toLowerCase()
            .includes(searchText.toLowerCase())) ||
        (item.status &&
          item.status.toLowerCase().includes(searchText.toLowerCase())) ||
        (item.idNumber && item.idNumber.toString().includes(searchText)) ||
        (item.financialYear &&
          item.financialYear.toString().includes(searchText)) ||
        (item.additionalCharges &&
          item.additionalCharges.toString().includes(searchText)) ||
        (item.totalTaxAmount &&
          item.totalTaxAmount.toString().includes(searchText)) ||
        (item.taxable && item.taxable.toString().includes(searchText)) ||
        (item.grandTotal && item.grandTotal.toString().includes(searchText))
      );
    });
    setFilteredData(result);
  }, [searchText, procurementsList]);

  return (
    <CContainer>
      <DataTableComponent
        columns={updatedColumns}
        title="PROCUREMENTS LIST"
        // data={filteredData}
        data={getPaginatedData()} // Use the paginated data here
        searchText={searchText}
        onChange={handleSearchChange}
        striped
        itemsPerPage={10}
        pagination
        totalRows={filteredData?.length}
        paginationTotalRows={filteredData?.length} // Total number of items
        onChangeRowsPerPage={handleRowsPerPageChange}
        onChangePage={handlePageChange}
        paginationServer={false} // Set to false for client-side pagination
      />

      {openViewModal ? (
        <CModal
          size="lg"
          alignment="center"
          visible={openViewModal}
          onClose={() => setOpenViewModal(false)}
          aria-labelledby="VerticallyCenteredExample"
        >
          <CModalHeader style={{ backgroundColor: "#f2f2f2" }}>
            <h5 className="fw-semibold" style={{ marginLeft: "240px" }}>
              VIEW PROCUREMENT DETAILS
            </h5>
          </CModalHeader>

          <CModalBody>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th
                    colspan="6"
                    className="text-center fw-semibold"
                    style={{ borderBottom: "1.5px solid #000" }}
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <span
                        style={{
                          flex: "1",
                        }}
                      >
                        PROCUREMENT DETAILS
                      </span>
                      {singleProcurementDetails.status === "PENDING" && (
                        <CDropdown
                          variant="btn-group"
                          direction="dropend"
                          className="cursor-pointer"
                        >
                          <CDropdownToggle color="secondary" size="sm">
                            Action
                          </CDropdownToggle>
                          <CDropdownMenu>
                            <CDropdownItem
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                handleEditModal(singleProcurementDetails)
                              }
                            >
                              Edit Procurement
                            </CDropdownItem>
                            <CDropdownItem
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                handleOpenEditStatusWithRemarksModal(
                                  singleProcurementDetails
                                )
                              }
                            >
                              Update Procurement Status with Remarks
                            </CDropdownItem>
                          </CDropdownMenu>
                        </CDropdown>
                      )}
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan="3" valign="top" width="50%">
                    <table
                      className="table table-striped table-condensed"
                      style={{
                        width: "100%",
                        borderCollapse: "collapse",
                        marginTop: "10px",
                      }}
                    >
                      <thead>
                        <tr>
                          <th
                            colSpan="2"
                            className=" text-center fw-semibold"
                            style={{
                              borderBottom: "1px solid #000",
                              fontSize: "14px",
                            }}
                          >
                            PROCUREMENT DETAILS
                          </th>
                        </tr>
                      </thead>
                      <tbody style={{ fontSize: "13px" }}>
                        <tr>
                          <th
                            className="w-50 fw-semibold"
                            style={{
                              borderBottom: "1px solid #000",
                              padding: "8px",
                            }}
                          >
                            ID Number
                          </th>
                          <td
                            className="fw-semibold"
                            style={{
                              borderBottom: "1px solid #000",
                              padding: "8px",
                            }}
                          >
                            {singleProcurementDetails?.idNumber}
                          </td>
                        </tr>
                        <tr>
                          <th
                            className="fw-semibold"
                            style={{
                              borderBottom: "1px solid #000",
                              padding: "8px",
                            }}
                          >
                            Serial Number
                          </th>
                          <td
                            className="fw-semibold"
                            style={{
                              borderBottom: "1px solid #000",
                              padding: "8px",
                            }}
                          >
                            {singleProcurementDetails?.serialNo}
                          </td>
                        </tr>
                        <tr>
                          <th
                            className="fw-semibold"
                            style={{
                              borderBottom: "1px solid #000",
                              padding: "8px",
                            }}
                          >
                            Financial Year
                          </th>
                          <td
                            style={{
                              borderBottom: "1px solid #000",
                              padding: "8px",
                            }}
                          >
                            {singleProcurementDetails?.financialYear}
                          </td>
                        </tr>
                        <tr>
                          <th
                            className="fw-semibold"
                            style={{
                              borderBottom: "1px solid #000",
                              padding: "8px",
                            }}
                          >
                            Status
                          </th>
                          <td
                            style={{
                              borderBottom: "1px solid #000",
                              padding: "8px",
                            }}
                          >
                            {singleProcurementDetails?.status}
                          </td>
                          
                        </tr>
                        <tr>
                          <th
                            className="fw-semibold"
                            style={{
                              borderBottom: "1px solid #000",
                              padding: "8px",
                            }}
                          >
                            Additional Chargers
                          </th>
                          <td
                            style={{
                              borderBottom: "1px solid #000",
                              padding: "8px",
                            }}
                          >
                            ₹ {singleProcurementDetails?.additionalCharges}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>

                  <td colspan="3" valign="top">
                    <table
                      className="table table-striped table-condensed"
                      style={{
                        width: "100%",
                        borderCollapse: "collapse",
                        marginTop: "10px",
                      }}
                    >
                      <thead>
                        <tr>
                          <th
                            colspan="2"
                            className="text-center fw-semibold"
                            style={{
                              borderBottom: "1px solid #000",
                              fontSize: "14px",
                            }}
                          >
                            VENDOR DETAILS
                          </th>
                        </tr>
                      </thead>
                      <tbody style={{ fontSize: "13px" }}>
                        <tr>
                          <th
                            className="fw-semibold"
                            style={{
                              borderBottom: "1px solid #000",
                              padding: "8px",
                            }}
                          >
                            GST
                          </th>
                          <th
                            className="fw-semibold"
                            style={{
                              borderBottom: "1px solid #000",
                              padding: "8px",
                            }}
                          >
                            {" "}
                            {singleProcurementDetails?.vendor?.gstin}
                          </th>
                        </tr>
                        <tr>
                          <th
                            className="fw-semibold"
                            style={{
                              borderBottom: "1px solid #000",
                              padding: "8px",
                            }}
                          >
                            Name
                          </th>
                          <td
                            style={{
                              borderBottom: "1px solid #000",
                              padding: "8px",
                            }}
                          >
                            {singleProcurementDetails?.vendor?.name}
                          </td>
                        </tr>
                        <tr>
                          <th
                            className="fw-semibold"
                            style={{
                              borderBottom: "1px solid #000",
                              padding: "8px",
                            }}
                          >
                            Email
                          </th>
                          <td
                            style={{
                              borderBottom: "1px solid #000",
                              padding: "8px",
                            }}
                          >
                            {singleProcurementDetails?.vendor?.email}
                          </td>
                        </tr>
                        <tr>
                          <th
                            className="fw-semibold"
                            style={{
                              borderBottom: "1px solid #000",
                              padding: "8px",
                            }}
                          >
                            Phone
                          </th>
                          <td
                            style={{
                              borderBottom: "1px solid #000",
                              padding: "8px",
                            }}
                          >
                            {singleProcurementDetails?.vendor?.phone}
                          </td>
                        </tr>
                        <tr>
                          <th
                            className="fw-semibold"
                            style={{
                              borderBottom: "1px solid #000",
                              padding: "8px",
                            }}
                          >
                            Address
                          </th>
                          <td
                            style={{
                              borderBottom: "1px solid #000",
                              padding: "8px",
                            }}
                          >
                            {singleProcurementDetails?.vendor?.address}
                          </td>
                        </tr>
                        <tr>
                          <th
                            className="fw-semibold"
                            style={{
                              borderBottom: "1px solid #000",
                              padding: "8px",
                            }}
                          >
                            Pincode
                          </th>
                          <td
                            style={{
                              borderBottom: "1px solid #000",
                              padding: "8px",
                            }}
                          >
                            {singleProcurementDetails?.vendor?.pincode}
                          </td>
                        </tr>
                        <tr>
                          <th
                            className="fw-semibold"
                            style={{
                              borderBottom: "1px solid #000",
                              padding: "8px",
                            }}
                          >
                            City
                          </th>
                          <td
                            style={{
                              borderBottom: "1px solid #000",
                              padding: "8px",
                            }}
                          >
                            {singleProcurementDetails?.vendor?.city}
                            {singleProcurementDetails?.vendor?.state}
                          </td>
                        </tr>
                        <tr>
                          <th
                            className="fw-semibold"
                            style={{
                              borderBottom: "1px solid #000",
                              padding: "8px",
                            }}
                          >
                            {" "}
                            State
                          </th>
                          <td
                            style={{
                              borderBottom: "1px solid #000",
                              padding: "8px",
                            }}
                          >
                            {singleProcurementDetails?.vendor?.state}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>

            <CTable striped bordered className="mt-4">
              <CTableHead>
                <CTableRow>
                  <CTableHeaderCell
                    className="fs-6 fw-semibold pt-2 pb-2 text-center"
                    colSpan="9"
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <span style={{ flex: "1" }}>PROCUREMENT ITEMS</span>
                      {singleProcurementDetails?.status === "PENDING" && (
                        <CButton
                          color="warning"
                          style={{ borderRadius: "18px" }}
                          onClick={() =>
                            handleOpenAddProcurementItemModal(
                              singleProcurementDetails
                            )
                          }
                        >
                          Add Item
                        </CButton>
                      )}
                    </div>
                  </CTableHeaderCell>
                </CTableRow>

                <CTableRow>
                  <CTableHeaderCell
                    className="text-center fw-bold"
                    style={{ fontSize: "12px" }}
                  >
                    #
                  </CTableHeaderCell>
                  <CTableHeaderCell
                    className="text-center fw-bold"
                    style={{ fontSize: "12px" }}
                  >
                    MATERIAL NAME
                  </CTableHeaderCell>
                  <CTableHeaderCell
                    className="text-center fw-bold"
                    style={{ fontSize: "12px" }}
                  >
                    QTY
                  </CTableHeaderCell>
                  <CTableHeaderCell
                    className="text-center fw-bold"
                    style={{ fontSize: "12px" }}
                  >
                    RATE
                  </CTableHeaderCell>
                  <CTableHeaderCell
                    className="text-end fw-bold"
                    style={{ fontSize: "12px" }}
                  >
                    TAXABLE
                  </CTableHeaderCell>
                  <CTableHeaderCell
                    className="text-end fw-bold"
                    style={{ fontSize: "12px" }}
                  >
                    TAX AMOUNT
                  </CTableHeaderCell>
                  <CTableHeaderCell
                    className="text-end fw-bold"
                    style={{ width: "100px", fontSize: "12px" }}
                  >
                    GRAND TOTAL
                  </CTableHeaderCell>
                  <CTableHeaderCell
                    className="text-end fw-bold "
                    style={{ fontSize: "12px" }}
                  >
                    STATUS
                  </CTableHeaderCell>
                  <CTableHeaderCell
                    className="text-end fw-bold "
                    style={{ fontSize: "12px" }}
                  >
                    ACTIONS
                  </CTableHeaderCell>
                </CTableRow>
              </CTableHead>
              <CTableBody>
                {singleProcurementItemsDetails &&
                  singleProcurementItemsDetails?.map((item, index) => (
                    <CTableRow key={item._id}>
                      <CTableDataCell
                        className="text-center"
                        style={{ fontSize: "13px" }}
                      >
                        {index + 1}
                      </CTableDataCell>
                      <CTableDataCell
                        className="text-center"
                        style={{ fontSize: "13px" }}
                      >
                        {item?.material?.name}
                      </CTableDataCell>
                      <CTableDataCell
                        className="text-center"
                        style={{ fontSize: "13px" }}
                      >
                        {item?.quantity}
                      </CTableDataCell>
                      <CTableDataCell
                        className="text-center"
                        style={{ fontSize: "13px" }}
                      >
                        {item?.rate}
                      </CTableDataCell>
                      <CTableDataCell
                        className="text-end"
                        style={{ fontSize: "13px" }}
                      >
                        {item?.taxable.toFixed(3)}
                      </CTableDataCell>
                      <CTableDataCell
                        className="text-end"
                        style={{ fontSize: "13px" }}
                      >
                        {item?.taxAmount.toFixed(3)}
                      </CTableDataCell>
                      <CTableDataCell
                        className="text-end"
                        style={{ fontSize: "13px" }}
                      >
                        {item?.grandTotal}
                      </CTableDataCell>
                      <CTableDataCell
                        className="text-center"
                        style={{ fontSize: "13px", cursor: "pointer" }}
                      >
                        {item?.status}
                      </CTableDataCell>
                      <CTableDataCell className="text-center">
                        <CDropdown
                          variant="btn-group"
                          direction="dropend"
                          className="cursor-pointer"
                        >
                          <div style={{ display: 'inline-block', position: 'relative' }}>
                              {item.status !== "PENDING" && (
                                  <CTooltip content="No actions can be performed" placement="top">
                                      <span style={{ display: 'inline-block', cursor: 'not-allowed' }}>
                                          <CDropdownToggle color="secondary" size="sm" disabled>
                                              Action
                                          </CDropdownToggle>
                                      </span>
                                  </CTooltip>
                              )}
                              {item.status === "PENDING" && (
                                  <CDropdownToggle color="secondary" size="sm">
                                      Action
                                  </CDropdownToggle>
                              )}
                          </div>
                          <CDropdownMenu>
                            {item.status === "PENDING" ? (
                              <CDropdownItem
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  handleOpenProcurementItemEditModal(item)
                                }
                              >
                                Edit
                              </CDropdownItem>
                            ) : null}
                            {item.status === "PENDING" ? (
                              <CDropdownItem
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  handleOpenProcurementItemDeleteModal(item)
                                }
                              >
                                Delete
                              </CDropdownItem>
                            ): null}
                            {item.status === "PENDING" ? (
                              <CDropdownItem
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  handleOpenProcurementItemUpdateStatus(item)
                                }
                              >
                                Update Status
                              </CDropdownItem>
                            ) : null}
                          </CDropdownMenu>
                        </CDropdown>
                      </CTableDataCell>
                    </CTableRow>
                  ))}
              </CTableBody>
              <CTableFoot>
                <CTableRow>
                  <CTableDataCell colSpan="6" className="text-end">
                    <span style={{ fontWeight: "550", fontSize: "14px" }}>
                      <p style={{ fontSize: "13px" }}> Taxable</p>
                      <p style={{ fontSize: "13px" }}>+ Tax Amount</p>
                      <p style={{ fontSize: "10px" }}>(+ Additional Charges)</p>
                      <i
                        style={{ borderTop: "1px solid #000", padding: "8px" }}
                      >
                        = Grand Total
                      </i>
                    </span>
                  </CTableDataCell>
                  <CTableDataCell className="text-end">
                    <p style={{ fontSize: "13px", fontWeight: "600" }}>
                      {" "}
                      {singleProcurementDetails?.taxable?.toFixed(2)}{" "}
                    </p>
                    <p style={{ fontSize: "13px", fontWeight: "600" }}>
                      + {singleProcurementDetails?.totalTaxAmount?.toFixed(2)}{" "}
                    </p>
                    <p style={{ fontSize: "11px", fontWeight: "400" }}>
                      (+ {singleProcurementDetails?.additionalCharges?.toFixed(2)}){" "}
                    </p>
                    <p
                      style={{
                        borderTop: "1px solid #000",
                        fontSize: "14px",
                        fontWeight: "600",
                      }}
                    >
                      {" "}
                      { 
                        singleProcurementDetails?.additionalCharges 
                          ? (singleProcurementDetails?.grandTotal + singleProcurementDetails?.additionalCharges).toFixed(2)
                          : singleProcurementDetails?.grandTotal?.toFixed(2)
                      }
                    </p>
                  </CTableDataCell>
                </CTableRow>
              </CTableFoot>
            </CTable>
          </CModalBody>
          <CModalFooter>
            <a href={singleProcurementDetails?.scannedInvoice}>
              <CButton type="button" style={{ borderRadius: "18px" }}>
                Download Invoice
              </CButton>
            </a>

            <CButton
              style={{ borderRadius: "18px" }}
              className="btn btn-warning"
              onClick={() => setOpenViewModal(false)}
            >
              Close
            </CButton>
          </CModalFooter>
        </CModal>
      ) : null}

      {openEditModal ? (
        <CModal
          alignment="center"
          visible={openEditModal}
          onClose={() => setOpenEditModal(false)}
          aria-labelledby="VerticallyCenteredExample"
        >
          <CModalHeader>
            <CModalTitle id="VerticallyCenteredExample" className="fs-6">
              EDIT PROCUREMENT DETAILS
            </CModalTitle>
          </CModalHeader>
          <CForm onSubmit={handleEditSubmit}>
            <CModalBody>
              <CRow>
                <CCol>
                  <div>
                    <CFormLabel className="fw-semibold">
                      Select Vendor <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormSelect
                      size="sm"
                      onChange={handleEditChange}
                      value={editedData.vendor}
                      name="vendor"
                      className="mb-3"
                    >
                      <option>Select Vendor</option>
                      {vendors &&
                        vendors.map((rm) => (
                          <option key={rm._id} value={rm._id}>
                            {rm.name}
                          </option>
                        ))}
                    </CFormSelect>
                    <div className="text-danger">
                      {editValidationErrors.vendor}
                    </div>
                  </div>
                </CCol>
                <CCol className="col-md-5">
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      ID Number <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      type="text"
                      placeholder="ID Number"
                      className="form-control"
                      name="idNumber"
                      value={editedData.idNumber}
                      onChange={handleIdNumberChange}
                    />
                    <div className="text-danger">
                      {editValidationErrors.idNumber}
                    </div>
                  </div>
                </CCol>
              </CRow>

              <CRow>
                <CCol>
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Scanned Invoice <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      type="file"
                      onChange={handleFileChange}
                      className="form-control"
                      id="formFile"
                    />
                  </div>
                  <div className="text-danger">
                    {editValidationErrors.scannedInvoice}
                  </div>
                </CCol>
                <CCol className="col-md-5">
                  {" "}
                  <CFormLabel className="fw-semibold">
                    Additional Charges <span className="text-danger">*</span>
                  </CFormLabel>
                  <div className="mb-3">
                    <CFormInput
                      min={0}
                      step="any"
                      type="number"
                      placeholder="additionalCharges"
                      className="form-control no-arrows"
                      name="additionalCharges"
                      value={editedData.additionalCharges}
                      onChange={handleEditChange}
                    />
                    <div className="text-danger">
                      {editValidationErrors.additionalCharges}
                    </div>
                  </div>
                </CCol>
              </CRow>
            </CModalBody>
            <CModalFooter>
              <PrimaryButton
                title="SAVE CHANGES"
                isLoading={isEditBtnLoading}
              />
            </CModalFooter>
          </CForm>
        </CModal>
      ) : null}

      {openDeleteModal ? (
        <CModal
          alignment="center"
          visible={openDeleteModal}
          onClose={() => setOpenDeleteModal(false)}
          aria-labelledby="VerticallyCenteredExample"
        >
          <CModalHeader>
            <CModalTitle id="VerticallyCenteredExample" className="fs-6">
              DELETE PROCUREMENT
            </CModalTitle>
          </CModalHeader>
          <CForm onSubmit={handleDeleteProcurement}>
            <CModalBody>
              Are you sure you want to delete this procurement ?
            </CModalBody>
            <CModalFooter>
              <CButton
                color="warning"
                onClick={() => setOpenDeleteModal(false)}
                style={{
                  borderRadius: "18px",
                }}
              >
                Close
              </CButton>
              <PrimaryButton title="CONFIRM" isLoading={isDeleteBtnLoading} />
            </CModalFooter>
          </CForm>
        </CModal>
      ) : null}

      {openUpdateStatusModal ? (
        <CModal
          alignment="center"
          visible={openUpdateStatusModal}
          onClose={() => setOpenUpdateStatusModal(false)}
          aria-labelledby="VerticallyCenteredExample"
        >
          <CModalHeader>
            <CModalTitle id="VerticallyCenteredExample" className="fs-6">
              UPDATE STATUS OF THE PROCUREMENT
            </CModalTitle>
          </CModalHeader>
          <CForm onSubmit={handleProcurementUpdateStatus}>
            <CModalBody>
              Are you sure you want to update the status of this procurement ?
            </CModalBody>
            <CModalFooter>
              <CButton
                color="warning"
                onClick={() => setOpenUpdateStatusModal(false)}
                style={{
                  borderRadius: "18px",
                }}
              >
                Close
              </CButton>
              <PrimaryButton title="CONFIRM" isLoading={isStatusBtnLoading} />
            </CModalFooter>
          </CForm>
        </CModal>
      ) : null}

      {openAddProcurementItemModal ? (
        <CModal
          size="lg"
          alignment="center"
          visible={openAddProcurementItemModal}
          onClose={() => setOpenAddProcurementItemModal(false)}
          aria-labelledby="VerticallyCenteredExample"
        >
          <CModalHeader>
            <CModalTitle id="VerticallyCenteredExample" className="fs-6">
              ADD NEW PROCUREMENT ITEM
            </CModalTitle>
          </CModalHeader>
          <CForm onSubmit={addProcurementItem}>
            <CModalBody>
              <CRow>
                <CCol>
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Select Material <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormSelect
                      size="sm"
                      onChange={handleAddProcurementItemChanges}
                      name="material"
                      className="mb-3"
                    >
                      <option>Select Material</option>
                      {rawMaterialList &&
                        rawMaterialList.map((rm) => (
                          <option key={rm._id} value={rm._id}>
                            {rm.name}
                          </option>
                        ))}
                    </CFormSelect>
                  </div>
                </CCol>
                <CCol>
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Rate <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      min={0}
                      step="any"
                      type="number"
                      placeholder="In Rupees"
                      className="form-control no-arrows"
                      name="rate"
                      value={addProcurementItemDetails.rate}
                      onChange={handleAddProcurementItemChanges}
                    />
                  </div>
                </CCol>
                <CCol>
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Quantity <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      min={0}
                      step="any"
                      type="number"
                      placeholder="Quantity"
                      className="form-control no-arrows"
                      name="quantity"
                      value={addProcurementItemDetails.quantity}
                      onChange={handleAddProcurementItemChanges}
                    />
                  </div>
                </CCol>
              </CRow>

              <CRow>
                <CCol>
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Tax Percent <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      min={0}
                      type="number"
                      step="any"
                      placeholder="Tax Percent"
                      className="form-control no-arrows"
                      name="taxPercent"
                      value={addProcurementItemDetails.taxPercent}
                      disabled
                    />
                  </div>
                </CCol>
                <CCol>
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Taxable <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      min={0}
                      type="number"
                      step="any"
                      placeholder="In Rupees"
                      className="form-control no-arrows"
                      name="taxable"
                      // value={addProcurementItemDetails.taxable?.toFixed(2)}
                      value={parseFloat(addProcurementItemDetails.taxable)?.toFixed(2)}
                      disabled
                    />
                  </div>
                </CCol>
                <CCol>
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Tax Amount <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      min={0}
                      type="number"
                      step="any"
                      placeholder="In Rupees"
                      className="form-control no-arrows"
                      name="taxAmount"
                      value={parseFloat(addProcurementItemDetails.taxAmount)?.toFixed(2)}
                      disabled
                    />
                  </div>
                </CCol>
                <CCol>
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Grand Total <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      min={0}
                      type="number"
                      step="any"
                      placeholder="In Rupees"
                      className="form-control no-arrows"
                      name="grandTotal"
                      value={parseFloat(addProcurementItemDetails?.grandTotal).toFixed(2)}
                      disabled />
                  </div>
                </CCol>
              </CRow>
            </CModalBody>
            <CModalFooter>
              <PrimaryButton
                isDisable={
                  !addProcurementItemDetails.material ||
                  !addProcurementItemDetails.quantity ||
                  !addProcurementItemDetails.rate
                }
                title="ADD PROCUREMENT ITEM"
                isLoading={isAddItemBtnLoading}
              />
            </CModalFooter>
          </CForm>
        </CModal>
      ) : null}

      {openProcurementItemEditModal ? (
        <CModal
          size="lg"
          alignment="center"
          visible={openProcurementItemEditModal}
          onClose={() => setOpenProcurementItemEditModal(false)}
          aria-labelledby="VerticallyCenteredExample"
        >
          <CModalHeader>
            <CModalTitle id="VerticallyCenteredExample" className="fs-6">
              EDIT PROCUREMENT ITEM DETAILS
            </CModalTitle>
          </CModalHeader>
          <CForm onSubmit={handleEditProcurementItemSubmit}>
            <CModalBody>
              <CRow>
                <CCol className="col-md-6">
                  <CFormLabel>
                    Select Material <span className="text-danger">*</span>
                  </CFormLabel>
                  <div className="mb-3">
                    <CFormSelect
                      size="sm"
                      onChange={handleEditProcurementItemChange}
                      name="material"
                      className="mb-3"
                      value={editProcurementItemDetails.material}
                    >
                      <option>Select Material</option>
                      {rawMaterialList &&
                        rawMaterialList.map((rm) => (
                          <option key={rm._id} value={rm._id}>
                            {rm.name}
                          </option>
                        ))}
                    </CFormSelect>
                    <div className="text-danger">
                      {editProcurementItemValidationErrors.material}
                    </div>
                  </div>
                </CCol>

                <CCol>
                  <div className="mb-3">
                    <CFormLabel>
                      Rate <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      min={1}
                      type="number"
                      step="any"
                      placeholder="Rate"
                      className="form-control no-arrows"
                      name="rate"
                      value={editProcurementItemDetails.rate}
                      onChange={handleEditProcurementItemChange}
                    />
                    <div className="text-danger">
                      {editProcurementItemValidationErrors.rate}
                    </div>
                  </div>
                </CCol>
                <CCol>
                  <div className="mb-3">
                    <CFormLabel>
                      Quantity <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      min={1}
                      type="number"
                      step="any"
                      placeholder="Quantity"
                      className="form-control no-arrows"
                      name="quantity"
                      value={editProcurementItemDetails.quantity}
                      onChange={handleEditProcurementItemChange}
                    />
                    <div className="text-danger">
                      {editProcurementItemValidationErrors.quantity}
                    </div>
                  </div>
                </CCol>
              </CRow>
              <CRow>
                <CCol>
                  <CFormLabel>
                    Tax Percent <span className="text-danger">*</span>
                  </CFormLabel>
                  <div className="mb-3">
                    <CFormInput
                      min={0}
                      step="any"
                      type="number"
                      placeholder="Tax Percent"
                      className="form-control no-arrows"
                      name="taxPercent"
                      value={editProcurementItemDetails.taxPercent}
                      disabled
                    />
                  </div>
                </CCol>

                <CCol>
                  <div className="mb-3">
                    <CFormLabel>
                      Taxable <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      min={0}
                      step="any"
                      type="number"
                      placeholder="In Rupees"
                      className="form-control no-arrows"
                      name="taxable"
                      value={editProcurementItemDetails.taxable?.toFixed(2)}
                      disabled />
                  </div>
                </CCol>
                <CCol>
                  <div className="mb-3">
                    <CFormLabel>
                      Tax Amount <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      min={0}
                      step="any"
                      type="number"
                      placeholder="In Rupees"
                      className="form-control no-arrows"
                      name="taxAmount"
                      value={editProcurementItemDetails.taxAmount?.toFixed(2)}
                      disabled />
                  </div>
                </CCol>
                <CCol>
                  <div className="mb-3">
                    <CFormLabel>
                      Grand Total <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      min={0}
                      step="any"
                      type="number"
                      placeholder="In Rupees"
                      className="form-control no-arrows"
                      name="quantity"
                      value={editProcurementItemDetails.grandTotal?.toFixed(2)}
                      disabled
                    />
                  </div>
                </CCol>
              </CRow>
            </CModalBody>
            <CModalFooter>
              <PrimaryButton
                title="SAVE CHANGES"
                isLoading={isEditItemBtnLoading}
              />
            </CModalFooter>
          </CForm>
        </CModal>
      ) : null}

      {openProcurementItemDeleteModal ? (
        <CModal
          alignment="center"
          visible={openProcurementItemDeleteModal}
          onClose={() => setOpenProcurementDeleteModal(false)}
          aria-labelledby="VerticallyCenteredExample"
        >
          <CModalHeader>
            <CModalTitle id="VerticallyCenteredExample" className="fs-6">
              DELETE PROCUREMENT ITEM
            </CModalTitle>
          </CModalHeader>
          <CForm onSubmit={handleDeleteProcurementItems}>
            <CModalBody>
              Are you sure you want to delete this procurement Item ?
            </CModalBody>
            <CModalFooter>
              <CButton
                color="warning"
                onClick={() => setOpenProcurementDeleteModal(false)}
                style={{
                  background: "#ef9a9a",
                  outline: "none",
                  border: "none",
                }}
              >
                Close
              </CButton>
              <PrimaryButton
                title="CONFIRM"
                isLoading={isItemDeleteBtnLoading}
              />
            </CModalFooter>
          </CForm>
        </CModal>
      ) : null}

      {openUpdateStatusItemModal ? (
        <CModal
          alignment="center"
          visible={openUpdateStatusItemModal}
          onClose={() => setOpenUpdateStatusItemModal(false)}
          aria-labelledby="VerticallyCenteredExample"
        >
          <CModalHeader>
            <CModalTitle id="VerticallyCenteredExample" className="fs-6">
              UPDATE STATUS OF THE PROCUREMENT ITEM
            </CModalTitle>
          </CModalHeader>
          <CForm onSubmit={handleProcurementItemUpdateStatus}>
            <CModalBody>
              Are you sure you want to update the status of this procurement
              Item?
            </CModalBody>
            <CModalFooter>
              <CButton
                color="warning"
                onClick={() => setOpenUpdateStatusItemModal(false)}
                style={{
                  background: "#ef9a9a",
                  outline: "none",
                  border: "none",
                }}
              >
                Close
              </CButton>
              <PrimaryButton
                title="CONFIRM"
                isLoading={isItemStatusBtnLoading}
              />
            </CModalFooter>
          </CForm>
        </CModal>
      ) : null}

      {openEditStatusWithRemarksModal ? (
        <CModal
          alignment="center"
          visible={openEditStatusWithRemarksModal}
          onClose={() => setOpenUpdateStatusModal(false)}
          aria-labelledby="VerticallyCenteredExample"
        >
          <CModalHeader>
            <CModalTitle id="VerticallyCenteredExample" className="fs-6">
              UPDATE STATUS OF THE PROCUREMENT
            </CModalTitle>
          </CModalHeader>
          <CForm onSubmit={handleEditStatusWithRemarksSubmit}>
            <CModalBody>
              Are you sure you want to update the status of this procurement ?
              <div className="mb-3 mt-4">
                <CFormLabel>Remark</CFormLabel>
                <CFormTextarea
                  rows={4}
                  onChange={(e) => {
                    setRemark(e.target.value);
                    setValidateRemark("");
                  }}
                />
                <div className="text-danger">{validateRemark}</div>
              </div>
            </CModalBody>
            <CModalFooter>
              <CButton
                color="warning"
                onClick={() => setOpenUpdateStatusModal(false)}
                style={{
                  background: "#ef9a9a",
                  outline: "none",
                  border: "none",
                }}
              >
                Close
              </CButton>
              <PrimaryButton
                title="CONFIRM"
                isLoading={isEditwithRemarkBtnLoading}
              />
            </CModalFooter>
          </CForm>
        </CModal>
      ) : null}
    </CContainer>
  );
};

export default ProcurementCatalog;
