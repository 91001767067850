import CIcon from "@coreui/icons-react";
import {
  CContainer,
  CHeader,
  CHeaderBrand,
  CHeaderDivider,
  CHeaderNav,
  CHeaderToggler,
} from "@coreui/react";
import React from "react";
import { RiMenuFoldLine, RiMenuUnfoldLine } from "react-icons/ri";
import AppHeaderDropDown from "./AppHeaderDropDown";
import { useDispatch, useSelector } from "react-redux";
import AppBreadCrumb from "./AppBreadCrumb";

const AppHeader = () => {
  const dispatch = useDispatch();
  const sidebarShow = useSelector((state) => state.sidebarShow);

  const headerStyle = {
    transition: "height 0.3s ease",
  };
  return (
    <CHeader position="sticky" className="mb-4" style={headerStyle}>
      <CContainer fluid>
        <CHeaderToggler
          className="ps-1"
          onClick={() => dispatch({ type: "set", sidebarShow: !sidebarShow })}
        >
          {sidebarShow ? (
            <RiMenuFoldLine size={25} />
          ) : (
            <RiMenuUnfoldLine size={25} />
          )}
        </CHeaderToggler>
        <CHeaderBrand className="mx-auto d-md-none" to="/">
          <CIcon height={48} alt="Logo" />
        </CHeaderBrand>
        <CHeaderNav className="ms-3">
          <AppHeaderDropDown />
        </CHeaderNav>
      </CContainer>
      <CHeaderDivider />
      <CContainer fluid>
        <AppBreadCrumb />
      </CContainer>
    </CHeader>
  );
};

export default AppHeader;
