import React, { useEffect, useRef, useState } from "react";
import {
  CBadge,
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCardTitle,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CFormTextarea,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import axios from "../../api/axios";
import { toast } from "react-toastify";
import { DataTableComponent, PrimaryButton } from "../../components";
import { FaTrash, FaPencilAlt, FaEye, FaPlus } from "react-icons/fa";
import { isValidDescription, isValidName } from "../../utils/validation";
import { Modal } from "react-bootstrap";

const Category = () => {
  const accessToken = localStorage.getItem("authToken");
  const [inputData, setInputData] = useState({
    sequenceNumber: "",
    name: "",
    description: "",
    isEventCategory: "",
  });
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openImageUploadModal, setOpenImageUploadModal] = useState(false);
  const [openBannerImageUploadModal, setOpenBannerImageUploadModal] = useState(false);
  const [editedData, setEditedData] = useState({});
  const [categoryId, setCategoryId] = useState("");
  const [image, setImage] = useState(null);
  const [bannerImage, setBannerImage] = useState(null);
  const [imageId, setImageId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isEditBtnLoading, setIsEditBtnLoading] = useState(false);
  const [isDeleteBtnLoading, setIsDeleteBtnLoading] = useState(false);
  const [isImageEditBtnLoading, setIsImageEditBtnLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showImageModal, setShowImageModal] = useState(false);
  const [showBannerImageModal, setShowBannerImageModal] = useState(false);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [singleCategoryDetails, setSingleCategoryDetails] = useState({});
  const imageInputRef = useRef(null);
  const [addNewOpenModal, setAddNewOpenModal] = useState(false);
  const [pagination, setPagination] = useState({ page: 1, rowsPerPage: 10 });

  const handleImageClick = (row) => {
    setSelectedImage(row);
    setShowImageModal(true);
  };

  const handleBannerImageClick = (row) => {
    setSelectedImage(row);
    setShowBannerImageModal(true);
  };

  const handleCloseImageModal = () => {
    setShowImageModal(false);
  };

  const columns = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      cell: (row) => (
        <div className="fs-7 fw-semibold">{row.serialNumber ? row.serialNumber : "--------"}</div>
      ),
      width: "100px",
      center: true,
    },
    {
      name: "BANNER IMAGE",
      cell: (row) => (
        <div className="d-flex flex-column align-items-center gap-2">
          <img
            className="fs-7"
            style={{
              width: "100%",
              height: "100%",
              marginTop: "8px",
              marginBottom: '8px',
              border: "0.2px solid bluevoilet",
              cursor: "pointer",
              // marginRight: 
            }}
            src={row.bannerImageUrl || "/dummy-image.png"}
            alt="category-image"
            onClick={() => handleBannerImageClick(row)}
          />
        </div>
      ),
      width: "200px",
    },
    {
      name: "IMAGE",
      cell: (row) => (
        <div className="d-flex flex-column align-items-center gap-2">
          <img
            className="fs-7"
            style={{
              width: "80px",
              height: "80px",
              borderRadius: "50%",
              marginTop: "8px",
              marginBottom: "8px",
              border: "0.2px solid bluevoilet",
              cursor: "pointer",
            }}
            src={row.imageURL || "/dummy-image.png"}
            alt="category-image"
            onClick={() => handleImageClick(row)}
          />
        </div>
      ),
      width: "120px",
    },
    {
      name: "SEQ NO",
      selector: (row) => row.sequenceNumber,
      cell: (row) => (
        <div className="fs-7 fw-semibold">{row.sequenceNumber ? row.sequenceNumber : "--------"}</div>
      ),
      width: "110px",
    },
    {
      name: "NAME",
      selector: (row) => row.name,
      cell: (row) => (
        <div>
          <div className="fs-7 fw-semibold">{row.name ? row.name : "--------"}</div>
          <div className="fs-7" style={{ color: row.isEventCategory === true ? "chocolate" : "teal" }}>{row.isEventCategory === true ? "Event Outlet" : "Regular Outlet"}</div>
        </div>
      ),
      width: "160px",
    },
    {
      name: "STATUS",
      selector: (row) => row.name,
      cell: (row) => (
        <div className="fs-7 w-100">
          <CBadge className="w-100"
            size="sm"
            color={
              row?.status === "ACTIVE"
                ? "success"
                : row?.status === "INACTIVE"
                ? "danger"
                : "info"
            }
          >{row?.status ? row?.status : "------"}</CBadge>
        </div>
      ),
      width: "100px",
    },
    {
      name: "DESCRIPTION",
      selector: (row) => row.description,
      cell: (row) => (
        <div className="fs-7">
          {row.description ? row.description : "--------"}
        </div>
      ),
      width: "240px",
    },
    {
      name: "ACTIONS",
      right: "true",
      cell: (row) => (
        <div className="d-flex justify-content-around align-items-center">
          {/* <a
            href="#"
            className="text-info ms-1 me-1"
            style={{ cursor: "pointer", textDecoration: "none" }}
            onClick={() => handleOpenViewModal(row)}
          >
            <FaEye /> View
          </a>{" "} */}
          <a
            href="#"
            className="text-primary ms-1 me-1 "
            style={{ cursor: "pointer", textDecoration: "none" }}
            onClick={() => handleEditModal(row)}
          >
            <FaPencilAlt /> Edit
          </a>{" "}
          |
          <a
            href="#"
            className="text-danger ms-1 "
            style={{ cursor: "pointer", textDecoration: "none" }}
            onClick={() => handleDeleteModal(row)}
          >
            <FaTrash /> Delete
          </a>
        </div>
      ),
      minWidth: "160px",
    },
  ];

  const updatedColumns = columns.map((column) => ({
    ...column,
    name: (
      <div style={{ fontSize: "13px", fontWeight: "bold" }}>{column.name}</div>
    ),
  }));

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleImageChange = (e) => {
    const allowedExtensions = ["png", "jpg", "jpeg"];
    const selectedImage = e.target.files[0];

    if (selectedImage) {
      const extension = selectedImage.name.split(".").pop().toLowerCase();
      if (!allowedExtensions.includes(extension)) {
        toast.error("Invalid file format. Upload only jpg or png.", {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
        return;
      } else {
        setImage(selectedImage);
      }
    }
  };

  const handleBannerImageChange = () => {
    const input = document.getElementById("imageInput");

    const file = input.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        const image = new Image();
        image.src = reader.result;

        image.onload = () => {
          const width = image.width;
          const height = image.height;

          const maxWidth = 1000;
          const maxHeight = 200;

          if (width === maxWidth && height === maxHeight) {
            setBannerImage(file);
          } else {
            alert(
              "Image dimensions are not valid. Please choose an image with dimensions equal to " +
              maxWidth +
              "x" +
              maxHeight
            );
            input.value = "";
          }
        };
      };
      reader.readAsDataURL(file);
    }
  };

  const handleEditBannerImage = () => {
    const file = imageInputRef.current.files[0];

    if (file) {
      const reader = new FileReader();
      setBannerImage(file);

      // reader.onloadend = () => {
      //   const image = new Image();
      //   image.src = reader.result;

      //   image.onload = () => {
      //     const width = image.width;
      //     const height = image.height;

      //     const maxWidth = 300;
      //     const maxHeight = 60;

      //     if (width === maxWidth && height === maxHeight) {
      //       setBannerImage(file);
      //     } else {
      //       alert(
      //         "Image dimensions are not valid. Please choose an image with dimensions equal to " +
      //         maxWidth +
      //         "x" +
      //         maxHeight
      //       );
      //       imageInputRef.current.value = "";
      //     }
      //   };
      // };
      reader.readAsDataURL(file);
    }
  };

  const addCategory = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const formData = new FormData();
      formData.append("sequenceNumber", inputData.sequenceNumber);
      formData.append("name", inputData.name);
      formData.append("isEventCategory", inputData.isEventCategory);
      formData.append("description", inputData.description);
      formData.append("image", image);
      formData.append("bannerImage", bannerImage);

      const headers = {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${accessToken}`,
      };

      const response = await axios.post("masters/category/add", formData, {
        headers,
      });

      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
        e.target.reset();
        getCategoryList();

        setInputData({
          name: "",
          description: "",
        });
        setImage(null);
      }
    } catch (error) {      
      if (error?.response) {
        let errorMessage = '';
        if (
          error.response?.status === 406
        ) {
          errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
        }
        else if (
          error.response?.status === 412
        ) {
          errorMessage = error?.response?.data?.data?.message;
        } else if (error.response?.status === 500) {
          errorMessage = error?.response?.data?.error
        }else if(error?.response?.status === 404) {
          errorMessage = error?.response?.data?.error
        }

        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleImageUploadModal = (data) => {
    setImage(data?.displayImage);
    setImageId(data?._id);
    setOpenImageUploadModal(true);
    setShowImageModal(false);
  };

  const handleBannerImageUpload = (data) => {
    setOpenBannerImageUploadModal(true);
    setBannerImage(data?.bannerImage);
    setImageId(data?._id);
    setShowBannerImageModal(false);
  };

  const handleEditImage = async (e) => {
    e.preventDefault();
    setIsImageEditBtnLoading(true);
    try {
      const formData = new FormData();
      formData.append("image", image);
      formData.append("id", imageId);

      const headers = {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${accessToken}`,
      };

      const response = await axios.put(
        "masters/category/update-images",
        formData,
        { headers }
      );

      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
        getCategoryList();
        setOpenImageUploadModal(false);
      }
    } catch (error) {
      if (error.response.status === 404) {
        toast.error(error.response.data.error, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response?.data?.data?.message;

        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      setIsImageEditBtnLoading(false);
    }
  };

  const handleBannerEditImage = async (e) => {
    e.preventDefault();
    setIsImageEditBtnLoading(true);
    try {
      const formData = new FormData();
      formData.append("bannerImage", bannerImage);
      formData.append("id", imageId);

      const headers = {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${accessToken}`,
      };

      const response = await axios.put(
        "masters/category/update-images",
        formData,
        { headers }
      );

      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
        getCategoryList();
        setOpenBannerImageUploadModal(false);
      }
    } catch (error) {
      if (error.response.status === 404) {
        toast.error(error.response.data.error, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response?.data?.data?.message;

        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      setIsImageEditBtnLoading(false);
    }
  };

  const getCategoryList = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get("masters/category/get", { headers });

      if (response?.data?.status === true) {
        response?.data?.data?.categories.forEach((item, index) => {
          item.serialNumber = index + 1;
        });

        setCategoryList(response?.data?.data?.categories);
        setFilteredData(response?.data?.data?.categories);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const handleEditModal = async (data) => {
    setEditedData({
      id: data._id,
      sequenceNumber: data?.sequenceNumber,
      name: data?.name,
      status: data?.status,
      isEventCategory: data?.isEventCategory,
      description: data?.description,
    });
    setOpenEditModal(true);
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditedData((prevState) => ({ ...prevState, [name]: value }));
  };

  const editCategorySubmit = async (e) => {
    e.preventDefault();
    setIsEditBtnLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.put("masters/category/update", editedData, {
        headers,
      });
      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
        getCategoryList();
        setOpenEditModal(false);
      }
    } catch (error) {
      if (error?.response) {
        let errorMessage = '';
        if (
          error.response?.status === 406
        ) {
          errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
        }
        else if (
          error.response?.status === 412
        ) {
          errorMessage = error?.response?.data?.data?.message;
        } else if (error.response?.status === 500) {
          errorMessage = error?.response?.data?.error
        }

        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      setIsEditBtnLoading(false);
    }
  };

  const handleDeleteModal = async (data) => {
    setOpenDeleteModal(true);
    setCategoryId(data?._id);
  };

  const handleDeleteCategory = async (e) => {
    e.preventDefault();
    setIsDeleteBtnLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.delete(
        `masters/category/delete?id=${categoryId}`,
        { headers }
      );
      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
        getCategoryList();
        setOpenDeleteModal(false);
      }
    } catch (error) {
      if (error.response.status === 403) {
        toast.error(error?.response?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }

      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      setIsDeleteBtnLoading(false);
    }
  };

  const getSingleCategoryDetails = async (categoryId) => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get(
        `masters/category/get?id=${categoryId}`,
        {
          headers,
        }
      );

      if (response?.data?.status === true) {
        setSingleCategoryDetails(response?.data?.data?.categories);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const handleOpenViewModal = (data) => {
    getSingleCategoryDetails(data?._id);
    setOpenViewModal(true);
  };

  const handleAddNewOpenModal = () => {
    setAddNewOpenModal(true);
  };

  const handlePageChange = (page) => {
    setPagination({ ...pagination, page });
  };
  
  const handleRowsPerPageChange = (rowsPerPage) => {
    setPagination({ page: 1, rowsPerPage }); // Reset to the first page when changing rows per page
  };
  
  // Function to get paginated data
  const getPaginatedData = () => {
    const startIndex = (pagination.page - 1) * pagination.rowsPerPage;
    const endIndex = startIndex + pagination.rowsPerPage;
    return filteredData.slice(startIndex, endIndex);
  };

  useEffect(() => {
    getCategoryList();
  }, []);

  useEffect(() => {
    const result = categoryList.filter((item) => {
      return (
        item.name.toLowerCase().match(searchText.toLocaleLowerCase()) ||
        item.description.toLowerCase().match(searchText.toLocaleLowerCase())
      );
    });
    setFilteredData(result);
  }, [searchText]);

  return (
    <CContainer>
      <div className="mb-3 text-end">
        <button
          className="btn btn-warning"
          onClick={handleAddNewOpenModal}
          style={{ borderRadius: "18px" }}
        >
          ADD NEW CATEGORY <FaPlus style={{ marginTop: "-4px" }} size={15} />
        </button>
      </div>

      <div>
        <DataTableComponent
          columns={updatedColumns}
          title="CATEGORIES LIST"
          searchText={searchText}
          onChange={handleSearchChange}
          data={getPaginatedData()} // Use the paginated data here
          pagination
          totalRows={filteredData?.length}
          paginationTotalRows={filteredData.length} // Total number of items
          onChangeRowsPerPage={handleRowsPerPageChange}
          onChangePage={handlePageChange}
          paginationServer={false} // Set to false for client-side pagination
        />
      </div>

      {/*------------------ Show Image Modal -------------------*/}
      {showImageModal && (
        <Modal show={showImageModal} onHide={handleCloseImageModal}>
          <Modal.Header closeButton style={{ backgroundColor: "#F5F5F5" }}>
            <Modal.Title>Image Preview</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedImage ? (
              <>
                <img
                  src={selectedImage?.imageURL}
                  alt="preview"
                  style={{ width: "100%", height: "auto" }}
                />
                <button
                  className="btn btn-primary rounded-pill mt-2"
                  variant="primary"
                  onClick={() =>
                    handleImageUploadModal({
                      displayImage: selectedImage?.displayImage,
                      _id: selectedImage?._id,
                    })
                  }
                >
                  Change Image
                </button>
              </>
            ) : (
              <>
                <p>No Image Profile. Upload a Picture.</p>
                <button className="btn btn-primary rounded-pill mt-2">
                  Upload Image
                </button>
              </>
            )}
          </Modal.Body>
        </Modal>
      )}

      {showBannerImageModal && (
        <Modal
          show={showBannerImageModal}
          onHide={() => setShowBannerImageModal(false)}
        >
          <Modal.Header closeButton style={{ backgroundColor: "#F5F5F5" }}>
            <Modal.Title>Banner Image Preview</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedImage ? (
              <>
                <img
                  src={selectedImage?.bannerImageUrl}
                  alt="preview"
                  style={{ width: "100%", height: "auto" }}
                />
                <button
                  className="btn btn-primary rounded-pill mt-2"
                  variant="primary"
                  onClick={() =>
                    handleBannerImageUpload({
                      bannerImage: selectedImage?.bannerImage,
                      _id: selectedImage?._id,
                    })
                  }
                >
                  Change Image
                </button>
              </>
            ) : (
              <>
                <p>No Image Profile. Upload a Picture.</p>
                <button className="btn btn-primary rounded-pill mt-2">
                  Upload Image
                </button>
              </>
            )}
          </Modal.Body>
        </Modal>
      )}

      {openImageUploadModal ? (
        <CModal
          alignment="center"
          visible={openImageUploadModal}
          onClose={() => setOpenImageUploadModal(false)}
          aria-labelledby="VerticallyCenteredExample"
        >
          <CModalHeader>
            <CModalTitle id="VerticallyCenteredExample" className="fs-6">
              UPDATE IMAGE
            </CModalTitle>
          </CModalHeader>
          <CForm onSubmit={handleEditImage}>
            <CModalBody>
              <div className="mb-3">
                <CFormLabel>
                  Choose File <span className="text-danger">*</span>
                </CFormLabel>
                <CFormInput
                  type="file"
                  onChange={handleImageChange}
                  className="form-control"
                  id="formFile"
                />
              </div>
            </CModalBody>
            <CModalFooter>
              <PrimaryButton
                style={{ borderRadius: "18px" }}
                title="SAVE CHANGES"
                isLoading={isImageEditBtnLoading}
              />
            </CModalFooter>
          </CForm>
        </CModal>
      ) : null}

      {openBannerImageUploadModal ? (
        <CModal
          alignment="center"
          visible={openBannerImageUploadModal}
          onClose={() => setOpenBannerImageUploadModal(false)}
          aria-labelledby="VerticallyCenteredExample"
        >
          <CModalHeader>
            <CModalTitle id="VerticallyCenteredExample" className="fs-6">
              UPDATE BANNER IMAGE
            </CModalTitle>
          </CModalHeader>
          <CForm onSubmit={handleBannerEditImage}>
            <CModalBody>
              <div className="mb-3">
                <CFormLabel>
                  Choose banner image <span className="text-danger">*</span>
                </CFormLabel>
                <CFormInput
                  accept="image/*"
                  type="file"
                  onChange={handleEditBannerImage}
                  className="form-control"
                  ref={imageInputRef}
                />
                <span className="text-danger mt-3" style={{ fontSize: '13px' }}>Choose an image with dimensions equal to 1000x200</span>
              </div>
            </CModalBody>
            <CModalFooter>
              <PrimaryButton
                style={{ borderRadius: "18px" }}
                title="SAVE CHANGES"
              isLoading={isImageEditBtnLoading}
              />
            </CModalFooter>
          </CForm>
        </CModal>
      ) : null}

      {openEditModal ? (
        <CModal
          alignment="center"
          visible={openEditModal}
          onClose={() => setOpenEditModal(false)}
          aria-labelledby="VerticallyCenteredExample"
        >
          <CModalHeader>
            <CModalTitle id="VerticallyCenteredExample" className="fs-6">
              EDIT CATEGORY
            </CModalTitle>
          </CModalHeader>
          <CForm onSubmit={editCategorySubmit}>
            <CModalBody>
              <div className="mb-3">
                <CFormLabel className="fw-semibold">
                  Sequence Number <span className="text-danger">*</span>
                </CFormLabel>
                <CFormInput
                  type="number"
                  step="any"
                  id="exampleFormControlInput1"
                  placeholder="Sequence Number"
                  className="form-control"
                  name="sequenceNumber"
                  value={editedData?.sequenceNumber}
                  onChange={handleEditChange}
                />
              </div>

              <div className="mb-3">
                <CFormLabel className="fw-semibold">
                  Name <span className="text-danger">*</span>
                </CFormLabel>
                <CFormInput
                  type="text"
                  placeholder="Name"
                  className="form-control"
                  name="name"
                  value={editedData?.name}
                  onChange={handleEditChange}
                />
              </div>

              <div className="mb-3">
                <CFormLabel className='fw-semibold'>Specify Outlet Type <span className="text-danger">*</span></CFormLabel>
                <CFormSelect id='selectField' name="isEventCategory" value={editedData?.isEventCategory} onChange={handleEditChange}>
                    <option value="" disabled>Select for which outlet</option>
                    <option value="false">For Regular Outlet</option>
                    <option value="true">For Event Outlet</option>
                </CFormSelect>
              </div>

              <div className="mb-3">
                <CFormLabel className="fw-semibold">
                  Select Status <span className="text-danger">*</span>
                </CFormLabel>
                <CFormSelect
                  size="sm"
                  onChange={handleEditChange}
                  name="status"
                  className="mb-3"
                  value={editedData?.status}
                >
                  <option value="">Select Status</option>
                  <option value="ACTIVE">ACTIVE</option>
                  <option value="INACTIVE">INACTIVE</option>
                </CFormSelect>
              </div>

              <div className="mb-3">
                <CFormLabel className="fw-semibold">
                  Description <span className="text-danger">*</span>
                </CFormLabel>
                <CFormTextarea
                  rows={4}
                  type="text"
                  placeholder="Description"
                  className="form-control"
                  name="description"
                  value={editedData?.description}
                  onChange={handleEditChange}
                />
              </div>
            </CModalBody>
            <CModalFooter>
              <PrimaryButton
                title="SAVE CHANGES"
                isLoading={isEditBtnLoading}
              />
            </CModalFooter>
          </CForm>
        </CModal>
      ) : null}

      {openDeleteModal ? (
        <CModal
          alignment="center"
          visible={openDeleteModal}
          onClose={() => setOpenDeleteModal(false)}
          aria-labelledby="VerticallyCenteredExample"
        >
          <CModalHeader>
            <CModalTitle id="VerticallyCenteredExample" className="fs-6">
              DELETE CATEGORY
            </CModalTitle>
          </CModalHeader>
          <CForm onSubmit={handleDeleteCategory}>
            <CModalBody>
              Are you sure you want to delete this category ?
            </CModalBody>
            <CModalFooter>
              <CButton
                color="warning"
                onClick={() => setOpenDeleteModal(false)}
                style={{
                  borderRadius: "18px",
                }}
              >
                CLOSE
              </CButton>
              <PrimaryButton title="CONFIRM" isLoading={isDeleteBtnLoading} />
            </CModalFooter>
          </CForm>
        </CModal>
      ) : null}

      {/* {openViewModal && (
        <CModal
          size={
            singleCategoryDetails &&
              singleCategoryDetails.products &&
              singleCategoryDetails.products[0] &&
              Object.keys(singleCategoryDetails.products[0]).length > 0
              ? "xl"
              : "md"
          }
          alignment="center"
          visible={openViewModal}
          onClose={() => setOpenViewModal(false)}
          aria-labelledby="VerticallyCenteredExample"
        >
          <CModalHeader>
            <CModalTitle
              id="VerticallyCenteredExample"
              className="fs-7 fw-semibold"
            >
              VIEW CATEGORY DETAILS
            </CModalTitle>
          </CModalHeader>
          <CModalBody style={{ backgroundColor: "#f8f9fa" }}>
            <div>
              {singleCategoryDetails &&
                singleCategoryDetails.products &&
                singleCategoryDetails.products[0] &&
                Object.keys(singleCategoryDetails.products[0]).length > 0 ? (
                <CTable className="table table-bordered">
                  <CTableHead>
                    <CTableRow>
                      <CTableHeaderCell className="text-center" colSpan={7}>
                        PRODUCTS
                      </CTableHeaderCell>
                    </CTableRow>
                  </CTableHead>
                  <CTableBody>
                    <CTableRow>
                      <CTableHeaderCell className="text-center">
                        IMAGE
                      </CTableHeaderCell>
                      <CTableHeaderCell className="text-center">
                        PRODUCT SQ.NO
                      </CTableHeaderCell>
                      <CTableHeaderCell className="text-center">
                        NAME
                      </CTableHeaderCell>
                      <CTableHeaderCell className="text-center">
                        DESCRIPTION
                      </CTableHeaderCell>
                      <CTableHeaderCell className="text-center">
                        HSN
                      </CTableHeaderCell>
                      <CTableHeaderCell className="text-center">
                        MRP
                      </CTableHeaderCell>
                      <CTableHeaderCell className="text-center">
                        OFFER PRICE
                      </CTableHeaderCell>
                    </CTableRow>
                    {singleCategoryDetails.products.map((item) => (
                      <CTableRow>
                        <CTableDataCell className="text-center">
                          <img
                            src={item?.displayImageUrl}
                            alt="product-image"
                            style={{ width: "70px", height: "70px" }}
                          />
                        </CTableDataCell>
                        <CTableDataCell className="text-center">
                          {item.sequenceNumber}
                        </CTableDataCell>
                        <CTableDataCell className="text-center">
                          {item.name}
                        </CTableDataCell>
                        <CTableDataCell className="text-center">
                          {item.description}
                        </CTableDataCell>
                        <CTableDataCell className="text-center">
                          {item.hsn}
                        </CTableDataCell>
                        <CTableDataCell className="text-center">
                          {item.mrp}
                        </CTableDataCell>
                        <CTableDataCell className="text-center">
                          {item.offerPrice}
                        </CTableDataCell>
                      </CTableRow>
                    ))}
                  </CTableBody>
                </CTable>
              ) : (
                <p className="text-center">
                  This category does not contain any products.
                </p>
              )}
            </div>
          </CModalBody>
          <CModalFooter style={{ backgroundColor: "#f8f9fa" }}>
            <CButton
              style={{ borderRadius: "18px" }}
              className="btn btn-primary"
              onClick={() => setOpenViewModal(false)}
            >
              CLOSE
            </CButton>
          </CModalFooter>
        </CModal>
      )} */}

      {addNewOpenModal ? (
        <CModal
          size="lg"
          alignment="center"
          visible={addNewOpenModal}
          onClose={() => setAddNewOpenModal(false)}
          aria-labelledby="VerticallyCenteredExample"
        >
          <CModalHeader>
            <CModalTitle>ADD CATEGORY</CModalTitle>
          </CModalHeader>
          <CForm onSubmit={addCategory}>
            <CModalBody>
              <CRow>
              <CCol md="12" lg="5">
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Name <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      type="text"
                      id="exampleFormControlInput1"
                      placeholder="Name"
                      className="form-control"
                      name="name"
                      value={inputData.name}
                      onChange={handleChange}
                    />
                  </div>
                </CCol>
                <CCol md="12" lg="3">
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Sequence Number <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      type="number"
                      step="any"
                      id="exampleFormControlInput1"
                      placeholder="Sequence Number"
                      className="form-control"
                      name="sequenceNumber"
                      value={inputData?.sequenceNumber}
                      onChange={handleChange}
                    />
                  </div>
                </CCol>
                <CCol md={12} lg={4}>
                    <CFormLabel className='fw-semibold'>Specify Outlet Type</CFormLabel>
                    <CFormSelect id='selectField' name="isEventCategory" value={inputData?.isEventCategory} onChange={handleChange}>
                        <option value="" disabled>Select for which outlet</option>
                        <option value="false">For Regular Outlet</option>
                        <option value="true">For Event Outlet</option>
                    </CFormSelect>
                </CCol>
              </CRow>
              <CRow>
                <CCol md="12" lg="12">
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Description <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormTextarea
                      rows={2}
                      type="text"
                      placeholder="Description"
                      className="form-control"
                      name="description"
                      value={inputData.description}
                      onChange={handleChange}
                    />
                  </div>
                </CCol>
              </CRow>
              <CRow>
                <CCol md="12" lg="6">
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Choose Banner Image <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      id="imageInput"
                      accept="image/*"
                      type="file"
                      onChange={handleBannerImageChange}
                      className="form-control"
                    />
                    <span className="text-danger mt-3" style={{ fontSize: '13px' }}>Choose an image with dimensions equal to 1000x200</span>
                  </div>
                </CCol>
                <CCol md="12" lg="6">
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Choose Image <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      type="file"
                      onChange={handleImageChange}
                      className="form-control"
                      id="formFile"
                    />
                  </div>
                </CCol>
              </CRow>
            </CModalBody>
            <CModalFooter>
              <PrimaryButton
                // isDisable={disableButton()}
                title="ADD CATEGORY"
                isLoading={isLoading}
              />
            </CModalFooter>
          </CForm>
        </CModal>
      ) : null}

    </CContainer>

  );
};

export default Category;
