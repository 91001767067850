import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const useDynamicTitle = () => {
  const location = useLocation();

  useEffect(() => {
    const setTitle = () => {
      let title = "XPANSE Admin Panel";

      if (location.pathname === "/product-management/categories") {
        title = "XPANSE - Category";
      } else if (
        location.pathname === "/raw-materials-management/rm-main-category"
      ) {
        title = "XPANSE - RM-Main-Category";
      } else if (
        location.pathname === "/raw-materials-management/rm-sub-category"
      ) {
        title = "XPANSE - RM-Sub-Category";
      } else if (location.pathname === "/outlet-management/outlets") {
        title = "XPANSE - Outlets";
      } else if (location.pathname === "/vendors") {
        title = "XPANSE - Vendors";
      } else if (location.pathname === "/admin-roles") {
        title = "XPANSE - Admin-Roles";
      } else if (location.pathname === "/outlet-management/outletusers") {
        title = "XPANSE - Outlet-Users";
      } else if (
        location.pathname === "/raw-materials-management/rawmaterials"
      ) {
        title = "XPANSE - Add-Raw-Materials";
      } else if (location.pathname === "/product-management/add-product") {
        title = "XPANSE - Add-Products";
      } else if (location.pathname === "/product-management/products-catalog") {
        title = "XPANSE - Products-Catalog";
      } else if (location.pathname === "/dashboard") {
        title = "XPANSE - Dashboard";
      } else if (location.pathname === "/product-management/product-addon") {
        title = "XPANSE - Product-Addon";
      } else if (location.pathname === "/central-inventory/add-procurement") {
        title = "XPANSE - Add-Procurement";
      } else if (location.pathname === "/central-inventory/procurements-list") {
        title = "XPANSE - Procurements-List";
      } else if (location.pathname === "/central-inventory/stock-transfer") {
        title = "XPANSE - Stock-Transfer";
      } else if (location.pathname === "/central-inventory/rm-stock-reports") {
        title = "XPANSE - RM Stock-Reports";
      }  else if (location.pathname === "/coupons/add") {
        title = "XPANSE - Coupon-Add";
      } else if (location.pathname === "/coupons/view") {
        title = "XPANSE - Coupon-View";
      } 
      document.title = title;
    };

    setTitle();
  }, [location.pathname]);
};

export default useDynamicTitle;
